import { DASHBOARD_VERSION, isDev } from '@/config';
import { store } from '@/store';
import { PageView } from '@/store/page-view';
import { LanguageSelection } from '@/web/@components/LanguageSelection';
import { LogoImage } from '@/web/@components/LogoImage';
import { ScrollShadowBox } from '@/web/@components/ScrollShadowBox';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { TOOLBAR_HEIGHT } from '../config';
import { SIDEBAR_ITEMS } from './sidebar-items';
import { SidebarTree } from './SidebarTree';

export function Sidebar(props) {
  const { isCollapsed } = props;

  const toggleSidebar = () => {
    store.dispatch(PageView.toggleSidebarVisibility());
  };

  return (
    <Box position="relative" height="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent={{ xs: 'flex-start', lg: 'center' }}
        height={`${TOOLBAR_HEIGHT}px`}
      >
        <LogoImage to="/" sx={{ display: { xs: 'none', lg: 'block' } }} />
        <IconButton
          onClick={toggleSidebar}
          color="primary"
          sx={{
            display: { lg: 'none' },
            bgcolor: '#f2f7fd',
            ml: '8px',
          }}
        >
          <MenuIcon style={{ fontSize: '24px' }} />
        </IconButton>
      </Box>

      <Divider sx={{ display: { lg: 'none' } }} />

      <ScrollShadowBox
        height={`calc(100% - ${isCollapsed && isDev ? '150px' : '110px'})`}
        mt="11px"
      >
        <SidebarTree items={SIDEBAR_ITEMS} level={0} />
      </ScrollShadowBox>

      <Box
        px={2}
        py={0.5}
        gap="5px"
        display={isCollapsed ? 'row' : 'flex'}
        alignItems="center"
        justifyContent="space-between"
        style={{ opacity: 0.65 }}
      >
        <Typography variant="caption">v{DASHBOARD_VERSION}</Typography>

        {isDev && (
          <Box px="5px" color="#fff" fontSize="10px" bgcolor="#336" borderRadius="4px">
            dev
          </Box>
        )}

        <LanguageSelection />
      </Box>
    </Box>
  );
}
