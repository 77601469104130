import { isDev } from '@/config';
import { formatTimestamp } from '@/utils/datetime';
import { DriverScoreCircleSvg } from '@/web/@components/DriverScoreCircleSvg';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton, MobileStepper, Typography, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';

/** @param {{ driverTripWithScore: Array<DriverTripWithScore> }} props */
export function TripDriverScoreInfo(props) {
  const { driverTripWithScore } = props;

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = driverTripWithScore?.length || 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const trip = useMemo(() => driverTripWithScore[activeStep], [activeStep, driverTripWithScore]);

  if (!trip || !isDev) {
    return null;
  }

  return (
    <Box
      sx={{
        minWidth: '250px',
        bgcolor: '#ffffffee',
        userSelect: 'none',
        boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
        padding: '10px',
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb="10px">
        <Box fontWeight="600" fontSize="14px">
          Drivers Score
        </Box>
        <MobileStepper
          sx={{ fontSize: '0.75rem', height: '24px', p: 0 }}
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <IconButton
              size="small"
              sx={{ m: 0, p: 0 }}
              disabled={activeStep === maxSteps - 1}
              onClick={activeStep === maxSteps - 1 ? undefined : handleNext}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
          }
          backButton={
            <IconButton
              size="small"
              sx={{ m: 0, p: 0 }}
              disabled={activeStep === 0}
              onClick={activeStep === 0 ? undefined : handleBack}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
          }
        />
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection={'column'} gap="4px">
          <Box>
            <Typography variant="subtitle2" fontSize="0.625rem" lineHeight={'12px'}>
              Driver Name
            </Typography>
            <Typography fontSize="0.825rem" variant="subtitle1">
              {trip?.driverName}
            </Typography>
          </Box>

          {trip?.startTimestamp && (
            <Box>
              <Typography variant="subtitle2" fontSize="0.625rem" lineHeight={'12px'}>
                Login
              </Typography>
              <Typography fontSize="0.75rem" variant="subtitle1">
                {formatTimestamp(trip.startTimestamp || 1)}
              </Typography>
            </Box>
          )}

          {trip?.endTimestamp && (
            <Box>
              <Typography variant="subtitle2" fontSize="0.625rem" lineHeight={'12px'}>
                Logout
              </Typography>
              <Typography fontSize="0.75rem" variant="subtitle1">
                {formatTimestamp(trip.endTimestamp || 1)}
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Box display="grid" justifyItems="center">
            <Box width="40px" height="40px" position="relative">
              <DriverScoreCircleSvg value={trip?.score / 100} />
              <Typography
                position="absolute"
                left={5}
                right={5}
                top={5}
                bottom={0}
                textAlign={'center'}
                borderRadius="50%"
                lineHeight={trip?.score ? '28px' : '20px'}
                fontWeight={600}
                fontSize={trip?.score > 99 ? '10px' : '12px'}
              >
                {Math.floor(trip?.score) || '...'}
              </Typography>
            </Box>
            <Typography variant="subtitle2" fontSize="10px">
              Trip Score
            </Typography>
          </Box>
          <Box display="grid" justifyItems="center">
            <Box width="40px" height="40px" position="relative">
              <DriverScoreCircleSvg value={(trip?.driverGlobalScore || 0) / 100} />
              <Typography
                position="absolute"
                left={5}
                right={5}
                top={5}
                bottom={0}
                textAlign={'center'}
                borderRadius="50%"
                lineHeight={trip?.driverGlobalScore ? '28px' : '20px'}
                fontWeight={600}
                fontSize={trip?.driverGlobalScore > 99 ? '10px' : '12px'}
              >
                {Math.floor(trip?.driverGlobalScore) || '...'}
              </Typography>
            </Box>
            <Typography variant="subtitle2" fontSize="10px">
              Global Score
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
