import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { BoxImage } from '@/web/@components/BoxImage';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function TripReportButton() {
  const navigate = useNavigate();

  /** @type {import('react').MouseEventHandler<HTMLElement> } */
  const handleClick = (e) => {
    const from = startOfTheDay();
    const to = endOfTheDay();
    const url = `/trips/reports?from=${from}&to=${to}`;
    if (e.ctrlKey || e.metaKey) {
      window.open(url);
    } else {
      navigate(url);
    }
  };

  return (
    <Button variant="outlined" onClick={handleClick} sx={{ gap: '5px' }}>
      <BoxImage src={'/images/pages/trips/report-icon-2.svg'} size="16px" />
      Reports
      <div />
      <BoxImage src={'/images/pages/trips/arrow-medium.svg'} size="16px" />
    </Button>
  );
}
