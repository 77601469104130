/**
 * @param {AssignedHealthTagResponse} tag
 * @param {number} groupId
 */
export function makeCTALink(tag, groupId) {
  switch (tag.tagName) {
    case 'Events.None':
    case 'Events.NoSnapshot':
    case 'Events.NoVideo':
      return `/cameras/${tag.endpointId}/events`;
    case 'Imager.Cabin.Blue':
    case 'Imager.Road.Blue':
      return `cameras/live?id=${tag.endpointId}`;
    case 'Internet.NoData':
    case 'Internet.RateLimited':
    case 'Internet.OutOfRange':
      return `cameras/${tag.endpointId}`;
    case 'Camera.SD.Absent':
    case 'Camera.SD.Corrupt':
      return `/administration/device-groups/${groupId}/cameras/${tag.endpointId}/sensors`;
    case 'Internet.SIM.Absent':
    case 'Internet.SIM.Removed':
      return `/administration/device-groups/${groupId}/cameras/${tag.endpointId}/usages`;
    case 'LMK.Updater':
      return `/administration/device-groups/${groupId}/cameras/${tag.endpointId}/ota-logs`;
    case 'LMK.Camera':
    case 'Power.Cut':
    case 'Power.Loose':
    case 'Camera.Unstable':
    case 'Camera.Offline.30d':
    case 'Camera.Offline.3d+':
    case 'Cover.Removed':
      return `/administration/device-groups/${groupId}/cameras/${tag.endpointId}/diagnostics-logs`;
    case 'Vision.Error':
      return `/administration/device-groups/${groupId}/cameras/${tag.endpointId}/diagnostics-logs?type=Vision`;
    case 'Trip.GPS.None':
    case 'Trip.GPS.Partial':
    case 'Trip.NoEnd':
    case 'Trip.Short':
    case 'Trip.Thumbnail.None':
    case 'Trip.Thumbnail.Partial':
    case 'Trip.TooLong':
      // return `cameras/${tag.resourceId}`; // trip
      return undefined; // trip details
    case 'Imager.Cabin.Adjust':
    case 'Imager.Cabin.Inoperable':
    case 'Imager.Cabin.Obstructed':
    case 'Imager.Road.Adjust':
    case 'Event.GPS.None':
    case 'Event.GPS.Partial':
      // return `/events/play?id=${...}`;
      return undefined; // event player
    default:
      return undefined;
  }
}
