import { formatDateTimeWeek, timeFromNow } from '@/utils/datetime';
import { PageLink } from '@/web/@components/PageLink';
import { Close } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { TAG_TITLES } from '../../mapping';
import { makeCTALink } from './utils';

/**
 * @typedef {object} HealthTagItemProps
 * @property {AssignedHealthTagResponse} tag
 * @property {number} groupId
 * @property {boolean} [loading]
 * @property {() => Promise} onRemove
 */

/** @param {{loading: boolean, tag: AssignedHealthTagResponse, groupId: number, onRemove: () => Promise}} props */
export function HealthTagItem(props) {
  const { tag, loading, groupId, onRemove } = props;

  const [ctaLink, setCtaLink] = useState('');

  useEffect(() => {
    if (!tag) return undefined;
    setCtaLink(makeCTALink(tag, groupId));
  }, [tag, groupId]);

  return (
    <Box
      py="8px"
      pr="8px"
      position="relative"
      sx={{
        '.hover': {
          visibility: 'hidden',
        },
        ':hover .hover': {
          visibility: 'visible',
        },
      }}
    >
      <PageLink
        to={ctaLink}
        component={ctaLink ? undefined : 'div'}
        underline={ctaLink ? undefined : 'none'}
        style={{
          cursor: ctaLink ? undefined : 'default',
          padding: '2px 5px',
          background: '#fff',
          borderRadius: '4px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: ctaLink ? undefined : '#011e42',
        }}
        title={
          TAG_TITLES[tag.tagName] +
          '\n' +
          formatDateTimeWeek(tag.reportedTimestamp) +
          '\n' +
          timeFromNow(tag.reportedTimestamp, { addSuffix: true })
        }
      >
        {loading && <CircularProgress size="10px" sx={{ mr: '5px' }} />}
        {tag.tagName}
      </PageLink>
      {!loading && (
        <IconButton
          onClick={onRemove}
          className="hover"
          style={{
            position: 'absolute',
            top: '2px',
            right: '2px',
            minWidth: 0,
            width: '14px',
            height: '14px',
            backgroundColor: '#fff',
          }}
        >
          <Close sx={{ fontSize: '16px' }} />
        </IconButton>
      )}
    </Box>
  );
}
