import { GOOGLE_MAP_API_KEY, GOOGLE_MAP_ID } from '@/config';
import { CenterBox } from '@/web/@components/CenterBox';
import { Box, Card, CircularProgress } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

/**
 * @typedef {object} GeofenceMapProps
 * @property {MapAutocompletePlaceData} [searchedLocation]
 * @property {boolean} [disabled]
 * @property {(data: boolean) => any} [onGeofenceLoaded]
 */

/**
 * @param {GeofenceMapProps} props
 */

export function GeofenceMap(props) {
  const { getValues, setValue } = useFormContext();

  const { searchedLocation, disabled, onGeofenceLoaded } = props;

  const params = useParams();
  const geofenceId = useMemo(() => params.id, [params]);

  const latitude = getValues('latitude');
  const longitude = getValues('longitude');
  const locationRadius = getValues('radius');

  const [loading, setLoading] = useState(true);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [location, setLocation] = useState(null);
  const [radius, setRadius] = useState(100);
  const [mapCircle, setMapCircle] = useState(null);
  const [googleObj, setGoogleObj] = useState(null);

  useEffect(() => {
    if (geofenceId && !location) return;
    if (navigator.geolocation) {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      function success(pos) {
        const crd = pos.coords;
        setDefaultLocation({ lat: crd.latitude, lng: crd.longitude });
        setLoading(false);
      }
      function error(err) {
        setDefaultLocation({ lat: 10.99835602, lng: 77.01502627 });
        setLoading(false);
      }
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      setDefaultLocation({ lat: 10.99835602, lng: 77.01502627 });
      setLoading(false);
    }
  }, [geofenceId, getValues, location]);

  useEffect(() => {
    if (!latitude || !longitude) return;
    setDefaultLocation({
      lat: latitude,
      lng: longitude,
    });
    setRadius(locationRadius);
    setLoading(false);
  }, [latitude, longitude, locationRadius]);

  const handleApiLoaded = ({ map, maps }) => {
    setGoogleObj({ map, maps });
    const circle = new maps.Circle({
      strokeColor: '#3B7CD6',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#3B7CD6',
      fillOpacity: 0.3,
      map,
      center: location || defaultLocation,
      radius: radius,
      draggable: !disabled,
      editable: !disabled,
    });

    circle?.addListener('center_changed', () => {
      const loc = circle?.getCenter().toJSON();
      setValue('latitude', loc.lat);
      setValue('longitude', loc.lng);
    });

    circle?.addListener('radius_changed', () => {
      const rad = circle?.getRadius();
      setValue('radius', rad);
    });

    map?.fitBounds(circle.getBounds());
    setMapCircle(circle);

    onGeofenceLoaded(true);

    return () => circle?.setMap(null);
  };

  useEffect(() => {
    if (!searchedLocation) return;
    const { lat, lng, types } = searchedLocation;
    const isPremise = types?.includes('premise');
    let radius = isPremise ? 500 : 2000;
    setRadius(radius);
    setLocation({ lat, lng });
    mapCircle?.setCenter(new googleObj.maps.LatLng(lat, lng));
    googleObj?.map?.fitBounds(mapCircle.getBounds());
  }, [searchedLocation, mapCircle, googleObj, setValue]);

  useEffect(() => {
    if (!location) return;
    setValue('radius', radius);
  }, [location, radius, setValue]);

  useEffect(() => {
    if (!defaultLocation) return;
    if (location) return;
    setValue('latitude', defaultLocation.lat);
    setValue('longitude', defaultLocation.lng);
    setValue('radius', radius);
  }, [location, defaultLocation, radius, setValue]);

  if (!radius || loading) {
    return (
      <CenterBox>
        <CircularProgress></CircularProgress>
      </CenterBox>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <Card sx={{ zIndex: 1, height: 'calc(100vh - 160px)' }}>
        <GoogleMapReact
          defaultZoom={10}
          defaultCenter={defaultLocation}
          center={location || defaultLocation}
          onGoogleApiLoaded={handleApiLoaded}
          yesIWantToUseGoogleMapApiInternals={true}
          bootstrapURLKeys={{
            key: GOOGLE_MAP_API_KEY,
          }}
          options={{
            mapTypeControl: true,
            mapId: GOOGLE_MAP_ID,
          }}
        />
      </Card>
    </Box>
  );
}
