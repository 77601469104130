import { Box, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { SafetyScoreLayoutContext } from '../../@layouts/SafetyScoreLayout';
import { ScoreCircularChart } from '../ScoreCircularChart';

/**
 * @typedef {object} ScoreByPersonaWidgetProps
 * @property {number} [score]
 * @property {number} [prevScore]
 * @property {number} [personaId]
 * @property {boolean} [isSelected]
 */

/**
 * @param {ScoreByPersonaWidgetProps} props
 */
export function ScoreByPersonaWidget(props) {
  const { score = 0, prevScore = 0, personaId, isSelected } = props;

  const { personaList } = useContext(SafetyScoreLayoutContext);

  const personaName = useMemo(() => {
    return personaList?.find((persona) => persona?.id === personaId)?.name;
  }, [personaId, personaList]);

  const gridHeight = useMemo(() => (personaList?.length > 4 ? 145 : 300), [personaList]);

  if (!personaName) return null;

  return (
    <Box
      display="grid"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      justifyItems="center"
      sx={{
        maxHeight: gridHeight,
        border: '1px solid #E2EDFC',
        borderRadius: '7px',
        backgroundColor: isSelected ? '#f5f9ff' : 'none',
      }}
    >
      <ScoreCircularChart value={Math.round(score)} prevScore={prevScore} height="85%" />
      <Typography variant="body1" fontWeight={500} mt={-1} mb={1}>
        {personaName || '...'}
      </Typography>
    </Box>
  );
}
