import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProductForm } from '../../@components/ProductForm';
import { ProductDetailsContext } from '../@components/ProductDetailsLayout';

export function EditProductPage() {
  const navigate = useNavigate();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { product } = useContext(ProductDetailsContext);

  const [submitting, setSubmitting] = useState(false);

  /** @type {import('../../@components/ProductForm').ProductFormProps['onSubmit']} */
  const handleSubmit = async (form) => {
    const request = api.ac.v1.tenant.product.$put({
      params: {
        tenantId,
        secretToken,
        productId: product.productId,
        forceUpdate: false,
      },
      data: {
        ownerTenantId: tenantId,
        inputs: form.inputSources,
        firmwareTypes: form.firmwareTypes,
        aiAccelerators: [form.aiAccelerator?.codeName || 'NONE'],
        assignedTenants: product.assignedTenants,
        properties: [
          {
            key: 'productId',
            value: product.productId,
          },
          {
            key: 'productName',
            value: form.name + '',
          },
          {
            key: 'serial',
            value: form.serial + '',
          },
          {
            key: 'cpu',
            value: form.cpu || '',
          },
          {
            key: 'gpu',
            value: form.gpu || '',
          },
          {
            key: 'npu',
            value: form.npu || '',
          },
          {
            key: 'ram',
            value: form.ram || '',
          },
          {
            key: 'storage',
            value: form.storage || '',
          },
          {
            key: 'wifi',
            value: form.wifi + '',
          },
          {
            key: 'bluetooth',
            value: form.bluetooth + '',
          },
          {
            key: 'ethernet',
            value: form.ethernet + '',
          },
          {
            key: 'cellular',
            value: form.cellular + '',
          },
          {
            key: 'website',
            value: form.website || '',
          },
          {
            key: 'manual',
            value: form.manual || '',
          },
          {
            key: 'description',
            value: form.description || '',
          },
        ],
      },
    });
    try {
      setSubmitting(true);
      await request.process();
      toastSuccess('Success', 'Product updated');
      navigate('../..');
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Could not update the product');
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    return () => navigate('/smarter-ai/products');
  }, [tenantId, navigate]);

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Edit Product
      </Typography>
      <ProductForm onSubmit={handleSubmit} disabled={submitting} product={product} />
    </Box>
  );
}
