import { FilterContextProvider } from '@/web/@components/FilterContext';
import { Box } from '@mui/material';
import { CoachableEventsFilteringArea } from '../@components/CoachableEvantsFilteringArea';
import { CoachableEventList } from '../@components/CoachableEventList';
import { CoachingFilterContextProvider } from '../@context/CoachingFilterContext';

export function CoachableEventsPage() {
  return (
    <FilterContextProvider>
      <CoachingFilterContextProvider>
        <Box m={2}>
          <CoachableEventsFilteringArea />
        </Box>
        <CoachableEventList />
      </CoachingFilterContextProvider>
    </FilterContextProvider>
  );
}
