import { TRIGGER_CHIME_ICON, TRIGGER_VOICE_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Popover, Typography } from '@mui/material';
import { useState } from 'react';

export function CabinDataInfo(props) {
  const { isChimeEnabled, isTtsEnabled, tts = '', chime = '' } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverType, setPopoverType] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverType(null);
  };

  const open = Boolean(anchorEl);

  return !isChimeEnabled && !isTtsEnabled ? (
    <Typography fontSize="0.75rem">N/A</Typography>
  ) : (
    <Box
      gap={0}
      display={'inline-flex'}
      textAlign="center"
      aria-haspopup="true"
      alignItems="center"
    >
      {isChimeEnabled && (
        <Box
          display={'inline-flex'}
          gap={'2px'}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          fontSize={{ sx: '1rem', md: '0.875rem' }}
          alignItems="center"
          onMouseEnter={(event) => {
            handlePopoverOpen(event);
            setPopoverType('CHIME');
          }}
          onMouseLeave={handlePopoverClose}
        >
          <BoxImage src={TRIGGER_CHIME_ICON} size="16px" />
          <Typography fontSize={{ sx: '1rem', md: '0.875rem' }}>Chime</Typography>
        </Box>
      )}

      {isChimeEnabled && isTtsEnabled && <Box pr={1}>,</Box>}

      {isTtsEnabled && (
        <Box
          display={'inline-flex'}
          sx={{ alignItems: 'flex-start' }}
          gap={'3px'}
          fontSize={{ sx: '1rem', md: '0.875rem' }}
          alignItems="center"
          aria-owns={open ? 'mouse-over-popover' : undefined}
          onMouseEnter={(event) => {
            handlePopoverOpen(event);
            setPopoverType('TTS');
          }}
          onMouseLeave={handlePopoverClose}
        >
          <BoxImage src={TRIGGER_VOICE_ICON} size="15px" />
          <Typography fontSize={{ sx: '1rem', md: '0.875rem' }}>Voice</Typography>
        </Box>
      )}

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(open)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box m={1} display="grid">
          <Box display="flex" textAlign="center" alignItems="center">
            <Box
              display={'inline-flex'}
              sx={{ alignItems: 'flex-start' }}
              gap={'3px'}
              fontSize={{ sx: '1rem', md: '0.875rem' }}
              alignItems="center"
            >
              {popoverType === 'TTS' ? (
                <>
                  <BoxImage src={TRIGGER_VOICE_ICON} size="15px" />
                  <Typography variant="caption">Voice: {tts || '...'}</Typography>
                </>
              ) : (
                <>
                  <BoxImage src={TRIGGER_CHIME_ICON} size="15px" />
                  <Typography variant="caption">Chime: {chime || '...'}</Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
