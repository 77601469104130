import { SCHEDULE_TIMEZONES } from '@/assets/operations/geofences';
import { convertTimeToSeconds, humanReadableTimeDiff } from '@/utils/geofence';
import { toastWarning } from '@/utils/toaster';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Hidden,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GeofenceDateRangePicker } from '../GeofenceDateTimeSelection';

/**
 * @typedef {object} GeoFenceSchedulePickerProps
 * @property {() => any} onHide
 */

/**
 * @param {GeoFenceSchedulePickerProps} props
 */
export function GeoFenceSchedulePicker(props) {
  const { onHide } = props;

  const [loading, setLoading] = useState(false);

  const { watch, setValue } = useFormContext();

  const diffInText = watch('diffInText');
  const startTimestamp = watch('startTimestamp');
  const endTimestamp = watch('endTimestamp');
  const dayTimeStart = watch('daySecondStart');
  const dayTimeEnd = watch('daySecondEnd');

  const closeModal = () => {
    onHide && onHide();
  };

  const handleScheduleSubmit = async (e) => {
    setLoading(true);
    const startTimeSec = convertTimeToSeconds(dayTimeStart);
    const endTimeSec = convertTimeToSeconds(dayTimeEnd);
    if (!startTimestamp || !endTimestamp) {
      toastWarning('Please select a date range');
      setLoading(false);
      return;
    }
    if (endTimeSec < startTimeSec) {
      toastWarning('End time can not be smaller than start time.');
      setLoading(false);
      return;
    }
    if (endTimeSec - startTimeSec <= 58) {
      toastWarning('Start time and end time can not be the same.');
      setLoading(false);
      return;
    }

    closeModal();
  };

  useEffect(() => {
    if (!endTimestamp || !dayTimeEnd) return;
    const diffInText = humanReadableTimeDiff(
      startTimestamp,
      endTimestamp,
      convertTimeToSeconds(dayTimeStart),
      convertTimeToSeconds(dayTimeEnd)
    );
    setValue('diffInText', diffInText);
  }, [dayTimeStart, dayTimeEnd, startTimestamp, endTimestamp, setValue]);

  return (
    <Dialog open={true} fullWidth maxWidth="lg" onClose={closeModal}>
      <DialogContent>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
          <Box width={{ sx: '80%', md: '60%' }}>
            <GeofenceDateRangePicker />
          </Box>
          <Hidden smDown>
            <Divider orientation="vertical" flexItem sx={{ mx: '40px' }}></Divider>
          </Hidden>
          <Hidden mdUp>
            <Divider orientation="horizontal" flexItem sx={{ my: '10px' }}></Divider>
          </Hidden>
          <Box width={{ sx: '100%', md: '30%' }}>
            <CustomSelect
              name="timezone"
              label="Timezone"
              size="small"
              options={SCHEDULE_TIMEZONES}
              placeholder="Select Timezone"
              AutocompleteProps={{
                disableClearable: true,
              }}
            />

            <CustomInput
              name="daySecondStart"
              label="From"
              size="small"
              placeholder="Select a time"
              TextFieldProps={{
                type: 'time',
              }}
            />
            <CustomInput
              name="daySecondEnd"
              label="To"
              size="small"
              placeholder="Select a time"
              TextFieldProps={{
                type: 'time',
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ gap: '20px' }}>
        {diffInText && (
          <Box display="inline-flex" gap="10px">
            <Typography variant="body2">Scheduled: </Typography>
            <Typography variant="body1">{diffInText}</Typography>
          </Box>
        )}

        <Button type="button" variant="text" onClick={closeModal} size="small">
          Cancel
        </Button>

        <Button
          onClick={handleScheduleSubmit}
          disabled={loading}
          variant="contained"
          type="submit"
          size="small"
        >
          {loading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
