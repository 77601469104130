import { Box } from '@mui/material';
import TriggerTable from './@components/TriggerTable';
import { TriggerConfigurationProvider } from './@context/TriggerConfigurationContext';

export function TriggerConfiguration() {
  return (
    <Box p={2.5}>
      <TriggerConfigurationProvider>
        <TriggerTable />
      </TriggerConfigurationProvider>
    </Box>
  );
}
