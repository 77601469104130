import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { ItemsListView } from '@/web/@components/ItemsListView';
import { ItemsTableView } from '@/web/@components/ItemsTableView';
import { Box, CircularProgress, Hidden } from '@mui/material';
import { useContext } from 'react';
import { TripReportContext } from '../TripReportContext';
import { TRIP_TABLE_COLUMNS } from './columns';

export function TripTableView() {
  const { loading, results } = useContext(TripReportContext);

  if (loading || !results) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (!results?.length) {
    return (
      <CenterBox fullView>
        <IconMessageBox
          size="256px"
          src="/images/pages/trips/no-trips-icon.svg"
          message="No Trips available for the selected dates"
        />
      </CenterBox>
    );
  }

  return (
    <Box mt={2} mb={5}>
      <Hidden mdDown>
        <ItemsTableView data={results} columns={TRIP_TABLE_COLUMNS} />
      </Hidden>
      <Hidden mdUp>
        <ItemsListView data={results} columns={TRIP_TABLE_COLUMNS} />
      </Hidden>
    </Box>
  );
}
