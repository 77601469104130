import { Box } from '@mui/material';
import { useMemo } from 'react';

/**
 * @typedef {object} TriggerStreakProps
 * @property {number} value
 * @property {number} min
 * @property {number} max
 */

/**
 * @param {TriggerStreakProps} props
 */
export function TriggerStreak(props) {
  const { value, min, max } = props;

  const midValue = useMemo(() => (max + min) / 2, [max, min]);

  const colors = ['#FA8282', '#EFDB8E', '#95E895', '#ECF0F4'];
  //red, yellow, green, shadow

  return (
    <Box display={'flex'} gap={2}>
      <Box
        height="10px"
        width="108px"
        sx={{ backgroundColor: value < min ? colors[0] : colors[3], borderRadius: '9px' }}
      />
      <Box
        height="10px"
        width="108px"
        sx={{
          backgroundColor: value >= min && value < midValue ? colors[1] : colors[3],
          borderRadius: '9px',
        }}
      />
      <Box
        height="10px"
        width="108px"
        sx={{ backgroundColor: value >= midValue ? colors[2] : colors[3], borderRadius: '9px' }}
      />
    </Box>
  );
}
