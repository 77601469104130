import { CircleBox } from '@/web/@components/CircleBox';
import { Box, IconButton } from '@mui/material';
import { TripListViewItemHeader } from '../TripListViewItem/Header';
import { TripListViewItemStartAddress } from '../TripListViewItem/StartAddress';

/**
 * @typedef {object} TripStartPopperContentProps
 * @property {Trip} item
 * @property {import('react').EventHandler<import('react').SyntheticEvent<HTMLElement>>} onClose
 */

/** @param {TripStartPopperContentProps} props */
export function TripStartPopperContent(props) {
  const { item, onClose } = props;

  return (
    <Box pb={1} position="relative" maxWidth="300px">
      <TripListViewItemHeader item={item} />
      <TripListViewItemStartAddress item={item} />
      <IconButton size="small" onClick={onClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
        <CircleBox size="16px" fontSize="1.25rem" bgcolor="transparent">
          &times;
        </CircleBox>
      </IconButton>
    </Box>
  );
}
