import {
  BOX_CREATE_ICON,
  BOX_CUSTOMER_ICON,
  BOX_LIST_ICON,
  BOX_SOFTWARE_ICON,
  NAV_CONFIGURATION_ICON_ON,
  NAV_GALLERY_ICON_ON,
  NAV_REPORT_ICON_ON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';

/** @type {Array<PageCardItem>} */
export const WINTER_OPERATION_ITEMS = [
  {
    name: T['winter-operations.gallery.page.card.name'],
    image: NAV_GALLERY_ICON_ON,
    color: '#EFF3F7',
    link: '/winter-operations/gallery',
  },
  {
    name: T['winter-operations.configurations.page.card.name'],
    image: NAV_CONFIGURATION_ICON_ON,
    color: '#F7F5EF',
    link: '/winter-operations/configurations',
    components: [
      {
        name: T['winter-operations.configurations.page.card.customer'],
        image: BOX_CUSTOMER_ICON,
        link: '',
      },
      {
        name: T['winter-operations.configurations.page.card.softwareConf'],
        image: BOX_SOFTWARE_ICON,
        link: '',
      },
    ],
  },
  {
    name: T['winter-operations.reports.page.card.name'],
    image: NAV_REPORT_ICON_ON,
    color: '#F4F7EF',
    link: '/winter-operations/reports',
    components: [
      {
        name: T['winter-operations.reports.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '',
      },
      {
        name: T['winter-operations.reports.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '',
      },
    ],
  },
];
