import { T } from '@/assets/locales';
import { SchemeFormLayout } from '@/web/@layouts/SchemeLayout';
import { CreateScheme } from '@/web/smarter-ai/scheme-management/schemes/create';
import { SchemeListView } from '.';
import { SchemeDetails } from './_id';
import { UpdateScheme } from './_id/edit';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <SchemeListView />,
  },
  {
    path: 'create',
    breadcrumb: 'Create Scheme',
    element: <SchemeFormLayout />,
    children: [
      {
        path: '',
        element: <CreateScheme />,
        breadcrumb: T['scheme.create.page.title'],
      },
    ],
  },
  {
    path: ':id',
    breadcrumb: 'Loading...',
    element: <SchemeFormLayout />,
    children: [
      {
        path: '',
        element: <SchemeDetails />,
        breadcrumb: 'Details',
      },
      {
        path: 'edit',
        element: <UpdateScheme />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
