import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { CircularProgress, Paper } from '@mui/material';
import { createContext, useState } from 'react';
import { CenterBox } from '../CenterBox';
import { IconMessageBox } from '../IconMessageBox';
import { InfiniteScrollListView } from './InfiniteScrollListView';
import { InfiniteScrollLoadNext } from './InfiniteScrollLoadNext';

/**
 * @template T
 * @type {import('react').Context<InfiniteListDataFetcherState<T>>}
 */
export const InfiniteScrollViewContext = createContext(null);

/**
 * @template T
 * @param {InfiniteScrollViewProps<T>} props
 */
export function InfiniteScrollView(props) {
  const {
    fetcher,
    initialToken,
    itemsPerPage = 20,
    fullView = true,
    mergeResults = (prev, cur) => [...prev, ...cur],
    renderEmpty = () => <IconMessageBox message="Empty list" />,
    renderFailure = () => (
      <IconMessageBox
        size="256px"
        src={NO_SEARCH_RESULT_IMG}
        message="Sorry! Could not fetch data"
      />
    ),
    renderList = (state) => (
      <InfiniteScrollListView
        state={state}
        renderItem={(item) => (
          <Paper elevation={1} sx={{ p: 1, my: 1 }}>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(item, null, 2)}</pre>
          </Paper>
        )}
      />
    ),
  } = props;

  /** @type {StateVariable<AbortController>} */
  const [loading, setLoading] = useState(null);
  /** @type {StateVariable<any>} */
  const [token, setToken] = useState(initialToken);
  /** @type {StateVariable<boolean>} */
  const [finished, setFinished] = useState(false);
  /** @type {StateVariable<Array<T>>} */
  const [results, setResults] = useState([]);
  /** @type {StateVariable<Error>} */
  const [error, setError] = useState(null);

  const loadNext = async () => {
    if (finished) return;
    const aborter = new AbortController();
    try {
      setError(null);
      setLoading((prev) => {
        prev?.abort();
        return aborter;
      });
      const {
        result,
        token: nextToken,
        resultLengthBeforeFilter,
      } = await fetcher({
        token,
        limit: itemsPerPage,
        signal: aborter.signal,
      });
      setToken(nextToken);
      setResults((prev) => mergeResults(prev, result));
      setFinished(
        (resultLengthBeforeFilter ? resultLengthBeforeFilter : result.length) < itemsPerPage ||
          !nextToken ||
          nextToken === token ||
          nextToken === initialToken
      );
    } catch (err) {
      setError(err);
      console.error('Could not fetch data', loadNext);
    } finally {
      setLoading(null);
    }
  };

  /** @type {InfiniteListDataFetcherState<any>} */
  const state = {
    error,
    token,
    loading: Boolean(loading),
    finished,
    itemsPerPage,
    loadNext,
    results,
  };

  return (
    <InfiniteScrollViewContext.Provider value={state}>
      {!results?.length ? (
        <CenterBox fullView={fullView}>
          <InfiniteScrollLoadNext>
            {loading ? <CircularProgress /> : error ? renderFailure(state) : renderEmpty()}
          </InfiniteScrollLoadNext>
        </CenterBox>
      ) : (
        renderList(state)
      )}
    </InfiniteScrollViewContext.Provider>
  );
}
