import { endOfTheDay, minusOneMonth } from '@/utils/datetime';
import { trimString } from '@/utils/formatting';
import qs from 'qs';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * @typedef {object} FilterParams
 * @property {string} [tripId]
 * @property {number} [startTime]
 * @property {number} [endTime]
 * @property {string} [driverId]
 * @property {string} [driverName]
 * @property {string} [endpointId]
 * @property {string} [endpointName]
 * @property {string} [tenantId]
 */

/** @type {FilterParams} */
const defaultValues = {
  tripId: null,
  startTime: minusOneMonth(),
  endTime: endOfTheDay(),
  driverId: null,
  driverName: null,
  endpointId: null,
  endpointName: null,
};

const paramAlias = {
  tripId: 'id',
  endTime: 'to',
  startTime: 'from',
};

/** @type {import("react").Context<FilterParams>} */
export const TripFilterContext = createContext({});

/** @type {import("react").Context<(params: FilterParams, replace?: boolean) => any>} */
export const SetTripFilterContext = createContext(null);

/** @param {{children: import("react").ReactNode}} props */
function SetTripFilterContextProvider(props) {
  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();

  /**
   * @type {(data: {[key: string]: any}, replace?: boolean) => any}
   */
  const setTripFilter = useCallback(
    (data, replace = false) => {
      let params = {};
      for (const key of Object.keys(data)) {
        if (paramAlias.hasOwnProperty(key)) {
          params[paramAlias[key]] = data[key];
        } else if (defaultValues.hasOwnProperty(key)) {
          params[key] = data[key];
        }
      }

      const query = qs.parse(location.search.substring(1));
      if (!replace) {
        params = { ...query, ...params };
      }

      for (const key of Object.keys(params)) {
        if (key in params) {
          if (!params[key]) delete params[key];
          else params[key] = trimString(params[key]);
        }
      }

      if (
        JSON.stringify(Object.entries(query).sort()) ===
        JSON.stringify(Object.entries(params).sort())
      ) {
        return;
      }

      navigate(
        {
          ...location,
          search: qs.stringify(params),
        },
        { replace: false }
      );
    },
    [location, navigate]
  );

  return (
    <SetTripFilterContext.Provider value={setTripFilter}>{children}</SetTripFilterContext.Provider>
  );
}

/** @param {{children: import("react").ReactNode, defaults?: FilterParams}} props */
export function TripFilterContextProvider(props) {
  const { children, defaults = defaultValues } = props;

  const location = useLocation();

  /** @type {StateVariable<FilterParams>} */
  const [value, setValue] = useState(defaults);

  const query = useMemo(() => qs.parse(location.search.substring(1)), [location.search]);

  /** @type {(value: any, defaultValue: string) => string} */
  const toString = (value, defaultValue) => (value || defaultValue || '') + '';

  /** @type {(value: any, defaultValue: number) => number} */
  const toNumber = (value, defaultValue) => Number(value) || defaultValue || 0;

  useEffect(() => {
    const startTime = toNumber(query?.from, defaults.startTime);
    setValue((v) => (v.startTime === startTime ? v : { ...v, startTime }));
  }, [query?.from, defaults]);

  useEffect(() => {
    const endTime = toNumber(query?.to, defaults.endTime);
    setValue((v) => (v.endTime === endTime ? v : { ...v, endTime }));
  }, [query?.to, defaults]);

  useEffect(() => {
    const tripId = toString(query?.id, defaults.tripId);
    setValue((v) => (v.tripId === tripId ? v : { ...v, tripId }));
  }, [query?.id, defaults]);

  useEffect(() => {
    const driverId = toString(query?.driverId, defaults.driverId);
    setValue((v) => (v.driverId === driverId ? v : { ...v, driverId }));
  }, [query?.driverId, defaults]);

  useEffect(() => {
    const driverName = toString(query.driverName, defaults.driverName);
    setValue((v) => (v.driverName === driverName ? v : { ...v, driverName }));
  }, [query?.driverName, defaults]);

  useEffect(() => {
    const endpointId = toString(query.endpointId, defaults.endpointId);
    setValue((v) => (v.endpointId === endpointId ? v : { ...v, endpointId }));
  }, [query?.endpointId, defaults]);

  useEffect(() => {
    const endpointName = toString(query.endpointName, defaults.endpointName);
    setValue((v) => (v.endpointName === endpointName ? v : { ...v, endpointName }));
  }, [query?.endpointName, defaults]);

  return (
    <SetTripFilterContextProvider>
      <TripFilterContext.Provider value={value}>{children}</TripFilterContext.Provider>
    </SetTripFilterContextProvider>
  );
}
