import { useDashcamPlayer } from '@/hooks/useDashcamPlayer';
import { store } from '@/store';
import {
  selectIsSupport,
  selectRefreshToken,
  selectSecretToken,
  selectTenantId,
} from '@/store/auth';
import { PageView } from '@/store/page-view';
import { SmartCache } from '@/utils/caching/smart-cache';
import { formatTriggerName } from '@/utils/events';
import { toastSuccess } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { HubConnectionProvider } from '@/web/@components/HubConnectionContext';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CoachingDriverDetailsContext } from '../../@context/DriverDetailsContext';
import { CoachingCommentArea } from './CoachingCommentArea';
import { CoachingPlayerEvents } from './CoachingPlayerEvents';

/** @type {SmartCache<{endpointId: number, tenantId: string, triggerName: string}>} */
const eventCache = new SmartCache('event-player-info-cache', 365 * 24 * 3600 * 1000);

export function GuidedCoachingPage() {
  /** @type {import('react').Ref<HTMLDivElement>} */
  const parentRef = useRef();
  const navigate = useNavigate();
  const player = useDashcamPlayer();
  const { setBreadcrumbTitle } = useContext(MainContext);
  const { selectedEvent, markVisited } = useContext(CoachingDriverDetailsContext);

  const tenantId = useSelector(selectTenantId);
  const support = useSelector(selectIsSupport);
  const accessToken = useSelector(selectSecretToken);
  const refreshToken = useSelector(selectRefreshToken);
  const endpointId = useMemo(() => selectedEvent?.endpointId, [selectedEvent]);
  const triggerName = useMemo(() => selectedEvent?.triggerName, [selectedEvent]);

  useEffect(() => {
    if (!selectedEvent) return;
    eventCache.setItem(selectedEvent.id, { tenantId, endpointId, triggerName });
  }, [selectedEvent, tenantId, endpointId, triggerName]);

  useEffect(() => {
    if (!triggerName) return;
    setBreadcrumbTitle(formatTriggerName(triggerName));
  }, [triggerName, setBreadcrumbTitle]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('tenant-id', tenantId);
    if (support) {
      player.setAttribute('support', 'on');
    } else {
      player.removeAttribute('support');
    }
    player.setAttribute('refresh-token', refreshToken);
    player.setAttribute('access-token', accessToken);
  }, [player, support, tenantId, refreshToken, accessToken]);

  useEffect(() => {
    if (!player || !selectedEvent) return;
    player.setAttribute('event-id', selectedEvent.id + '');
  }, [player, selectedEvent]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('show-trigger-report', 'on');
    player.setAttribute('custom-report-area', 'on');
  }, [player]);

  useEffect(() => {
    if (!player) return;
    const aborter = new AbortController();
    player.addEventListener(
      'watch-complete',
      () => markVisited(player.getAttribute('event-id')),
      aborter
    );
    return () => aborter.abort();
  }, [player, markVisited]);

  useEffect(() => {
    if (!player || !selectedEvent) return;
    parentRef?.current?.replaceChildren(player);
    store.dispatch(PageView.setHideToolbar(true));
    store.dispatch(PageView.setFullWidthLayout(true));
  }, [selectedEvent, refreshToken, accessToken, player]);

  const handleFinishWatching = () => {
    toastSuccess('Success', 'Coaching successfully completed.');
    navigate('/safety/coaching');
  };

  return (
    <>
      {player && <CoachingCommentArea player={player} onFinish={handleFinishWatching} />}
      {selectedEvent && player && (
        <HubConnectionProvider>
          <CoachingPlayerEvents player={player} />
        </HubConnectionProvider>
      )}
      <Box ref={parentRef} width="100%" height="100%" p={1}>
        <CenterBox>
          <CircularProgress />
          <Typography variant="subtitle2" fontSize="12px" mt={1}>
            Verifying access
          </Typography>
        </CenterBox>
      </Box>
    </>
  );
}
