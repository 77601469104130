import { Box } from '@mui/material';
import { styled } from '@mui/system';

const MiniLineChartContainer = styled('div')({
  width: '100%',
  height: '40px',
  position: 'relative',
  overflow: 'hidden',
});

const Line = styled('path')(({ color }) => ({
  fill: 'none',
  stroke: color,
  strokeWidth: '4px',
}));

const Node = styled('circle')(({ color }) => ({
  fill: color,
  stroke: 'white', // border color
  strokeWidth: '1', // border width
  r: '5',
}));

const YAxisLine = styled('line')({
  stroke: '#DFE8F4',
  strokeWidth: '1px',
});

const MiniLineChart = ({ data, defaultValues, min, max, aggregatedValue, chartWidth = 200 }) => {
  const calculatePath = (data, width, height) => {
    const maxY = max || Math.max(...data);
    const minY = min || Math.min(...data);
    const scaleY = height / (maxY - minY);

    const points = data.map((value, index) => ({
      x: (index * width) / (data.length - 1),
      y: height - (value - minY) * scaleY,
    }));

    const path = points.map((point, index) => {
      if (index === 0) {
        return `M${point.x},${point.y}`;
      }
      return `L${point.x},${point.y}`;
    });

    return path.join(' ');
  };

  const drawYAxes = (minY, maxY, height) => {
    const interval = (maxY - minY) / 3;

    return Array.from({ length: 4 }).map((_, axisIndex) => {
      const yAxisValue = minY + axisIndex * interval;
      const y = height - ((yAxisValue - minY) / (maxY - minY)) * height;

      return <YAxisLine key={axisIndex} x1="0" y1={y} x2={chartWidth} y2={y} />;
    });
  };

  const maxY = max || Math.max(...data);
  const minY = min || Math.min(...data);

  const maxDY = max || Math.max(...defaultValues);
  const minDY = min || Math.min(...defaultValues);

  // Add a margin of 10px to the viewBox
  const viewBoxMargin = 10;
  const viewBoxWidth = chartWidth - 2 * viewBoxMargin;
  const viewBoxHeight = 75 - 2 * viewBoxMargin;

  return (
    <Box>
      <MiniLineChartContainer>
        <svg
          width="100%"
          height="100%"
          viewBox={`${-viewBoxMargin} ${-viewBoxMargin} ${viewBoxWidth + 2 * viewBoxMargin} ${
            viewBoxHeight + 2 * viewBoxMargin
          }`}
        >
          {/* Ensure that the entire content fits within the viewBox */}
          {drawYAxes(Math.min(minY, minDY), Math.max(maxY, maxDY), viewBoxHeight)}

          {/* DEFAULT CHART */}
          <Line color={'#b7c4d5'} d={calculatePath(defaultValues, viewBoxWidth, viewBoxHeight)} />
          {defaultValues.map((value, index) => (
            <Node
              key={index}
              color={'#b7c4d5'}
              cx={(index * viewBoxWidth) / (defaultValues.length - 1)}
              cy={viewBoxHeight - ((value - minDY) / (maxDY - minDY)) * viewBoxHeight}
            />
          ))}

          {/* CURRENT VALUES */}
          <Line color={'#2196f3'} d={calculatePath(data, viewBoxWidth, viewBoxHeight)} />
          {data.map((value, index) => (
            <Node
              key={index}
              color={'#2196f3'}
              cx={(index * viewBoxWidth) / (data.length - 1)}
              cy={viewBoxHeight - ((value - minY) / (maxY - minY)) * viewBoxHeight}
            />
          ))}
        </svg>
      </MiniLineChartContainer>
    </Box>
  );
};

export default MiniLineChart;
