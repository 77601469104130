import { EllipsisTextViewContainer } from '@/web/@components/EllipsisTextView';
import { PageLink } from '@/web/@components/PageLink';
import { Typography } from '@mui/material';

/** @type {Array<TableColumn<{[key: string]: any}>>} */
export const TRIP_TABLE_COLUMNS = [
  {
    id: 'Camera Name',
    label: 'Camera Name',
    align: 'left',
    isPrimary: true,
    format: (value, row) => (
      <EllipsisTextViewContainer>
        <PageLink to={`/cameras/${row.trip.endpointId}`}>{value}</PageLink>
      </EllipsisTextViewContainer>
    ),
  },
  {
    id: 'Start Location',
    label: 'Start Location',
    align: 'left',
    width: '175px',
    format: (value) => (
      <EllipsisTextViewContainer>
        <Typography variant="subtitle1">{value}</Typography>
      </EllipsisTextViewContainer>
    ),
  },
  {
    id: 'Start Time',
    label: 'Start Time',
    align: 'left',
    minWidth: '175px',
  },
  {
    id: 'End Location',
    label: 'End Location',
    align: 'left',
    width: '175px',
    format: (value) => (
      <EllipsisTextViewContainer>
        <Typography variant="subtitle1">{value}</Typography>
      </EllipsisTextViewContainer>
    ),
  },
  {
    id: 'End Time',
    label: 'End Time',
    align: 'left',
    minWidth: '175px',
  },
  {
    id: 'Distance',
    label: 'Distance',
    align: 'left',
    minWidth: '100px',
  },
  {
    id: 'Duration',
    label: 'Duration',
    align: 'left',
    minWidth: '120px',
  },
];
