import api from '@/api';
import { parseJwtToken } from '@/utils/crypto';
import { toastWarning } from '@/utils/toaster';
import { Auth, selectGeoTabTenant, selectTenant } from '..';

/**
 * Login to a new session
 * @param {AccountLoginRequest} data
 * @returns {StoreAction<Promise>}
 */
export const doLogin = (data) => async (dispatch) => {
  const request = api.ac.v5.auth.login.$post({ data });
  await request.process();
  const result = request.result;
  const tenantList = result.tenantAccessList || [];
  dispatch(Auth.setRequest(data));
  if (result.tenantAccessList.length === 1) {
    const action = selectTenant({
      tenantId: tenantList[0].tenantId,
      token: result.refreshToken,
      email: result.email,
    });
    await dispatch(action);
  }
  dispatch(Auth.setAccount(result));
  dispatch(getAllDescendants()).catch(console.error);
};

/**
 * Refresh all descendants of the current logged in user
 * @returns {StoreAction<Promise>}
 */
export const getAllDescendants = () => async (dispatch, getState) => {
  const state = getState();
  const request = api.ac.v5.auth['tenant-access'].batch.$post({
    data: state.auth.tenantList
      .filter((x) => x.userRole === 'SUPER_ADMIN' || x.userRole === 'SUPPORT')
      .map((x) => ({
        tenantId: x.tenantId,
        email: state.auth.email,
        token: state.auth.accessToken || state.auth.accountToken,
      })),
  });
  const result = await request.process();
  if (result.errors?.length) {
    console.error('Failed to acquire some access tokens', result.errors);
  }
  for (const token of result.accessTokenResponses || []) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    const request = api.ac.v5.tenant.descendants.$get({
      headers: {
        Authorization: token.accessToken,
      },
    });
    const result = await request.process();
    result.tenantId = token.tenantId;
    result.tenantName = state.auth.tenantIdToName[token.tenantId];
    dispatch(Auth.setTenantTree(result));
  }
};

/**
 * Get GeoTab seesion Data
 * @param {*} data
 */
const getGeoTabSessios = async (data) => {
  try {
    const { database, password, username } = data;
    const response = await fetch('https://my.geotab.com/apiv1', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'Authenticate',
        params: {
          userName: username,
          password,
          database,
        },
      }),
    });

    const result = await response?.json();
    if (result?.error) {
      toastWarning('Error', result?.error?.message);
      return false;
    }
    return result?.result;
  } catch (ex) {
    console.log('data', ex);
    console.error('GeoTab Session API failed');
  }
};

/**
 * @typedef {object} LoginWithGeoTabData
 * @property {string} [database]
 * @property {string} [username]
 * @property {string} [password]
 * @property {string} [geotabUrl]
 * @property {string} [authenticationType]
 */

/**
 * Get GeoTab Access Data by using session id
 * @param {LoginWithGeoTabData} data
 * @param {string} sessionId
 * @param {string} geotabUrl
 */
const getGeoTabAccessToken = async (data, sessionId, geotabUrl) => {
  try {
    const { database, username, geotabUrl } = data;

    var parser = new URL(geotabUrl);
    var domain = parser?.hostname || 'my.geotab.com';

    const response = await fetch(
      'https://camera-services.geotab.com/api/smarterai/authenticate/access-token',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-MyGeotab-Database': database,
          'X-MyGeotab-Path': domain,
          'X-MyGeotab-SessionId': sessionId,
          'X-MyGeotab-Username': username,
        },
      }
    );

    const result = await response?.json();
    if (result?.error) {
      toastWarning('Error', result?.error?.message || 'Failed to login.');
      return false;
    }
    return result;
  } catch (ex) {
    toastWarning('Failed to login.');
    console.error('GeoTab Access API failed');
  }
};

/**
 * Login by GeoTab
 * @param {LoginWithGeoTabData} data
 */
export const doLoginGeotab = (data) => async (dispatch) => {
  try {
    dispatch(Auth.setRequest(data));

    const result = await getGeoTabSessios(data);
    if (!result) return;
    const sesssionId = result?.credentials?.sessionId;
    const accessTokenresult = await getGeoTabAccessToken(data, sesssionId, data?.geotabUrl);
    const action = selectGeoTabTenant({ tokenData: accessTokenresult });
    await dispatch(action);

    const parsedData = parseJwtToken(accessTokenresult?.accessToken);
    const res = {
      accountId: parsedData?.account_id,
      email: data?.username,
      refreshToken: accessTokenresult?.accessToken,
      teamList: [],
      isGeoTabLogin: true,
      tenantAccessList: [
        {
          tenantId: accessTokenresult?.tenantId,
          tenantName: 'Geotab', //TODO:: IF service/geotab provide access then we'll replace it
          userRole: parsedData?.role,
        },
      ],
    };
    dispatch(Auth.setAccount(res));
  } catch (error) {
    console.error(error);
  }
};
