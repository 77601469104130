import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export function GuidePowerAndInterfacesTable() {
  return (
    <Box width="100%" style={{ overflowX: 'auto' }}>
      <Table padding="none" sx={{ '& .MuiTableCell-root': { px: 2, py: 1 } }}>
        <TableHead
          sx={{ '& .MuiTableCell-root': { color: '#fff', fontWeight: '600', border: 'none' } }}
        >
          <TableRow style={{ background: '#596A81' }}>
            <TableCell colSpan={2}>Power & Interfaces</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-root': { border: '1px solid #DCE7F5', borderTop: 'none' },
          }}
        >
          <TableRow>
            <TableCell width="30%">Operating Voltage</TableCell>
            <TableCell>10 - 32 VDC</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ACC/Ignition Voltage</TableCell>
            <TableCell>7 - 32 VDC</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Power Consumption</TableCell>
            <TableCell>Max 6W (12V/500mA) </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Parking Mode Power Consumption</TableCell>
            <TableCell>50mW (12V/4.2mA)</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
