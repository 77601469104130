import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { store } from '@/store';
import {
  Auth,
  selectSupportPath,
  selectTenant,
  selectTenantList,
  selectTenantTree,
} from '@/store/auth';
import { SentryEvents, reportEvent } from '@/utils/sentry';
import { toastWarning } from '@/utils/toaster';
import { Box, MenuItem } from '@mui/material';
import { sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CenterBox } from '../CenterBox';
import { IconMessageBox } from '../IconMessageBox';
import { SearchField } from '../SearchField';

/**
 * @typedef {object} SwitchTenantProps
 * @property {() => any} [onSwitch]
 */

/** @param {SwitchTenantProps} props */
export function SwitchTenant(props) {
  const { onSwitch } = props;

  const tenantList = useSelector(selectTenantList);
  const tenantTree = useSelector(selectTenantTree);
  const supportPath = useSelector(selectSupportPath);

  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);

  /** @type {DescendantTenant[]} */
  const filteredTenantTree = useMemo(() => {
    /** @param {DescendantTenant[]} tree */
    const applyFilter = (tree) => {
      if (!tree?.length) return undefined;
      let tenants = [];
      for (const tenant of tree) {
        if (!tenant) continue;
        const child = applyFilter(tenant.descendantTenantList);
        if (filter && !tenant.tenantName?.toLowerCase()?.includes(filter) && !child?.length) {
          continue;
        }
        tenants.push({
          ...tenant,
          descendantTenantList: child,
        });
      }
      return sortBy(tenants, 'tenantName');
    };
    return applyFilter(tenantList.map((x) => (tenantTree && tenantTree[x.tenantId]) || x));
  }, [tenantList, tenantTree, filter]);

  /**
   * @param {string[]} treePath
   */
  const handleTenantChange = async (treePath) => {
    if (!treePath?.length) return;
    setLoading(true);
    const support = treePath.length > 1;
    const tenantId = treePath[treePath.length - 1];
    try {
      if (onSwitch) onSwitch();
      await store.dispatch(selectTenant({ tenantId, support: treePath }));
    } catch (err) {
      console.error('Tenant switch failed', err);
      if ([400, 403].includes(err?.response?.status)) {
        store.dispatch(Auth.logout());
        return;
      }
      toastWarning('Failed to switch tenant');
      reportEvent(SentryEvents.TENANT_SWITCH_FAILED, '', {
        err,
        support,
        tenantId,
        tags: {
          selected: treePath,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {(tenantList && tenantList.length > 1) || Object.keys(tenantTree).length ? (
        <Box sx={{ bgcolor: '#F6F9FE' }} style={{ padding: '10px 16px' }}>
          <SearchField
            clearable
            ignoreCase
            value={filter}
            onSearch={setFilter}
            placeholder="Search Tenant"
            sx={{ width: '100%' }}
          />
        </Box>
      ) : null}
      {filteredTenantTree.length ? (
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {filteredTenantTree.map((tenant) => (
            <TenantTreeItem
              key={tenant.tenantId}
              tree={tenant}
              filter={filter}
              selected={supportPath || []}
              onSelect={loading ? undefined : handleTenantChange}
            />
          ))}
        </div>
      ) : (
        <CenterBox>
          <IconMessageBox
            src={NO_SEARCH_RESULT_IMG}
            gap={0}
            size="56px"
            message="No search results"
          />
        </CenterBox>
      )}
    </>
  );
}

/**
 * @typedef {object} TenantTreeItemProps
 * @property {number} [indent]
 * @property {string} [filter]
 * @property {string[]} selected
 * @property {DescendantTenant} tree
 * @property {(path: string[]) => any} [onSelect]
 */

/** @param {TenantTreeItemProps} props */
export function TenantTreeItem(props) {
  const { indent = 0, tree, filter, selected, onSelect } = props;
  const [collapsed, setCollapsed] = useState(true);

  const leaf = !tree.descendantTenantList?.length;
  const active = selected?.length === 1 && selected[0] === tree.tenantId;

  useEffect(() => {
    setCollapsed(!filter?.length && (!selected?.length || tree.tenantId !== selected[0]));
  }, [tree.tenantId, filter, selected]);

  const nameParts = useMemo(() => {
    const name = tree.tenantName || '';
    if (!filter?.length) {
      return [name];
    }
    let offset = 0;
    const parts = [];
    for (const part of name.toLowerCase().split(filter)) {
      parts.push(name.substring(offset, offset + part.length));
      offset += part.length;
      if (name.length === offset) break;
      parts.push(name.substring(offset, offset + filter.length));
      offset += filter.length;
    }
    return parts;
  }, [tree.tenantName, filter]);

  return (
    <>
      <MenuItem
        disableRipple
        key={tree.tenantId}
        onClick={() => onSelect([tree.tenantId])}
        sx={{
          'pl': 2 + indent * 2,
          'color': active ? '#fff' : undefined,
          'bgcolor': active ? '#586B81' : undefined,
          '&:hover': {
            bgcolor: active ? undefined : '#F6F9FE',
          },
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box flex="1">{nameParts?.map((part, i) => (i % 2 ? <b>{part}</b> : part))}</Box>
          {leaf ? null : (
            <Box
              width="24px"
              height="24px"
              bgcolor="#F8FAFF"
              borderRadius="50%"
              onClick={(e) => {
                e.stopPropagation();
                setCollapsed((v) => !v);
              }}
            >
              <svg
                width="12px"
                height="12px"
                viewBox="0 0 2 2"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transition: 'all 0.3s ease-in',
                  margin: collapsed ? '6px 10px' : '10px 6px',
                  transform: collapsed ? 'rotate(-90deg)' : undefined,
                }}
              >
                <path d="M1,1,2,0H0Z" fill="#5C6A80" />
              </svg>
            </Box>
          )}
        </Box>
      </MenuItem>
      {collapsed || !tree.descendantTenantList?.length ? null : (
        <>
          {tree.descendantTenantList.map((child) => (
            <TenantTreeItem
              key={child.tenantId}
              indent={indent + 1}
              tree={child}
              filter={filter}
              selected={selected.slice(1)}
              onSelect={(path) => onSelect([tree.tenantId, ...path])}
            />
          ))}
        </>
      )}
    </>
  );
}
