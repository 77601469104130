import { PLAYER_DOWNLOAD_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { isDev } from '@/config';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<FirmwareDto>>} */
export const ACTION_ITEMS = [
  // {
  //   label: 'Discontinue',
  //   iconImage: BOX_DISCONTINUE,
  //   component: 'BUTTON',
  //   type: 'DISCONTINUE',
  //   variant: 'outlined',
  //   hidden: (selected) => !selected.length,
  //   confirmationMessage: T['firmware.confirmations.discontinue'],
  // },
  // {
  //   label: 'Modify',
  //   iconImage: BOX_MODIFY_ICON,
  //   component: 'BUTTON',
  //   variant: 'outlined',
  //   hidden: (selected) => selected.length !== 1,
  //   link: (row) => `/smarter-ai/firmwares/${row?.firmwareId}/edit`,
  // },
  {
    type: 'DOWNLOAD',
    label: 'Download',
    iconImage: PLAYER_DOWNLOAD_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    useCustomDialog: true,
    hidden: (selected) => !isDev || selected.length !== 1,
  },
  {
    component: 'SEARCH',
    label: 'Search',
    placeholder: T['device-group.placeholder.search'],
    options: [
      { value: 'firmwareName', label: 'Name' },
      { value: 'version', label: 'Version' },
      { value: 'firmwareTypeId', label: 'Type' },
    ],
  },
  {
    label: 'Firmware',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/smarter-ai/firmwares/create',
  },
];
