import { useRouteQuery } from '@/hooks/useRouteQuery';
import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { DriverGridList } from './@components/DriverGridList';
import { CoachingHistory } from './@components/CoachingHistory';
import { FilterContextProvider } from '@/web/@components/FilterContext';
import { CoachingFilterContextProvider } from './@context/CoachingFilterContext';
import { CoachingFilteringArea } from './@components/CoahingfilteringArea';

export function DriverCoachingPage() {
  const { query, updateQuery } = useRouteQuery();

  /** @type {StateVariable<number>} */
  const [selectedParentType, setSelectedParentType] = useState(1);

  const handleChange = (event, newValue) => {
    setSelectedParentType(newValue);
    updateQuery({ type: newValue });
  };

  useEffect(() => {
    const type = Number(query?.type);
    if ([1, 2]?.includes(type)) {
      setSelectedParentType(type);
    } else {
      setSelectedParentType(1);
    }
  }, [query?.type]);

  return (
    <Box mx={2.5}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        sx={{
          '& .MuiButtonBase-root': {
            textTransform: 'none',
          },
        }}
      >
        <Tabs
          value={selectedParentType}
          onChange={handleChange}
          aria-label="disabled tabs example"
          sx={{
            '.MuiTab-root.MuiTab-textColorPrimary.Mui-selected': {
              color: '#4177BF !important',
            },
            '.MuiTab-root.MuiTab-textColorPrimary.Mui-disabled': {
              color: '#A6AFBA !important',
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#4177BF !important',
            },
            '.MuiTabs-scroller': {
              height: '40px',
            },
          }}
        >
          <Tab key={1} value={1} label="Coach Driver" />
          <Tab key={2} value={2} label="Coaching History" />
        </Tabs>
      </Box>

      <FilterContextProvider>
        <CoachingFilterContextProvider>
          <Box mb={2}>
            <CoachingFilteringArea />
          </Box>
          {selectedParentType === 1 ? <DriverGridList /> : <CoachingHistory />}
        </CoachingFilterContextProvider>
      </FilterContextProvider>
    </Box>
  );
}
