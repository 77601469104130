import { CheckBox as CheckBoxIcon } from '@mui/icons-material';
import { Box, Button, Divider, MenuItem, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { SearchField } from '../SearchField';

/**
 * @typedef {object} SelectionFilterContentProps
 * @property {import('.').FilterMenuItem} item
 * @property {string|string[]} [initialValue]
 * @property {boolean} [partial]
 * @property {() => any} onCancel
 * @property {boolean} [multi]
 * @property {(value: string|string[]) => any} onSubmit
 */

/** @param {SelectionFilterContentProps} props */
export const SelectionFilterContent = (props) => {
  const { item, multi, partial, initialValue, onCancel, onSubmit } = props;

  /** @type {StateVariable<{[key: string]: boolean}>} */
  const [selected, setSelected] = useState({});
  /** @type {StateVariable<Array<string>>} */
  const [options, setOptions] = useState(item?.submenu || []);

  const initialValues = useMemo(
    () => (typeof initialValue === 'string' ? [initialValue] : initialValue || []),
    [initialValue]
  );

  useEffect(() => {
    /** @type {{[key: string]: boolean}} */
    const checked = {};
    initialValues.forEach((x) => (checked[x] = true));
    setSelected(checked);
  }, [initialValues]);

  useEffect(() => {
    if (!partial || !initialValue || typeof initialValue !== 'string') return;
    const options = item?.submenu || [];
    if (options.find((x) => x === initialValue)) return;
    setOptions([initialValue, ...options]);
  }, [initialValue, partial, item?.submenu]);

  /** @param {string} search */
  const handleSearch = (search) => {
    let options = item?.submenu || [];
    if (search) {
      const s = search.toLowerCase();
      options = options.filter((x) => x && x.toLowerCase().includes(s));
      if (partial && !multi) {
        options = [search, ...options];
        setSelected({ [search]: true });
      }
    }
    setOptions(options);
  };

  /** @type {import('react').KeyboardEventHandler<HTMLInputElement>} */
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  /** @param {string} option */
  const handleSelect = (option) => {
    if (multi) {
      setSelected((v) => ({ ...v, [option]: !v[option] }));
    } else {
      setSelected({ [option]: true });
    }
  };

  const handleSubmit = () => {
    const checked = Object.keys(selected).filter((x) => selected[x]);
    if (multi) {
      onSubmit(checked);
    } else {
      onSubmit(checked[0]);
    }
  };

  return (
    <>
      <Box px={2} pt={2}>
        <Typography fontWeight="bold" variant="h3" fontSize="0.85rem">
          {item.title}
        </Typography>
        <SearchField
          autoFocus
          clearable
          onSearch={handleSearch}
          placeholder="Filter items"
          sx={{ width: '100%', mt: 1 }}
          InputProps={{ onKeyDown: handleKeyDown }}
          value={partial && !multi ? initialValues[0] : undefined}
        />
      </Box>

      <Box sx={{ maxHeight: '250px', minWidth: '400px', overflowY: 'auto', marginTop: '2px' }}>
        {options?.length ? (
          options.map((option) => (
            <MenuItem key={option} onClick={() => handleSelect(option)}>
              {selected[option] ? (
                <CheckBoxIcon htmlColor="#4EA5F1" />
              ) : (
                <CheckBoxIcon htmlColor="#EBEBEB" />
              )}
              <Typography fontSize="0.875rem" pl={1}>
                {option}
              </Typography>
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            <Typography fontSize="0.875rem" pl={1}>
              No Data
            </Typography>
          </MenuItem>
        )}
      </Box>

      <Divider />

      <Box p={1} display="flex" justifyContent="flex-end" gap="10px">
        <Button onClick={() => onCancel()} size="small">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" size="small">
          Apply
        </Button>
      </Box>
    </>
  );
};
