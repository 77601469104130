import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Grid, Typography } from '@mui/material';

export function GuideOnboardingAndAdjustment() {
  return (
    <>
      <Box fontSize="1.375rem" fontWeight="600" mt={3} mb={2}>
        IV. Onboarding & Adjustment
      </Box>

      <Box>
        <Typography fontSize="1.125rem" fontWeight="600" py={1}>
          Onboarding
        </Typography>
        <Typography fontSize="1rem" py={1}>
          Power the Smarter AI Dashcam on & follow the process in the Smarter AI Mobile Application
          on your mobile device.
        </Typography>
      </Box>

      <Box fontSize="1.125rem" fontWeight="600" py={1}>
        Camera Adjustment
      </Box>

      <Grid container spacing={4} my={1} textAlign="center">
        <Grid item xs={12} md={6}>
          <BoxImage src="/images/guide/onboarding-image.svg" width="90%" />
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxImage src="/images/guide/onboarding-image-2.svg" width="90%" />
        </Grid>
      </Grid>
    </>
  );
}
