import { store } from '@/store';
import { selectMainTenantId } from '@/store/auth';
import { PageView } from '@/store/page-view';
import { Box } from '@mui/material';
import { createContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CameraHealthHeader } from './@components/CameraHealthHeader';
import { GroupedList } from './@components/GroupedList';
import { CameraHealthFetcher } from './fetcher';

/** @type {import('react').Context<CameraHealthFetcher>} */
export const CameraHealthContext = createContext(null);

export function CameraHealthMonitoring() {
  const tenantId = useSelector(selectMainTenantId);

  const fetcher = useMemo(() => new CameraHealthFetcher(), []);

  useEffect(() => {
    const tid = setTimeout(() => {
      store.dispatch(PageView.setFullWidthLayout(true));
    }, 100);
    return () => clearTimeout(tid);
  }, []);

  useEffect(() => {
    if (!fetcher) return;
    fetcher.process().catch(console.error);
    return () => fetcher.abort();
  }, [fetcher, tenantId]);

  return (
    <Box p={2.5} mb={10}>
      <CameraHealthContext.Provider value={fetcher}>
        <CameraHealthHeader />
        <GroupedList />
      </CameraHealthContext.Provider>
    </Box>
  );
}
