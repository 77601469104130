import { useDriverList } from '@/hooks/useDriverList';
import { CenterBox } from '@/web/@components/CenterBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, CircularProgress, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import qs from 'qs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DriverGridItem } from '../DriverGridItem';
import SelfCoachingModal from '../SelfCoachingModal';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';

export function DriverGridList() {
  const navigate = useNavigate();

  /** @type {StateVariable<any>} */
  const [anchorEl, setAnchorEl] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [showSelfModal, setShowSelfModal] = useState(false);

  const { result: drivers, loading } = useDriverList();

  /** @type {import('react').MouseEventHandler} */
  const handleClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelfClick = () => {
    handleClose();
    setShowSelfModal(true);
  };

  if (loading) {
    return (
      <CenterBox style={{ height: '280px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (!drivers?.length && !loading) {
    return (
      <CenterBox fullView>
        <IconMessageBox size="256px" src={NO_SEARCH_RESULT_IMG} message="No Data Found." />
      </CenterBox>
    );
  }

  return (
    <Box mb={15}>
      {showSelfModal ? (
        <SelfCoachingModal onCancelSelfModal={() => setShowSelfModal(false)} />
      ) : null}

      <Grid container spacing={2}>
        {drivers?.map((item, index) => (
          <Grid item key={index} xs={12} md={6} lg={3}>
            <Box position="relative">
              <DriverGridItem driver={item} />
              <IconButton
                size="small"
                className="hover-item"
                onClick={handleClick}
                sx={{
                  'position': 'absolute',
                  'top': '5px',
                  'right': '5px',
                  'bgcolor': '#fff',
                  '&:hover': {
                    bgcolor: '#fff',
                  },
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>

            <Menu
              elevation={0}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                sx={{ fontSize: '0.875rem' }}
                onClick={() => {
                  const search = qs.stringify({
                    driverId: item.driverId,
                    driverName: `${item.firstName} ${item.lastName}`,
                  });
                  navigate(`/safety/coaching/coachable-events?${search}`);
                }}
              >
                Coachable Events
              </MenuItem>
              <MenuItem
                sx={{ fontSize: '0.875rem' }}
                onClick={() => {
                  navigate(`/safety/coaching/${item.driverId}/guided`);
                }}
              >
                Guided Coaching
              </MenuItem>
              <MenuItem sx={{ fontSize: '0.875rem' }} onClick={handleSelfClick}>
                Self Coaching
              </MenuItem>
            </Menu>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
