import { CenterBox } from '@/web/@components/CenterBox';
import { Box } from '@mui/material';
import { GuidePageContainer } from '../GuidePageContainer';

export function GuideFooterArea() {
  return (
    <CenterBox id="footer-area" borderTop="1px solid #DCE7F5">
      <GuidePageContainer>
        <Box>©2023 Smarter AI. All Rights Reserved.</Box>
      </GuidePageContainer>
    </CenterBox>
  );
}
