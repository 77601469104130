import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { endOfTheDay, formatDuration, formatTimestamp, startOfTheDay } from '@/utils/datetime';
import { fetchGeoInfo } from '@/utils/geoinfo';
import { collapseDecimal } from '@/utils/sensors/extractor';
import { TripFilterContext } from '@/web/trips/@components/TripFilterContext';
import FileSaver from 'file-saver';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} TripReportContextValue
 * @property {boolean} loading
 * @property {() => any} download
 * @property {Array<{[key: string]: any}>} results
 */

/** @type {import("react").Context<TripReportContextValue>} */
export const TripReportContext = createContext(null);

/** @param {{children: any}} props */
export function TripReportContextProvider(props) {
  const { children } = props;

  const filters = useContext(TripFilterContext);
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<Array<{[key: string]: any}>>} */
  const [results, setResults] = useState(null);
  /** @type {StateVariable<string> */
  const [csv, setCSV] = useState(null);
  /** @type {StateVariable<string> */
  const [filename, setFilename] = useState(null);

  useEffect(() => {
    setLoading(true);
    const aborter = new AbortController();
    const process = async () => {
      /** @type {Array<{[key: string]: string}>} */
      const reports = [];
      try {
        const limit = 50;
        let offset = 0;
        const fields = [
          'Camera Name',
          'Start Location',
          'Start Time',
          'End Location',
          'End Time',
          'Distance',
          'Duration',
        ];

        let csv = '';
        csv += fields.join(',') + '\n';
        while (!aborter.signal.aborted) {
          const request = api.ac.v5.trips.$get({
            signal: aborter.signal,
            headers: {
              Authorization: secretToken,
            },
            params: {
              limit,
              offset,
              tenantId,
              fromTimestamp: filters.startTime || startOfTheDay(),
              toTimestamp: filters.endTime || endOfTheDay(),
              ...(filters.endpointId ? { endpointId: filters.endpointId + '' } : {}),
              ...(filters.driverId ? { driverId: filters.driverId } : {}),
              ...(filters.driverName ? { driverName: filters.driverName } : {}),
              ...(filters.endpointName ? { endpointName: filters.endpointName } : {}),
            },
          });
          await request.process();
          const result = request.result.tripList;
          // const total = Number(request.response.headers['x-total-count']);
          for (const trip of result) {
            if (trip.tripStatus !== 'STARTED' && trip?.distance <= 50) continue;
            const duration = trip.endTimestamp - trip.startTimestamp;
            const distance = trip.distance
              ? collapseDecimal(trip.distance / 1000 || 0, 2) + ' km'
              : '-';
            const [startGeoInfo, endGeoInfo] = await Promise.allSettled([
              fetchGeoInfo(trip.startGeo?.lat, trip.startGeo?.lon),
              fetchGeoInfo(trip.endGeo?.lat, trip.endGeo?.lon),
            ]);
            const startAddress =
              (startGeoInfo.status === 'fulfilled' && startGeoInfo.value?.fullAddress) || '-';
            const endAddress =
              (endGeoInfo.status === 'fulfilled' && endGeoInfo.value?.fullAddress) || '-';
            const data = [
              trip.endpointName,
              `"${startAddress}"`,
              `"${formatTimestamp(trip.startTimestamp)}"`,
              `"${endAddress}"`,
              `"${formatTimestamp(trip.endTimestamp)}"`,
              distance,
              Number.isNaN(duration) ? '-' : formatDuration(duration),
            ];
            csv += data.join(',') + '\n';

            /** @type {{[key: string]: any}} */
            const item = { trip };
            for (let i = 0; i < fields.length; ++i) {
              item[fields[i]] = data[i];
            }
            reports.push(item);
          }
          if (result.length < limit) break;
          offset += limit;
        }
        setResults(reports);
        setCSV(csv);

        const nameParts = [];
        if (filters.endpointId) nameParts.push(filters.endpointId);
        if (filters.endpointName) nameParts.push(filters.endpointName);
        if (filters.startTime) nameParts.push(filters.startTime);
        if (filters.endTime) nameParts.push(filters.endTime);
        const filename = `Trips_${nameParts.join('_')}.csv`;
        setFilename(filename);
      } catch (err) {
        console.error('Report download failed', err);
      } finally {
        setLoading(false);
      }
    };
    process();
    return () => aborter.abort();
  }, [filters, tenantId, secretToken]);

  function download() {
    const blob = new Blob([csv], { type: 'text/plain' });
    FileSaver.saveAs(blob, filename);
  }

  return (
    <TripReportContext.Provider
      value={{
        loading,
        results,
        download,
      }}
    >
      <>{children}</>
    </TripReportContext.Provider>
  );
}
