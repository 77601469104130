import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<import('@/types').ProductProperties>>} */
export const PRODUCT_TABLE_COLUMNS = [
  {
    id: 'productId',
    label: '',
    align: 'left',
    isPrimary: true,
    width: '1%',
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'productId'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'productName',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    searchable: true,
  },
  {
    id: 'serial',
    label: 'Serial',
    align: 'left',
    searchable: true,
  },
  {
    id: 'aiAccelerator',
    label: 'AI Accelerator',
    align: 'left',
    searchable: true,
    format: (value, row) => row?.aiAccelerator?.name || '...',
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    searchable: true,
  },
];
