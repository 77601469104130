import { useVisibleRoutes } from '@/hooks/useVisibleRoutes';
import { wrapUseRoutes } from '@sentry/react';
import { useRoutes } from 'react-router-dom';

const useSentryRoutes = wrapUseRoutes(useRoutes);

export function Pages() {
  const routes = useVisibleRoutes();
  return useSentryRoutes(routes);
}
