import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { Typography } from '@mui/material';
import { truncate } from 'lodash';
import { getTriggerImageByName } from '../../utils';

export function TriggerNameWithImage({ triggerName }) {
  const img = getTriggerImageByName(triggerName);

  return (
    <IconLabelItem
      src={img}
      iconSize={{
        xs: '17px',
        md: img.includes('events-n-off') ? '21px' : '25px',
      }}
      fontSize="0.875rem"
      pl={'4px'}
      sx={{ alignItems: 'center', gap: '10px', alignContent: 'left' }}
      title={
        <Typography
          fontSize={{ sx: '1rem', md: '0.875rem' }}
          title={triggerName}
          sx={{ textAlign: 'left' }}
        >
          {truncate(triggerName, { length: 40 })}
        </Typography>
      }
    />
  );
}
