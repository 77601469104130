import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { formatTriggerName } from '@/utils/events';
import { CenterBox } from '@/web/@components/CenterBox';
import { FilterContext } from '@/web/@components/FilterContext';
import { FilteredDateRangePicker } from '@/web/@components/FilteredDateRangePicker';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { TableActionButton } from '@/web/@components/TableActionButton';
import { CloseOutlined, SelectAll } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SelectTriggerDialog } from './@components/SelectTriggersDialog';
import { TRIGGER_REPORT_TABLE_COLUMNS } from './columns';

/**
 * @typedef {object} TriggerReportItem
 * @property {string} triggerId
 * @property {string} triggerName
 * @property {number} [precision]
 * @property {number} [reviewed]
 */

export function TriggerReports() {
  const { t } = useTranslation();
  const { startTime, endTime } = useContext(FilterContext);
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  /** @type {StateVariable<number>} */
  const [changeCounter, setChangeCounter] = useState(0);
  /** @type {StateVariable<number>} */
  const [loading, setLoading] = useState(0);
  /** @type {StateVariable<{[key: string]: TriggerReportItem}>} */
  const [reports, setReports] = useState({});
  /** @type {StateVariable<boolean>} */
  const [showDialog, setShowDialog] = useState(false);

  const selectedTriggerIds = useMemo(() => new Set(Object.keys(reports)), [reports]);

  /** @type {SmartCache<CompositeTriggerDto>} */
  const triggerReportCache = useMemo(
    () => new SmartCache(`trigger-report-${tenantId}`, 120 * 24 * 3600 * 1000),
    [tenantId]
  );

  useEffect(() => {
    const aborter = new AbortController();
    const process = async () => {
      setLoading((v) => v + 1);
      /** @type {{[key: string]: TriggerReportItem}} */
      const results = {};
      try {
        for await (const trigger of triggerReportCache.getAllValues()) {
          if (aborter.signal.aborted) break;
          const triggerId = trigger.id;
          const triggerName = formatTriggerName(trigger.name);
          results[triggerId] = { triggerId, triggerName };
        }
        setReports((prev) => {
          for (const triggerId in results) {
            Object.assign(results[triggerId], prev[triggerId] || {});
          }
          return results;
        });
      } finally {
        setLoading((v) => v - 1);
      }
      for (const triggerId in results) {
        if (aborter.signal.aborted) break;
        const request = api.ac.v2['event-messaging'].trigger.report.result.$get({
          signal: aborter.signal,
          params: {
            fromTimestamp: startTime,
            toTimestamp: endTime,
            secretToken,
            triggerId,
          },
        });
        const result = await request.process();
        if (!result) continue;
        setReports((prev) => {
          const item = prev[triggerId];
          const tp = result?.correctTotal ?? 0;
          item.reviewed = result?.eventsTotal ?? 0;
          const fp = item.reviewed - tp;
          const p = 1 - fp / (fp + tp);
          item.precision = Math.floor(100 * p) || 0;
          return { ...prev };
        });
      }
    };
    process().catch(console.error);
    return () => aborter.abort();
  }, [secretToken, startTime, endTime, changeCounter, triggerReportCache]);

  /** @param {Array<CompositeTriggerDto>} selected */
  const handleNewTriggers = async (selected) => {
    setShowDialog(false);
    if (!selected?.length) return;
    setLoading((v) => v + 1);
    try {
      for (const trigger of selected) {
        await triggerReportCache.setItem(trigger.id, trigger);
      }
      setChangeCounter((v) => v + 1);
    } catch (err) {
      console.error('Failed to save selection', err);
    } finally {
      setLoading((v) => v - 1);
    }
  };

  /**
   * @param {string} triggerId
   * @returns {import('react').MouseEventHandler<HTMLElement>}
   */
  const handleRemove = (triggerId) => async (e) => {
    e.stopPropagation();
    await triggerReportCache.removeItem(triggerId);
    setChangeCounter((v) => v + 1);
  };

  return (
    <Box px={2.5} py={2}>
      <Box display="flex" gap={1} flexWrap="wrap" alignItems="center">
        <Typography variant="subtitle2" fontWeight="500" fontSize="1.125rem">
          {t(T['triggers.report.page.title'])}
        </Typography>
        <Box flex={1} />
        <FilteredDateRangePicker />
        <TableActionButton
          onClick={() => setShowDialog(true)}
          item={{
            variant: 'contained',
            icon: SelectAll,
            label: 'Select Triggers',
          }}
        />
      </Box>
      <Box mt={2.5}>
        {loading ? (
          <CenterBox fullView>
            <CircularProgress />
          </CenterBox>
        ) : !Object.keys(reports).length ? (
          <CenterBox fullView>
            <IconMessageBox src={NO_SEARCH_RESULT_IMG} message="No selected triggers" />
          </CenterBox>
        ) : (
          <ItemsResponsiveView
            columns={[
              ...TRIGGER_REPORT_TABLE_COLUMNS,
              {
                id: 'triggerId',
                align: 'right',
                width: '60px',
                format: (triggerId) => (
                  <IconButton
                    size="small"
                    onClick={handleRemove(triggerId)}
                    children={<CloseOutlined fontSize="small" />}
                  />
                ),
              },
            ]}
            results={Object.values(reports)}
            defaultSortKey="triggerName"
          />
        )}
      </Box>
      {showDialog && (
        <PaginatedTableContextProvider>
          <SelectTriggerDialog value={selectedTriggerIds} onChange={handleNewTriggers} />
        </PaginatedTableContextProvider>
      )}
    </Box>
  );
}
