import { formatTimestamp } from '@/utils/datetime';
import { ScoreMiniWidget } from '@/web/safety/scoring/@components/ScoreMiniWidget';
import { Avatar, Box, Typography } from '@mui/material';
import { CoachingNotes } from '../CoachingNotes';
import { random } from 'lodash';
// /**
//  * @typedef {object} CoachingHistoryProps
//  * @property {boolean} [dataResidencyEnabled]
//  * @property {string} [tenantCode] Tenant Code
//  * @property {string} [tenantId] Tenant ID
//  * @property {string} [tenantName] Tenant Name
//  * @property {string} [tenantRegion] Tenant Region
//  * @property {string} [tenantStatus] Tenant Code
//  * @property {string} [type] action type
//  */

// /** @type {Array<TableColumn<CoachingHistoryProps>>} */

export const COACHING_HISTORY_TABLE_COLUMNS = [
  {
    id: 'row?.driver?.name',
    label: 'Driver',
    align: 'left',
    isPrimary: true,
    searchable: true,
    format: (value, row) => {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Avatar
            alt={row?.driver?.name}
            src={row?.driver?.pictureURL || 'https://mui.com/static/images/avatar/2.jpg'}
            sx={{ width: 46, height: 46 }}
          />
          <Typography fontSize="0.875rem">{row?.driver?.name}</Typography>
        </Box>
      );
    },
  },
  {
    id: '',
    label: 'Coach',
    align: 'left',
    searchable: true,
    format: (value, row) => {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Avatar
            alt={row?.coach?.name}
            src={row?.coach?.pictureURL || 'https://mui.com/static/images/avatar/3.jpg'}
            sx={{ width: 46, height: 46 }}
          />
          <Typography fontSize="0.875rem">{row?.coach?.name}</Typography>
        </Box>
      );
    },
  },
  {
    id: '',
    label: 'Event Coached',
    align: 'center',
    searchable: true,
    format: (value) => random(5, 20),
  },
  {
    id: 'prevScore',
    label: 'Previous Score',
    align: 'center',
    format: (value, row) => {
      return (
        <Box width="28px" height="28px" position="relative" sx={{ width: '100%' }}>
          <ScoreMiniWidget value={value} />
          <Typography
            position="absolute"
            left={5}
            right={5}
            top={1}
            bottom={0}
            textAlign={'center'}
            borderRadius="50%"
            lineHeight={value ? '28px' : '20px'}
            fontWeight={600}
            fontSize={Math.round(value) > 99 ? '10px' : '12px'}
          >
            {Math.round(value) || '...'}
          </Typography>
        </Box>
      );
    },
  },
  {
    id: 'currentScore',
    label: 'New Score',
    align: 'center',
    format: (value, row) => {
      return (
        <Box width="28px" height="28px" position="relative" sx={{ width: '100%' }}>
          <ScoreMiniWidget value={value} />
          <Typography
            position="absolute"
            left={5}
            right={5}
            top={1}
            bottom={0}
            textAlign={'center'}
            borderRadius="50%"
            lineHeight={value ? '28px' : '20px'}
            fontWeight={600}
            fontSize={Math.round(value) > 99 ? '10px' : '12px'}
          >
            {Math.round(value) || '...'}
          </Typography>
        </Box>
      );
    },
  },
  {
    id: 'date',
    label: 'Date',
    align: 'center',
    format: (value, row) => formatTimestamp(value),
  },
  {
    id: '',
    label: 'Notes',
    align: 'center',
    width: '10px',
    format: (value, row) => {
      return <CoachingNotes row={row} />;
    },
  },
];
