import { Box, Link, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { formatTimestamp } from '../../../../../utils/datetime';

export function CoachingNotes(props) {
  const { row } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Link onClick={handleClick} sx={{ fontWeight: 500 }}>
        Notes
      </Link>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ px: 2, py: 1 }}>
          <Typography variant="subtitle2">Coach Notes</Typography>
          {row?.coach?.notes?.map((item) => {
            return (
              <Box pb={2}>
                <Typography fontSize="0.75rem">{formatTimestamp(item?.date)}</Typography>
                <Typography fontSize="0.875rem">{item?.note}</Typography>
              </Box>
            );
          })}
        </Box>

        <Box sx={{ px: 2, pb: 1 }}>
          <Typography variant="subtitle2">Driver Notes</Typography>
          {row?.driver?.notes?.map((item) => {
            return (
              <Box pb={2}>
                <Typography fontSize="0.75rem">{formatTimestamp(item?.date)}</Typography>
                <Typography fontSize="0.875rem">{item?.note}</Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
    </>
  );
}
