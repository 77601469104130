import { T } from '@/assets/locales';
import { LabelListView } from '.';
import { CreateLabelPage } from './create';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <LabelListView />,
  },
  {
    path: 'create',
    element: <CreateLabelPage />,
    breadcrumb: T['label.route.create'],
  },
];

export default routes;
