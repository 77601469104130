import { selectSecretToken } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { COACHING_HISTORY_TABLE_COLUMNS } from './columns';

const COACHING_HISTORY = [
  {
    id: 1,
    driver: {
      name: 'Ayaz Mohmmad',
      pictureURL: '',
      notes: [
        {
          id: 1,
          date: 1713877328000,
          note: 'Lorem Ipsum sdasd saefasdg asdgagd dsgfafg ',
        },
      ],
    },
    coach: {
      id: 11,
      name: 'Jessica',
      pictureURL: '',
      notes: [
        {
          id: 1,
          date: 1713877328000,
          note: 'Lorem Ipsum',
        },
      ],
    },
    prevScore: 85,
    currentScore: 100,
    date: 1713877328000,
  },
  {
    id: 2,
    driver: {
      name: 'Bappa Raz',
      pictureURL: '',
      notes: [
        {
          id: 1,
          date: 1713877328000,
          note: 'Lorem Ipsum sdasd saefasdg asdgagd dsgfafg ',
        },
      ],
    },
    coach: {
      id: 22,
      name: 'Jessica',
      pictureURL: '',
      notes: [
        {
          id: 1,
          date: 1713877328000,
          note: 'Lorem Ipsum sdasd saefasdg asdgagd dsgfafg ',
        },
      ],
    },
    prevScore: 95,
    currentScore: 100,
    date: 1713877328000,
  },
  {
    id: 3,
    driver: {
      name: 'Omor sani',
      pictureURL: '',
      notes: [
        {
          id: 1,
          date: 1713877328000,
          note: 'Lorem Ipsum sdasd saefasdg asdgagd dsgfafg ',
        },
      ],
    },
    coach: {
      id: 33,
      name: 'Jessica',
      pictureURL: '',
      notes: [
        {
          id: 1,
          date: 1713877328000,
          note: 'Lorem Ipsum sdasd saefasdg asdgagd dsgfafg ',
        },
      ],
    },
    prevScore: 75,
    currentScore: 92,
    date: 1713877328000,
  },
];

export function CoachingHistory() {
  const secretToken = useSelector(selectSecretToken);

  return (
    <Box mt={2}>
      <PaginatedTableContextProvider>
        <ContinuationTokenTable
          key={generateUniqueKey([secretToken, 'coaching_history'])}
          results={COACHING_HISTORY}
          loading={false}
          // @ts-ignore
          columns={COACHING_HISTORY_TABLE_COLUMNS}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
