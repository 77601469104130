import { Box } from '@mui/material';
import { GuideAppInstallationArea } from '../@components/GuideAppInstallationArea';
import { GuideFooterArea } from '../@components/GuideFooterArea';
import { GuideHeaderArea } from '../@components/GuideHeaderArea';
import { GuidePdfDownloadArea } from '../@components/GuidePdfDownloadArea';
import { GuideQuickInstallationArea } from '../@components/GuideQuickInstallationArea';
import { GuideQuickSpecificationsArea } from '../@components/GuideQuickSpecificationsArea';

export function InstallDashcamPage() {
  return (
    <Box textAlign="center">
      <GuideHeaderArea />
      <GuideAppInstallationArea />
      <GuidePdfDownloadArea />
      <GuideQuickInstallationArea />
      <GuideQuickSpecificationsArea />
      <GuideFooterArea />
    </Box>
  );
}
