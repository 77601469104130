import { DRIVER_CONTAINED_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { EllipsisTextViewContainer } from '@/web/@components/EllipsisTextView';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { PageLink } from '@/web/@components/PageLink';
import { DriverImageNotAvailable } from '@/web/fleets/drivers/@components/DriverImageNotAvailable';
import { ScoreMiniWidget } from '@/web/safety/scoring/@components/ScoreMiniWidget';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

/**
 * @typedef {object} DriverGridItemProps
 * @property {Driver} [driver]
 * @property {Number} [score]
 */

/**
 * @param {DriverGridItemProps} props
 */
export function DriverGridItem(props) {
  const { driver, score } = props;

  const driverScore = useMemo(() => (score ? Math.round(score) : null), [score]);

  return (
    <Box>
      <BoxImage
        width="100%"
        objectFit="cover"
        objectPosition="center"
        src={driver?.pictureUrl}
        sx={{
          cursor: 'pointer',
          height: { sx: 450, md: 315 },
        }}
        fallback={
          <DriverImageNotAvailable
            sx={{
              mb: '5px',
              cursor: 'pointer',
              height: { sx: 450, md: 315 },
            }}
          />
        }
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <IconLabelItem
          flex={1}
          src={DRIVER_CONTAINED_ICON}
          fontWeight={600}
          fontSize={'14px'}
          title={
            <EllipsisTextViewContainer>
              <PageLink to={`/safety/scoring/drivers/${driver?.driverId}`}>
                <Typography title={driver?.firstName + ' ' + driver?.lastName}>
                  {driver?.firstName + ' ' + driver?.lastName}
                </Typography>
              </PageLink>
            </EllipsisTextViewContainer>
          }
        />
        <Box width="28px" height="28px" position="relative">
          <ScoreMiniWidget value={driverScore} />
          <Typography
            position="absolute"
            left={5}
            right={5}
            top={1}
            bottom={0}
            textAlign={'center'}
            borderRadius="50%"
            lineHeight={driverScore ? '28px' : '20px'}
            fontWeight={600}
            fontSize={Math.round(driverScore) > 99 ? '10px' : '12px'}
          >
            {Math.round(driverScore) || '...'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
