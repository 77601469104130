import { formatTimestamp } from '@/utils/datetime';
import { LongMessageViewer } from '@/web/@components/LongMessageViewer';
import { capitalize } from 'lodash';

/** @type {Array<TableColumn<OnboardLog>>} */
export const ONBOARD_LOG_TABLE_COLUMNS = [
  {
    id: 'userName',
    label: 'User',
    align: 'left',
    isPrimary: true,
    link: (row) => `/administration/users/${row.userId}`,
  },
  {
    id: 'reportingTimestamp',
    label: 'Reporting Time',
    align: 'left',
    isPrimary: true,
    width: '16%',
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'onboardStatus',
    label: 'Status',
    align: 'left',
    format: (value) => capitalize(value),
  },
  {
    id: 'onboardingType',
    label: 'Platform',
    align: 'left',
    searchable: true,
    format: (value) => (value === 'IOS' ? 'iOS' : capitalize(value)),
  },
  {
    id: 'logFromApp',
    label: 'App Log',
    align: 'left',
    searchable: true,
    format: (value) => {
      return <LongMessageViewer message={value || ''} title="App Log" />;
    },
  },
  {
    id: 'logFromDevice',
    label: 'Device Log',
    align: 'left',
    searchable: true,
    format: (value) => {
      return <LongMessageViewer message={value || ''} title="Device Log" />;
    },
  },
];
