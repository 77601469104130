import { getTime, parse } from 'date-fns';

/**
 * Formats an ISO date string into a human-readable date format (DD/MM/YYYY).
 * @param {string} isoDate - The ISO date string to be formatted.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (isoDate) => {
  // Create a Date object from the ISO date string
  const dateObj = new Date(isoDate);
  // Extract day, month, and year components with leading zeros if necessary
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();
  // Construct the formatted date string (DD/MM/YYYY)
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

/**
 * Formats a date object into a string in the format 'YYYY-MM-DD'.
 * @param {Date} date - The date object to be formatted.
 * @returns {string} The formatted date string.
 */
export const formatDate1 = (date) => {
  const padZero = (value) => String(value).padStart(2, '0');
  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

/**
 * Convert a date string to Epoch time in seconds.
 * @param {string} dateString - The input date string in the format 'DD-MM-YYYY'.
 * @returns {number} - The Epoch time in seconds.
 * @throws {Error} - Throws an error if the input date string is invalid.
 * @example
 * const dateString = '27-01-2024';
 * const epochTime = getEpochTime(dateString);
 * console.log(epochTime); // Output: Epoch time in seconds
 */
export const getEpochTime = (dateString) => {
  const parsedDate = parse(dateString, 'dd-MM-yyyy', new Date());
  // Converting to Epoch time in seconds
  const epochTimeInSeconds = getTime(parsedDate);
  return epochTimeInSeconds;
};

/**
 * Filters items based on the specified property keys and search term.
 * @param {Array} items - The array of items to filter.
 * @param {Array} propertyKeys - The array of property keys to check for the search term.
 * @param {string} searchTermLowerCase - The lowercased search term.
 * @returns {Array} - The filtered items.
 */
export const filterItemsByProperties = (items, propertyKeys, searchTermLowerCase) => {
  return items?.filter((item) =>
    propertyKeys.some((key) => item[key]?.toLowerCase()?.includes(searchTermLowerCase))
  );
};

/**
 * Generate an array of n evenly spaced values between min and max (inclusive).
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @param {number} n - The number of values to generate.
 * @returns {number[]} - The array of evenly spaced values.
 */
export const generateEvenlySpacedArray = (min, max, n) => {
  if (min === max) {
    min = 0;
  }

  const step = (max - min) / (n - 1);
  let resultArray = Array.from({ length: n }, (_, index) =>
    max > 5 ? Math.round(min + step * index) : min + step * index
  );

  // Ensure zero is present in the array
  if (!resultArray.includes(0)) {
    resultArray[0] = 0;
  }

  return resultArray;
};

/**
 * Normalize a value within a specified range to a 0-100 scale.
 * @param {number} value - The value to be normalized.
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @returns {number} - The normalized value in the 0-100 scale.
 */
export const normalizeTo100 = (value, min, max) => {
  return ((value - min) / (max - min)) * 100;
};
