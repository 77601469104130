/** @type {Array<TableColumn<import('.').TriggerReportItem>>} */
export const TRIGGER_REPORT_TABLE_COLUMNS = [
  {
    id: 'triggerName',
    label: 'Triggers',
    align: 'left',
    isPrimary: true,
    tooltip: (row) => `${row.triggerName} - Trend`,
    link: (row) => `./${row.triggerId}`,
    sort: true,
  },
  {
    id: 'precision',
    label: 'Precision',
    format: (value) => (typeof value === 'number' ? `${value}%` : '...'),
    sort: true,
  },
  {
    id: 'reviewed',
    label: 'Reviewed',
    sort: true,
  },
];
