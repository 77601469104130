import { VIN_REGEX } from '@/assets/regex';
import { toastWarning } from '@/utils/toaster';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

/**
 * @typedef {object} GeoFenceVINAddModalProps
 * @property {() => any} onHide
 */

/**
 * @param {GeoFenceVINAddModalProps} props
 */
function GeoFenceVINAddModal(props) {
  const { onHide } = props;

  const { getValues, setValue } = useFormContext();

  const selectedVins = useMemo(() => getValues('vins'), [getValues]);

  const params = useParams();
  const [vins, setVins] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(true);

  const geofenceId = useMemo(() => params.id, [params]);

  useEffect(() => {
    setVins(selectedVins);
    setLoading(false);
  }, [selectedVins]);

  const handleVinSubmit = async () => {
    try {
      setValue('vins', vins);
      setLoading(true);
      onHide && onHide();
    } catch (err) {
      toastWarning('danger', 'Failed to add vins.');
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    onHide && onHide();
  };

  const isSubmitDisabled = useMemo(() => loading || !vins?.length, [loading, vins]);

  const handleKeyDown = (event) => {
    const value = inputValue?.trim();
    if (!value) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
        event.preventDefault();
        if (!VIN_REGEX.test(value)) {
          toastWarning('Incorrect VIN number');
          break;
        }
        setVins(Array.from(new Set([...vins, value])));
        setInputValue('');
        break;
      default:
        break;
    }
  };

  return (
    <Dialog open={true} onClose={closeModal} fullWidth>
      <DialogTitle>
        <Typography> {geofenceId ? 'Update' : 'Add'} VINs</Typography>
        <Typography variant="body2"> Type VIN number and press space or enter</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Autocomplete
          multiple
          disableClearable
          disablePortal
          value={vins}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onKeyDown={handleKeyDown}
          options={[]}
          freeSolo
          renderTags={(values, getTagProps) =>
            values.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                onDelete={() => {
                  setVins((items) => items?.filter((i) => i !== option));
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              sx={{
                '& .MuiInputBase-root': {
                  alignItems: 'flex-start',
                  textAlign: 'flex-start !important',
                },
              }}
              {...params}
              variant="outlined"
              // placeholder="Type VIN number and press space or enter.."
              multiline
              minRows="5"
            />
          )}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button size="small" type="button" variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          size="small"
          type="submit"
          disabled={isSubmitDisabled}
          variant="contained"
          onClick={handleVinSubmit}
          sx={{ gap: '5px' }}
        >
          <span>Apply</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GeoFenceVINAddModal;
