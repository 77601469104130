import { SchemeFormContext } from '@/web/@layouts/SchemeLayout';
import { SchemeForm } from '@/web/smarter-ai/scheme-management/schemes/@components/SchemeForm';
import { Box, Typography } from '@mui/material';
import { useContext } from 'react';

export function SchemeDetails() {
  const { scheme } = useContext(SchemeFormContext);

  return (
    <Box p={2.5} pb={14}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Scheme Details
      </Typography>
      <SchemeForm scheme={scheme} isReadOnly={true} />
    </Box>
  );
}
