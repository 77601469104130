import { FormLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * @template T
 * @typedef {object} CustomInputProps<T>
 * @property {any} name
 * @property {string} [label]
 * @property {number} [minRows]
 * @property {string} [placeholder]
 * @property {boolean} [disabled]
 * @property {import('@mui/material').TextFieldProps['type']} [type]
 * @property {Partial<import('@mui/material').TextFieldProps>} [TextFieldProps]
 * @property {import('react-hook-form').ControllerProps<T, any>['rules']} [rules]
 * @property {Partial<import('@mui/material').TextFieldProps['InputProps']>} [InputProps]
 */

/**
 * @template T
 * @param {CustomInputProps<T> & import('@mui/material').FormControlProps} props
 */
export function CustomInput(props) {
  const {
    name,
    type,
    label,
    minRows = 1,
    placeholder,
    disabled = false,
    rules,
    TextFieldProps,
    InputProps,
    ...extraProps
  } = props;

  /** @type {import('react-hook-form').UseFormReturn<T, any>} */
  const { control } = useFormContext();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      size="medium"
      margin="dense"
      {...extraProps}
      sx={{
        mt: 0,
        ...extraProps.sx,
      }}
    >
      {label && (
        <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
          {label}
        </FormLabel>
      )}
      <Controller
        name={name}
        rules={rules}
        control={control}
        disabled={disabled}
        render={({ field, fieldState: { error } }) => (
          <TextField
            type={type}
            variant="outlined"
            placeholder={placeholder}
            disabled={disabled}
            InputProps={InputProps}
            multiline={minRows > 1}
            minRows={minRows > 1 ? minRows : 1}
            error={Boolean(error)}
            helperText={error?.message || ''}
            {...field}
            {...TextFieldProps}
          />
        )}
      />
    </FormControl>
  );
}
