import { USER_FALLBACK_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Button, TextField, Typography } from '@mui/material';

/**
 * @typedef {object} UserNoteBoxProps
 * @property {() => any} onAdd
 */

/** @param {UserNoteBoxProps} props */
export function UserNoteCreateBox(props) {
  const { onAdd } = props;
  const username = 'Abu Talha';
  const avatar = null;
  return (
    <Box
      p={2}
      borderRadius={2}
      border="1px solid #C5D9F0"
      display="flex"
      alignItems="center"
      position="relative"
      gap={1.5}
    >
      <Box>
        <BoxImage
          size="48px"
          height="100%"
          borderRadius="50%"
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
          src={avatar}
          fallbackSrc={USER_FALLBACK_ICON}
        />
      </Box>
      <form style={{ width: '100%' }}>
        <Typography variant="body1" fontWeight={500}>
          {username}
        </Typography>
        <Box display="flex" alignItems="center" gap={1.5}>
          <TextField fullWidth placeholder="Enter note" style={{ flex: 1 }} />
          <Button variant="contained" onClick={onAdd}>
            Add
          </Button>
        </Box>
      </form>
    </Box>
  );
}
