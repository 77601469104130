import { PRODUCT_INPUT_TYPE_OPTIONS } from '@/assets/products';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { ModifySourceItemContainer } from '@/web/@components/ModifySourceItemContainer';
import { Grid } from '@mui/material';

/** @param {{fieldIndex: number, remove: (index: number) => any}} props */
export function ProductInputSourceFormItem(props) {
  const { fieldIndex, remove } = props;

  return (
    <ModifySourceItemContainer onRemove={() => remove(fieldIndex)} pl="10px">
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <CustomSelect
            name={`inputSources[${fieldIndex}].inputType`}
            rules={{ required: 'Input type is required' }}
            label={'Input Type *'}
            placeholder={'Select input type'}
            options={Object.keys(PRODUCT_INPUT_TYPE_OPTIONS)}
            getLabel={(value) => PRODUCT_INPUT_TYPE_OPTIONS[value]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomInput
            name={`inputSources[${fieldIndex}].inputName`}
            rules={{ required: 'Input name is required' }}
            label={'Input Name *'}
            placeholder={'Enter input name'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomInput
            name={`inputSources[${fieldIndex}].inputCode`}
            rules={{ required: 'Input code is required' }}
            label={'Input Code *'}
            placeholder={'Enter input code'}
          />
        </Grid>
      </Grid>
    </ModifySourceItemContainer>
  );
}
