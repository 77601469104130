import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Grid, Typography } from '@mui/material';
import { GuideCableKitECUTable } from './GuideCableKitECUTable';
import { GuideCableKitStandardTable } from './GuideCableKitStandardTable';

export function GuideCablesOverviewArea() {
  return (
    <>
      <Box fontSize="1.375rem" fontWeight="600" mt={3} mb={2}>
        II. Cables Overview
      </Box>

      <GuideCableKitStandardTable />
      <Grid container my={2} spacing="20px" textAlign="center">
        <Grid item xs={12} md={6}>
          <Typography fontSize="1.125rem" fontWeight="600" mb="10px">
            Main vehicle cable
          </Typography>
          <BoxImage src="/images/guide/main-cable-img-1.svg" width="90%" />
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxImage src="/images/guide/main-cable-img-2.svg" width="90%" />
        </Grid>
      </Grid>

      <GuideCableKitECUTable />
      <Grid container my={2} spacing="20px" textAlign="center">
        <Grid item xs={12} md={6}>
          <Typography fontSize="1.125rem" fontWeight="600" mb="10px">
            Split cable
          </Typography>
          <BoxImage src="/images/guide/split-cable-image.svg" width="85%" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography fontSize="1.125rem" fontWeight="600" mb="10px">
            ECU cable
          </Typography>
          <BoxImage src="/images/guide/ECU-cable-image.svg" width="85%" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography fontSize="1.125rem" fontWeight="600" mb="10px">
            Signal cable
          </Typography>
          <BoxImage src="/images/guide/signal-cable-image.svg" width="85%" />
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxImage src="/images/guide/car-cables-image.svg" width="85%" />
        </Grid>
      </Grid>
    </>
  );
}
