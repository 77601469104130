import { ANNOTATION_ICON, BOX_LIST_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';

/** @type {Array<PageCardItem>} */
export const SAFETY_PAGE_ITEMS = [
  {
    name: T['scoring.page.title'],
    image: ANNOTATION_ICON,
    color: '#F0EDF2',
    countHide: true,
    link: '/safety/scoring',
    accessLevel: 'ADMIN',
    components: [
      {
        name: T['scoring.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/safety/scoring',
      },
    ],
  },
  {
    name: T['review.page.title'],
    image: ANNOTATION_ICON,
    color: '#EDF2F2',
    countHide: true,
    link: '/safety/reviews',
    accessLevel: 'HIDDEN',
    components: [
      {
        name: T['review.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/safety/reviews',
      },
    ],
  },
  {
    name: T['coaching.page.title'],
    image: ANNOTATION_ICON,
    color: '#EFE7E7',
    countHide: true,
    link: '/safety/coaching',
    accessLevel: 'HIDDEN',
    components: [
      {
        name: T['coaching.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/safety/coaching',
      },
    ],
  },
];
