import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

/**
 * Fetch list of  Driver Tags
 */
export function useFetchDriverTags() {
  return useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      let afterName = '';
      const limit = 20;
      /** @type {Array<DriverTag>} */
      let results = [];
      while (!signal.aborted) {
        const request = api.ac.v5['driver-tag'].$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            tenantId,
            pageSize: limit,
            afterName,
          },
        });
        await request.process();
        if (!request.result) break;
        const driverTags = request.result.driverTags;
        afterName = driverTags[driverTags.length - 1]?.name;
        results.push(...driverTags);
        if (!afterName?.trim() || driverTags.length < limit) break;
      }
      return results;
    },
    [],
    { cache: true }
  );
}
