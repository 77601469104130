import { TripsPage } from '.';
import { DownloadTripReportPage } from './reports';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <TripsPage />,
  },
  {
    path: 'reports',
    element: <DownloadTripReportPage />,
  },
];

export default routes;
