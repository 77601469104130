import { createTheme } from '@mui/material/styles';
/**
 * Do not use this theme directly from the component. Use the `useTheme`.
 *
 * const theme = useTheme();
 *
 */
export const theme = createTheme({
  breakpoints: {
    values: {
      // extra-small
      xs: 0,
      // small
      sm: 600,
      // medium
      md: 900,
      // large
      lg: 1300,
      // extra-large
      xl: 1750,
    },
  },
  typography: {
    fontFamily: [
      '"Poppins"',
      '-apple-system',
      'Arial',
      'system-ui',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '16px',
      color: '#5a6c83',
    },
    subtitle1: {
      fontSize: '14px',
    },
    subtitle2: {
      fontSize: '14px',
      color: '#5a6c83',
    },
    caption: {
      fontWeight: 500,
      fontSize: '12px',
      color: '#5a6c83',
      letterSpacing: 0,
    },
  },
  palette: {
    mode: 'light',
    divider: '#c5d9f0',
    text: {
      disabled: '#57687D',
      primary: '#011e42',
      secondary: '#5a6c83',
    },
    primary: {
      light: '#7889A0',
      main: '#596A81',
      dark: '#405571',
      contrastText: '#fff',
    },
    secondary: {
      light: '#bfcbdb',
      main: '#bfcbdb',
      dark: '#bfcbdb',
      contrastText: '#fff',
    },
    common: {
      black: '#011e42',
      white: '#fff',
    },
    action: {
      hover: null,
      selected: '#F5F9FF',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
          color: '#1E59A8',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: 'middle',
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          verticalAlign: 'middle',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlined: {
          'borderRadius': '6px',
          'borderColor': '#C5D9F0 !important',
          '&:focus': {
            border: '1px solid #1E59A8 !important',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'white',
            height: '32px',
            marginTop: 1,
          },
          '&:hover': {
            backgroundColor: 'white',
            height: '32px',
            marginTop: 1,
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          'fontSize': '14px',
          '& fieldset': {
            borderColor: '#C5D9F0 !important',
          },
          '&:not(.Mui-disabled):hover fieldset': {
            borderColor: '#1E59A8 !important',
          },
          '&.Mui-focused fieldset': {
            border: '1px solid #1E59A8 !important',
          },
          ...(ownerState.size === 'medium' && {
            '& input': {
              padding: '0.851em 14px',
            },
          }),
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontSize: '14px',
          ...(ownerState.size === 'medium' && {
            '& .MuiOutlinedInput-root': {
              padding: '0.315em 14px !important',
            },
          }),
        }),
        popper: {
          '& .MuiPaper-root': {
            fontSize: '14px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          'color': '#608AC3',
          '&.Mui-disabled': {
            color: '#D3E0F2',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          'marginBottom': '10px',
          '& .MuiAccordionSummary-content > .MuiTypography-root ': {
            marginLeft: '8px',
          },
          '& .MuiAccordionSummary-root.Mui-expanded': {
            height: '48px',
            minHeight: '48px',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            '& .MuiTypography-root': {
              fontWeight: '500',
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderTop: '1px solid rgba(0, 0, 0, .125)',
          px: '25px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          color: '#5a6c83',
          fontWeight: 500,
          padding: '12px 24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
        },
      },
    },
  },
});
