import { blobAsDataUrl, downloadFileToBuffer, downloadJsonFile } from '@/utils/file-utils';
import { CustomLogger } from '@/utils/logger';
import untar from 'js-untar';
import { isArray } from 'lodash';

const logger = new CustomLogger('Recording');

/**
 * Process and generate all bounding box meta data from media recordings
 * @param {MediaEventRecordingV3} item
 * @param {AbortSignal} [signal]
 * @returns {Promise<Array<VideoMetaData>>}
 */
export async function processRecordingBBoxData(item, signal) {
  if (signal.aborted) return [];
  /** @type {Array<Array<VideoMetaData>>} */
  const bboxesList = await downloadJsonFile(item.url, signal);
  if (!isArray(bboxesList)) {
    logger.warn('Malformed video meta data', item.url);
    return [];
  }
  /** @type {Array<VideoMetaData>} */
  const results = [];
  for (const bboxes of bboxesList) {
    for (const metadata of bboxes) {
      results.push({
        ...metadata,
        source: item.source,
      });
    }
  }
  return results;
}

/**
 * Downloads and process all thumbnails
 * @param {Array<ThumbnailPack>} thumbnails
 * @param {AbortSignal} [signal]
 * @yields {VideoThumbnailData}
 */
export async function* processRecordingThumbnails(thumbnails, signal) {
  for (const thumbnail of thumbnails || []) {
    try {
      const buffer = await downloadFileToBuffer(thumbnail.url, signal);
      if (!buffer?.byteLength) {
        logger.debug('Empty buffer for', thumbnail.url);
        continue;
      }
      const files = await untar(buffer);
      for (const file of files) {
        if (file.size !== file.buffer.byteLength) continue;
        const nameParts = file.name.split('.');
        if (nameParts.length !== 2) continue;
        const ts = Number(nameParts[0]);
        const source = nameParts[1];
        const blob = new Blob([file.buffer], { type: 'image/jpeg' });
        const src = await blobAsDataUrl(blob);
        /** @type {VideoThumbnailData} */
        const item = { source, src, ts };
        yield item;
      }
    } catch (err) {
      if (signal.aborted) return;
      logger.warn('Failed to process recording thumbnail', thumbnail, err);
    }
  }
}
