import { Navigate } from 'react-router-dom';
import { CameraAssociatesFirmwares } from './cameras';
import { TenantsAssociateFirmwares } from './tenants';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <Navigate to={'./cameras'} replace />,
  },
  {
    path: 'cameras',
    element: <CameraAssociatesFirmwares />,
    breadcrumb: 'Cameras',
    accessLevel: 'SMARTER_AI_ADMIN',
  },
  {
    path: 'tenants',
    element: <TenantsAssociateFirmwares />,
    breadcrumb: 'Tenants',
    accessLevel: 'SMARTER_AI_ADMIN',
  },
];

export default routes;
