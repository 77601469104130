import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Grid, Typography } from '@mui/material';

export function GuidePositioningAndAttachmentArea() {
  return (
    <>
      <Box fontSize="1.375rem" fontWeight="600" mt={3} mb={2}>
        III. Positioning & attachment
      </Box>

      <Box py={2} px={3} gap={1} display="flex" borderRadius="10px" bgcolor="#F0F5FA">
        <Typography fontWeight="600">Note:</Typography>
        <Typography>
          Smarter AI recommends cleaning the windshield with alcohol prior to mounting the Smarter
          AI Dashcam.
        </Typography>
      </Box>

      <Grid
        container
        textAlign="center"
        spacing={3}
        my={1}
        sx={{
          '& .image-label': {
            py: 1,
            px: 3,
            mb: 2,
            display: 'inline-block',
            bgcolor: '#d4d7de',
            fontSize: '1rem',
            fontWeight: '600',
            borderRadius: '22px',
            textShadow: '0px 0px 99px #FFFFFF',
          },
        }}
      >
        <Grid item xs={12} md={6}>
          <Box className="image-label">First Step</Box>
          <BoxImage src="/images/guide/first-step-image.svg" width="90%" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="image-label">Second Step</Box>
          <BoxImage src="/images/guide/second-step-image.svg" width="90%" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="image-label">Third Step</Box>
          <BoxImage src="/images/guide/third-step-image.svg" width="90%" />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="image-label">Fourth Step</Box>
          <BoxImage src="/images/guide/fourth-step-image.svg" width="90%" />
        </Grid>
      </Grid>
    </>
  );
}
