import { Box, FormControl, FormLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';

const Android12Switch = styled(Switch)(({ theme }) => ({
  'padding': 8,
  '& .MuiSwitch-track': {
    borderRadius: 11,
    border: '1px solid #608AC3',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: '#B7C4D5',
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      'color': '#fff',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#608AC3',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        border: '1px solid #608AC3',
        backgroundColor: '#fff',
      },
    },
  },
}));

/**
 * @typedef {object} CustomSwitchProps
 * @property {any} name
 * @property {string} label
 * @property {boolean} [readOnlyValue]
 * @property {import('@mui/material').SwitchProps} [SwitchProps]
 * @property {import('react-hook-form').ControllerProps['rules']} [rules]
 */

/**
 * @param {CustomSwitchProps} props
 */
export function CustomSwitch(props) {
  const { name, readOnlyValue, label, rules, SwitchProps } = props;

  const { control } = useFormContext();

  return (
    <FormControl variant="standard" fullWidth>
      <Box display="flex" alignItems="center" gap="4px">
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Android12Switch
              checked={Boolean(readOnlyValue || field.value)}
              onChange={(e) => field.onChange(e.target.checked)}
              color={error ? 'error' : 'default'}
              title={error?.message || ''}
              {...SwitchProps}
            />
          )}
        />
        <FormLabel sx={{ fontWeight: 500, fontSize: '0.875rem' }}>{label}</FormLabel>
      </Box>
    </FormControl>
  );
}
