import api from '@/api';
import { STATUS_LIST } from '@/assets/constants/table';
import { useFetchTriggerCategories } from '@/hooks/useFetchTriggerCategories';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { SetFilterContext } from '@/web/@components/FilterContext';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { ACTIONS_ITEMS } from '@/web/smarter-ai/triggers/actions';
import { TRIGGER_TABLE_COLUMNS } from '@/web/smarter-ai/triggers/columns';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} SelectTriggerDialogProps
 * @property {Set<string>} value
 * @property {(triggers?: Array<CompositeTriggerDto>) => any} onChange
 */

/** @param {SelectTriggerDialogProps} props */
export function SelectTriggerDialog(props) {
  const { value, onChange } = props;

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const setFilter = useContext(SetFilterContext);
  const { selectedItems, setDisabledItems } = useContext(PaginatedTableContext);

  const { result: triggerCategories, loading: triggerCategoryLoading } =
    useFetchTriggerCategories();

  const clearFilters = () => {
    setFilter({
      searchText: null,
      searchType: null,
      limit: null,
      offset: null,
    });
  };

  const handleClose = () => {
    clearFilters();
    onChange(null);
  };

  const handleSelect = () => {
    clearFilters();
    onChange(selectedItems);
  };

  if (triggerCategoryLoading) {
    return <CenterBox style={{ height: '50vh' }} children={<CircularProgress />} />;
  }

  return (
    <Dialog open onClose={handleClose} fullWidth keepMounted maxWidth="lg">
      <DialogTitle component="div" display="flex" alignItems="center">
        Select Triggers
        <Box flex={1} />
        <ItemsListHeader actions={ACTIONS_ITEMS.filter((x) => x.component === 'SEARCH')} mb={0} />
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ pb: 0 }}>
        <OffsetPagination
          key={secretToken}
          fetcher={async ({ signal, limit, offset, searchText, searchType }) => {
            const request = api.ac.v5.trigger.composite.tenant.$tenantId(tenantId).filter.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                limit: limit,
                offset: offset,
                ...(searchText && searchType && { [searchType]: searchText.toUpperCase() }),
              },
            });
            await request.process();
            const data = request.result.compositeTriggers
              .filter((x) =>
                [STATUS_LIST.DRAFT, STATUS_LIST.RELEASED].includes(x.publicationStatus)
              )
              .map((item) => ({
                ...item,
                category: triggerCategories?.find((cat) => cat.id === item.triggerCategoryId)?.name,
              }));
            const selected = data.filter((x) => value.has(x.id));
            setDisabledItems(selected);
            return {
              data,
              count: Number(request.response.headers['x-total-count']),
            };
          }}
          renderList={(/** @type {Array<CompositeTriggerDto>} */ results) => (
            <ItemsResponsiveView
              results={results}
              columns={TRIGGER_TABLE_COLUMNS}
              sx={{
                'height': {
                  sm: 'fit-content',
                  md: 'calc(100vh - 300px)',
                },
                '& .MuiTableHead-root .MuiCheckbox-root > .MuiSvgIcon-root': null,
              }}
            />
          )}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSelect}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
