import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Grid } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DriverSafetyGridListItem } from '../@components/DriverSafetyGridListItem';
import { ScoringFilterContext } from '../@context/ScoringFilterContext';
import { SafetyScoreLayoutContext } from '../@layouts/SafetyScoreLayout';

export function DriverList() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { setBreadcrumbTitle } = useContext(MainContext);
  const { filteredData, setSelectedFilterTypes, setSelectedPersonaList } =
    useContext(ScoringFilterContext);
  const { personaList } = useContext(SafetyScoreLayoutContext);

  const personaId = params.get('personaId');

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<Array<DriverScoreWithDriverDetailDto>>} */
  const [drivers, setDrivers] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);

  const fetchDriversByPersona = useCallback(
    (signal) => {
      if (!personaId) return;
      let offset = 0;
      const limit = 100;
      try {
        setLoading(true);
        const request = api.ac.v5.score.persona
          .$personaId(Number(personaId))
          .tenant?.$tenantId(tenantId)
          .driver.details.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
            params: {
              driverIds: filteredData?.drivers?.map((driver) => driver?.driverId),
              fromTimestamp: startOfTheDay(filteredData?.dateRange?.from),
              toTimestamp: endOfTheDay(filteredData?.dateRange?.to),
              limit,
              offset,
              sort: 'DESC',
            },
          });
        request
          .process()
          .then((data) => {
            setDrivers(data?.list || []);
            if (data?.list) {
              setLoading(false);
            }
          })
          .catch((ex) => {
            setLoading(false);
          });
      } catch (ex) {
        setLoading(false);
        console.error(ex);
      }
    },
    [
      tenantId,
      secretToken,
      personaId,
      filteredData?.drivers,
      filteredData?.dateRange?.from,
      filteredData?.dateRange?.to,
    ]
  );

  useEffect(() => {
    const aborter = new AbortController();
    setLoading(true);

    const debouncedFetchDrivers = debounce(() => {
      fetchDriversByPersona(aborter?.signal);
    }, 500);

    debouncedFetchDrivers();

    return () => {
      setLoading(true);
      aborter?.abort();
    };
  }, [fetchDriversByPersona]);

  useEffect(() => {
    if (!personaId) return;
    const selectedPersona = personaList?.filter((persona) => persona?.id === Number(personaId));
    setSelectedPersonaList(selectedPersona);
    setBreadcrumbTitle('Driver - ' + selectedPersona?.at(0)?.name);
    setSelectedFilterTypes([1, 3, 4, 103]);
  }, [personaId, setSelectedPersonaList, personaList, setBreadcrumbTitle, setSelectedFilterTypes]);

  return (
    <Box mt={2} mb={10} px={2.5}>
      <Grid container spacing={2}>
        {loading ? (
          <CenterBox fullView>
            <CircularProgress />
          </CenterBox>
        ) : drivers?.length > 0 ? (
          drivers.map((driver, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <DriverSafetyGridListItem driver={driver?.driver} score={driver?.score} />
            </Grid>
          ))
        ) : (
          <CenterBox fullView>
            <IconMessageBox size="150px" src="/images/empty/no-users.svg" message="No Drivers!" />
          </CenterBox>
        )}
      </Grid>
    </Box>
  );
}
