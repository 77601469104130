import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export function GuideRFInterfacesTable() {
  return (
    <Box width="100%" style={{ overflowX: 'auto' }}>
      <Table padding="none" sx={{ '& .MuiTableCell-root': { px: 2, py: 1 } }}>
        <TableHead
          sx={{ '& .MuiTableCell-root': { color: '#fff', fontWeight: '600', border: 'none' } }}
        >
          <TableRow style={{ background: '#596A81' }}>
            <TableCell colSpan={2}>RF Interfaces</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-root': { border: '1px solid #DCE7F5', borderTop: 'none' },
          }}
        >
          <TableRow>
            <TableCell width="30%">Wi-Fi</TableCell>
            <TableCell>802.11 a/b/g/n/ac (2.4GHz & 5GHz)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Bluetooth</TableCell>
            <TableCell>4.2 BLE</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>GNSS</TableCell>
            <TableCell>GPS, QZSS, GLONASS, Galileo</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>LTE</TableCell>
            <TableCell>
              <b>European Version</b>
              <br />
              LTE FDD Band 1/2/3/4/5/7/8/20/28;
              <br />
              LTE TDD Band 38/39/40/41;
              <br />
              WCDMA Band 1/2/4/5/8;
              <br />
              GSM 850/900/1800/1900MHz
              <br />
              <br />
              <b>North America Version</b>
              <br />
              LTE FDD Band 2/4/5/7/12/13/14/17/25/26/66/71;
              <br />
              LTE TDD Band 41;
              <br />
              WCDMA Band 2/4/5
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Driver ID Cards</TableCell>
            <TableCell>
              NFC: MiFare®, FeliCa
              <br />
              RFID (HF): ISO14443A&B, ISO15693
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
