import { UnderDevelop } from '@/web/@components/UnderDevelop';
import { DeviceBatchListView } from '@/web/smarter-ai/device-batches';
import { T } from '@/assets/locales';
import { UpdateDeviceBatchPage } from '@/web/smarter-ai/device-batches/_id/edit';
import { CreateDeviceBatchPage } from '@/web/smarter-ai/device-batches/create';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <DeviceBatchListView />,
    breadcrumb: T['device-batch.title'],
  },
  {
    path: 'create',
    element: <CreateDeviceBatchPage />,
    breadcrumb: T['device-batch.create'],
  },
  {
    path: ':id',
    children: [
      {
        path: 'details',
        element: <UnderDevelop />,
        breadcrumb: T['button.details'],
      },
      {
        path: 'edit',
        element: <UpdateDeviceBatchPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
