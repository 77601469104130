import api from '@/api';
import { T } from '@/assets/locales';
import { TRIGGERS_MEDIA_TYPES, TRIGGER_CHIME_OPTIONS } from '@/assets/triggers';
import { useFetchTriggerCategories } from '@/hooks/useFetchTriggerCategories';
import { useLabelListFetch } from '@/hooks/useLabelListFetch';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { findExpressionLabels } from '@/utils/triggers';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpressionGenerator } from '../ExpressionGenerator';

/**
 * @typedef {object} ExpressionTriggerFormPropsBase
 * @property {(data: CompositeTriggerRequest, triggerId: any) => any} onSubmitData
 * @property {boolean} isLoading
 */

const defaultValues = {
  cooldownTimer: 30,
  mediaType: TRIGGERS_MEDIA_TYPES[3],
  chime: TRIGGER_CHIME_OPTIONS[0],
  tts: '',
  expression: '',
  displayFormula: '',
  critical: false,
  triggerCategory: null,
  classifications: [],
};

/**
 * @typedef {ExpressionTriggerFormPropsBase & import('@mui/material').BoxProps} ExpressionTriggerFormProps
 */

/**
 * @param {ExpressionTriggerFormProps} props
 */
export function ExpressionTriggerForm(props) {
  const { onSubmitData, isLoading, ...extra } = props;
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { result: labels } = useLabelListFetch();
  const { result: triggerCategories, loading: triggerCategoryLoading } =
    useFetchTriggerCategories();

  const triggerId = useMemo(() => params.id, [params]);

  const form = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });
  const displayFormulaField = useWatch({
    control: form.control,
    name: `displayFormula`,
  });
  const classificationsField = useWatch({
    control: form.control,
    name: `classifications`,
  });

  const handleCancel = () => {
    navigate('/smarter-ai/triggers');
  };

  /** @param {typeof defaultValues} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;

    data.expression = trimString(data.expression);
    data.displayFormula = trimString(data.displayFormula);

    const {
      cooldownTimer,
      mediaType,
      chime,
      tts,
      critical,
      displayFormula,
      triggerCategory,
      classifications,
    } = data;
    const name = triggerCategory?.name || 'Default';

    const bodyObj = {
      expression: displayFormula,
      displayFormula: displayFormula,
      classifications: classifications || [],
      type: 'EXPRESSION',
    };

    /** @type {CompositeTriggerRequest} */
    const formData = {
      name,
      cooldown: false,
      cooldownTimer: cooldownTimer ? cooldownTimer * 1000 : 0,
      mediaType: mediaType?.value,
      critical,
      tts,
      triggerCategoryId: triggerCategory?.id || 1,
      chime: chime?.value,
      body: JSON.stringify(bodyObj),
    };

    onSubmitData(formData, triggerId);
  };

  useEffect(() => {
    if (triggerCategoryLoading || !triggerCategories?.length) return;

    if (!triggerId) {
      const triggerCategory = triggerCategories?.find((item) => item.id === 1); //default
      form.setValue('triggerCategory', triggerCategory);
      setBreadcrumbTitle(triggerCategory?.name || 'Default');
      return;
    }

    const req = api.ac.v5.trigger.composite.$triggerId(triggerId).$get({
      headers: {
        Authorization: secretToken,
      },
      params: {
        tenantId,
      },
    });
    req.process().then((result) => {
      if (!result) return;
      const { name, mediaType, critical, chime, tts, cooldownTimer, body, triggerCategoryId } =
        result;

      if (cooldownTimer) {
        form.setValue('cooldownTimer', cooldownTimer?.value / 1000);
      }
      form.setValue('tts', tts);
      form.setValue('critical', critical);

      const triggerCategory = triggerCategories?.find((item) => item.id === triggerCategoryId);
      form.setValue('triggerCategory', triggerCategory);
      setBreadcrumbTitle(triggerCategory?.name || name);

      const selectedMediaType = TRIGGERS_MEDIA_TYPES?.find(
        (item) => item.value === mediaType?.value
      );
      if (selectedMediaType) {
        form.setValue('mediaType', selectedMediaType);
      }

      const selectedChime = TRIGGER_CHIME_OPTIONS?.find((item) => item.value === chime?.value);
      if (selectedChime) form.setValue('chime', selectedChime);
      const expression = body?.displayFormula || body?.expression;
      form.setValue('expression', expression);
      form.setValue('displayFormula', expression);
      form.setValue('classifications', body?.classifications || []);
    });

    return () => req.abort();
  }, [
    form,
    triggerId,
    secretToken,
    tenantId,
    setBreadcrumbTitle,
    triggerCategories,
    triggerCategoryLoading,
  ]);

  useEffect(() => {
    const exp = findExpressionLabels(labels, displayFormulaField);
    const value = classificationsField || [];
    const extra = exp?.filter((x) => !value.some((y) => y.label === x.label));
    if (!extra?.length) return;
    form.setValue('classifications', [...value, ...extra]);
  }, [form, labels, classificationsField, displayFormulaField]);

  return (
    <Box {...extra}>
      <Typography
        variant="body2"
        fontSize="1rem"
        mt={'20px'}
        mb={'10px'}
        sx={{ color: ' #0B2547', opacity: 0.68, fontSize: '16px' }}
      >
        Trigger Details
      </Typography>
      <Divider />
      <FormProvider {...form}>
        <form autoComplete="off" noValidate>
          <Box
            display="grid"
            gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr 1fr' }}
            gap="10px"
            paddingTop="10px"
            paddingX="10px"
          >
            <CustomSelect
              name="triggerCategory"
              label="Trigger Name *"
              options={triggerCategories || []}
              loading={triggerCategoryLoading}
              getKey="id"
              getLabel="name"
              placeholder="Select Trigger Name"
              rules={{ required: 'Trigger name is required' }}
            />

            <CustomInput
              name="cooldownTimer"
              label="Cooldown Timer (Seconds) *"
              placeholder="Enter cooldown timer"
              type="number"
              rules={{
                required: 'Cooldown Timer is required',
                validate: {
                  cooldownTimer: (value) =>
                    value < 1 || value > 3600
                      ? 'Cooldown Timer should be between 1 to 3600'
                      : undefined,
                },
              }}
            />

            <CustomSelect
              name="mediaType"
              label="Media Type *"
              options={TRIGGERS_MEDIA_TYPES}
              placeholder="Select media type"
              rules={{ required: 'Media type is required' }}
            />

            <CustomSelect
              name="chime"
              label="Chime"
              options={TRIGGER_CHIME_OPTIONS}
              placeholder="Select chime"
            />

            <CustomInput
              name="tts"
              label="Voice Alert"
              placeholder="Voice alert for your Driver"
              TextFieldProps={{
                multiline: false,
                inputProps: {
                  maxLength: 30,
                },
              }}
              rules={{
                validate: {
                  tts: (value) => (value?.length > 30 ? 'Maximum length is 30' : undefined),
                },
              }}
            />
          </Box>

          <ExpressionGenerator labels={labels} />

          <Box pt={2.5} px={1.5}>
            <CustomSelect
              name="classifications"
              label="Labels"
              getKey="label"
              getLabel="name"
              loading={labels?.length <= 0}
              options={labels?.length > 0 ? labels : []}
              placeholder="Select Labels"
              AutocompleteProps={{
                multiple: true,
              }}
            />
          </Box>

          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={10}>
            <Button variant="text" onClick={handleCancel}>
              {t(T['button.cancel'])}
            </Button>
            <Button
              disabled={isLoading}
              variant="contained"
              onClick={form.handleSubmit(prepareAndSubmit)}
            >
              {isLoading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              {triggerId ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
