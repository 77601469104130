export const TAG_TITLES = {
  'Camera.SD.Absent': 'SD Card Absent',
  'Camera.SD.Corrupt': 'SD Card Corrupted',
  'Events.None': 'Camera Events Absent',
  'Events.NoSnapshot': 'Camera Events Snapshot Absent',
  'Events.NoVideo': 'Camera Events Video Absent',
  'GPS.None': 'Camera GPS Absent',
  'GPS.Partial': 'Camera GPS Partial Absent',
  'Imager.Cabin.Adjust': 'Adjust Cabin Camera',
  'Imager.Cabin.Blue': 'Remove Cabin Camera Lens Blue Cover',
  'Imager.Cabin.Inoperable': 'Inoperable Driver Camera Position',
  'Imager.Cabin.Obstructed': 'Cabin Camera Obstructed',
  'Imager.Road.Adjust': 'Adjust Road Camera',
  'Imager.Road.Blue': 'Remove Road Camera Lens Blue Cover',
  'Imager.Road.Inoperable': 'Inoperable Road Camera Position',
  'Imager.Road.Obstructed': 'Road Camera Obstructed',
  'Internet.NoData': 'Camera Internet Run Out of Data',
  'Internet.RateLimited': 'Camera Internet Poor Connectivity',
  'Internet.SIM.Absent': 'Camera GSM Data Absent',
  'LMK.Camera': 'Camera App Killed For Low Memory',
  'LMK.Updater': 'Updater App Killed For Low Memory',
  'Power.Cut': 'Camera Power Absent',
  'Power.Loose': 'Camera Power Loose',
  'Trip.NoEnd': 'Camera Trip Endless',
  'Trip.Short': 'Camera Trip Short',
  'Trip.Thumbnail.None': 'Camera Trip Thumbnails Absent',
  'Trip.Thumbnail.Partial': 'Camera Trip Thumbnails Partial Absent',
  'Trip.TooLong': 'Camera Trip Long',
  'Internet.OutOfRange': 'Internet Out of Range',
  'Trip.GPS.None': 'Trip GPS Absent',
  'Trip.GPS.Partial': 'Trip GPS Partial Absent',
  'Event.GPS.None': 'Event GPS Absent',
  'Event.GPS.Partial': 'Event GPS Partial Absent',
  'Camera.Unstable': 'Camera Problem',
  'Camera.Offline.30d': 'Camera at Offline 30Days',
  'Camera.Offline.3d+': 'Camera at Offline 3Days',
  'Cover.Removed': 'Camera Cover Removed',
  'Internet.SIM.Removed': 'Camera SIM Removed',
  'GO.Protocol.Error': 'GO protocol Error',
  'GO.Reset': 'GO Reset',
  'GO.Changed': 'GO Changed',
};
