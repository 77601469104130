import { ItemStatus } from '@/web/@components/ItemStatus';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';
import { PageLink } from '../../@components/PageLink/index';

/** @type {Array<TableColumn<GeofenceResponseModel>>} */
export const GEOFENCE_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    format: (value, row) => <PageLink to={`/operations/geofences/${row.id}`}>{row.name}</PageLink>,
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
  },
  {
    id: 'publicationStatus',
    label: 'Status',
    align: 'left',
    format: (value) => <ItemStatus status={value} />,
  },
];
