import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { App } from './App';
import { onBeforeLift, persistor, store } from './store';
import { setupI18n } from './utils/i18n-config';
import { setupConsole } from './utils/logger';
import { setupSentryIO } from './utils/sentry';
import { setupToaster } from './utils/toaster';

setupConsole();
setupI18n();
setupToaster();
setupSentryIO();

const root = createRoot(document.getElementById('root'), {});
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} onBeforeLift={onBeforeLift} loading={<></>}>
      <App />
    </PersistGate>
  </Provider>
);
