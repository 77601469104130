import { sortedIndexBy } from 'lodash';

/**
 * Creates a virtual element from the mouse event for popper.js
 * @param {google.maps.MapMouseEvent['domEvent']} e
 * @returns {import("@popperjs/core").VirtualElement?}
 */
export function createVirtualElement(e) {
  if (!(e instanceof MouseEvent)) return null;
  if (!(e.target instanceof Element)) return null;
  const size = 64;
  const rect = new DOMRect(e.clientX - size, e.clientY - size, 2 * size, 2 * size);
  return {
    getBoundingClientRect: () => rect,
    contextElement: e.target,
  };
}

/**
 * Find a geo point index pair for the given timestamp
 * @param {Array<GeoWithTimestamp>} points
 * @param {number} timestamp
 * @returns {[number, number]}
 */
export function findGeoPointIndices(points, timestamp) {
  if (!points.length) return null;
  const index = sortedIndexBy(points, { timestamp }, 'timestamp');
  const left = Math.max(index - 1, 0);
  const right = Math.min(index, points.length - 1);
  return [left, right];
}

/**
 * Find a geo point pair for the given timestamp
 * @param {Array<GeoWithTimestamp>} points
 * @param {number} timestamp
 * @returns {[GeoWithTimestamp, GeoWithTimestamp]}
 */
export function findGeoPoints(points, timestamp) {
  if (!points.length) return null;
  const [left, right] = findGeoPointIndices(points, timestamp);
  return [points[left], points[right]];
}

/**
 * Interpolate a geo point using the given timestamp
 * @param {number} timestamp
 * @param {GeoWithTimestamp} left
 * @param {GeoWithTimestamp} right
 * @returns {Geo?}
 */
export function interpolatePoint(timestamp, left, right) {
  // linear-interpolation
  let x0, x1, y0, y1, x;

  x = timestamp;
  x0 = left.timestamp;
  x1 = right.timestamp;
  if (x0 === x) return left.geo;
  if (x1 === x) return right.geo;
  if (x < x0 || x > x1) return null;

  y0 = left.geo.lat;
  y1 = right.geo.lat;
  const lat = (y0 * (x1 - x) + y1 * (x - x0)) / (x1 - x0); // https://en.wikipedia.org/wiki/Linear_interpolation

  y0 = left.geo.lon;
  y1 = right.geo.lon;
  const lon = (y0 * (x1 - x) + y1 * (x - x0)) / (x1 - x0);

  return { lat, lon };
}
