import { CenterBox } from '@/web/@components/CenterBox';
import { GuidePageContainer } from '../GuidePageContainer';
import { GuideCablesOverviewArea } from './GuideCablesOverviewArea';
import { GuideOnboardingAndAdjustment } from './GuideOnboardingAndAdjustment';
import { GuidePositioningAndAttachmentArea } from './GuidePositioningAndAttachmentArea';
import { GuideQuickInstallationIntroductionArea } from './GuideQuickInstallationIntroductionArea';
import { GuideSmarterAIDashcamOverviewArea } from './GuideSmarterAIDashcamOverviewArea';

export function GuideQuickInstallationArea() {
  return (
    <CenterBox id="quick-installation-area" textAlign="left">
      <GuidePageContainer flexDirection="column" alignItems={null}>
        <GuideQuickInstallationIntroductionArea />
        <GuideSmarterAIDashcamOverviewArea />
        <GuideCablesOverviewArea />
        <GuidePositioningAndAttachmentArea />
        <GuideOnboardingAndAdjustment />
      </GuidePageContainer>
    </CenterBox>
  );
}
