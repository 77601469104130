import { Box, Typography } from '@mui/material';

const calculatePercentage = (value, min, max) => {
  // Ensure that the value is within the specified range
  const clampedValue = Math.min(max, Math.max(min, value));
  // Calculate the percentage within the range
  return ((clampedValue - min) / (max - min)) * 100;
};

export function SensitivityMiniRangeBar(props) {
  const { value, unit, min = 0, max = 100, isHovered } = props;

  // Normalize the value to be between min and max
  const positionPercentage = calculatePercentage(value, min, max);

  const lineStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '4.6px',
    backgroundColor: isHovered ? '#fff' : '#ECF0F4',
    position: 'relative',
    borderRadius: '50px',
  };

  const circleStyle = {
    width: '12px',
    height: '12px',
    borderRadius: '70%',
    border: '2px solid white',
    backgroundColor: '#608ac3',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: `${positionPercentage}%`,
  };

  const blueLineStyle = {
    flexGrow: 1,
    height: '100%',
    backgroundColor: '#608AC3',
    margin: `0 ${100 - positionPercentage - 6}% 0 0`,
    borderRadius: '50px',
  };

  return (
    <Box sx={{ ...lineStyle, mt: -1 }}>
      <Box sx={blueLineStyle}></Box>
      <Box sx={circleStyle}>
        <Box pt={0.4}></Box>
        <Typography
          variant="caption"
          sx={{
            whiteSpace: 'nowrap',
            ml: value === 0 ? 0 : `${-4 * unit?.length}px`,
          }}
        >
          {value} {unit}
        </Typography>
      </Box>
    </Box>
  );
}
