import { formatDateRangePair } from '@/utils/datetime';
import { DateRangePickerPopoverContent } from '@/web/@components/CustomDateRangePicker/DateRangePickerPopoverContent';
import { Add, Close } from '@mui/icons-material';
import { Box, Button, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { SelectionFilterContent } from './SelectionFilterContent';
import { TextFilterContent } from './TextFilterContent';
import { ScoreFilterContent } from './ScoreFilterContent';

/**
 * @typedef {object} FilterMenuItem
 * @property {string} key
 * @property {string} title
 * @property {boolean} [hidden]
 * @property {boolean} [required]
 * @property {Array<string>} [submenu]
 * @property {boolean} [partialSearch]
 * @property {boolean} [disableClose]
 * @property {'DATE'|'SELECT'|'CHOICE'|'TEXT'|'BOOLEAN'| 'SCORE'} type
 */

/**
 * @typedef {object} PageFilteringAreaProps
 * @property {{[key: string]: any}} value
 * @property {(next: {[key: string]: any}) => any} onChange
 * @property {Array<FilterMenuItem>} filters
 */

/**
 * @param {PageFilteringAreaProps} props
 */
export function PageFilteringArea(props) {
  const { filters, value, onChange } = props;

  /** @type {StateVariable<any>} */
  const [showMenu, setShowMenu] = useState();
  /** @type {StateVariable<string>} */
  const [selectedKey, setSelectedKey] = useState();

  const isActive = useCallback(
    /** @param {FilterMenuItem} filter */
    (filter) => {
      if (typeof value[filter.key] === 'object') {
        return !isEmpty(value[filter.key]);
      }
      return Boolean(value[filter.key]);
    },
    [value]
  );

  const visibleFilters = useMemo(() => filters.filter((item) => !item.hidden), [filters]);

  const activeFilters = useMemo(() => visibleFilters.filter(isActive), [visibleFilters, isActive]);

  const selected = useMemo(
    () => filters.find((x) => x.key === selectedKey),
    [filters, selectedKey]
  );

  /**
   * @param {Element} target
   */
  const openMainMenu = (target) => {
    setSelectedKey(undefined);
    setShowMenu(target);
  };

  /**
   * @param {Element} target
   * @param {FilterMenuItem} item
   */
  const openSubMenu = (target, item) => {
    if (item.type === 'BOOLEAN') {
      handelSubmitFilter(item.key, !value[item.key]);
      return;
    }
    setSelectedKey(item.key);
    setShowMenu(target);
  };

  const closeMenu = () => {
    setShowMenu(undefined);
    setSelectedKey(undefined);
  };

  /**
   * @param {string} key
   * @param {any} [val]
   */
  const handelSubmitFilter = (key, val) => {
    onChange({ ...value, [key]: val });
    closeMenu();
  };

  /**
   * @param {[any, any]} [range]
   * @param {string} [sep]
   */
  const formatScoreRangePair = (range, sep = ' - ') => {
    if (!range?.length || range.length !== 2) return '';
    return `${range[0]}${sep}${range[1]} `;
  };

  return (
    <Box
      p={1}
      gap="10px"
      display="flex"
      alignItems={'center'}
      border="1px solid #C5D9F0"
      borderRadius="6px"
      mb={1}
      sx={{
        'overflowX': 'auto',
        'scrollbarWidth': 'none',
        'msOverflowStyle': 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <Button
        size="small"
        variant="outlined"
        onClick={(e) => openMainMenu(e.currentTarget)}
        style={{
          color: '#4E76BA',
          borderRadius: '20px',
          padding: '1.5px 10px',
          border: '1px dashed #4E76BA',
          minWidth: '120px',
          fontSize: '0.875rem',
        }}
        startIcon={<Add />}
      >
        Add Filter
      </Button>

      {activeFilters.map((item) => (
        <Box
          key={item.key}
          border="1px solid #C5D9F0"
          borderRadius="20px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p="0 5px"
        >
          <Box p="0 5px" onClick={(e) => openSubMenu(e.currentTarget, item)}>
            <Typography
              variant="subtitle2"
              sx={{
                'width': 'max-content',
                '& > b': { fontWeight: 500 },
              }}
            >
              {item.type === 'BOOLEAN' ? (
                <b>{item.title}</b>
              ) : item.type === 'DATE' ? (
                <span>
                  <b>{item.title}</b>: {formatDateRangePair(value[item.key])}
                </span>
              ) : item.type === 'SCORE' ? (
                <span>
                  <b>{item.title}</b>: {formatScoreRangePair(value[item.key])}
                </span>
              ) : item.type === 'SELECT' ? (
                <span>
                  <b>{item.title}</b>: {value[item.key].join(', ')}
                </span>
              ) : (
                <span>
                  <b>{item.title}</b>: {value[item.key]}
                </span>
              )}
            </Typography>
          </Box>
          {!item.required && !item?.disableClose && (
            <IconButton size="small" onClick={() => handelSubmitFilter(item.key)}>
              <Close style={{ fontSize: '16px' }} />
            </IconButton>
          )}
        </Box>
      ))}

      {!showMenu ? null : !selected ? (
        <Menu
          open
          elevation={2}
          anchorEl={showMenu}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              style: {
                marginTop: '5px',
                minWidth: '125px',
              },
              sx: {
                '.MuiMenuItem-root': {
                  fontSize: '0.875rem',
                },
              },
            },
          }}
        >
          {visibleFilters.map((item) => (
            <MenuItem key={item.key} onClick={() => openSubMenu(showMenu, item)}>
              <span style={{ fontWeight: isActive(item) ? '500' : undefined }}>{item.title}</span>
            </MenuItem>
          ))}
        </Menu>
      ) : (
        <Popover
          open
          elevation={2}
          anchorEl={showMenu}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {selected.type === 'DATE' ? (
            <DateRangePickerPopoverContent
              startTime={value[selected.key][0]}
              endTime={value[selected.key][1]}
              onChange={(range) => {
                handelSubmitFilter(selected.key, [range.startTime, range.endTime]);
              }}
            />
          ) : selected.type === 'CHOICE' ? (
            <SelectionFilterContent
              item={selected}
              partial={selected.partialSearch}
              initialValue={value[selected.key]}
              onSubmit={(val) => handelSubmitFilter(selected.key, val)}
              onCancel={closeMenu}
            />
          ) : selected.type === 'SELECT' ? (
            <SelectionFilterContent
              multi
              item={selected}
              initialValue={value[selected.key]}
              onSubmit={(val) => handelSubmitFilter(selected.key, val)}
              onCancel={closeMenu}
            />
          ) : selected.type === 'SCORE' ? (
            <ScoreFilterContent
              from={value[selected.key][0]}
              to={value[selected.key][1]}
              onChange={(range) => {
                handelSubmitFilter(selected.key, [range.from, range.to]);
              }}
              onCancel={closeMenu}
            />
          ) : (
            <TextFilterContent
              item={selected}
              initialValue={selected[selected.key]}
              onSubmit={(val) => handelSubmitFilter(selected.key, val)}
              onCancel={closeMenu}
            />
          )}
        </Popover>
      )}
    </Box>
  );
}
