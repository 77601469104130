/**
 * Stack-based Douglas Peucker line simplification algorithm
 * @param {Array<GeoWithTimestamp>} source input
 * @param {number} [tolerance] in meters
 * @returns {Array<GeoWithTimestamp>}
 */
export function DouglasPeucker(source, tolerance = 0) {
  /* check for simple cases */
  if (source.length < 3 || !tolerance) {
    return source;
  }

  /* more complex case. initialize stack */
  let start, end, i, sig;
  let n_source, n_dest, n_stack;
  let dev_sqr, max_dev_sqr, band_sqr;
  let x12, y12, d12, x13, y13, d13, x23, y23, d23;

  n_source = source.length;

  const F = (Math.PI / 180.0) * 0.5;
  const index = new Array(n_source); /* indexes of source points in the reduced line */
  const sig_start = new Array(n_source); /* indices of start of working section */
  const sig_end = new Array(n_source); /* indices of end of working section */

  n_dest = 0;
  n_stack = 1;
  sig_start[0] = 0;
  sig_end[0] = n_source - 1;

  band_sqr = (tolerance * 360.0) / (2.0 * Math.PI * 6378137.0); /* Now in degrees */
  band_sqr *= band_sqr;

  /* while the stack is not empty  ... */
  while (n_stack > 0) {
    /* ... pop the top-most entries off the stacks */

    start = sig_start[n_stack - 1];
    end = sig_end[n_stack - 1];
    n_stack--;

    if (end - start > 1) {
      /* any intermediate points ? */

      /* ... yes, so find most deviant intermediate point to
                       either side of line joining start & end points */

      x12 = source[end].geo.lon - source[start].geo.lon;
      y12 = source[end].geo.lat - source[start].geo.lat;
      if (Math.abs(x12) > 180.0) x12 = 360.0 - Math.abs(x12);
      x12 *= Math.cos(
        F * (source[end].geo.lat + source[start].geo.lat)
      ); /* use avg lat to reduce lng */
      d12 = x12 * x12 + y12 * y12;

      for (i = start + 1, sig = start, max_dev_sqr = -1.0; i < end; i++) {
        x13 = source[i].geo.lon - source[start].geo.lon;
        y13 = source[i].geo.lat - source[start].geo.lat;
        if (Math.abs(x13) > 180.0) {
          x13 = 360.0 - Math.abs(x13);
        }
        x13 *= Math.cos(F * (source[i].geo.lat + source[start].geo.lat));
        d13 = x13 * x13 + y13 * y13;

        x23 = source[i].geo.lon - source[end].geo.lon;
        y23 = source[i].geo.lat - source[end].geo.lat;
        if (Math.abs(x23) > 180.0) {
          x23 = 360.0 - Math.abs(x23);
        }
        x23 *= Math.cos(F * (source[i].geo.lat + source[end].geo.lat));
        d23 = x23 * x23 + y23 * y23;

        if (d13 >= d12 + d23) {
          dev_sqr = d23;
        } else if (d23 >= d12 + d13) {
          dev_sqr = d13;
        } else {
          dev_sqr = ((x13 * y12 - y13 * x12) * (x13 * y12 - y13 * x12)) / d12; // solve triangle
        }

        if (dev_sqr > max_dev_sqr) {
          sig = i;
          max_dev_sqr = dev_sqr;
        }
      }

      if (max_dev_sqr < band_sqr) {
        /* is there a sig. intermediate point ? */
        /* ... no, so transfer current start point */
        index[n_dest] = start;
        n_dest++;
      } else {
        /* ... yes, so push two sub-sections on stack for further processing */
        n_stack++;
        sig_start[n_stack - 1] = sig;
        sig_end[n_stack - 1] = end;
        n_stack++;
        sig_start[n_stack - 1] = start;
        sig_end[n_stack - 1] = sig;
      }
    } else {
      /* ... no intermediate points, so transfer current start point */
      index[n_dest] = start;
      n_dest++;
    }
  }

  /* transfer last point */
  index[n_dest] = n_source - 1;
  n_dest++;

  /* make return array */
  const r = [];
  for (let i = 0; i < n_dest; i++) {
    r.push(source[index[i]]);
  }
  return r;
}
