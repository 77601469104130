import { Link } from '@mui/material';
import { useState } from 'react';
import { CameraNetworkChangeModal } from '../CameraNetworkChangeModal';

/**
 * @typedef {object} CameraNetworkChangeDetailsModalProps
 * @property {CameraTypeRecord} [row]
 */

/** @param {CameraNetworkChangeDetailsModalProps} props */
export function CameraNetworkChangeDetails(props) {
  const { row } = props;

  /** @type {StateVariable<boolean>} */
  const [detailsDialog, setDetailsDialog] = useState(false);

  return (
    <>
      {detailsDialog && (
        <CameraNetworkChangeModal onCancel={() => setDetailsDialog(false)} row={row} />
      )}
      <Link onClick={() => setDetailsDialog(true)}>Details</Link>
    </>
  );
}
