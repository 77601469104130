import api from '@/api';
import { T } from '@/assets/locales';
import { SCHEDULE_TIMEZONES } from '@/assets/operations/geofences';
import { GOOGLE_MAP_API_KEY } from '@/config';
import { useFetchAIContainers } from '@/hooks/useFetchAIContainers';
import { useFetchTriggers } from '@/hooks/useFetchTriggers';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { secondToTimeFormat } from '@/utils/datetime';
import { trimString } from '@/utils/formatting';
import { humanReadableTimeDiff } from '@/utils/geofence';
import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, Button, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GeoFenceSchedulePicker } from '../GeoFenceSchedulePicker';
import GeoFenceVINAddModal from '../GeoFenceVINAddModal';
import { GeofenceMap } from '../GeofenceMap';

/**
 * @typedef {object} GeoFenceDefaultValues
 * @property {string} name
 * @property {AIContainerV2} aiContainer
 * @property {Array<CompositeTriggerDto>} triggers
 * @property {Array<string>} vins
 * @property {string} description
 * @property {string} diffInText
 * @property {string} locationName
 * @property {{value: string, label: string}} timezone
 * @property {number} radius
 * @property {number} latitude
 * @property {number} longitude
 * @property {number} startTimestamp
 * @property {number} endTimestamp
 * @property {string} daySecondStart
 * @property {string} daySecondEnd
 * @property {string} scheduleId
 */

/** @type {GeoFenceDefaultValues} */
const defaultValues = {
  name: '',
  aiContainer: null,
  triggers: [],
  vins: [],
  description: '',
  diffInText: '',
  locationName: '',
  timezone: SCHEDULE_TIMEZONES[20],
  radius: null,
  latitude: null,
  longitude: null,
  startTimestamp: null,
  endTimestamp: null,
  daySecondStart: '',
  daySecondEnd: '',
  scheduleId: '',
};

/**
 * @typedef {object} GeofenceFormPropsBase
 * @property {(data: GeoFenceDefaultValues) => any} onSubmitData
 * @property {boolean} [loading]
 */

/**
 * @typedef {GeofenceFormPropsBase & import('@mui/material').BoxProps} GeofenceFormProps
 */

/**
 * @param {GeofenceFormProps} props
 */
export function GeofenceForm(props) {
  const { onSubmitData, loading } = props;
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);

  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const geofenceId = useMemo(() => params.id, [params]);

  const methods = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showAddVINModal, setShowAddVINModal] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState(null);
  const [isGeofenceLoaded, setIsGeofenceLoaded] = useState(false);
  const [isGeofenceReleased, setIsGeofenceReleased] = useState(false);

  const { handleSubmit, watch, setValue } = methods;

  const startTimestamp = watch('startTimestamp');
  const triggers = watch('triggers');
  const diffInText = watch('diffInText');
  const totalVin = watch('vins')?.length || 0;

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAP_API_KEY,
    options: {
      types: ['geocode'],
      fields: ['address_components', 'geometry', 'place_id', 'formatted_address'],
    },
    onPlaceSelected: (place) => {
      const loc = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        types: place?.address_components[0]?.types || [],
      };
      setValue('latitude', loc.lat);
      setValue('longitude', loc.lng);
      setSearchedLocation(loc);
    },
  });

  const { result: containerList, loading: containerLoading } = useFetchAIContainers();
  const { result: triggerList, loading: triggerListLoading } = useFetchTriggers();

  /** @param {GeoFenceDefaultValues} data */
  const onSubmit = (data) => {
    data.name = trimString(data.name);
    data.description = trimString(data.description);
    data.diffInText = trimString(data.diffInText);
    data.locationName = trimString(data.locationName);
    data.daySecondStart = trimString(data.daySecondStart);
    data.daySecondEnd = trimString(data.daySecondEnd);
    data.scheduleId = trimString(data.scheduleId);
    onSubmitData && onSubmitData(data);
  };

  const handleCancel = () => {
    navigate('/operations/geofences');
  };

  useEffect(() => {
    if (!geofenceId || triggerList?.length <= 0 || containerList?.length <= 0) return;
    const req = api.ac.v3.geolocation.fence.$id(geofenceId).$get({
      headers: {
        Authorization: secretToken,
      },
      params: {
        tenantId,
      },
    });
    req.process().then((result) => {
      if (!result) return;
      const { geofence, scheduleWithVinListAndDeploymentDetails } = result;
      const { deployedAiContainerId, attachedVinList, deployedTriggerIdList, schedule } =
        scheduleWithVinListAndDeploymentDetails;

      const { description, name, latitude, longitude, radius, timezone, publicationStatus } =
        geofence;
      const { id, startTimestamp, endTimestamp, daySecondStart, daySecondEnd } = schedule;

      setBreadcrumbTitle(name);

      setIsGeofenceReleased(publicationStatus === 'RELEASED');

      setValue('description', description);
      setValue('name', name);
      setValue('vins', attachedVinList);

      setValue('latitude', latitude);
      setValue('longitude', longitude);
      setValue('radius', radius);
      setValue('scheduleId', id);

      const selectedContainer = containerList?.find(
        (item) => item.containerId === deployedAiContainerId
      );
      setValue('aiContainer', selectedContainer);
      setValue('locationName', schedule?.name);

      const selectedTriggers = triggerList?.filter((item) =>
        deployedTriggerIdList.includes(item.id)
      );

      setValue('triggers', selectedTriggers);

      const selectedTimezone = SCHEDULE_TIMEZONES?.find((item) => item.value === timezone);
      setValue('timezone', selectedTimezone);

      setValue('startTimestamp', startTimestamp);
      setValue('endTimestamp', endTimestamp);

      setValue('daySecondStart', secondToTimeFormat(daySecondStart));
      setValue('daySecondEnd', secondToTimeFormat(daySecondEnd));

      const diffInText = humanReadableTimeDiff(
        startTimestamp,
        endTimestamp,
        daySecondStart,
        daySecondEnd
      );
      setValue('diffInText', diffInText);
    });
    return () => req.abort();
  }, [triggerList, containerList, geofenceId, setValue, secretToken, tenantId, setBreadcrumbTitle]);

  useEffect(() => {
    if (geofenceId) return;
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const fourHoursInSeconds = 4 * 3600;
    const newTimeInSeconds = currentTimeInSeconds + fourHoursInSeconds;
    setValue('daySecondStart', secondToTimeFormat(currentTimeInSeconds));
    setValue('daySecondEnd', secondToTimeFormat(newTimeInSeconds));
  }, [geofenceId, setValue]);

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" noValidate>
        {showAddVINModal && <GeoFenceVINAddModal onHide={() => setShowAddVINModal(false)} />}
        {showScheduleModal && <GeoFenceSchedulePicker onHide={() => setShowScheduleModal(false)} />}
        <Box display="flex" gap="20px" flexDirection={{ xs: 'column', md: 'row' }} pb="20px">
          <Box width={{ xs: '100%', md: '30%' }}>
            <Box display="grid">
              <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'} pb="20px">
                {geofenceId ? 'Update Geofence' : 'Create Geofence'}
              </Typography>

              <CustomInput
                name="name"
                label="Geofence Name *"
                placeholder="Enter Geofence Name"
                rules={{ required: 'This is required' }}
              />

              <CustomSelect
                name="aiContainer"
                label="AI Container"
                options={containerList}
                loading={Boolean(containerLoading)}
                placeholder="Select AI Container"
                getLabel={(option) =>
                  `${option.name} ${option.versionNumber && `[${option.versionNumber}]`}`
                }
                AutocompleteProps={{
                  disableClearable: true,
                  loadingText: 'Fetching AI Containers...',
                }}
              />

              <CustomSelect
                name="triggers"
                label="Triggers *"
                options={triggerList}
                loading={Boolean(triggerListLoading)}
                placeholder={triggers?.length > 0 ? ' ' : 'Triggers'}
                rules={{ required: 'This is required' }}
                getKey="id"
                getLabel="name"
                AutocompleteProps={{
                  multiple: true,
                  forcePopupIcon: false,
                  limitTags: 1,
                  loadingText: 'Fetching Triggers...',
                  disableClearable: true,
                }}
              />

              <CustomInput
                name="totalVin"
                label="Add VINs *"
                placeholder="Click to add VINs"
                rules={{ required: !totalVin ? 'This is required' : false }}
                TextFieldProps={{
                  // disabled: true,
                  sx: { cursor: 'pointer' },
                  value: `${totalVin ? `${totalVin} VINs` : ''}`,
                }}
                onClick={() => {
                  setShowAddVINModal(true);
                }}
              />

              <CustomInput
                name="schedule"
                label="Schedule *"
                placeholder="Click here to schedule"
                rules={{ required: !startTimestamp ? 'This is required' : false }}
                TextFieldProps={{
                  //disabled: true,
                  sx: { cursor: 'pointer' },
                  value: diffInText,
                }}
                onClick={() => {
                  setShowScheduleModal(true);
                }}
              />

              <CustomInput
                name="description"
                label="Description"
                placeholder="Enter a description"
                TextFieldProps={{
                  minRows: 2,
                  multiline: true,
                }}
              />
            </Box>

            <Box display="flex" justifyContent="flex-end" gap="5px" mt="20px">
              <Button variant="text" onClick={handleCancel}>
                {t(T['button.cancel'])}
              </Button>
              <Button disabled={loading} variant="contained" onClick={handleSubmit(onSubmit)}>
                {loading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
                {geofenceId ? t(T['button.update']) : t(T['button.save'])}
              </Button>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: '100%', md: '70%' }}
            rowGap="20px"
          >
            <TextField
              disabled={isGeofenceReleased || !isGeofenceLoaded}
              inputRef={ref}
              type="text"
              variant="outlined"
              placeholder="Enter a location"
              defaultValue=""
              sx={{ width: '100%' }}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <BoxImage src="/images/commons/search-icon.svg" size="100%" />
                  </IconButton>
                ),
              }}
            />
            <GeofenceMap
              disabled={isGeofenceReleased}
              searchedLocation={searchedLocation}
              onGeofenceLoaded={setIsGeofenceLoaded}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
