import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { endOfTheDay, minusOneMonth } from '@/utils/datetime';
import { SentryEvents, reportEvent } from '@/utils/sentry';
import { SetTripFilterContext, TripFilterContext } from '@/web/trips/@components/TripFilterContext';
import { useMediaQuery, useTheme } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} TripContextValue
 * @property {string} tableKey
 * @property {number} totalTrips
 * @property {InfiniteScrollViewProps<Trip>['fetcher']} fetcher
 */

/** @type {SmartCache<TripDetailsResponse>} */
export const TripDetailsCache = new SmartCache('trip-details-cache');

/** @type {import("react").Context<TripContextValue>} */
export const TripContext = createContext(null);

/** @param {{cameraId?: number, children: any}} props */
export function TripContextProvider(props) {
  const { cameraId, children } = props;

  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));

  const updateTripFilter = useContext(SetTripFilterContext);
  const { driverId, driverName, endpointId, endpointName, startTime, endTime, tripId } =
    useContext(TripFilterContext);

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<Array<Trip>>} */
  const [results, setResults] = useState(null);
  /** @type {StateVariable<string>} */
  const [tableKey, setTableKey] = useState('0');
  /** @type {StateVariable<number>} */
  const [totalTrips, setTotalTrips] = useState(null);

  useEffect(() => {
    if (!startTime || !endTime) {
      updateTripFilter({
        startTime: minusOneMonth(),
        endTime: endOfTheDay(),
      });
    }
  }, [updateTripFilter, startTime, endTime]);

  useEffect(() => {
    setTableKey(Date.now() + '');
  }, [startTime, endTime, secretToken, tenantId, driverId, driverName, endpointId, endpointName]);

  /** @type {InfiniteScrollViewProps<Trip>['fetcher']} */
  const fetcher = async ({ token, limit, signal }) => {
    if (!token) {
      setResults(null);
      setTotalTrips(null);
    }
    let time = Date.now();
    const request = api.ac.v5.trips.$get({
      signal,
      headers: {
        Authorization: secretToken,
      },
      params: {
        limit,
        offset: token || 0,
        fromTimestamp: startTime,
        toTimestamp: endTime,
        tenantId,
        ...(cameraId ? { endpointId: cameraId + '' } : {}),
        ...(driverId ? { driverId } : {}),
        ...(driverName ? { driverName } : {}),
        ...(endpointId ? { endpointId } : {}),
        ...(endpointName ? { endpointName } : {}),
      },
    });
    try {
      await request.process();
      const result = request.result.tripList;
      const total = Number(request.response.headers['x-total-count']);
      const filteredData = result?.filter(
        (trip) => trip.tripStatus === 'STARTED' || trip?.distance > 50
      );
      setResults((v) => [...(v || []), ...filteredData]);
      setTotalTrips(token + filteredData.length);
      return {
        result: filteredData,
        token: token + limit < total ? token + limit : null,
        resultLengthBeforeFilter: result.length,
      };
    } catch (err) {
      setResults(null);
      setTotalTrips(0);
      reportEvent(SentryEvents.TRIP_LIST_LOAD_FAILED, '', {
        err,
        url: request.request.url,
        params: request.request.params,
        headers: request.request.headers,
        tags: { tenantId, cameraId, driverName, endpointName },
      });
      throw err;
    } finally {
      setLoading(false);
      const duration = Date.now() - time;
      if (duration > 10000) {
        reportEvent(SentryEvents.SLOW_TRIP_LIST_LOAD, '', {
          duration,
          url: request.request.url,
          params: request.request.params,
          headers: request.request.headers,
          tags: { duration, tenantId, cameraId, driverName, endpointName },
        });
      }
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!results?.length) {
      updateTripFilter({ tripId: null });
    } else if ((tripId || mdAndUp) && !results.find((x) => x.id === tripId)) {
      updateTripFilter({ tripId: results[0].id });
    }
  }, [loading, results, mdAndUp, tripId, updateTripFilter]);

  return (
    <TripContext.Provider
      value={{
        tableKey,
        fetcher,
        totalTrips,
      }}
    >
      <>{children}</>
    </TripContext.Provider>
  );
}
