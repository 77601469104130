import {
  EVENTS_N_OFF_ICON,
  TRIGGER_COLLISIONS,
  TRIGGER_DISTRACTION,
  TRIGGER_DRIVER_EATING,
  TRIGGER_DRIVER_ON_PHONE,
  TRIGGER_DRIVER_SMOKING,
  TRIGGER_ENGINE_COOLANT,
  TRIGGER_HARSH_ABC,
  TRIGGER_NO_SEATBELT,
  TRIGGER_PEDESTRIAN,
  TRIGGER_RED_LINE_VIOLATION,
  TRIGGER_ROAD_CONDITION,
  TRIGGER_ROLLING_STOP,
  TRIGGER_SPEEDING,
  TRIGGER_STOP_SIGN,
  TRIGGER_STRADDLING,
  TRIGGER_TAILGATING,
  TRIGGER_YELLOW_LINE_VIOLATION,
} from '@/assets/constants/images';

function formatLabel(value, unit) {
  const formattedValue = value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
  return formattedValue + ' ' + unit;
}
/**
import { includes } from 'lodash';
 * Generates marks for a slider based on given parameters.
 * @param {number} min - The minimum value of the slider.
 * @param {number} max - The maximum value of the slider.
 * @param {number} step - The step size between marks on the slider.
 * @param {string} unit - The unit to be displayed with each mark.
 * @returns {Array} An array of slider marks with values and labels.
 */
export const getSliderMarks = (min, max, step, unit) => {
  const result = [];
  let totalObjects = Math.ceil((max - min) / step) + 1; // Calculate total objects based on range and step size
  totalObjects = Math.min(Math.max(totalObjects, 5), 6); // Limit totalObjects to between 5 and 10

  // Calculate the interval between values
  const interval = (max - min) / (totalObjects - 1);

  // Check if the step size is an integer
  const isIntegerStep = Number.isInteger(step);

  // Add minimum value object
  const minValueObj = {
    value: min.toFixed(2),
    label: formatLabel(min, unit),
  };
  result.push(minValueObj);

  // Add other values with the same interval
  for (let i = 1; i < totalObjects - 1; i++) {
    let value;
    if (isIntegerStep) {
      value = Math.round(min + interval * i); // Round to the nearest integer
    } else {
      value = min + interval * i;
    }
    const valueObj = {
      value: value.toFixed(2),
      label: formatLabel(value, unit),
    };
    result.push(valueObj);
  }

  // Add maximum value object
  const maxValueObj = {
    value: max.toFixed(2),
    label: isIntegerStep ? formatLabel(Math.round(max), unit) : formatLabel(max, unit),
  };
  result.push(maxValueObj);

  return result;
};

/**
 * Converts a given unit to a standardized unit.
 * @param {string} unit - The unit to be converted.
 * @returns {string} The standardized unit.
 */
export const convertUnit = (unit) => {
  unit = unit?.toLowerCase();
  if (unit === 'm/s^2') return 'g';
  else if (unit === 'm/s') return 'km/h';
  else if (['s', 'seconds', 'second'].includes(unit)) return 'sec';
  else if (['min', 'minute', 'minutes'].includes(unit)) return 'sec';
  else return unit;
};

/**
 * Converts a given value from one unit to another.
 * @param {number|string} value - The value to be converted.
 * @param {string} currentUnit - The current unit of the value.
 * @param {string} newUnit - The target unit for the conversion.
 * @returns {number|string} The converted value in the new unit or the original value if conversion is not applicable.
 */
export const convertValueByUnit = (value, currentUnit, newUnit) => {
  currentUnit = currentUnit?.toLowerCase();
  newUnit = newUnit?.toLowerCase();
  // m/s^2 <=>g
  if (currentUnit === 'm/s^2' && newUnit === 'g') {
    return Number((Number(value) / 9.8).toFixed(1));
  } else if (currentUnit === 'g' && newUnit === 'm/s^2') {
    return Number((Number(value) * 9.8).toFixed(1));
  }
  // m/s <=> km
  else if (currentUnit === 'm/s' && newUnit === 'km/h') {
    return Number((Number(value) * 3.6).toFixed(1));
  } else if (currentUnit === 'km/h' && newUnit === 'm/s') {
    return Number((Number(value) / 3.6).toFixed(1));
  }
  // minute <=> seconds
  else if (['min', 'minute', 'minutes'].includes(currentUnit) && newUnit === 'sec') {
    return Number((Number(value) * 60).toFixed(1));
  } else if (currentUnit === 'sec' && ['min', 'minute', 'minutes'].includes(newUnit)) {
    return Number((Number(value) / 60).toFixed(1));
  }
  // else
  return Number(value);
};

export const getTriggerImageByName = (triggerName) => {
  const name = triggerName?.toLowerCase();

  if (name.includes('distraction')) return TRIGGER_DISTRACTION;
  else if (name.includes('phone')) return TRIGGER_DRIVER_ON_PHONE;
  else if (name.includes('harsh')) return TRIGGER_HARSH_ABC;
  else if (name.includes('collision')) return TRIGGER_COLLISIONS;
  else if (name.includes('rolling')) return TRIGGER_ROLLING_STOP;
  else if (name.includes('speeding')) return TRIGGER_SPEEDING;
  else if (name.includes('straddling')) return TRIGGER_STRADDLING;
  else if (name.includes('tailgating')) return TRIGGER_TAILGATING;
  else if (name.includes('collision')) return TRIGGER_COLLISIONS;
  else if (name.includes('eating')) return TRIGGER_DRIVER_EATING;
  else if (name.includes('smoking')) return TRIGGER_DRIVER_SMOKING;
  else if (name.includes('coolant')) return TRIGGER_ENGINE_COOLANT;
  else if (name.includes('seatbelt')) return TRIGGER_NO_SEATBELT;
  else if (name.includes('pedestrian')) return TRIGGER_PEDESTRIAN;
  else if (name.includes('red')) return TRIGGER_RED_LINE_VIOLATION;
  else if (name.includes('yellow')) return TRIGGER_YELLOW_LINE_VIOLATION;
  else if (name.includes('condition')) return TRIGGER_ROAD_CONDITION;
  else if (name.includes('stop')) return TRIGGER_STOP_SIGN;
  else return EVENTS_N_OFF_ICON;
};

export const secondsToHMS = (seconds) => {
  if (!seconds) return 0;
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedDays = days > 0 ? `${days}d ` : '';
  const formattedHours = hours > 0 ? `${hours}h ` : '';
  const formattedMinutes = minutes > 0 ? `${minutes}m ` : '';
  const formattedSeconds = remainingSeconds > 0 ? `${remainingSeconds}s` : '';

  let short;
  if (days > 0) {
    short = `${formattedDays}+`;
  } else if (hours > 0) {
    short = `${formattedHours}+`;
  } else {
    short = formattedMinutes + formattedSeconds;
  }

  return {
    short,
    full: formattedDays + formattedHours + formattedMinutes + formattedSeconds,
  };
};
