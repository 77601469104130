import { parseDeviceCellularData } from '@/web/administration/device-groups/_id/cameras/manage-cameras/@utils';
import { Box } from '@mui/material';
import { useMemo } from 'react';

/** @param {{record: any}} props */
export function NetworkStatusButtons(props) {
  const { record } = props;

  // value is [0, 1, 2, 3]
  const wifiStrength = useMemo(() => {
    if (record.wifi_available !== 'yes') {
      return 0;
    }
    const rssi = Number(record.wifi_rssi);
    if (rssi < -80) return 1;
    if (rssi < -60) return 2;
    return 3;
  }, [record]);

  // value is [0, 1, 2, 3, 4]
  const cellularStrength = useMemo(() => {
    if (record.cellular_available !== 'yes') {
      return 0;
    }
    const data = parseDeviceCellularData(record.cellular_signal_strength);
    const quality = Number(data.signal_quality); // a value between 0 to 4
    return Math.round(quality);
  }, [record]);

  return (
    <>
      {wifiStrength > 0 && (
        <Box
          title={record.wifi_ssid}
          borderRadius="50px"
          width="24px"
          height="24px"
          padding="4px"
          bgcolor="#fff"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14.188 10.128"
            style={{ width: '100%', height: '100%' }}
          >
            <path
              d="M9.337,6.75a10.462,10.462,0,0,0-7.01,2.795.252.252,0,0,0-.009.361l.845.883a.26.26,0,0,0,.367.009A8.5,8.5,0,0,1,9.34,8.529,8.5,8.5,0,0,1,15.151,10.8a.26.26,0,0,0,.367-.009l.845-.883a.252.252,0,0,0-.009-.361A10.49,10.49,0,0,0,9.337,6.75Z"
              transform="translate(-2.246 -6.75)"
              fill={wifiStrength >= 3 ? '#4177bf' : '#c8cfe5'}
            />
            <path
              d="M7.866,16.948l.905.9a.259.259,0,0,0,.354.009,4.923,4.923,0,0,1,6.513,0,.259.259,0,0,0,.354-.009l.905-.9a.253.253,0,0,0-.009-.37,6.749,6.749,0,0,0-9.02,0A.258.258,0,0,0,7.866,16.948Z"
              transform="translate(-5.294 -11.204)"
              fill={wifiStrength >= 2 ? '#4177bf' : '#c8cfe5'}
            />
            <path
              d="M15.619,22.8a2.579,2.579,0,0,0-1.88.807.253.253,0,0,0,.006.354l1.69,1.668a.26.26,0,0,0,.367,0l1.69-1.668A.253.253,0,0,0,17.5,23.6,2.591,2.591,0,0,0,15.619,22.8Z"
              transform="translate(-8.528 -15.573)"
              fill={wifiStrength >= 1 ? '#4177bf' : '#c8cfe5'}
            />
          </svg>
        </Box>
      )}
      {cellularStrength > 0 && (
        <Box
          title={record.cellular_network_operator_name}
          borderRadius="50px"
          width="24px"
          height="24px"
          padding="4px"
          bgcolor="#fff"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 12"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <rect
              rx="1.5"
              width="3"
              height="12"
              transform="translate(10 0)"
              fill={cellularStrength >= 3 ? '#4177bf' : '#c8cfe5'}
            />
            <rect
              rx="1.5"
              width="3"
              height="8"
              transform="translate(6 4)"
              fill={cellularStrength >= 2 ? '#4177bf' : '#c8cfe5'}
            />
            <rect
              rx="1.5"
              width="3"
              height="4"
              transform="translate(2 8)"
              fill={cellularStrength >= 1 ? '#4177bf' : '#c8cfe5'}
            />
          </svg>
        </Box>
      )}
    </>
  );
}
