import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { Box, Hidden, Link } from '@mui/material';
import { GuidePageContainer } from '../GuidePageContainer';

export function GuideHeaderArea() {
  return (
    <CenterBox id="header-area" borderBottom="2px solid #DCE7F5" height="75px">
      <GuidePageContainer>
        <Hidden mdDown>
          <Box flex={1} />
        </Hidden>
        <Link href="https://smarterai.camera/" target="_blank">
          <BoxImage src="/images/layouts/logo.svg" height="24px" />
        </Link>
        <Hidden mdDown>
          <Box flex={1} textAlign="right">
            <Link href="https://smarterai.camera/" target="_blank" fontSize="14px">
              Visit Smarter AI Website
            </Link>
          </Box>
        </Hidden>
      </GuidePageContainer>
    </CenterBox>
  );
}
