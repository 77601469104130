import { store } from '@/store';
import { PageView, selectSidebarMode, selectSidebarVisible } from '@/store/page-view';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Drawer, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH } from '../config';
import { Sidebar } from './Sidebar';

/** @param {import('@mui/material').DrawerProps} props */
export function SidebarDrawer(props) {
  const theme = useTheme();
  const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'));

  const sidebar = useSelector(selectSidebarVisible);
  const isSidebarCollapsed = useSelector(selectSidebarMode);

  const [isHover, setIsHover] = useState(false);

  const drawerWidth = useMemo(
    () => (isSidebarCollapsed ? COLLAPSED_DRAWER_WIDTH : DRAWER_WIDTH),
    [isSidebarCollapsed]
  );

  const toggleSidebar = () => {
    store.dispatch(PageView.toggleSidebarVisibility());
  };

  const onCollapse = (event) => {
    store.dispatch(PageView.toggleSidebarMode(!isSidebarCollapsed));
    setIsHover(false);
    event.preventDefault();
  };

  return (
    <Drawer
      {...props}
      open={sidebar}
      onClose={toggleSidebar}
      variant={lgAndUp ? 'permanent' : 'temporary'}
      ModalProps={{ keepMounted: true }} // Better open performance on mobile
      sx={{
        '& .MuiList-root': {
          p: 0,
        },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: `${drawerWidth}px`,
        },
      }}
      // onMouseEnter={() => setIsHover(true)}
      // onMouseLeave={() => setIsHover(false)}
    >
      {isHover && lgAndUp && (
        <Box position="fixed" zIndex={1} onClick={onCollapse}>
          <IconButton
            size="large"
            sx={{
              height: '30px',
              zIndex: '1000',
              width: '30px',
              border: '1px solid #c2cddc',
              background: 'white !important',
              position: 'absolute',
              marginTop: '48px',
              marginLeft: isSidebarCollapsed ? '80px' : '235px',
            }}
          >
            {isSidebarCollapsed ? (
              <KeyboardArrowRight htmlColor="black" />
            ) : (
              <KeyboardArrowLeft htmlColor="black" />
            )}
          </IconButton>
        </Box>
      )}
      <Sidebar isCollapsed={isSidebarCollapsed} />
    </Drawer>
  );
}
