import { T } from '@/assets/locales';
import { ProductListView } from '@/web/smarter-ai/products';
import { CreateProductPage } from './create';
import { ProductDetailsLayout } from './_id/@components/ProductDetailsLayout';
import { EditProductPage } from './_id/edit';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <ProductListView />,
    breadcrumb: 'Products',
  },
  {
    path: 'create',
    element: <CreateProductPage />,
    breadcrumb: 'Create Product',
  },
  {
    path: ':id',
    element: <ProductDetailsLayout />,
    breadcrumb: 'Product Details',
    children: [
      {
        path: 'edit',
        element: <EditProductPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
