import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { selectTenantId } from '@/store/auth';
import { formatCameraName } from '@/utils/cameras';
import { formatQuery, testMatch } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CameraHealthContext } from '../..';
import { EndpointListSlider } from '../EndpointListSlider';

export function TenantWiseGroupedList() {
  const { query, updateQuery } = useRouteQuery();
  const tenantId = useSelector(selectTenantId);
  const fetcher = useContext(CameraHealthContext);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<import('../../fetcher').CameraHealthFetcher['tenants']>} */
  const [tenants, setTenants] = useState(undefined);
  /** @type {StateVariable<{[key: string]: EndpointHealthInfo[]}>} */
  const [endpoints, setEndpoints] = useState(undefined);

  const searchText = useMemo(() => formatQuery(query.search), [query.search]);

  useEffect(() => {
    if (!fetcher) return;
    const process = () => {
      try {
        /** @type {import('../../fetcher').CameraHealthFetcher['tenants']} */
        const tenants = [];
        /** @type {{[key: string]: EndpointHealthInfo[]}} */
        const endpoints = {};
        for (const tenant of fetcher.tenants) {
          const lastName = tenant.tenantName[tenant.tenantName.length - 1];
          const tenantMatch = testMatch(lastName, searchText);
          for (const endpointId of fetcher.tenantEndpoints[tenant.tenantId] || []) {
            const endpoint = fetcher.endpointInfo[endpointId];
            let endpointMatch = testMatch(formatCameraName(endpoint.deviceLabel), searchText);
            if (!endpointMatch) {
              for (const tag of endpoint.tags || []) {
                endpointMatch = testMatch(tag.tagName, searchText);
                if (endpointMatch) break;
              }
            }
            if (tenantMatch || endpointMatch) {
              endpoints[tenant.tenantId] ||= [];
              endpoints[tenant.tenantId].push(endpoint);
            }
          }
          if (endpoints[tenant.tenantId]?.length) {
            tenants.push(tenant);
          }
        }
        setTenants(tenants);
        setEndpoints(endpoints);
        if (tenants?.length) setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    const start = () => {
      setTenants(undefined);
      setEndpoints(undefined);
      setLoading(fetcher.loading);
    };
    const finish = () => {
      setLoading(false);
    };
    process();
    fetcher.on('started', start);
    fetcher.on('endpoints', process);
    fetcher.on('endpoints', finish);
    return () => {
      fetcher.off('started', start);
      fetcher.off('endpoints', process);
      fetcher.off('endpoints', finish);
    };
  }, [searchText, fetcher]);

  if (loading) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (!tenants?.length) {
    return (
      <CenterBox fullView>
        <IconMessageBox src={NO_SEARCH_RESULT_IMG} size="256px" message="No items" />
      </CenterBox>
    );
  }

  /** @param {string} id */
  const handleViewAll = (id) => {
    updateQuery({ view: query.view === id ? null : id });
  };

  return (
    <Box>
      {tenants.map((tenant) => (
        <EndpointListSlider
          key={tenant.tenantId}
          endpoints={endpoints[tenant.tenantId]}
          onViewAll={() => handleViewAll(tenant.tenantId)}
          display={query.view && query.view !== tenant.tenantId ? 'none' : 'block'}
          headerTitle={
            <Typography variant="body2" fontWeight={500}>
              {tenant.tenantName.map((name, i) => (
                <span key={name}>
                  {i > 0 ? <span color="#4D6076"> &gt; </span> : ''}
                  {name}
                </span>
              ))}
              {tenantId === tenant.tenantId && (
                <Typography component={'span'} variant="subtitle2">
                  (My Tenant)
                </Typography>
              )}
            </Typography>
          }
        />
      ))}
    </Box>
  );
}
