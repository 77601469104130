import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { Box, Link, Typography } from '@mui/material';
import { GuidePageContainer } from '../GuidePageContainer';

export function GuidePdfDownloadArea() {
  return (
    <CenterBox py={{ md: 2 }} id="pdf-download-area" bgcolor="#596A81">
      <GuidePageContainer>
        <Typography fontWeight="500" fontSize="1.5rem" color="#fff">
          Smarter AI Dashcam Installation Guide
        </Typography>
        <Box flex={1} />
        <Link
          target="_blank"
          href="/images/guide/Smarter AI Dashcam Installation Guide 20220922.pdf"
          sx={{
            color: '#fff',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.125rem',
            fontWeight: '500',
            gap: 1,
          }}
        >
          <BoxImage src="/images/guide/pdf-icon.svg" size="24px" />
          <Box>Download PDF</Box>
        </Link>
      </GuidePageContainer>
    </CenterBox>
  );
}
