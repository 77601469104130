import { T } from '@/assets/locales';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<SchemeInfo>>} */
export const DEVICE_GROUP_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'name',
    label: T['device-group.columns.name'],
    align: 'left',
    isPrimary: true,
    link: (row) => `/smarter-ai/scheme-management/schemes/${row.id}`,
  },
];
