import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Grid } from '@mui/material';

export function GuideSmarterAIDashcamOverviewArea() {
  return (
    <>
      <Box fontSize="1.375rem" fontWeight="600" mt={3} mb={2}>
        I. Smarter AI Dashcam Overview
      </Box>
      <Grid container spacing="15px">
        <Grid item xs={12} md={6} textAlign={{ xs: 'center', md: 'right' }}>
          <BoxImage src="/images/guide/overview-img.svg" width="100%" />
        </Grid>
        <Grid item xs={12} md={6} textAlign={{ xs: 'center', md: 'left' }}>
          <BoxImage src="/images/guide/overview-text-img.svg" maxWidth="100%" />
        </Grid>
      </Grid>
    </>
  );
}
