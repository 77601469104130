import { SmartCache } from '@/utils/caching/smart-cache';
import { createContext, useContext, useEffect, useState } from 'react';
import { SafetyScoreLayoutContext } from '../../@layouts/SafetyScoreLayout';
import { selectTenantId } from '@/store/auth';
import { useSelector } from 'react-redux';

export const ScoringFilterContext = createContext(null);

//1 day cache
const scoringCache = new SmartCache(`scoring`, 1 * 24 * 3600 * 1000);

/**
 * Data object containing selected filters.
 * @typedef {object} FiltersData
 * @property {Array} cameras - List of selected cameras.
 * @property {Array} drivers - List of selected drivers.
 * @property {Array} personas - List of selected personas.
 * @property {object} dateRange - Date range selected.
 * @property {string} dateRange.from - Start date of the range.
 * @property {string} dateRange.to - End date of the range.
 * @property {object} scoreRange - Score range selected.
 * @property {number} scoreRange.from - Start score of the range.
 * @property {number} scoreRange.to - End score of the range.
 */

export const ScoringFilterProvider = ({ children }) => {
  const { personaList } = useContext(SafetyScoreLayoutContext);

  const tenantId = useSelector(selectTenantId);

  /** @type {StateVariable<FiltersData>} */
  const [filteredData, setFilteredData] = useState(null);
  /** @type {StateVariable<Array<EndpointInfoAggregated>>} */
  const [selectedCameras, setSelectedCameras] = useState([]);
  /** @type {StateVariable<Array<Driver>>} */
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  /** @type {StateVariable<string>} */
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  /** @type {StateVariable<string>} */
  const [selectedToDate, setSelectedToDate] = useState(null);
  /** @type {StateVariable<Array<PersonaDto>>} */
  const [selectedPersonaList, setSelectedPersonaList] = useState([]);
  /** @type {StateVariable<number>} */
  const [selectedScoreFrom, setSelectedScoreFrom] = useState(null);
  /** @type {StateVariable<number>} */
  const [selectedScoreTo, setSelectedScoreTo] = useState(null);
  /** @type {StateVariable<Array<number>>} */
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);
  /** @type {StateVariable<number>} */
  const [submitCount, setSubmitCount] = useState(1);
  /** @type {StateVariable<number>} */
  const [selectedParentType, setSelectedParentType] = useState(2);

  const [initialTenantId] = useState(tenantId);

  const formatDate1 = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const currentDate = new Date();
    setSelectedToDate(formatDate1(currentDate));
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 29);
    setSelectedFromDate(formatDate1(sevenDaysAgo));
    setSelectedScoreFrom(0);
    setSelectedScoreTo(100);
  }, []);

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      const savedData = async () => {
        const data = await scoringCache.getItem('filters');
        data['cameras'] = [];
        data['drivers'] = [];
        scoringCache.setItem('filters', data);
      };
      savedData();
      setSelectedDrivers([]);
      setSelectedCameras([]);
    }
  }, [initialTenantId, tenantId]);

  useEffect(() => {
    if (submitCount === 0) return;
    const data = {
      cameras: selectedCameras,
      drivers: selectedDrivers,
      personas: selectedPersonaList,
      dateRange: {
        from: selectedFromDate,
        to: selectedToDate,
      },
      scoreRange: {
        from: selectedScoreFrom,
        to: selectedScoreTo,
      },
      parentType: selectedParentType,
    };
    if (submitCount === 2) {
      scoringCache.setItem('filters', data);
    }
    setFilteredData(data);
    if (selectedPersonaList?.length) {
      setSubmitCount(0);
    }
  }, [
    selectedCameras,
    selectedDrivers,
    selectedPersonaList,
    selectedFromDate,
    selectedToDate,
    selectedScoreFrom,
    selectedScoreTo,
    submitCount,
    selectedParentType,
  ]);

  useEffect(() => {
    scoringCache.getItem('filters').then((item) => {
      if (!item) {
        setSelectedPersonaList(personaList?.slice(0, 1));
        return;
      }

      const { cameras, dateRange, drivers, personas, scoreRange, parentType } = item || {};
      setSelectedCameras(cameras);
      setSelectedDrivers(drivers);
      setSelectedFromDate(dateRange?.from);
      setSelectedToDate(dateRange?.to);
      if (personas?.length === 0) {
        setSelectedPersonaList(personaList?.slice(0, 1));
      } else {
        setSelectedPersonaList((prev) => (prev?.length ? prev : personas));
      }
      setSelectedScoreFrom(scoreRange?.from);
      setSelectedScoreTo(scoreRange?.to);

      if (parentType) {
        setSelectedParentType(parentType);
      }
    });
  }, [personaList]);

  useEffect(() => {
    if (selectedFilterTypes.includes(1)) {
      setSelectedCameras([]);
    }
    if (selectedFilterTypes.includes(2)) {
      setSelectedDrivers([]);
    }
  }, [selectedFilterTypes]);

  return (
    <ScoringFilterContext.Provider
      value={{
        selectedCameras,
        setSelectedCameras,
        selectedDrivers,
        setSelectedDrivers,
        selectedFromDate,
        setSelectedFromDate,
        selectedToDate,
        setSelectedToDate,
        selectedPersonaList,
        setSelectedPersonaList,
        selectedScoreFrom,
        setSelectedScoreFrom,
        selectedScoreTo,
        setSelectedScoreTo,
        setSubmitCount,
        submitCount,
        selectedFilterTypes,
        setSelectedFilterTypes,
        filteredData /** @type {StateVariable<FiltersData>} */,
        selectedParentType,
        setSelectedParentType,
      }}
    >
      {children}
    </ScoringFilterContext.Provider>
  );
};
