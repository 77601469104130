import api from '@/api';
import { T } from '@/assets/locales';
import { PASSWORD_VALIDATION_REGEX } from '@/assets/regex';
import { selectSecretToken, selectUserEmail } from '@/store/auth';
import { reportEvent, SentryEvents } from '@/utils/sentry';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CustomPasswordInput } from '../CustomForm/CustomPasswordInput';

/** @param {import('@mui/material').DialogProps} props */
export function ChangePasswordDialog(props) {
  const { open, onClose, ...others } = props;

  const { t } = useTranslation();
  const email = useSelector(selectUserEmail);
  const secretToken = useSelector(selectSecretToken);
  const [submitting, setSubmitting] = useState(false);

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      passwordConfirm: '',
    },
  });

  const handleReset = () => {
    form.reset();
    onClose({}, null);
  };

  /** @param {{currentPassword: string, newPassword: string}} data */
  const onValidSubmit = async ({ currentPassword, newPassword }) => {
    setSubmitting(true);
    try {
      const request = api.ac.v5.auth.account['update-password'].$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          email,
          currentPassword,
          newPassword,
        },
      });
      await request.process();
      toastSuccess('Success', 'The password is reset');
      handleReset();
    } catch (err) {
      console.error('Failed to change password', err);
      toastWarning('Failed', 'Could not change the password');
      reportEvent(SentryEvents.CHANGE_PASSWORD_FAILED, '', {
        err,
        email,
        tags: { email },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleReset}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
      {...others}
    >
      <DialogTitle>Reset Password</DialogTitle>
      <Divider />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onValidSubmit)}>
          <DialogContent>
            <CustomPasswordInput
              name="currentPassword"
              label={t(T['change.password.current.password.label'])}
              placeholder={t(T['change.password.current.password.placeholder'])}
              InputProps={{ autoComplete: 'password' }}
              rules={{
                required: t(T['change.password.current.password.required']),
              }}
            />
            <Box height="10px" />
            <CustomPasswordInput
              name="newPassword"
              label={t(T['change.password.new.password.label'])}
              placeholder={t(T['change.password.new.password.placeholder'])}
              InputProps={{ autoComplete: 'new-password' }}
              rules={{
                required: t(T['change.password.new.password.required']),
                minLength: {
                  value: 8,
                  message: t(T['change.password.rules.min.length']),
                },
                pattern: {
                  value: PASSWORD_VALIDATION_REGEX,
                  message: t(T['change.password.rules.regex']),
                },
              }}
            />
            <Box height="10px" />
            <CustomPasswordInput
              name="passwordConfirm"
              label={t(T['change.password.confirm.password.label'])}
              placeholder={t(T['change.password.confirm.password.placeholder'])}
              InputProps={{ autoComplete: 'new-password' }}
              rules={{
                required: t(T['change.password.confirm.password.required']),
                validate: (value) => {
                  return value && value !== form.getValues('newPassword')
                    ? t(T['change.password.rules.password.match'])
                    : null;
                },
              }}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button type="reset" variant="text" onClick={handleReset}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={!form.formState.isDirty || !form.formState.isValid}
              sx={{ gap: 1 }}
            >
              {submitting && <CircularProgress size="16px" sx={{ color: 'white' }} />}
              <span>Reset</span>
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
