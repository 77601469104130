import { intersection, not, union } from '@/utils/group-settings';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useEffect, useState } from 'react';

export function SchemeSelection(props) {
  const { assignedSchemes, availableSchemes, onItemChange } = props;

  const [checked, setChecked] = useState([]);
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);

  const leftChecked = intersection(checked, leftItems);
  const rightChecked = intersection(checked, rightItems);

  const handleToggle = (value) => () => {
    const currentIndex = checked?.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => {
    return intersection(checked, items)?.length;
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const updateRightItems = rightItems.concat(leftChecked);
    setRightItems(updateRightItems);
    setLeftItems(not(leftItems, leftChecked));
    setChecked(not(checked, leftChecked));
    onItemChange(updateRightItems);
  };

  const handleCheckedLeft = () => {
    setLeftItems(leftItems.concat(rightChecked));
    const updateRightItems = not(rightItems, rightChecked);
    setRightItems(updateRightItems);
    setChecked(not(checked, rightChecked));
    onItemChange(updateRightItems);
  };

  useEffect(() => {
    if (!assignedSchemes?.length) return;
    setRightItems(assignedSchemes);
  }, [availableSchemes, assignedSchemes]);

  useEffect(() => {
    let filteredArray = availableSchemes?.filter(
      (x) => !rightItems.some((item) => item.id === x.id)
    );

    setLeftItems(filteredArray);
  }, [rightItems, availableSchemes]);

  const customList = (title, items, type) => {
    const itemsLength = items?.length;
    const checkedItemsLength = numberOfChecked(items);
    return (
      <Card sx={{ width: '100%', border: '1px solid #C5D9F0', borderRadius: '15px' }} elevation={0}>
        <CardHeader
          sx={{
            'px': 2,
            'py': 0.5,
            'color': '#0B2547',
            'fontSize': '1rem',
            '& .MuiCardHeader-title': {
              fontWeight: 500,
              opacity: 0.75,
            },
            '& .MuiCardHeader-avatar': {
              mr: 1,
            },
          }}
          avatar={
            <Checkbox
              sx={{
                '& .MuiSvgIcon-root': {
                  color:
                    itemsLength === 0
                      ? '#d3e0f2'
                      : itemsLength === checkedItemsLength
                        ? '#596a81'
                        : checkedItemsLength > 0
                          ? 'rgba(89, 106, 129, 0.7)'
                          : '#d3e0f2',
                  opacity: 1,
                },
              }}
              disabled={itemsLength === 0}
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === itemsLength && itemsLength !== 0}
              indeterminate={numberOfChecked(items) !== itemsLength && numberOfChecked(items) !== 0}
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
          }
          title={title}
        />
        <Divider />
        <List
          sx={{
            width: '100%',
            height: '363px',
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
        >
          <>
            {(items || [])
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item, index) => {
                const schemeId = `transfer-list-all-item-${item.id}-scheme`;
                const itemDisabled = type === 'RIGHT' && !item?.isSchemeAssigned;

                return (
                  <ListItem
                    disabled={itemDisabled}
                    key={item.name + index}
                    role="listitem"
                    button
                    onClick={handleToggle(item)}
                    sx={{ py: 0 }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(item) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': schemeId,
                        }}
                        sx={{ color: '#D3E0F2' }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        'fontWeight': 700,
                        'fontSize': '0.75rem',
                        'color': '#5a6c83',
                        'opacity': 1,
                        '& .MuiTypography-body1': {
                          fontWeight: checked.indexOf(item) !== -1 ? 500 : 400,
                        },
                      }}
                      id={schemeId}
                      primary={item.name}
                    />
                  </ListItem>
                );
              })}
          </>

          <ListItem />
        </List>
      </Card>
    );
  };

  return (
    <Box
      py={3}
      gap={'20px'}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      flexDirection={{ xs: 'column', md: 'row' }}
      sx={{ pointerEvents: 'auto' }}
    >
      <Box width="100%">{customList('Available Schemes', leftItems, 'LEFT')}</Box>
      <Box>
        <Box
          display={'flex'}
          flexDirection="column"
          gap="10px"
          alignItems="center"
          sx={{
            '& .MuiButton-root': {
              minWidth: '40px',
              px: '3px',
            },
          }}
        >
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked?.length === 0}
            aria-label="move selected rightItems"
          >
            <ArrowRight fontSize="large" />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked?.length === 0}
            aria-label="move selected leftItems"
          >
            <ArrowLeft fontSize="large" />
          </Button>
        </Box>
      </Box>
      <Box width="100%">{customList('Assigned Schemes', rightItems, 'RIGHT')}</Box>
    </Box>
  );
}
