import { T } from '@/assets/locales';
import { SchemeManagementLandingPage } from '.';
import { ChildTenantsAccordions } from './tenant-deployment';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <SchemeManagementLandingPage />,
  },
  {
    path: 'schemes',
    children: require('./schemes/routes').default,
    breadcrumb: T['navigation.smarter.ai.scheme'],
  },
  {
    path: 'tenant-deployment',
    breadcrumb: 'Tenant Deployment',
    children: [
      {
        path: '',
        element: <ChildTenantsAccordions />,
        breadcrumb: 'Tenant Deployment',
      },
    ],
  },
];

export default routes;
