import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Link, Typography } from '@mui/material';

export function GuideQuickInstallationIntroductionArea() {
  return (
    <>
      <Box py={2} px={3} gap={2} display="flex" borderRadius="10px" bgcolor="#F0F5FA">
        <BoxImage src="/images/guide/warning-icon.svg" size="48px" />
        <Typography fontWeight="600" fontSize="1rem">
          The content below is provided to you as a reference only. By proceeding, you confirmed
          having read and understood the{' '}
          <Link
            target="_blank"
            href="/images/guide/Smarter AI Dashcam Installation Guide 20220922.pdf"
          >
            Smarter AI Dashcam Installation Guide.
          </Link>
        </Typography>
      </Box>

      <Box fontSize="1.5rem" fontWeight="600" mt={3} mb={2}>
        Smarter AI Dashcam Quick Installation Guide
      </Box>

      <Box py={2} px={3} gap={1} display="flex" borderRadius="10px" bgcolor="#F0F5FA">
        <Typography fontWeight="600">Note:</Typography>
        <Typography>
          The Smarter AI Dashcam should, ideally, be placed near the rear-view mirror as close to
          the center of the vehicle as possible. Make sure that the driver's field of view is not
          obstructed by the device.
        </Typography>
      </Box>
    </>
  );
}
