import api from '@/api';
import { useFullProductList } from '@/hooks/useFullProductList';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/smarter-ai/products/actions';
import { PRODUCT_TABLE_COLUMNS } from '@/web/smarter-ai/products/columns';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProductAssignTenantDialog } from './@components/AssignTenantDialog';

export function ProductListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const { result, loading, error, retry: reload } = useFullProductList();

  /** @type {StateVariable<import('@/types').ProductProperties>} */
  const [assignDialog, setAssignDialog] = useState(null);

  useEffect(() => {
    setAssignDialog(null);
  }, [result]);

  /** @param {Array<import('@/types').ProductProperties>} products */
  const deleteProducts = async (products) => {
    const success = [];
    const failed = [];
    await Promise.all(
      products.map(async (item) => {
        try {
          const request = api.ac.v1.tenant.product.$delete({
            params: {
              productId: item.productId,
              secretToken,
              tenantId,
            },
          });
          await request.process();
          success.push(item.productName);
        } catch (err) {
          failed.push(item.productName);
        }
      })
    );
    if (failed.length) {
      toastWarning(
        'Error',
        failed.length === 1
          ? `Failed to delete <b>${failed[0]}</b>`
          : `Failed to delete ${failed.length} products`
      );
    }
    if (success.length) {
      reload();
    }
  };

  /** @type {import('./@components/AssignTenantDialog').ProductAssignTenantDialogProps['onDone']} */
  const handleAssign = async (selectedTenants) => {
    const product = assignDialog;
    try {
      let updated = false;
      if (selectedTenants?.length) {
        const request = api.ac.v1.tenant.product.assign.$post({
          data: {
            tenantInfoList: selectedTenants.map((tenantId) => ({
              tenantId,
              role: 'Admin',
            })),
          },
          params: {
            productId: product.productId,
            secretToken,
            tenantId,
          },
        });
        await request.process();
        updated = true;
      }

      await Promise.allSettled(
        (product?.assignedTenants || '').split(',').map(async (item) => {
          const unassignTenantId = item.trim();
          if (!unassignTenantId) return;
          if (unassignTenantId === product?.ownerTenantId) return;
          if (selectedTenants?.indexOf(unassignTenantId) >= 0) return;
          const request = api.ac.v1.tenant.product.assign.$delete({
            params: {
              productId: product.productId,
              secretToken,
              tenantId,
              unassignTenantId,
            },
          });
          await request.process();
          updated = true;
        })
      );

      if (updated) {
        toastSuccess('Success', `Updated the assigned tenant of <b>${product.productName}</b>`);
        reload();
      }
    } catch (err) {
      console.error('Tenant assign failed', err);
      toastWarning(
        'Sorry!',
        `Could not update the assign tenants of <b>${product.productName}</b>`
      );
    } finally {
      setAssignDialog(null);
    }
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<import('@/types').ProductProperties>['onAction']} */
  const onActionHandle = (type, selectedItems, setTableReload) => {
    switch (type) {
      case 'DISCONTINUE':
        deleteProducts(selectedItems);
        break;
      case 'ASSIGN':
        setAssignDialog(selectedItems[0]);
        break;
      default:
        break;
    }
  };

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch Product list');
  }

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title={'Products'} actions={ACTION_ITEMS} onAction={onActionHandle} />
        <ContinuationTokenTable
          results={result}
          loading={loading}
          columns={PRODUCT_TABLE_COLUMNS}
          dataSortKey={'productName'}
        />
      </PaginatedTableContextProvider>
      <ProductAssignTenantDialog
        onDone={handleAssign}
        product={assignDialog}
        onCancel={() => setAssignDialog(null)}
      />
    </Box>
  );
}
