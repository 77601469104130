module.exports = {
  '$baseURL': 'https://dev-api.smarterai.com',
  'auth': {
    device: {
      lease: {
        token: {
          /**
           * GET /auth/device/lease/token
           * @summary Lease a device token for onboarding.
           * @param {RequestConfig & { params: AuthDeviceLeaseTokenGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeviceLeaseTokenResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
    },
    factoryauthtoken: {
      /**
       * POST /auth/factoryauthtoken
       * @summary Generate an auth token for the updater module.
       * @param {RequestConfig & { params: AuthFactoryauthtokenPostParams, headers: AuthFactoryauthtokenPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SignUpResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    health: {
      /**
       * GET /auth/health
       * @summary Health Check
       * @param {RequestConfig & { params: AuthHealthGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    invite: {
      /**
       * GET /auth/invite
       * @summary Send invitation email to a non registered user on ACL share.
       * @param {RequestConfig & { params: AuthInviteGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SendInvitationResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    renew: {
      token: {
        /**
         * GET /auth/renew/token
         * @summary Renew the authorization token.
         * @param {RequestConfig & { params: AuthRenewTokenGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RenewSecretTokenResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    signin: {
      /**
       * POST /auth/signin
       * @summary Sign-in to AnyConnect system.
       * @param {RequestConfig & { params: AuthSigninPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<LoginUserResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    signup: {
      /**
       * POST /auth/signup
       * @summary Sign-up to AnyConnect dashboard user.
       * @param {RequestConfig & { params: AuthSignupPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SignUpResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    user: {
      delete: {
        /**
         * DELETE /auth/user/delete
         * @summary Delete a user.
         * @param {RequestConfig & { params: AuthUserDeleteDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeleteUserResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      get: {
        list: {
          /**
           * GET /auth/user/get/list
           * @deprecated
           * @summary Get user list. [Deprecated]
           * @param {RequestConfig & { params: AuthUserGetListGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetUserListResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        search: {
          user: {
            /**
             * GET /auth/user/get/search/user
             * @deprecated
             * @summary Search tenant user's information by email.[Deprecated]
             * @param {RequestConfig & { params: AuthUserGetSearchUserGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GetTenantUserResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        user: {
          /**
           * GET /auth/user/get/user
           * @summary Get a user's information.
           * @param {RequestConfig & { params: AuthUserGetUserGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetUserResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      list: {
        /**
         * GET /auth/user/list
         * @summary Get user list.
         * @param {RequestConfig & { params: AuthUserListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetUserListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      role: {
        /**
         * PUT /auth/user/role
         * @summary Change a user's role.
         * @param {RequestConfig & { params: AuthUserRolePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ChangeUserRoleResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      search: {
        /**
         * GET /auth/user/search
         * @summary Search tenant user's information by email.
         * @param {RequestConfig & { params: AuthUserSearchGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetTenantUserResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      signin: {
        /**
         * POST /auth/user/signin
         * @deprecated
         * @summary Sign-in to AnyConnect system.[Deprecated]
         * @param {RequestConfig & { params: AuthUserSigninPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<LoginUserResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      update: {
        /**
         * PUT /auth/user/update
         * @summary Update a user's information.
         * @param {RequestConfig & { params: AuthUserUpdatePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateUserInfoResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
    },
  },
  'endpoint': {
    acl: {
      appendpoints: {
        /**
         * GET /endpoint/acl/appendpoints
         * @summary Returns the list of associated user app endpoints for the calling endpoint ID.
         * @param {RequestConfig & { params: EndpointAclAppendpointsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AppEndpoints>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      associatedendpoints: {
        /**
         * GET /endpoint/acl/associatedendpoints
         * @summary Returns the list of associated endpoints for the calling user ID.
         * @param {RequestConfig & { params: EndpointAclAssociatedendpointsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AssociatedEndpoints>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      associatedusers: {
        /**
         * GET /endpoint/acl/associatedusers
         * @summary Returns a list of all Users associated with an Endpoint
         * @param {RequestConfig & { params: EndpointAclAssociatedusersGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AssociatedUsers>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      change: {
        userrole: {
          /**
           * PUT /endpoint/acl/change/userrole
           * @summary Change the role of user for specific endpoint.
           * @param {RequestConfig & { params: EndpointAclChangeUserrolePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
      },
      online: {
        appendpoints: {
          /**
           * GET /endpoint/acl/online/appendpoints
           * @summary Returns the list of associated user app endpoints for the calling endpoint ID.
           * @param {RequestConfig & { params: EndpointAclOnlineAppendpointsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AppEndpoints>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      remove: {
        user: {
          /**
           * DELETE /endpoint/acl/remove/user
           * @summary Removes a user from an endpoint
           * @param {RequestConfig & { params: EndpointAclRemoveUserDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
      },
      share: {
        /**
         * POST /endpoint/acl/share
         * @summary Share an endpoint with another user.
         * @param {RequestConfig & { params: EndpointAclSharePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      update: {
        userrole: {
          /**
           * PUT /endpoint/acl/update/userrole
           * @deprecated
           * @summary Updates the role of user for specific endpoint. [Deprecated]
           * @param {RequestConfig & { params: EndpointAclUpdateUserrolePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
      },
    },
    add: {
      device: {
        /**
         * PUT /endpoint/add/device
         * @deprecated
         * @summary Add device to a tenant. [Deprecated]
         * @param {RequestConfig & { params: EndpointAddDevicePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
    },
    capabilities: {
      /**
       * GET /endpoint/capabilities
       * @summary Get an endpoint capability
       * @param {RequestConfig & { params: EndpointCapabilitiesGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetEndpointCapabilities>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /endpoint/capabilities
       * @summary Post an endpoint capability
       * @param {RequestConfig & { data: EndpointCapabilities, params: EndpointCapabilitiesPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    delete: {
      device: {
        /**
         * DELETE /endpoint/delete/device
         * @summary Delete device from all restricted tenants.
         * @param {RequestConfig & { params: EndpointDeleteDeviceDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
    },
    device: {
      /**
       * GET /endpoint/device
       * @summary Get device info.
       * @param {RequestConfig & { params: EndpointDeviceGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<Device>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      capabilities: {
        /**
         * GET /endpoint/device/capabilities
         * @summary Get a device capability
         * @param {RequestConfig & { params: EndpointDeviceCapabilitiesGetParams, headers: EndpointDeviceCapabilitiesGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetDeviceCapabilities>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /endpoint/device/capabilities
         * @summary Post a device capability
         * @param {RequestConfig & { data: DeviceCapabilities, params: EndpointDeviceCapabilitiesPostParams, headers: EndpointDeviceCapabilitiesPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      gateway_close: {
        /**
         * PUT /endpoint/device/gateway_close
         * @summary Gateway close for specific device list.
         * @param {RequestConfig & { data: DeviceGatewayCloseRequest, params: EndpointDeviceGatewayClosePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      gateway_open: {
        /**
         * PUT /endpoint/device/gateway_open
         * @summary Gateway open from specific device list.
         * @param {RequestConfig & { data: DeviceGatewayOpenRequest, params: EndpointDeviceGatewayOpenPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      onboard_credential: {
        /**
         * GET /endpoint/device/onboard_credential
         * @summary Transfer devices one tenant to another tenant.
         * @param {RequestConfig & { params: EndpointDeviceOnboardCredentialGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeviceOnboardCredentialResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    devicepools: {
      /**
       * GET /endpoint/devicepools
       * @summary Get list of device pools.
       * @param {RequestConfig & { params: EndpointDevicepoolsGetParams, headers: EndpointDevicepoolsGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetListOfDevicePoolsResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /endpoint/devicepools
       * @summary Create a device-pool in a tenant.
       * @param {RequestConfig & { params: EndpointDevicepoolsPostParams, headers: EndpointDevicepoolsPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<CreateDevicePoolResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      /** @param {!string} poolId poolId */
      $poolId: (poolId) => ({
        devices: {
          /**
           * GET /endpoint/devicepools/{poolId}/devices
           * @summary List of devices from a pool.
           * @param {RequestConfig & { params: EndpointDevicepoolsPoolIdDevicesGetParams, headers: EndpointDevicepoolsPoolIdDevicesGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeleteDeviceFromThePoolResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /endpoint/devicepools/{poolId}/devices
           * @summary Add device in a pool.
           * @param {RequestConfig & { params: EndpointDevicepoolsPoolIdDevicesPostParams, headers: EndpointDevicepoolsPoolIdDevicesPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AddDeviceInThePoolResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /endpoint/devicepools/{poolId}/devices
           * @summary Delete device from the pool.
           * @param {RequestConfig & { params: EndpointDevicepoolsPoolIdDevicesDeleteParams, headers: EndpointDevicepoolsPoolIdDevicesDeleteHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeleteDeviceFromThePoolResponse>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
      }),
    },
    devices: {
      /** @param {!string} deviceSerialNumber deviceSerialNumber */
      $deviceSerialNumber: (deviceSerialNumber) => ({
        firmwareversion: {
          /**
           * GET /endpoint/devices/{deviceSerialNumber}/firmwareversion
           * @summary Retrieve list of firmware version against a device serial number
           * @param {RequestConfig & { headers: EndpointDevicesDeviceSerialNumberFirmwareversionGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetDeviceFirmwareVersionResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /endpoint/devices/{deviceSerialNumber}/firmwareversion
           * @summary Post firmwareVersion against a deviceSerialNumber
           * @param {RequestConfig & { params: EndpointDevicesDeviceSerialNumberFirmwareversionPostParams, headers: EndpointDevicesDeviceSerialNumberFirmwareversionPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        otasettings: {
          /**
           * GET /endpoint/devices/{deviceSerialNumber}/otasettings
           * @summary Return otasettings for a specific device.
           * @param {RequestConfig & { headers: EndpointDevicesDeviceSerialNumberOtasettingsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OtaSettingsDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      }),
    },
    group: {
      /**
       * GET /endpoint/group
       * @summary Get group
       * @param {RequestConfig & { params: EndpointGroupGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetGroupResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      change: {
        resource_group_id: {
          /**
           * PUT /endpoint/group/change/resource_group_id
           * @summary Add list of endpoints to the group.
           * @param {RequestConfig & { params: EndpointGroupChangeResourceGroupIdPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ChangeResourceGroupIDResponse>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
      },
      count: {
        /**
         * GET /endpoint/group/count
         * @summary Retrieve counts by list of groups(user, device, app
         * @param {RequestConfig & { params: EndpointGroupCountGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GroupCountResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      create: {
        /**
         * PUT /endpoint/group/create
         * @summary Create a group.
         * @param {RequestConfig & { params: EndpointGroupCreatePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CreateGroupResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      delete: {
        /**
         * DELETE /endpoint/group/delete
         * @summary Delete a group.
         * @param {RequestConfig & { params: EndpointGroupDeleteDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeleteGroupResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      filtered_settings: {
        /**
         * GET /endpoint/group/filtered_settings
         * @summary Get group settings with filtered query
         * @param {RequestConfig & { params: EndpointGroupFilteredSettingsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetGroupSettings>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      global_list: {
        /**
         * GET /endpoint/group/global_list
         * @summary Get global list of groups except default ones.
         * @param {RequestConfig & { params: EndpointGroupGlobalListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetListGroupResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      inference: {
        configurations: {
          /**
           * GET /endpoint/group/inference/configurations
           * @summary Get group inference configuration.
           * @param {RequestConfig & { params: EndpointGroupInferenceConfigurationsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetGroupInferenceConfigurationResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /endpoint/group/inference/configurations
           * @summary Post a group inference configuration.
           * @param {RequestConfig & { data: InferenceConfiguration, params: EndpointGroupInferenceConfigurationsPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      list: {
        /**
         * GET /endpoint/group/list
         * @summary Get List of groups.
         * @param {RequestConfig & { params: EndpointGroupListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetListGroupResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        resource_groups: {
          /**
           * GET /endpoint/group/list/resource_groups
           * @summary Get List of assigned groups of an resource ID.
           * @param {RequestConfig & { params: EndpointGroupListResourceGroupsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetListGroupResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        resources: {
          /**
           * GET /endpoint/group/list/resources
           * @summary Get List of group resources.
           * @param {RequestConfig & { params: EndpointGroupListResourcesGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetGroupResourcesResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      restore: {
        default_settings: {
          /**
           * POST /endpoint/group/restore/default_settings
           * @summary Restore default group settings
           * @param {RequestConfig & { params: EndpointGroupRestoreDefaultSettingsPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      settings: {
        /**
         * GET /endpoint/group/settings
         * @summary Get group settings
         * @param {RequestConfig & { params: EndpointGroupSettingsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetGroupSettings>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /endpoint/group/settings
         * @summary Post group settings
         * @param {RequestConfig & { data: GroupSettings, params: EndpointGroupSettingsPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      update: {
        /**
         * PUT /endpoint/group/update
         * @summary Update a group [Name and description]
         * @param {RequestConfig & { params: EndpointGroupUpdatePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RenameGroupResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
    },
    health: {
      /**
       * GET /endpoint/health
       * @summary Health Check
       * @param {RequestConfig & { params: EndpointHealthGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    inference: {
      configurations: {
        /**
         * GET /endpoint/inference/configurations
         * @summary Get an endpoint inference configuration.
         * @param {RequestConfig & { params: EndpointInferenceConfigurationsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetEndpointInferenceConfigurationResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /endpoint/inference/configurations
         * @summary Post an endpoint inference configuration.
         * @param {RequestConfig & { data: InferenceConfiguration, params: EndpointInferenceConfigurationsPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
    },
    info: {
      /**
       * GET /endpoint/info
       * @summary Retrieve info of an endpoint
       * @param {RequestConfig & { params: EndpointInfoGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<Endpoint>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * PUT /endpoint/info
       * @deprecated
       * @summary Updates info of an endpoint
       * @param {RequestConfig & { data: EndpointUpdateInfo, params: EndpointInfoPutParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
       */
      $put: (config) => null,
      by_device_id: {
        /**
         * GET /endpoint/info/by_device_id
         * @summary Retrieve info of an endpoint
         * @param {RequestConfig & { params: EndpointInfoByDeviceIdGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Endpoint>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    info_fields: {
      /**
       * PUT /endpoint/info_fields
       * @summary List of update field requests for an endpoint
       * @param {RequestConfig & { data: Array<EndpointUpdateRequest>, params: EndpointInfoFieldsPutParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
       */
      $put: (config) => null,
    },
    list: {
      /**
       * GET /endpoint/list
       * @summary Returns a list of Endpoints of specific tenant ID.
       * @param {RequestConfig & { params: EndpointListGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetEndpointListResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      byproduct: {
        /**
         * GET /endpoint/list/byproduct
         * @summary Return list of endpoints for specific product id.
         * @param {RequestConfig & { params: EndpointListByproductGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetProductEndpointsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      device: {
        /**
         * GET /endpoint/list/device
         * @summary Get device list of a tenant.
         * @param {RequestConfig & { params: EndpointListDeviceGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeviceListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    onboard: {
      /**
       * POST /endpoint/onboard
       * @summary Onboarding a new endpoint [APP/DEVICE]. Returns endpointId.
       * @param {RequestConfig & { params: EndpointOnboardPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<EndpointOnboardResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    properties: {
      /**
       * GET /endpoint/properties
       * @summary Properties of an endpoint as a list of key-value pair.
       * @param {RequestConfig & { params: EndpointPropertiesGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<EndpointProperties>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /endpoint/properties
       * @summary Set properties of an endpoint as a list of key-value pair
       * @param {RequestConfig & { data: EndpointProperties, params: EndpointPropertiesPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<EndpointProperties>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    reset: {
      /**
       * DELETE /endpoint/reset
       * @summary Reset calling endpoint ID, only owner and superuser can reset an endpoint
       * @param {RequestConfig & { params: EndpointResetDeleteParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
       */
      $delete: (config) => null,
      device: {
        /**
         * PUT /endpoint/reset/device
         * @summary Reset endpoints by device id.
         * @param {RequestConfig & { params: EndpointResetDevicePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
    },
    rollback: {
      transfer_device: {
        /**
         * PUT /endpoint/rollback/transfer_device
         * @summary Rollback device from customer tenant.
         * @param {RequestConfig & { params: EndpointRollbackTransferDevicePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
    },
    serverconfig: {
      /**
       * GET /endpoint/serverconfig
       * @summary Get turn, stun server configuration
       * @param {RequestConfig & { params: EndpointServerconfigGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<EndpointServerConfig>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /endpoint/serverconfig
       * @summary Add static turn, stun server configuration.
       * @param {RequestConfig & { params: EndpointServerconfigPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<PostStaticServerConfigResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      /**
       * PUT /endpoint/serverconfig
       * @summary Update static turn, stun server configuration.
       * @param {RequestConfig & { params: EndpointServerconfigPutParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<PutStaticServerConfigResponse>} An ApiRequest; to get result call 'process'.
       */
      $put: (config) => null,
      /**
       * DELETE /endpoint/serverconfig
       * @summary Delete static turn, stun server configuration.
       * @param {RequestConfig & { params: EndpointServerconfigDeleteParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<DeleteStaticServerConfigResponse>} An ApiRequest; to get result call 'process'.
       */
      $delete: (config) => null,
    },
    state: {
      /**
       * PUT /endpoint/state
       * @summary Update an endpoint state [ONLINE or OFFLINE].
       * @param {RequestConfig & { params: EndpointStatePutParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<EndpointStateUpdateResponse>} An ApiRequest; to get result call 'process'.
       */
      $put: (config) => null,
    },
    status: {
      /**
       * GET /endpoint/status
       * @summary Get endpoint active status.[ACTIVE, RESET].
       * @param {RequestConfig & { params: EndpointStatusGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<'ACTIVE'|'RESET'|'UNKNOWN'|'ANY_STATUS'|'UNRECOGNIZED'>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    tenantstats: {
      /**
       * GET /endpoint/tenantstats
       * @summary Get tenant stats [Number of online/offline users/devices].
       * @param {RequestConfig & { params: EndpointTenantstatsGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<TenantStatsResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    transfer_devices: {
      /**
       * PUT /endpoint/transfer_devices
       * @deprecated
       * @summary Transfer devices from one tenant to another tenant.
       * @param {RequestConfig & { data: TransferDevicesRequest, params: EndpointTransferDevicesPutParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
       */
      $put: (config) => null,
    },
    user: {
      deactivateuser: {
        /**
         * DELETE /endpoint/user/deactivateuser
         * @summary Removes all owned endpoints and deletes all access info for an user
         * @param {RequestConfig & { params: EndpointUserDeactivateuserDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      role: {
        /**
         * GET /endpoint/user/role
         * @summary Get access role of the user on the endpoint.
         * @param {RequestConfig & { params: EndpointUserRoleGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<object>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      roles: {
        /**
         * GET /endpoint/user/roles
         * @deprecated
         * @summary Get access roles of the user on the endpoint. [Deprecated]
         * @param {RequestConfig & { params: EndpointUserRolesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<object>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
  },
  'event-messaging': {
    'SendAIEvents': {
      /**
       * POST /event-messaging/SendAIEvents
       * @summary SendAiEvents
       * @param {RequestConfig & { data: EventVideoMetaDataModel, params: EventMessagingSendAIEventsPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SendMessageResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'check-presence': {
      /**
       * POST /event-messaging/check-presence
       * @summary checkPresence
       * @param {RequestConfig & { data: Message, params: EventMessagingCheckPresencePostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<PresenceStatus>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'getPresence': {
      /**
       * POST /event-messaging/getPresence
       * @summary Get presence status for a list of Endpoints
       * @param {RequestConfig & { data: GetPresenceRequest, params: EventMessagingGetPresencePostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<PresenceStatus>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'negotiate': {
      /**
       * GET /event-messaging/negotiate
       * @summary Negotiate
       * @param {RequestConfig & { params: EventMessagingNegotiateGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /event-messaging/negotiate
       * @summary Negotiate
       * @param {RequestConfig & { params: EventMessagingNegotiatePostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'send-event': {
      /**
       * POST /event-messaging/send-event
       * @summary sendEvent
       * @param {RequestConfig & { data: Message, params: EventMessagingSendEventPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SendMessageResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'send-keep-alive-message': {
      /**
       * POST /event-messaging/send-keep-alive-message
       * @summary sendKeepAliveMessage
       * @param {RequestConfig & { data: Message, params: EventMessagingSendKeepAliveMessagePostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SendMessageResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'send-message': {
      /**
       * POST /event-messaging/send-message
       * @summary sendMessage
       * @param {RequestConfig & { data: Message, params: EventMessagingSendMessagePostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SendMessageResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
  },
  'gateway': {
    api_requests: {
      /**
       * GET /gateway/api_requests
       * @summary Health Status
       * @param {RequestConfig & { params: GatewayApiRequestsGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetAPIRequestsResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    health: {
      /**
       * GET /gateway/health
       * @summary Health Status
       * @param {RequestConfig & { params: GatewayHealthGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    settings: {
      /**
       * GET /gateway/settings
       * @summary Get service configuration data
       * @param {RequestConfig & { headers: GatewaySettingsGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<ServiceConfiguration>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
  },
  'image': {
    health: {
      /**
       * GET /image/health
       * @summary Health Check
       * @param {RequestConfig & { params: ImageHealthGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    upload: {
      /**
       * POST /image/upload
       * @summary Upload image to blob storage.
       * @param {RequestConfig & { data: string, params: ImageUploadPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<UploadImageResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
  },
  'ip_locator': {
    geo_information: {
      /**
       * GET /ip_locator/geo_information
       * @summary Get geo information for latitude and longitude.
       * @param {RequestConfig & { params: IpLocatorGeoInformationGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetGeoInformationResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    health: {
      /**
       * GET /ip_locator/health
       * @summary Health Check
       * @param {RequestConfig & { params: IpLocatorHealthGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    ip_location: {
      /**
       * GET /ip_locator/ip_location
       * @summary Get ip address location.
       * @param {RequestConfig & { params: IpLocatorIpLocationGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetIPLocationResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
  },
  'media': {
    'entry-recording': {
      /**
       * POST /media/entry-recording
       * @summary Request for creating event entry.
       * @param {RequestConfig & { params: MediaEntryRecordingPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RequestRecordingResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'event-recordings': {
      /**
       * GET /media/event-recordings
       * @summary Gets Recording URLs for an Endpoint
       * @param {RequestConfig & { params: MediaEventRecordingsGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RecordingUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'event': {
      recordings: {
        /**
         * GET /media/event/recordings
         * @summary Get range event recordings.
         * @param {RequestConfig & { params: MediaEventRecordingsGetParams, headers: MediaEventRecordingsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetMediaEventRecordingsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /media/event/recordings
         * @summary Request for an adding media data.
         * @param {RequestConfig & { params: MediaEventRecordingsPostParams, headers: MediaEventRecordingsPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddMediaEventRecordingResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
    },
    'files': {
      /**
       * GET /media/files
       * @summary Get upload urls for given extensions.
       * @param {RequestConfig & { params: MediaFilesGetParams, headers: MediaFilesGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetUploadUrlsResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      token: {
        /**
         * GET /media/files/token
         * @summary Get refresh upload URL.
         * @param {RequestConfig & { params: MediaFilesTokenGetParams, headers: MediaFilesTokenGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetRefreshUrlResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    'health': {
      /**
       * GET /media/health
       * @summary Health Check
       * @param {RequestConfig & { params: MediaHealthGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'range-recordings': {
      /**
       * GET /media/range-recordings
       * @summary Get Range Recording URLs for specific time range of an Endpoint
       * @param {RequestConfig & { params: MediaRangeRecordingsGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RangeRecordingVideosResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'recording-url': {
      /**
       * GET /media/recording-url
       * @deprecated
       * @summary GetRecordingUrl
       * @param {RequestConfig & { params: MediaRecordingUrlGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RecordedUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'recordingRequestStatus': {
      /**
       * GET /media/recordingRequestStatus
       * @summary The status of the recording request
       * @param {RequestConfig & { params: MediaRecordingRequestStatusGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<EventRecordingRequest>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * PUT /media/recordingRequestStatus
       * @summary Update the status of the recording request.
       * @param {RequestConfig & { params: MediaRecordingRequestStatusPutParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<PutRecordingRequestStatusResponse>} An ApiRequest; to get result call 'process'.
       */
      $put: (config) => null,
    },
    'recordingRequestStatusList': {
      /**
       * GET /media/recordingRequestStatusList
       * @summary List of the recording request status.
       * @param {RequestConfig & { params: MediaRecordingRequestStatusListGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetRecordingRequestStatusListResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'recordings': {
      /**
       * GET /media/recordings
       * @summary Gets Recording URLs for an Endpoint
       * @param {RequestConfig & { params: MediaRecordingsGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RecordingUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'requestRecording': {
      /**
       * POST /media/requestRecording
       * @summary Request for an event recording.
       * @param {RequestConfig & { params: MediaRequestRecordingPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RequestRecordingResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'upload-credential': {
      /**
       * GET /media/upload-credential
       * @summary Get a temporary access credential to upload recording data.
       * @param {RequestConfig & { params: MediaUploadCredentialGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RecordingAccessCredentialResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
  },
  'media_recording': {
    'entry-recording': {
      /**
       * POST /media_recording/entry-recording
       * @summary Request for creating event entry.
       * @param {RequestConfig & { params: MediaRecordingEntryRecordingPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RequestRecordingResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'event-recordings': {
      /**
       * GET /media_recording/event-recordings
       * @summary Gets Recording URLs for an Endpoint
       * @param {RequestConfig & { params: MediaRecordingEventRecordingsGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RecordingUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'event': {
      recordings: {
        /**
         * GET /media_recording/event/recordings
         * @summary Get range event recordings.
         * @param {RequestConfig & { params: MediaRecordingEventRecordingsGetParams, headers: MediaRecordingEventRecordingsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetMediaEventRecordingsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /media_recording/event/recordings
         * @summary Request for an adding media data.
         * @param {RequestConfig & { params: MediaRecordingEventRecordingsPostParams, headers: MediaRecordingEventRecordingsPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddMediaEventRecordingResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
    },
    'files': {
      /**
       * GET /media_recording/files
       * @summary Get upload urls for given extensions.
       * @param {RequestConfig & { params: MediaRecordingFilesGetParams, headers: MediaRecordingFilesGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetUploadUrlsResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      token: {
        /**
         * GET /media_recording/files/token
         * @summary Get refresh upload URL.
         * @param {RequestConfig & { params: MediaRecordingFilesTokenGetParams, headers: MediaRecordingFilesTokenGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetRefreshUrlResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    'health': {
      /**
       * GET /media_recording/health
       * @summary Health Check
       * @param {RequestConfig & { params: MediaRecordingHealthGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'range-recordings': {
      /**
       * GET /media_recording/range-recordings
       * @summary Get Range Recording URLs for specific time range of an Endpoint
       * @param {RequestConfig & { params: MediaRecordingRangeRecordingsGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RangeRecordingVideosResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'recording-url': {
      /**
       * GET /media_recording/recording-url
       * @deprecated
       * @summary GetRecordingUrl
       * @param {RequestConfig & { params: MediaRecordingRecordingUrlGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RecordedUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'recordingRequestStatus': {
      /**
       * GET /media_recording/recordingRequestStatus
       * @summary The status of the recording request
       * @param {RequestConfig & { params: MediaRecordingRecordingRequestStatusGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<EventRecordingRequest>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * PUT /media_recording/recordingRequestStatus
       * @summary Update the status of the recording request.
       * @param {RequestConfig & { params: MediaRecordingRecordingRequestStatusPutParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<PutRecordingRequestStatusResponse>} An ApiRequest; to get result call 'process'.
       */
      $put: (config) => null,
    },
    'recordingRequestStatusList': {
      /**
       * GET /media_recording/recordingRequestStatusList
       * @summary List of the recording request status.
       * @param {RequestConfig & { params: MediaRecordingRecordingRequestStatusListGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<GetRecordingRequestStatusListResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'recordings': {
      /**
       * GET /media_recording/recordings
       * @summary Gets Recording URLs for an Endpoint
       * @param {RequestConfig & { params: MediaRecordingRecordingsGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RecordingUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
    'requestRecording': {
      /**
       * POST /media_recording/requestRecording
       * @summary Request for an event recording.
       * @param {RequestConfig & { params: MediaRecordingRequestRecordingPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RequestRecordingResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'upload-credential': {
      /**
       * GET /media_recording/upload-credential
       * @summary Get a temporary access credential to upload recording data.
       * @param {RequestConfig & { params: MediaRecordingUploadCredentialGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<RecordingAccessCredentialResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
  },
  'tmp': {
    redirect: {
      /**
       * GET /tmp/redirect
       * @summary getRedirectUrl
       * @param {RequestConfig & { params: TmpRedirectGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<any>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
    },
  },
  'v1': {
    'auth': {
      device: {
        lease: {
          token: {
            /**
             * GET /v1/auth/device/lease/token
             * @summary Lease a device token for onboarding.
             * @param {RequestConfig & { params: V1AuthDeviceLeaseTokenGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeviceLeaseTokenResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      factoryauthtoken: {
        /**
         * POST /v1/auth/factoryauthtoken
         * @summary Generate an auth token for the updater module.
         * @param {RequestConfig & { params: V1AuthFactoryauthtokenPostParams, headers: V1AuthFactoryauthtokenPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SignUpResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      health: {
        /**
         * GET /v1/auth/health
         * @summary Health Check
         * @param {RequestConfig & { params: V1AuthHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      invite: {
        /**
         * GET /v1/auth/invite
         * @summary Send invitation email to a non registered user on ACL share.
         * @param {RequestConfig & { params: V1AuthInviteGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SendInvitationResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      renew: {
        token: {
          /**
           * GET /v1/auth/renew/token
           * @summary Renew the authorization token.
           * @param {RequestConfig & { params: V1AuthRenewTokenGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<RenewSecretTokenResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      signin: {
        /**
         * POST /v1/auth/signin
         * @summary Sign-in to AnyConnect system.
         * @param {RequestConfig & { params: V1AuthSigninPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<LoginUserResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      signup: {
        /**
         * POST /v1/auth/signup
         * @summary Sign-up to AnyConnect dashboard user.
         * @param {RequestConfig & { params: V1AuthSignupPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SignUpResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      user: {
        delete: {
          /**
           * DELETE /v1/auth/user/delete
           * @summary Delete a user.
           * @param {RequestConfig & { params: V1AuthUserDeleteDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeleteUserResponse>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
        get: {
          list: {
            /**
             * GET /v1/auth/user/get/list
             * @deprecated
             * @summary Get user list. [Deprecated]
             * @param {RequestConfig & { params: V1AuthUserGetListGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GetUserListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          search: {
            user: {
              /**
               * GET /v1/auth/user/get/search/user
               * @deprecated
               * @summary Search tenant user's information by email.[Deprecated]
               * @param {RequestConfig & { params: V1AuthUserGetSearchUserGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<GetTenantUserResponse>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          user: {
            /**
             * GET /v1/auth/user/get/user
             * @summary Get a user's information.
             * @param {RequestConfig & { params: V1AuthUserGetUserGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GetUserResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        list: {
          /**
           * GET /v1/auth/user/list
           * @summary Get user list.
           * @param {RequestConfig & { params: V1AuthUserListGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetUserListResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        role: {
          /**
           * PUT /v1/auth/user/role
           * @summary Change a user's role.
           * @param {RequestConfig & { params: V1AuthUserRolePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ChangeUserRoleResponse>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
        search: {
          /**
           * GET /v1/auth/user/search
           * @summary Search tenant user's information by email.
           * @param {RequestConfig & { params: V1AuthUserSearchGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetTenantUserResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        signin: {
          /**
           * POST /v1/auth/user/signin
           * @deprecated
           * @summary Sign-in to AnyConnect system.[Deprecated]
           * @param {RequestConfig & { params: V1AuthUserSigninPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<LoginUserResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        update: {
          /**
           * PUT /v1/auth/user/update
           * @summary Update a user's information.
           * @param {RequestConfig & { params: V1AuthUserUpdatePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<UpdateUserInfoResponse>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
      },
    },
    'endpoint': {
      acl: {
        appendpoints: {
          /**
           * GET /v1/endpoint/acl/appendpoints
           * @summary Returns the list of associated user app endpoints for the calling endpoint ID.
           * @param {RequestConfig & { params: V1EndpointAclAppendpointsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AppEndpoints>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        associatedendpoints: {
          /**
           * GET /v1/endpoint/acl/associatedendpoints
           * @summary Returns the list of associated endpoints for the calling user ID.
           * @param {RequestConfig & { params: V1EndpointAclAssociatedendpointsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AssociatedEndpoints>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        associatedusers: {
          /**
           * GET /v1/endpoint/acl/associatedusers
           * @summary Returns a list of all Users associated with an Endpoint
           * @param {RequestConfig & { params: V1EndpointAclAssociatedusersGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AssociatedUsers>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        change: {
          userrole: {
            /**
             * PUT /v1/endpoint/acl/change/userrole
             * @summary Change the role of user for specific endpoint.
             * @param {RequestConfig & { params: V1EndpointAclChangeUserrolePutParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
             */
            $put: (config) => null,
          },
        },
        online: {
          appendpoints: {
            /**
             * GET /v1/endpoint/acl/online/appendpoints
             * @summary Returns the list of associated user app endpoints for the calling endpoint ID.
             * @param {RequestConfig & { params: V1EndpointAclOnlineAppendpointsGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AppEndpoints>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        remove: {
          user: {
            /**
             * DELETE /v1/endpoint/acl/remove/user
             * @summary Removes a user from an endpoint
             * @param {RequestConfig & { params: V1EndpointAclRemoveUserDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          },
        },
        share: {
          /**
           * POST /v1/endpoint/acl/share
           * @summary Share an endpoint with another user.
           * @param {RequestConfig & { params: V1EndpointAclSharePostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        update: {
          userrole: {
            /**
             * PUT /v1/endpoint/acl/update/userrole
             * @deprecated
             * @summary Updates the role of user for specific endpoint. [Deprecated]
             * @param {RequestConfig & { params: V1EndpointAclUpdateUserrolePutParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
             */
            $put: (config) => null,
          },
        },
      },
      add: {
        device: {
          /**
           * PUT /v1/endpoint/add/device
           * @deprecated
           * @summary Add device to a tenant. [Deprecated]
           * @param {RequestConfig & { params: V1EndpointAddDevicePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
      },
      capabilities: {
        /**
         * GET /v1/endpoint/capabilities
         * @summary Get an endpoint capability
         * @param {RequestConfig & { params: V1EndpointCapabilitiesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetEndpointCapabilities>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/endpoint/capabilities
         * @summary Post an endpoint capability
         * @param {RequestConfig & { data: EndpointCapabilities, params: V1EndpointCapabilitiesPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      delete: {
        device: {
          /**
           * DELETE /v1/endpoint/delete/device
           * @summary Delete device from all restricted tenants.
           * @param {RequestConfig & { params: V1EndpointDeleteDeviceDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
      },
      device: {
        /**
         * GET /v1/endpoint/device
         * @summary Get device info.
         * @param {RequestConfig & { params: V1EndpointDeviceGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Device>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        capabilities: {
          /**
           * GET /v1/endpoint/device/capabilities
           * @summary Get a device capability
           * @param {RequestConfig & { params: V1EndpointDeviceCapabilitiesGetParams, headers: V1EndpointDeviceCapabilitiesGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetDeviceCapabilities>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/endpoint/device/capabilities
           * @summary Post a device capability
           * @param {RequestConfig & { data: DeviceCapabilities, params: V1EndpointDeviceCapabilitiesPostParams, headers: V1EndpointDeviceCapabilitiesPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        gateway_close: {
          /**
           * PUT /v1/endpoint/device/gateway_close
           * @summary Gateway close for specific device list.
           * @param {RequestConfig & { data: DeviceGatewayCloseRequest, params: V1EndpointDeviceGatewayClosePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
        gateway_open: {
          /**
           * PUT /v1/endpoint/device/gateway_open
           * @summary Gateway open from specific device list.
           * @param {RequestConfig & { data: DeviceGatewayOpenRequest, params: V1EndpointDeviceGatewayOpenPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
        onboard_credential: {
          /**
           * GET /v1/endpoint/device/onboard_credential
           * @summary Transfer devices one tenant to another tenant.
           * @param {RequestConfig & { params: V1EndpointDeviceOnboardCredentialGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeviceOnboardCredentialResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      devicepools: {
        /**
         * GET /v1/endpoint/devicepools
         * @summary Get list of device pools.
         * @param {RequestConfig & { params: V1EndpointDevicepoolsGetParams, headers: V1EndpointDevicepoolsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetListOfDevicePoolsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/endpoint/devicepools
         * @summary Create a device-pool in a tenant.
         * @param {RequestConfig & { params: V1EndpointDevicepoolsPostParams, headers: V1EndpointDevicepoolsPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CreateDevicePoolResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /** @param {!string} poolId poolId */
        $poolId: (poolId) => ({
          devices: {
            /**
             * GET /v1/endpoint/devicepools/{poolId}/devices
             * @summary List of devices from a pool.
             * @param {RequestConfig & { params: V1EndpointDevicepoolsPoolIdDevicesGetParams, headers: V1EndpointDevicepoolsPoolIdDevicesGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeleteDeviceFromThePoolResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v1/endpoint/devicepools/{poolId}/devices
             * @summary Add device in a pool.
             * @param {RequestConfig & { params: V1EndpointDevicepoolsPoolIdDevicesPostParams, headers: V1EndpointDevicepoolsPoolIdDevicesPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AddDeviceInThePoolResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
            /**
             * DELETE /v1/endpoint/devicepools/{poolId}/devices
             * @summary Delete device from the pool.
             * @param {RequestConfig & { params: V1EndpointDevicepoolsPoolIdDevicesDeleteParams, headers: V1EndpointDevicepoolsPoolIdDevicesDeleteHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeleteDeviceFromThePoolResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          },
        }),
      },
      devices: {
        /** @param {!string} deviceSerialNumber deviceSerialNumber */
        $deviceSerialNumber: (deviceSerialNumber) => ({
          firmwareversion: {
            /**
             * GET /v1/endpoint/devices/{deviceSerialNumber}/firmwareversion
             * @summary Retrieve list of firmware version against a device serial number
             * @param {RequestConfig & { headers: V1EndpointDevicesDeviceSerialNumberFirmwareversionGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GetDeviceFirmwareVersionResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v1/endpoint/devices/{deviceSerialNumber}/firmwareversion
             * @summary Post firmwareVersion against a deviceSerialNumber
             * @param {RequestConfig & { params: V1EndpointDevicesDeviceSerialNumberFirmwareversionPostParams, headers: V1EndpointDevicesDeviceSerialNumberFirmwareversionPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          otasettings: {
            /**
             * GET /v1/endpoint/devices/{deviceSerialNumber}/otasettings
             * @summary Return otasettings for a specific device.
             * @param {RequestConfig & { headers: V1EndpointDevicesDeviceSerialNumberOtasettingsGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OtaSettingsDto>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      },
      group: {
        /**
         * GET /v1/endpoint/group
         * @summary Get group
         * @param {RequestConfig & { params: V1EndpointGroupGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetGroupResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        change: {
          resource_group_id: {
            /**
             * PUT /v1/endpoint/group/change/resource_group_id
             * @summary Add list of endpoints to the group.
             * @param {RequestConfig & { params: V1EndpointGroupChangeResourceGroupIdPutParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<ChangeResourceGroupIDResponse>} An ApiRequest; to get result call 'process'.
             */
            $put: (config) => null,
          },
        },
        count: {
          /**
           * GET /v1/endpoint/group/count
           * @summary Retrieve counts by list of groups(user, device, app
           * @param {RequestConfig & { params: V1EndpointGroupCountGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GroupCountResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        create: {
          /**
           * PUT /v1/endpoint/group/create
           * @summary Create a group.
           * @param {RequestConfig & { params: V1EndpointGroupCreatePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CreateGroupResponse>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
        delete: {
          /**
           * DELETE /v1/endpoint/group/delete
           * @summary Delete a group.
           * @param {RequestConfig & { params: V1EndpointGroupDeleteDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeleteGroupResponse>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
        filtered_settings: {
          /**
           * GET /v1/endpoint/group/filtered_settings
           * @summary Get group settings with filtered query
           * @param {RequestConfig & { params: V1EndpointGroupFilteredSettingsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetGroupSettings>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        global_list: {
          /**
           * GET /v1/endpoint/group/global_list
           * @summary Get global list of groups except default ones.
           * @param {RequestConfig & { params: V1EndpointGroupGlobalListGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetListGroupResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        inference: {
          configurations: {
            /**
             * GET /v1/endpoint/group/inference/configurations
             * @summary Get group inference configuration.
             * @param {RequestConfig & { params: V1EndpointGroupInferenceConfigurationsGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GetGroupInferenceConfigurationResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v1/endpoint/group/inference/configurations
             * @summary Post a group inference configuration.
             * @param {RequestConfig & { data: InferenceConfiguration, params: V1EndpointGroupInferenceConfigurationsPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
        list: {
          /**
           * GET /v1/endpoint/group/list
           * @summary Get List of groups.
           * @param {RequestConfig & { params: V1EndpointGroupListGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetListGroupResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          resource_groups: {
            /**
             * GET /v1/endpoint/group/list/resource_groups
             * @summary Get List of assigned groups of an resource ID.
             * @param {RequestConfig & { params: V1EndpointGroupListResourceGroupsGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GetListGroupResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          resources: {
            /**
             * GET /v1/endpoint/group/list/resources
             * @summary Get List of group resources.
             * @param {RequestConfig & { params: V1EndpointGroupListResourcesGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GetGroupResourcesResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        restore: {
          default_settings: {
            /**
             * POST /v1/endpoint/group/restore/default_settings
             * @summary Restore default group settings
             * @param {RequestConfig & { params: V1EndpointGroupRestoreDefaultSettingsPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
        settings: {
          /**
           * GET /v1/endpoint/group/settings
           * @summary Get group settings
           * @param {RequestConfig & { params: V1EndpointGroupSettingsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetGroupSettings>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/endpoint/group/settings
           * @summary Post group settings
           * @param {RequestConfig & { data: GroupSettings, params: V1EndpointGroupSettingsPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        update: {
          /**
           * PUT /v1/endpoint/group/update
           * @summary Update a group [Name and description]
           * @param {RequestConfig & { params: V1EndpointGroupUpdatePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<RenameGroupResponse>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
      },
      health: {
        /**
         * GET /v1/endpoint/health
         * @summary Health Check
         * @param {RequestConfig & { params: V1EndpointHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      inference: {
        configurations: {
          /**
           * GET /v1/endpoint/inference/configurations
           * @summary Get an endpoint inference configuration.
           * @param {RequestConfig & { params: V1EndpointInferenceConfigurationsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetEndpointInferenceConfigurationResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/endpoint/inference/configurations
           * @summary Post an endpoint inference configuration.
           * @param {RequestConfig & { data: InferenceConfiguration, params: V1EndpointInferenceConfigurationsPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      info: {
        /**
         * GET /v1/endpoint/info
         * @summary Retrieve info of an endpoint
         * @param {RequestConfig & { params: V1EndpointInfoGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Endpoint>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * PUT /v1/endpoint/info
         * @deprecated
         * @summary Updates info of an endpoint
         * @param {RequestConfig & { data: EndpointUpdateInfo, params: V1EndpointInfoPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        by_device_id: {
          /**
           * GET /v1/endpoint/info/by_device_id
           * @summary Retrieve info of an endpoint
           * @param {RequestConfig & { params: V1EndpointInfoByDeviceIdGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Endpoint>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      info_fields: {
        /**
         * PUT /v1/endpoint/info_fields
         * @summary List of update field requests for an endpoint
         * @param {RequestConfig & { data: Array<EndpointUpdateRequest>, params: V1EndpointInfoFieldsPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      list: {
        /**
         * GET /v1/endpoint/list
         * @summary Returns a list of Endpoints of specific tenant ID.
         * @param {RequestConfig & { params: V1EndpointListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetEndpointListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        byproduct: {
          /**
           * GET /v1/endpoint/list/byproduct
           * @summary Return list of endpoints for specific product id.
           * @param {RequestConfig & { params: V1EndpointListByproductGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetProductEndpointsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        device: {
          /**
           * GET /v1/endpoint/list/device
           * @summary Get device list of a tenant.
           * @param {RequestConfig & { params: V1EndpointListDeviceGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeviceListResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      onboard: {
        /**
         * POST /v1/endpoint/onboard
         * @summary Onboarding a new endpoint [APP/DEVICE]. Returns endpointId.
         * @param {RequestConfig & { params: V1EndpointOnboardPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EndpointOnboardResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      properties: {
        /**
         * GET /v1/endpoint/properties
         * @summary Properties of an endpoint as a list of key-value pair.
         * @param {RequestConfig & { params: V1EndpointPropertiesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EndpointProperties>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/endpoint/properties
         * @summary Set properties of an endpoint as a list of key-value pair
         * @param {RequestConfig & { data: EndpointProperties, params: V1EndpointPropertiesPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EndpointProperties>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      reset: {
        /**
         * DELETE /v1/endpoint/reset
         * @summary Reset calling endpoint ID, only owner and superuser can reset an endpoint
         * @param {RequestConfig & { params: V1EndpointResetDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        device: {
          /**
           * PUT /v1/endpoint/reset/device
           * @summary Reset endpoints by device id.
           * @param {RequestConfig & { params: V1EndpointResetDevicePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
      },
      rollback: {
        transfer_device: {
          /**
           * PUT /v1/endpoint/rollback/transfer_device
           * @summary Rollback device from customer tenant.
           * @param {RequestConfig & { params: V1EndpointRollbackTransferDevicePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
      },
      serverconfig: {
        /**
         * GET /v1/endpoint/serverconfig
         * @summary Get turn, stun server configuration
         * @param {RequestConfig & { params: V1EndpointServerconfigGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EndpointServerConfig>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/endpoint/serverconfig
         * @summary Add static turn, stun server configuration.
         * @param {RequestConfig & { params: V1EndpointServerconfigPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<PostStaticServerConfigResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/endpoint/serverconfig
         * @summary Update static turn, stun server configuration.
         * @param {RequestConfig & { params: V1EndpointServerconfigPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<PutStaticServerConfigResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/endpoint/serverconfig
         * @summary Delete static turn, stun server configuration.
         * @param {RequestConfig & { params: V1EndpointServerconfigDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeleteStaticServerConfigResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      state: {
        /**
         * PUT /v1/endpoint/state
         * @summary Update an endpoint state [ONLINE or OFFLINE].
         * @param {RequestConfig & { params: V1EndpointStatePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EndpointStateUpdateResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      status: {
        /**
         * GET /v1/endpoint/status
         * @summary Get endpoint active status.[ACTIVE, RESET].
         * @param {RequestConfig & { params: V1EndpointStatusGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<'ACTIVE'|'RESET'|'UNKNOWN'|'ANY_STATUS'|'UNRECOGNIZED'>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      tenantstats: {
        /**
         * GET /v1/endpoint/tenantstats
         * @summary Get tenant stats [Number of online/offline users/devices].
         * @param {RequestConfig & { params: V1EndpointTenantstatsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantStatsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      transfer_devices: {
        /**
         * PUT /v1/endpoint/transfer_devices
         * @deprecated
         * @summary Transfer devices from one tenant to another tenant.
         * @param {RequestConfig & { data: TransferDevicesRequest, params: V1EndpointTransferDevicesPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      user: {
        deactivateuser: {
          /**
           * DELETE /v1/endpoint/user/deactivateuser
           * @summary Removes all owned endpoints and deletes all access info for an user
           * @param {RequestConfig & { params: V1EndpointUserDeactivateuserDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
        role: {
          /**
           * GET /v1/endpoint/user/role
           * @summary Get access role of the user on the endpoint.
           * @param {RequestConfig & { params: V1EndpointUserRoleGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<object>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        roles: {
          /**
           * GET /v1/endpoint/user/roles
           * @deprecated
           * @summary Get access roles of the user on the endpoint. [Deprecated]
           * @param {RequestConfig & { params: V1EndpointUserRolesGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<object>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
    },
    'event-messaging': {
      'SendAIEvents': {
        /**
         * POST /v1/event-messaging/SendAIEvents
         * @summary SendAiEvents
         * @param {RequestConfig & { data: EventVideoMetaDataModel, params: V1EventMessagingSendAIEventsPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SendMessageResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'check-presence': {
        /**
         * POST /v1/event-messaging/check-presence
         * @summary checkPresence
         * @param {RequestConfig & { data: Message, params: V1EventMessagingCheckPresencePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<PresenceStatus>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'getPresence': {
        /**
         * POST /v1/event-messaging/getPresence
         * @summary Get presence status for a list of Endpoints
         * @param {RequestConfig & { data: GetPresenceRequest, params: V1EventMessagingGetPresencePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<PresenceStatus>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'negotiate': {
        /**
         * GET /v1/event-messaging/negotiate
         * @summary Negotiate
         * @param {RequestConfig & { params: V1EventMessagingNegotiateGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/event-messaging/negotiate
         * @summary Negotiate
         * @param {RequestConfig & { params: V1EventMessagingNegotiatePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'send-event': {
        /**
         * POST /v1/event-messaging/send-event
         * @summary sendEvent
         * @param {RequestConfig & { data: Message, params: V1EventMessagingSendEventPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SendMessageResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'send-keep-alive-message': {
        /**
         * POST /v1/event-messaging/send-keep-alive-message
         * @summary sendKeepAliveMessage
         * @param {RequestConfig & { data: Message, params: V1EventMessagingSendKeepAliveMessagePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SendMessageResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'send-message': {
        /**
         * POST /v1/event-messaging/send-message
         * @summary sendMessage
         * @param {RequestConfig & { data: Message, params: V1EventMessagingSendMessagePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SendMessageResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
    },
    'gateway': {
      api_requests: {
        /**
         * GET /v1/gateway/api_requests
         * @summary Health Status
         * @param {RequestConfig & { params: V1GatewayApiRequestsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetAPIRequestsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      health: {
        /**
         * GET /v1/gateway/health
         * @summary Health Status
         * @param {RequestConfig & { params: V1GatewayHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      settings: {
        /**
         * GET /v1/gateway/settings
         * @summary Get service configuration data
         * @param {RequestConfig & { headers: V1GatewaySettingsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ServiceConfiguration>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    'iam': {
      AddApiResource: {
        /**
         * POST /v1/iam/AddApiResource
         * @summary AddApiResource
         * @param {RequestConfig & { params: V1IamAddApiResourcePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddApiResourceResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      AddApiResourceGroup: {
        /**
         * POST /v1/iam/AddApiResourceGroup
         * @summary AddApiResourceGroupFunction
         * @param {RequestConfig & { data: AddApiResourceGroupRequest, params: V1IamAddApiResourceGroupPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddApiResourceGroupResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      AddSmarterAIRoleRight: {
        /**
         * POST /v1/iam/AddSmarterAIRoleRight
         * @summary Add SmarterAIRoleRight
         * @param {RequestConfig & { data: SmarterAIRoleRight, params: V1IamAddSmarterAIRoleRightPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddSmarterAIRoleRightResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      AddSmarterAIUserRole: {
        /**
         * POST /v1/iam/AddSmarterAIUserRole
         * @summary AddSmarterAIUserRole
         * @param {RequestConfig & { params: V1IamAddSmarterAIUserRolePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddSmarterAIUserRoleResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      AddSubscription: {
        /**
         * POST /v1/iam/AddSubscription
         * @summary AddSubscription
         * @param {RequestConfig & { data: Subscription, params: V1IamAddSubscriptionPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      AddTenantManagerRoleRight: {
        /**
         * POST /v1/iam/AddTenantManagerRoleRight
         * @summary AddTenantManagerRoleRight
         * @param {RequestConfig & { data: TenantManagerRoleRight, params: V1IamAddTenantManagerRoleRightPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddTenantManagerRoleRightResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      AddTenantManagerUser: {
        /**
         * POST /v1/iam/AddTenantManagerUser
         * @summary AddTenantManagerUser
         * @param {RequestConfig & { params: V1IamAddTenantManagerUserPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddTenantManagerUserResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      AddTenantSubscription: {
        /**
         * POST /v1/iam/AddTenantSubscription
         * @summary AddTenantSubscription
         * @param {RequestConfig & { params: V1IamAddTenantSubscriptionPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddTenantSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      AddUserSubscription: {
        /**
         * POST /v1/iam/AddUserSubscription
         * @summary AddUserSubscription
         * @param {RequestConfig & { params: V1IamAddUserSubscriptionPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddUserSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      CreateApiKey: {
        /**
         * POST /v1/iam/CreateApiKey
         * @summary CreateApiKey
         * @param {RequestConfig & { params: V1IamCreateApiKeyPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CreateApiKeyResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      DeleteApiResource: {
        /**
         * DELETE /v1/iam/DeleteApiResource
         * @summary DeleteApiResource
         * @param {RequestConfig & { params: V1IamDeleteApiResourceDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeleteApiResourceResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      DeleteApiResourceGroup: {
        /**
         * DELETE /v1/iam/DeleteApiResourceGroup
         * @summary DeleteApiResourceGroup
         * @param {RequestConfig & { params: V1IamDeleteApiResourceGroupDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeleteApiResourceGroupResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      DeleteSubscription: {
        /**
         * DELETE /v1/iam/DeleteSubscription
         * @summary DeleteSubscription
         * @param {RequestConfig & { params: V1IamDeleteSubscriptionDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeleteSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      DeleteTenantSubscription: {
        /**
         * DELETE /v1/iam/DeleteTenantSubscription
         * @summary DeleteTenantSubscription
         * @param {RequestConfig & { params: V1IamDeleteTenantSubscriptionDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeleteTenantSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      DeleteUserSubscription: {
        /**
         * DELETE /v1/iam/DeleteUserSubscription
         * @summary DeleteUserSubscription
         * @param {RequestConfig & { params: V1IamDeleteUserSubscriptionDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeleteUserSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      GetAPIKeyInfo: {
        /**
         * GET /v1/iam/GetAPIKeyInfo
         * @summary GetAPIKeyInfo
         * @param {RequestConfig & { params: V1IamGetAPIKeyInfoGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetAPIKeyInfoResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetAPIKeyList: {
        /**
         * GET /v1/iam/GetAPIKeyList
         * @summary GetAPIKeyList
         * @param {RequestConfig & { params: V1IamGetAPIKeyListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetAPIKeyListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetApiResource: {
        /**
         * GET /v1/iam/GetApiResource
         * @summary GetApiResource
         * @param {RequestConfig & { params: V1IamGetApiResourceGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetApiResourceResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetApiResourceGroup: {
        /**
         * GET /v1/iam/GetApiResourceGroup
         * @summary GetApiResourceGroup
         * @param {RequestConfig & { params: V1IamGetApiResourceGroupGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetApiResourceGroupResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetApiResourceGroupList: {
        /**
         * GET /v1/iam/GetApiResourceGroupList
         * @summary GetApiResourceGroupList
         * @param {RequestConfig & { params: V1IamGetApiResourceGroupListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetApiResourceGroupListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetApiResourceList: {
        /**
         * GET /v1/iam/GetApiResourceList
         * @summary GetApiResourceList
         * @param {RequestConfig & { params: V1IamGetApiResourceListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetApiResourceListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetSmarterAIRoleRight: {
        /**
         * GET /v1/iam/GetSmarterAIRoleRight
         * @summary GetSmarterAIRoleRight
         * @param {RequestConfig & { params: V1IamGetSmarterAIRoleRightGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetSmarterAIRoleRightResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetSmarterAIRoleRightList: {
        /**
         * GET /v1/iam/GetSmarterAIRoleRightList
         * @summary GetSmarterAIRoleRightList
         * @param {RequestConfig & { params: V1IamGetSmarterAIRoleRightListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetSmarterAIRoleRightListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetSmarterAIUserRoleList: {
        /**
         * GET /v1/iam/GetSmarterAIUserRoleList
         * @summary GetSmarterAIUserRoleList
         * @param {RequestConfig & { params: V1IamGetSmarterAIUserRoleListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetSmarterAIUserRoleListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetSubscription: {
        /**
         * GET /v1/iam/GetSubscription
         * @summary GetSubscription
         * @param {RequestConfig & { params: V1IamGetSubscriptionGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetSubscriptionList: {
        /**
         * GET /v1/iam/GetSubscriptionList
         * @summary GetSubscriptionList
         * @param {RequestConfig & { params: V1IamGetSubscriptionListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetApiResourceGroupListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetTenantManagerRoleRight: {
        /**
         * GET /v1/iam/GetTenantManagerRoleRight
         * @summary GetTenantManagerRoleRight
         * @param {RequestConfig & { params: V1IamGetTenantManagerRoleRightGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetTenantManagerRoleRightResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetTenantManagerRoleRightList: {
        /**
         * GET /v1/iam/GetTenantManagerRoleRightList
         * @summary GetTenantManagerRoleRightList
         * @param {RequestConfig & { params: V1IamGetTenantManagerRoleRightListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetTenantManagerRoleRightListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetTenantManagerUserList: {
        /**
         * GET /v1/iam/GetTenantManagerUserList
         * @summary GetTenantManagerUserList
         * @param {RequestConfig & { params: V1IamGetTenantManagerUserListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetTenantManagerUserListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetTenantManagerUserRole: {
        /**
         * GET /v1/iam/GetTenantManagerUserRole
         * @summary GetTenantManagerUserRole
         * @param {RequestConfig & { params: V1IamGetTenantManagerUserRoleGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetTenantManagerUserRoleResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetTenantSubscription: {
        /**
         * GET /v1/iam/GetTenantSubscription
         * @summary GetTenantSubscription
         * @param {RequestConfig & { params: V1IamGetTenantSubscriptionGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetTenantSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetTenantSubscriptionList: {
        /**
         * GET /v1/iam/GetTenantSubscriptionList
         * @summary GetTenantSubscriptionList
         * @param {RequestConfig & { params: V1IamGetTenantSubscriptionListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetTenantSubscriptionListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetUserSubscription: {
        /**
         * GET /v1/iam/GetUserSubscription
         * @summary GetUserSubscription
         * @param {RequestConfig & { params: V1IamGetUserSubscriptionGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetUserSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      GetUserSubscriptionList: {
        /**
         * GET /v1/iam/GetUserSubscriptionList
         * @summary GetUserSubscriptionList
         * @param {RequestConfig & { params: V1IamGetUserSubscriptionListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetUserSubscriptionListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      RemoveSmarterAIUserRole: {
        /**
         * DELETE /v1/iam/RemoveSmarterAIUserRole
         * @summary RemoveSmarterAIUserRole
         * @param {RequestConfig & { params: V1IamRemoveSmarterAIUserRoleDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RemoveSmarterAIUserRoleResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      RemoveTenantManagerUser: {
        /**
         * DELETE /v1/iam/RemoveTenantManagerUser
         * @summary RemoveTenantManagerUser
         * @param {RequestConfig & { params: V1IamRemoveTenantManagerUserDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RemoveTenantManagerUserResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      UpdateAPIKeyInfo: {
        /**
         * PUT /v1/iam/UpdateAPIKeyInfo
         * @summary UpdateAPIKeyInfo
         * @param {RequestConfig & { data: Array<APIKeyUpdateRequest>, params: V1IamUpdateAPIKeyInfoPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateAPIKeyInfoResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      UpdateApiResource: {
        /**
         * PUT /v1/iam/UpdateApiResource
         * @summary UpdateApiResource
         * @param {RequestConfig & { params: V1IamUpdateApiResourcePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateApiResourceResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      UpdateApiResourceGroup: {
        /**
         * PUT /v1/iam/UpdateApiResourceGroup
         * @summary UpdateApiResourceGroup
         * @param {RequestConfig & { data: UpdateApiResourceGroupRequest, params: V1IamUpdateApiResourceGroupPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateApiResourceGroupResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      UpdateSmarterAIRoleRight: {
        /**
         * PUT /v1/iam/UpdateSmarterAIRoleRight
         * @summary Update SmarterAIRoleRight
         * @param {RequestConfig & { data: SmarterAIRoleRight, params: V1IamUpdateSmarterAIRoleRightPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateSmarterAIRoleRight>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      UpdateSmarterAIUserRole: {
        /**
         * PUT /v1/iam/UpdateSmarterAIUserRole
         * @summary UpdateSmarterAIUserRole
         * @param {RequestConfig & { params: V1IamUpdateSmarterAIUserRolePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateSmarterAIUserRoleResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      UpdateSubscription: {
        /**
         * PUT /v1/iam/UpdateSubscription
         * @summary UpdateSubscription
         * @param {RequestConfig & { data: Subscription, params: V1IamUpdateSubscriptionPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      UpdateTenantManagerRoleRight: {
        /**
         * PUT /v1/iam/UpdateTenantManagerRoleRight
         * @summary Update UpdateTenantManagerRoleRight
         * @param {RequestConfig & { data: TenantManagerRoleRight, params: V1IamUpdateTenantManagerRoleRightPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateTenantManagerRoleRightResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      UpdateTenantManagerUserRole: {
        /**
         * PUT /v1/iam/UpdateTenantManagerUserRole
         * @summary UpdateTenantManagerUserRole
         * @param {RequestConfig & { params: V1IamUpdateTenantManagerUserRolePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateTenantManagerUserRoleResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      UpdateTenantSubscription: {
        /**
         * PUT /v1/iam/UpdateTenantSubscription
         * @summary UpdateTenantSubscription
         * @param {RequestConfig & { params: V1IamUpdateTenantSubscriptionPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateTenantSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      UpdateUserSubscription: {
        /**
         * PUT /v1/iam/UpdateUserSubscription
         * @summary UpdateUserSubscription
         * @param {RequestConfig & { params: V1IamUpdateUserSubscriptionPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateUserSubscriptionResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      health: {
        /**
         * GET /v1/iam/health
         * @summary Health Check
         * @param {RequestConfig & { params: V1IamHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    'media': {
      'entry-recording': {
        /**
         * POST /v1/media/entry-recording
         * @summary Request for creating event entry.
         * @param {RequestConfig & { params: V1MediaEntryRecordingPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RequestRecordingResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'event-recordings': {
        /**
         * GET /v1/media/event-recordings
         * @summary Gets Recording URLs for an Endpoint
         * @param {RequestConfig & { params: V1MediaEventRecordingsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RecordingUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'event': {
        recordings: {
          /**
           * GET /v1/media/event/recordings
           * @summary Get range event recordings.
           * @param {RequestConfig & { params: V1MediaEventRecordingsGetParams, headers: V1MediaEventRecordingsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetMediaEventRecordingsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/media/event/recordings
           * @summary Request for an adding media data.
           * @param {RequestConfig & { params: V1MediaEventRecordingsPostParams, headers: V1MediaEventRecordingsPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AddMediaEventRecordingResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      'files': {
        /**
         * GET /v1/media/files
         * @summary Get upload urls for given extensions.
         * @param {RequestConfig & { params: V1MediaFilesGetParams, headers: V1MediaFilesGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetUploadUrlsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        token: {
          /**
           * GET /v1/media/files/token
           * @summary Get refresh upload URL.
           * @param {RequestConfig & { params: V1MediaFilesTokenGetParams, headers: V1MediaFilesTokenGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetRefreshUrlResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'health': {
        /**
         * GET /v1/media/health
         * @summary Health Check
         * @param {RequestConfig & { params: V1MediaHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'range-recordings': {
        /**
         * GET /v1/media/range-recordings
         * @summary Get Range Recording URLs for specific time range of an Endpoint
         * @param {RequestConfig & { params: V1MediaRangeRecordingsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RangeRecordingVideosResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'recording-url': {
        /**
         * GET /v1/media/recording-url
         * @deprecated
         * @summary GetRecordingUrl
         * @param {RequestConfig & { params: V1MediaRecordingUrlGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RecordedUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'recordingRequestStatus': {
        /**
         * GET /v1/media/recordingRequestStatus
         * @summary The status of the recording request
         * @param {RequestConfig & { params: V1MediaRecordingRequestStatusGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EventRecordingRequest>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * PUT /v1/media/recordingRequestStatus
         * @summary Update the status of the recording request.
         * @param {RequestConfig & { params: V1MediaRecordingRequestStatusPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<PutRecordingRequestStatusResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      'recordingRequestStatusList': {
        /**
         * GET /v1/media/recordingRequestStatusList
         * @summary List of the recording request status.
         * @param {RequestConfig & { params: V1MediaRecordingRequestStatusListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetRecordingRequestStatusListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'recordings': {
        /**
         * GET /v1/media/recordings
         * @summary Gets Recording URLs for an Endpoint
         * @param {RequestConfig & { params: V1MediaRecordingsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RecordingUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'requestRecording': {
        /**
         * POST /v1/media/requestRecording
         * @summary Request for an event recording.
         * @param {RequestConfig & { params: V1MediaRequestRecordingPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RequestRecordingResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'upload-credential': {
        /**
         * GET /v1/media/upload-credential
         * @summary Get a temporary access credential to upload recording data.
         * @param {RequestConfig & { params: V1MediaUploadCredentialGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RecordingAccessCredentialResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    'media_recording': {
      'entry-recording': {
        /**
         * POST /v1/media_recording/entry-recording
         * @summary Request for creating event entry.
         * @param {RequestConfig & { params: V1MediaRecordingEntryRecordingPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RequestRecordingResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'event-recordings': {
        /**
         * GET /v1/media_recording/event-recordings
         * @summary Gets Recording URLs for an Endpoint
         * @param {RequestConfig & { params: V1MediaRecordingEventRecordingsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RecordingUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'event': {
        recordings: {
          /**
           * GET /v1/media_recording/event/recordings
           * @summary Get range event recordings.
           * @param {RequestConfig & { params: V1MediaRecordingEventRecordingsGetParams, headers: V1MediaRecordingEventRecordingsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetMediaEventRecordingsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/media_recording/event/recordings
           * @summary Request for an adding media data.
           * @param {RequestConfig & { params: V1MediaRecordingEventRecordingsPostParams, headers: V1MediaRecordingEventRecordingsPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AddMediaEventRecordingResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      'files': {
        /**
         * GET /v1/media_recording/files
         * @summary Get upload urls for given extensions.
         * @param {RequestConfig & { params: V1MediaRecordingFilesGetParams, headers: V1MediaRecordingFilesGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetUploadUrlsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        token: {
          /**
           * GET /v1/media_recording/files/token
           * @summary Get refresh upload URL.
           * @param {RequestConfig & { params: V1MediaRecordingFilesTokenGetParams, headers: V1MediaRecordingFilesTokenGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetRefreshUrlResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'health': {
        /**
         * GET /v1/media_recording/health
         * @summary Health Check
         * @param {RequestConfig & { params: V1MediaRecordingHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'range-recordings': {
        /**
         * GET /v1/media_recording/range-recordings
         * @summary Get Range Recording URLs for specific time range of an Endpoint
         * @param {RequestConfig & { params: V1MediaRecordingRangeRecordingsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RangeRecordingVideosResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'recording-url': {
        /**
         * GET /v1/media_recording/recording-url
         * @deprecated
         * @summary GetRecordingUrl
         * @param {RequestConfig & { params: V1MediaRecordingRecordingUrlGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RecordedUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'recordingRequestStatus': {
        /**
         * GET /v1/media_recording/recordingRequestStatus
         * @summary The status of the recording request
         * @param {RequestConfig & { params: V1MediaRecordingRecordingRequestStatusGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EventRecordingRequest>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * PUT /v1/media_recording/recordingRequestStatus
         * @summary Update the status of the recording request.
         * @param {RequestConfig & { params: V1MediaRecordingRecordingRequestStatusPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<PutRecordingRequestStatusResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      'recordingRequestStatusList': {
        /**
         * GET /v1/media_recording/recordingRequestStatusList
         * @summary List of the recording request status.
         * @param {RequestConfig & { params: V1MediaRecordingRecordingRequestStatusListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetRecordingRequestStatusListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'recordings': {
        /**
         * GET /v1/media_recording/recordings
         * @summary Gets Recording URLs for an Endpoint
         * @param {RequestConfig & { params: V1MediaRecordingRecordingsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RecordingUrlWithSeekPos>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'requestRecording': {
        /**
         * POST /v1/media_recording/requestRecording
         * @summary Request for an event recording.
         * @param {RequestConfig & { params: V1MediaRecordingRequestRecordingPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RequestRecordingResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'upload-credential': {
        /**
         * GET /v1/media_recording/upload-credential
         * @summary Get a temporary access credential to upload recording data.
         * @param {RequestConfig & { params: V1MediaRecordingUploadCredentialGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RecordingAccessCredentialResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    'reportAssistant': {
      getloglevel: {
        /**
         * GET /v1/reportAssistant/getloglevel
         * @summary get log level
         * @param {RequestConfig & { params: V1ReportAssistantGetloglevelGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<LogLevelDTO>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      health: {
        /**
         * GET /v1/reportAssistant/health
         * @summary Health Check
         * @param {RequestConfig & { params: V1ReportAssistantHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      retentionperiod: {
        /**
         * GET /v1/reportAssistant/retentionperiod
         * @summary get retention period
         * @param {RequestConfig & { params: V1ReportAssistantRetentionperiodGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<object>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/reportAssistant/retentionperiod
         * @summary postRetentionPeriod
         * @param {RequestConfig & { params: V1ReportAssistantRetentionperiodPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      servicestatus: {
        /**
         * GET /v1/reportAssistant/servicestatus
         * @summary get service status
         * @param {RequestConfig & { params: V1ReportAssistantServicestatusGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      setloglevel: {
        /**
         * POST /v1/reportAssistant/setloglevel
         * @summary set log level
         * @param {RequestConfig & { params: V1ReportAssistantSetloglevelPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
    },
    'repository': {
      aimodel: {
        groups: {
          global: {
            /**
             * GET /v1/repository/aimodel/groups/global
             * @deprecated
             * @summary Returns List of aimodel groups with a continuation token
             * @param {RequestConfig & { params: V1RepositoryAimodelGroupsGlobalGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareGroupList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          local: {
            /**
             * GET /v1/repository/aimodel/groups/local
             * @deprecated
             * @summary Returns List of aimodel groups with a continuation token
             * @param {RequestConfig & { params: V1RepositoryAimodelGroupsLocalGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareGroupList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      aimodels: {
        deploy: {
          global: {
            /**
             * POST /v1/repository/aimodels/deploy/global
             * @deprecated
             * @summary deployGlobalAIModel
             * @param {RequestConfig & { params: V1RepositoryAimodelsDeployGlobalPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
            product: {
              /**
               * POST /v1/repository/aimodels/deploy/global/product
               * @deprecated
               * @summary deployGlobalAIModelForProduct
               * @param {RequestConfig & { params: V1RepositoryAimodelsDeployGlobalProductPostParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
            },
          },
          local: {
            /**
             * POST /v1/repository/aimodels/deploy/local
             * @deprecated
             * @summary deployLocalAIModel
             * @param {RequestConfig & { params: V1RepositoryAimodelsDeployLocalPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
            product: {
              /**
               * POST /v1/repository/aimodels/deploy/local/product
               * @deprecated
               * @summary deployLocalAIModelForProduct
               * @param {RequestConfig & { params: V1RepositoryAimodelsDeployLocalProductPostParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
            },
          },
        },
        deployed: {
          /**
           * GET /v1/repository/aimodels/deployed
           * @deprecated
           * @summary Returns List of deployed AIModels with a continuation token
           * @param {RequestConfig & { params: V1RepositoryAimodelsDeployedGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModels>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          latest: {
            /**
             * GET /v1/repository/aimodels/deployed/latest
             * @deprecated
             * @summary Returns List of deployed AIModels with a continuation token
             * @param {RequestConfig & { params: V1RepositoryAimodelsDeployedLatestGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AIModels>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          product: {
            /**
             * GET /v1/repository/aimodels/deployed/product
             * @deprecated
             * @summary Returns List of deployed AIModels with a continuation token
             * @param {RequestConfig & { params: V1RepositoryAimodelsDeployedProductGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AIModels>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          temp: {
            /**
             * GET /v1/repository/aimodels/deployed/temp
             * @deprecated
             * @summary Returns List of deployed AIModels with a continuation token
             * @param {RequestConfig & { params: V1RepositoryAimodelsDeployedTempGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AIModelsTemp>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        global: {
          /**
           * GET /v1/repository/aimodels/global
           * @deprecated
           * @summary Returns List of AIModels with a continuation token
           * @param {RequestConfig & { params: V1RepositoryAimodelsGlobalGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModels>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * PUT /v1/repository/aimodels/global
           * @deprecated
           * @summary Update AIModel info, only un-deployed AIModels can be updated
           * @param {RequestConfig & { data: AIModel, params: V1RepositoryAimodelsGlobalPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModel>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v1/repository/aimodels/global
           * @deprecated
           * @summary Delete AIModel
           * @param {RequestConfig & { params: V1RepositoryAimodelsGlobalDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModel>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
        local: {
          /**
           * GET /v1/repository/aimodels/local
           * @deprecated
           * @summary Returns List of AIModels with a continuation token
           * @param {RequestConfig & { params: V1RepositoryAimodelsLocalGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModels>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * PUT /v1/repository/aimodels/local
           * @deprecated
           * @summary Update AIModel info, only un-deployed AIModels can be updated
           * @param {RequestConfig & { data: AIModel, params: V1RepositoryAimodelsLocalPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModel>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v1/repository/aimodels/local
           * @deprecated
           * @summary Delete AIModel
           * @param {RequestConfig & { params: V1RepositoryAimodelsLocalDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModel>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
      },
      appversion: {
        /**
         * GET /v1/repository/appversion
         * @deprecated
         * @summary Returns List of AppVersions with a continuation token
         * @param {RequestConfig & { params: V1RepositoryAppversionGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AppVersionList>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/repository/appversion
         * @deprecated
         * @summary postAppVersion
         * @param {RequestConfig & { params: V1RepositoryAppversionPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/repository/appversion
         * @deprecated
         * @summary updateAppVersion
         * @param {RequestConfig & { data: AppVersion, params: V1RepositoryAppversionPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
      },
      firmware: {
        groups: {
          /**
           * GET /v1/repository/firmware/groups
           * @deprecated
           * @summary Returns List of firmware groups with a continuation token
           * @param {RequestConfig & { params: V1RepositoryFirmwareGroupsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<FirmwareGroupList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      firmwares: {
        /**
         * GET /v1/repository/firmwares
         * @deprecated
         * @summary Returns List of firmwares with a continuation token
         * @param {RequestConfig & { params: V1RepositoryFirmwaresGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Firmwares>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * PUT /v1/repository/firmwares
         * @deprecated
         * @summary Update firmware info, only un-deployed firmwares can be updated
         * @param {RequestConfig & { data: Firmware, params: V1RepositoryFirmwaresPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/repository/firmwares
         * @deprecated
         * @summary Delete firmware
         * @param {RequestConfig & { params: V1RepositoryFirmwaresDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        deploy: {
          /**
           * POST /v1/repository/firmwares/deploy
           * @deprecated
           * @summary deployFirmware
           * @param {RequestConfig & { params: V1RepositoryFirmwaresDeployPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        deployed: {
          /**
           * GET /v1/repository/firmwares/deployed
           * @deprecated
           * @summary Returns List of deployed firmwares with a continuation token
           * @param {RequestConfig & { params: V1RepositoryFirmwaresDeployedGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Firmwares>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      loglevel: {
        /**
         * GET /v1/repository/loglevel
         * @deprecated
         * @summary get log level
         * @param {RequestConfig & { params: V1RepositoryLoglevelGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/repository/loglevel
         * @deprecated
         * @summary set log level
         * @param {RequestConfig & { params: V1RepositoryLoglevelPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      packages: {
        /**
         * GET /v1/repository/packages
         * @deprecated
         * @summary Returns List of packages of a platform
         * @param {RequestConfig & { params: V1RepositoryPackagesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<PackageOfPlatform>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      platformvariants: {
        /**
         * GET /v1/repository/platformvariants
         * @deprecated
         * @summary Return all the variants of a tenant
         * @param {RequestConfig & { params: V1RepositoryPlatformvariantsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Platform>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/repository/platformvariants
         * @deprecated
         * @summary Adds or deletes a variant
         * @param {RequestConfig & { params: V1RepositoryPlatformvariantsPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      servicestatus: {
        /**
         * GET /v1/repository/servicestatus
         * @deprecated
         * @summary get service status
         * @param {RequestConfig & { params: V1RepositoryServicestatusGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      version: {
        /**
         * GET /v1/repository/version
         * @deprecated
         * @summary Gets package version for an endpoint
         * @param {RequestConfig & { params: V1RepositoryVersionGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EndpointLibraryVersion>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/repository/version
         * @deprecated
         * @summary Sets package version for an endpoint
         * @param {RequestConfig & { params: V1RepositoryVersionPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
    },
    'tenant': {
      aiaccelerator: {
        /**
         * GET /v1/tenant/aiaccelerator
         * @summary Gets ai accelerator by codeName
         * @param {RequestConfig & { params: V1TenantAiacceleratorGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AIAcceleratorList>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/tenant/aiaccelerator
         * @summary Posts ai accelerator
         * @param {RequestConfig & { data: AIAccelerator, params: V1TenantAiacceleratorPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/tenant/aiaccelerator
         * @summary Updates ai accelerator
         * @param {RequestConfig & { data: AIAccelerator, params: V1TenantAiacceleratorPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/tenant/aiaccelerator
         * @summary Deletes ai accelerator
         * @param {RequestConfig & { params: V1TenantAiacceleratorDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        list: {
          /**
           * GET /v1/tenant/aiaccelerator/list
           * @summary Gets list of ai accelerators
           * @param {RequestConfig & { params: V1TenantAiacceleratorListGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIAcceleratorList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      auth: {
        login: {
          /**
           * POST /v1/tenant/auth/login
           * @deprecated
           * @summary User login for tenant auth
           * @param {RequestConfig & { params: V1TenantAuthLoginPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantLoginResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          social: {
            /**
             * POST /v1/tenant/auth/login/social
             * @deprecated
             * @summary Social login for tenant auth
             * @param {RequestConfig & { params: V1TenantAuthLoginSocialPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<TenantLoginResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
        user: {
          /**
           * GET /v1/tenant/auth/user
           * @deprecated
           * @summary Get user details for tenant auth
           * @param {RequestConfig & { params: V1TenantAuthUserGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantUserDetailsResponse>} An ApiRequest; to get result call 'process'.
           */
          '$get': (config) => null,
          'recovery-email': {
            /**
             * POST /v1/tenant/auth/user/recovery-email
             * @deprecated
             * @summary Send a password recovery email
             * @param {RequestConfig & { params: V1TenantAuthUserRecoveryEmailPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<TenantResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
      },
      creditcard: {
        /**
         * GET /v1/tenant/creditcard
         * @deprecated
         * @summary Get card/s information
         * @param {RequestConfig & { params: V1TenantCreditcardGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ListOfCreditCardInformation>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/tenant/creditcard
         * @deprecated
         * @summary Add card information
         * @param {RequestConfig & { params: V1TenantCreditcardPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * DELETE /v1/tenant/creditcard
         * @deprecated
         * @summary Remove card information
         * @param {RequestConfig & { params: V1TenantCreditcardDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      dashboard: {
        user: {
          /**
           * POST /v1/tenant/dashboard/user
           * @summary Create a dashboard user
           * @param {RequestConfig & { data: DashboardUserForPost, params: V1TenantDashboardUserPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * PUT /v1/tenant/dashboard/user
           * @summary Modify a dashboard user
           * @param {RequestConfig & { data: DashboardUserForPost, params: V1TenantDashboardUserPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v1/tenant/dashboard/user
           * @summary Delete a dashboard user
           * @param {RequestConfig & { params: V1TenantDashboardUserDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          activate: {
            /**
             * POST /v1/tenant/dashboard/user/activate
             * @summary Activate a dashboard user
             * @param {RequestConfig & { data: ActivateDashboardUserForPost, params: V1TenantDashboardUserActivatePostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          list: {
            /**
             * GET /v1/tenant/dashboard/user/list
             * @summary Get dashboard user list
             * @param {RequestConfig & { params: V1TenantDashboardUserListGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DashboardUserList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      endpointservices: {
        /**
         * GET /v1/tenant/endpointservices
         * @deprecated
         * @summary Returns list of all anyconnect services available for endpoint along with subscription info about the calling endpoint
         * @param {RequestConfig & { params: V1TenantEndpointservicesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ListOfEndpointServices>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      endpointsubscribe: {
        /**
         * POST /v1/tenant/endpointsubscribe
         * @deprecated
         * @summary An endpoint subscribes to a service
         * @param {RequestConfig & { params: V1TenantEndpointsubscribePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      endpointunsubscribe: {
        /**
         * POST /v1/tenant/endpointunsubscribe
         * @deprecated
         * @summary An endpoint unsubscribes from a service
         * @param {RequestConfig & { params: V1TenantEndpointunsubscribePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      event: {
        configuration: {
          /**
           * GET /v1/tenant/event/configuration
           * @deprecated
           * @summary Get a specific event configuration
           * @param {RequestConfig & { params: V1TenantEventConfigurationGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EventConfiguration>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/tenant/event/configuration
           * @deprecated
           * @summary Add an event configuration
           * @param {RequestConfig & { data: EventConfigurationForPost, params: V1TenantEventConfigurationPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * PUT /v1/tenant/event/configuration
           * @deprecated
           * @summary Update a specific event configuration
           * @param {RequestConfig & { data: EventConfiguration, params: V1TenantEventConfigurationPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v1/tenant/event/configuration
           * @deprecated
           * @summary Delete an event configuration
           * @param {RequestConfig & { params: V1TenantEventConfigurationDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          assign: {
            /**
             * GET /v1/tenant/event/configuration/assign
             * @deprecated
             * @summary Get event configuration assign
             * @param {RequestConfig & { params: V1TenantEventConfigurationAssignGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<EventConfigurationAssignList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v1/tenant/event/configuration/assign
             * @deprecated
             * @summary Post event configuration assign
             * @param {RequestConfig & { data: Array<TenantWithRole>, params: V1TenantEventConfigurationAssignPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
            /**
             * DELETE /v1/tenant/event/configuration/assign
             * @deprecated
             * @summary Delete event configuration assign
             * @param {RequestConfig & { params: V1TenantEventConfigurationAssignDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
            self: {
              /**
               * POST /v1/tenant/event/configuration/assign/self
               * @deprecated
               * @summary Self Assign event configurations
               * @param {RequestConfig & { params: V1TenantEventConfigurationAssignSelfPostParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<EventConfigurationAssignList>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
            },
            tenant: {
              /**
               * GET /v1/tenant/event/configuration/assign/tenant
               * @deprecated
               * @summary Get list of assigned tenants of a event configuration
               * @param {RequestConfig & { params: V1TenantEventConfigurationAssignTenantGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<AssignedTenantList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          checkstatus: {
            deployment: {
              /**
               * GET /v1/tenant/event/configuration/checkstatus/deployment
               * @deprecated
               * @summary Return eventConfiguration deployment status
               * @param {RequestConfig & { params: V1TenantEventConfigurationCheckstatusDeploymentGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CheckEventConfigurationDeploymentResponse>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          deploy: {
            group: {
              /**
               * POST /v1/tenant/event/configuration/deploy/group
               * @deprecated
               * @summary Add an event configuration to a list of groups
               * @param {RequestConfig & { data: StringObj, params: V1TenantEventConfigurationDeployGroupPostParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
            },
          },
          deployed: {
            device: {
              list: {
                /**
                 * GET /v1/tenant/event/configuration/deployed/device/list
                 * @deprecated
                 * @summary Get a list of devices for an event configuration
                 * @param {RequestConfig & { params: V1TenantEventConfigurationDeployedDeviceListGetParams }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<DeviceIdList>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
              },
            },
            endpoint: {
              /**
               * GET /v1/tenant/event/configuration/deployed/endpoint
               * @deprecated
               * @summary Returns a list of event configurations for an endpoint
               * @param {RequestConfig & { params: V1TenantEventConfigurationDeployedEndpointGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<EventConfigurationWithTriggerList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
            group: {
              /**
               * GET /v1/tenant/event/configuration/deployed/group
               * @deprecated
               * @summary Get list of event configurations associated with a specific group
               * @param {RequestConfig & { params: V1TenantEventConfigurationDeployedGroupGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<Array<EventConfigurationAssign>>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
              /**
               * DELETE /v1/tenant/event/configuration/deployed/group
               * @deprecated
               * @summary Delete an event configuration from a specific group
               * @param {RequestConfig & { params: V1TenantEventConfigurationDeployedGroupDeleteParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
               */
              $delete: (config) => null,
              tenant: {
                /**
                 * GET /v1/tenant/event/configuration/deployed/group/tenant
                 * @deprecated
                 * @summary Get list of event configurations associated with a specific tenant
                 * @param {RequestConfig & { params: V1TenantEventConfigurationDeployedGroupTenantGetParams }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<Array<EventConfigurationList>>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
              },
            },
          },
        },
        deployed: {
          endpoint: {
            /**
             * GET /v1/tenant/event/deployed/endpoint
             * @deprecated
             * @summary Returns a list of events for a endpoint
             * @param {RequestConfig & { params: V1TenantEventDeployedEndpointGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<EventList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      eventConfiguration: {
        deployed: {
          endpoint: {
            /**
             * GET /v1/tenant/eventConfiguration/deployed/endpoint
             * @deprecated
             * @summary Returns a list of event configurations for an endpoint
             * @param {RequestConfig & { params: V1TenantEventConfigurationDeployedEndpointGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<EventConfigurationWithTriggerList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      eventType: {
        /**
         * GET /v1/tenant/eventType
         * @deprecated
         * @summary Get a specific event
         * @param {RequestConfig & { params: V1TenantEventTypeGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Event>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/tenant/eventType
         * @deprecated
         * @summary Add an event
         * @param {RequestConfig & { data: EventForPost, params: V1TenantEventTypePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/tenant/eventType
         * @deprecated
         * @summary Update a specific event
         * @param {RequestConfig & { data: Event, params: V1TenantEventTypePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/tenant/eventType
         * @deprecated
         * @summary Delete an event
         * @param {RequestConfig & { params: V1TenantEventTypeDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        assign: {
          /**
           * GET /v1/tenant/eventType/assign
           * @deprecated
           * @summary Get eventType assign
           * @param {RequestConfig & { params: V1TenantEventTypeAssignGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EventTypeList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/tenant/eventType/assign
           * @deprecated
           * @summary Posts eventType assign
           * @param {RequestConfig & { data: Array<TenantWithRole>, params: V1TenantEventTypeAssignPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /v1/tenant/eventType/assign
           * @deprecated
           * @summary Delete eventType assign
           * @param {RequestConfig & { params: V1TenantEventTypeAssignDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          tenant: {
            /**
             * GET /v1/tenant/eventType/assign/tenant
             * @deprecated
             * @summary Get list of assigned tenants of an event type
             * @param {RequestConfig & { params: V1TenantEventTypeAssignTenantGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AssignedTenantList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        deploy: {
          group: {
            /**
             * POST /v1/tenant/eventType/deploy/group
             * @deprecated
             * @summary Add an event to a list of groups
             * @param {RequestConfig & { data: StringObj, params: V1TenantEventTypeDeployGroupPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
        deployed: {
          device: {
            list: {
              /**
               * GET /v1/tenant/eventType/deployed/device/list
               * @deprecated
               * @summary Get a list of devices for an event type
               * @param {RequestConfig & { params: V1TenantEventTypeDeployedDeviceListGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<DeviceIdList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          group: {
            /**
             * GET /v1/tenant/eventType/deployed/group
             * @deprecated
             * @summary Get list of events associated with a specific group
             * @param {RequestConfig & { params: V1TenantEventTypeDeployedGroupGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<EventType>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * DELETE /v1/tenant/eventType/deployed/group
             * @deprecated
             * @summary Delete an event from a specific group
             * @param {RequestConfig & { params: V1TenantEventTypeDeployedGroupDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
            tenant: {
              /**
               * GET /v1/tenant/eventType/deployed/group/tenant
               * @deprecated
               * @summary Get list of events associated with a specific tenant
               * @param {RequestConfig & { params: V1TenantEventTypeDeployedGroupTenantGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<Array<EventList>>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
        },
        redeploy: {
          group: {
            /**
             * POST /v1/tenant/eventType/redeploy/group
             * @deprecated
             * @summary Redeploy an event to a specific group
             * @param {RequestConfig & { params: V1TenantEventTypeRedeployGroupPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
      },
      eventtype: {
        checkstatus: {
          deployment: {
            /**
             * GET /v1/tenant/eventtype/checkstatus/deployment
             * @deprecated
             * @summary Return ai eventtype deployment status
             * @param {RequestConfig & { params: V1TenantEventtypeCheckstatusDeploymentGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CheckEventTypeDeploymentResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      getloglevel: {
        /**
         * GET /v1/tenant/getloglevel
         * @summary get log level
         * @param {RequestConfig & { params: V1TenantGetloglevelGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      health: {
        /**
         * GET /v1/tenant/health
         * @summary Health Check
         * @param {RequestConfig & { params: V1TenantHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      models: {
        trigger: {
          classifications: {
            /**
             * GET /v1/tenant/models/trigger/classifications
             * @summary Get all trigger classification for given models
             * @param {RequestConfig & { params: V1TenantModelsTriggerClassificationsGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<TriggerClassificationListByModel>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      process: {
        eventType: {
          deployment: {
            /**
             * DELETE /v1/tenant/process/eventType/deployment
             * @deprecated
             * @summary Delete full EventType deployment
             * @param {RequestConfig & { params: V1TenantProcessEventTypeDeploymentDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          },
          management: {
            /**
             * DELETE /v1/tenant/process/eventType/management
             * @deprecated
             * @summary Delete full EventType management
             * @param {RequestConfig & { params: V1TenantProcessEventTypeManagementDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          },
        },
      },
      product: {
        /**
         * GET /v1/tenant/product
         * @summary Get a product
         * @param {RequestConfig & { params: V1TenantProductGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ProductWithAccelerator>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/tenant/product
         * @summary Save a product
         * @param {RequestConfig & { data: ProductForPost, params: V1TenantProductPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/tenant/product
         * @summary Update a product
         * @param {RequestConfig & { data: Product, params: V1TenantProductPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/tenant/product
         * @summary Delete a product
         * @param {RequestConfig & { params: V1TenantProductDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        assign: {
          /**
           * GET /v1/tenant/product/assign
           * @summary Get List of product assigned to a tenant
           * @param {RequestConfig & { params: V1TenantProductAssignGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ProductListWithAccelerator>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/tenant/product/assign
           * @summary Assign a product to multiple tenants
           * @param {RequestConfig & { data: SharedTenants, params: V1TenantProductAssignPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * PUT /v1/tenant/product/assign
           * @summary Assign a product to multiple tenants
           * @param {RequestConfig & { data: TenantsAssignUnAssign, params: V1TenantProductAssignPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v1/tenant/product/assign
           * @summary Delete Product assign of a tenant
           * @param {RequestConfig & { params: V1TenantProductAssignDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          self: {
            /**
             * POST /v1/tenant/product/assign/self
             * @summary Self assign products
             * @param {RequestConfig & { params: V1TenantProductAssignSelfPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<Product>>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          tenant: {
            /**
             * GET /v1/tenant/product/assign/tenant
             * @summary Get list of assigned tenants of a product
             * @param {RequestConfig & { params: V1TenantProductAssignTenantGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AssignedTenantList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        supported: {
          aimodel: {
            /**
             * GET /v1/tenant/product/supported/aimodel
             * @summary Get list of all AI Models accessible by a Tenant and Product
             * @param {RequestConfig & { params: V1TenantProductSupportedAimodelGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AIModelDtoList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      products: {
        /** @param {!string} productId A unique Product ID */
        $productId: (productId) => ({
          firmwares: {
            /**
             * GET /v1/tenant/products/{productId}/firmwares
             * @summary Get firmware deployment of a product
             * @param {RequestConfig & { params: V1TenantProductsProductIdFirmwaresGetParams, headers: V1TenantProductsProductIdFirmwaresGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<FirmwareList>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v1/tenant/products/{productId}/firmwares
             * @summary Post firmware deployment for a product
             * @param {RequestConfig & { params: V1TenantProductsProductIdFirmwaresPostParams, headers: V1TenantProductsProductIdFirmwaresPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        }),
      },
      provision: {
        /**
         * GET /v1/tenant/provision
         * @deprecated
         * @summary readAProvisionKey
         * @param {RequestConfig & { params: V1TenantProvisionGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ListOfProvisionKeys>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/tenant/provision
         * @deprecated
         * @summary createAProvisionKey
         * @param {RequestConfig & { data: ProvisionKeyRequest, params: V1TenantProvisionPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ProvisionKey>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/tenant/provision
         * @deprecated
         * @summary updateAProvisionKey
         * @param {RequestConfig & { params: V1TenantProvisionPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/tenant/provision
         * @deprecated
         * @summary deleteAProvisionKey
         * @param {RequestConfig & { params: V1TenantProvisionDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        check: {
          /**
           * GET /v1/tenant/provision/check
           * @deprecated
           * @summary isValidAProvisionKey
           * @param {RequestConfig & { params: V1TenantProvisionCheckGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        endpoint: {
          /**
           * POST /v1/tenant/provision/endpoint
           * @deprecated
           * @summary addEndpoint
           * @param {RequestConfig & { params: V1TenantProvisionEndpointPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        service: {
          /**
           * PUT /v1/tenant/provision/service
           * @deprecated
           * @summary Add/Merge given list of serviceIds
           * @param {RequestConfig & { params: V1TenantProvisionServicePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v1/tenant/provision/service
           * @deprecated
           * @summary Removes given list of serviceIds
           * @param {RequestConfig & { params: V1TenantProvisionServiceDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
      },
      right: {
        /**
         * POST /v1/tenant/right
         * @summary Create a right
         * @param {RequestConfig & { data: Right, params: V1TenantRightPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/tenant/right
         * @summary Modify a right
         * @param {RequestConfig & { data: Right, params: V1TenantRightPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/tenant/right
         * @summary Delete a right
         * @param {RequestConfig & { params: V1TenantRightDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        list: {
          /**
           * GET /v1/tenant/right/list
           * @summary Get right list
           * @param {RequestConfig & { params: V1TenantRightListGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<RightList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        /** @param {!string} id id */
        $id: (id) => ({
          /**
           * GET /v1/tenant/right/{id}
           * @summary Get a right
           * @param {RequestConfig & { params: V1TenantRightIdGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Right>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      role: {
        /**
         * POST /v1/tenant/role
         * @summary Create a role
         * @param {RequestConfig & { params: V1TenantRolePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/tenant/role
         * @summary Modify a role
         * @param {RequestConfig & { params: V1TenantRolePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/tenant/role
         * @summary Delete a role
         * @param {RequestConfig & { params: V1TenantRoleDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        list: {
          /**
           * GET /v1/tenant/role/list
           * @summary Get role list
           * @param {RequestConfig & { params: V1TenantRoleListGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<RoleList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        right: {
          /**
           * POST /v1/tenant/role/right
           * @summary Add right to a role
           * @param {RequestConfig & { params: V1TenantRoleRightPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /v1/tenant/role/right
           * @summary Remove right from a role
           * @param {RequestConfig & { params: V1TenantRoleRightDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          copy: {
            /**
             * POST /v1/tenant/role/right/copy
             * @summary Copy all rights from a role to another role
             * @param {RequestConfig & { params: V1TenantRoleRightCopyPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          list: {
            /**
             * GET /v1/tenant/role/right/list
             * @summary Get role right list
             * @param {RequestConfig & { params: V1TenantRoleRightListGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<RoleRightList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        tenant: {
          /**
           * POST /v1/tenant/role/tenant
           * @summary Add role to a tenant
           * @param {RequestConfig & { params: V1TenantRoleTenantPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /v1/tenant/role/tenant
           * @summary Remove role from a tenant
           * @param {RequestConfig & { params: V1TenantRoleTenantDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          list: {
            /**
             * GET /v1/tenant/role/tenant/list
             * @summary Get role tenant list
             * @param {RequestConfig & { params: V1TenantRoleTenantListGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<RoleTenantList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        /** @param {!string} id id */
        $id: (id) => ({
          /**
           * GET /v1/tenant/role/{id}
           * @summary Get a role
           * @param {RequestConfig & { params: V1TenantRoleIdGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Role>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      service: {
        api: {
          /**
           * POST /v1/tenant/service/api
           * @summary Post a service api
           * @param {RequestConfig & { data: ServiceAPI, params: V1TenantServiceApiPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * PUT /v1/tenant/service/api
           * @summary Update a service api
           * @param {RequestConfig & { data: ServiceAPI, params: V1TenantServiceApiPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v1/tenant/service/api
           * @summary Delete a service api
           * @param {RequestConfig & { params: V1TenantServiceApiDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          list: {
            /**
             * GET /v1/tenant/service/api/list
             * @summary Get service api list
             * @param {RequestConfig & { params: V1TenantServiceApiListGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<ServiceAPIList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      servicepackages: {
        /**
         * GET /v1/tenant/servicepackages
         * @deprecated
         * @summary Returns list of service packages of a particular service
         * @param {RequestConfig & { params: V1TenantServicepackagesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ListOfServicePackages>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/tenant/servicepackages
         * @deprecated
         * @summary Posts a new Service package
         * @param {RequestConfig & { data: ServicePackage, params: V1TenantServicepackagesPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/tenant/servicepackages
         * @deprecated
         * @summary Updates an existing service package
         * @param {RequestConfig & { data: ServicePackage, params: V1TenantServicepackagesPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/tenant/servicepackages
         * @deprecated
         * @summary Deletes an existing Service Package
         * @param {RequestConfig & { params: V1TenantServicepackagesDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      servicestatus: {
        /**
         * GET /v1/tenant/servicestatus
         * @deprecated
         * @summary get service status
         * @param {RequestConfig & { params: V1TenantServicestatusGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      setloglevel: {
        /**
         * POST /v1/tenant/setloglevel
         * @summary set log level
         * @param {RequestConfig & { params: V1TenantSetloglevelPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      tenant: {
        info: {
          /**
           * GET /v1/tenant/tenant/info
           * @summary Get tenant information
           * @param {RequestConfig & { params: V1TenantTenantInfoGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetTenantInfoResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      tenants: {
        /**
         * GET /v1/tenant/tenants
         * @summary Get tenants of an user
         * @param {RequestConfig & { params: V1TenantTenantsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ListOfTenants>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/tenant/tenants
         * @summary Create a tenant
         * @param {RequestConfig & { params: V1TenantTenantsPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantDTO>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/tenant/tenants
         * @summary Update tenant info
         * @param {RequestConfig & { params: V1TenantTenantsPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/tenant/tenants
         * @summary Delete a tenant
         * @param {RequestConfig & { params: V1TenantTenantsDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        approval: {
          /**
           * POST /v1/tenant/tenants/approval
           * @summary Approve a tenant
           * @param {RequestConfig & { params: V1TenantTenantsApprovalPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        assign: {
          /**
           * POST /v1/tenant/tenants/assign
           * @summary Assign tenant to another tenant as child
           * @param {RequestConfig & { params: V1TenantTenantsAssignPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /v1/tenant/tenants/assign
           * @summary Unassign child tenant from parent tenant
           * @param {RequestConfig & { params: V1TenantTenantsAssignDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
        creditcard: {
          /**
           * GET /v1/tenant/tenants/creditcard
           * @deprecated
           * @summary Get credit card info of a given tenant
           * @param {RequestConfig & { params: V1TenantTenantsCreditcardGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantCardRelationDTO>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/tenant/tenants/creditcard
           * @deprecated
           * @summary Add an card to a tenant
           * @param {RequestConfig & { params: V1TenantTenantsCreditcardPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /v1/tenant/tenants/creditcard
           * @deprecated
           * @summary Remove a card from tenant
           * @param {RequestConfig & { params: V1TenantTenantsCreditcardDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
        users: {
          /**
           * GET /v1/tenant/tenants/users
           * @summary Return list of tenant user
           * @param {RequestConfig & { params: V1TenantTenantsUsersGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ListOfTenantUsers>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * PUT /v1/tenant/tenants/users
           * @summary Update Tenant User Role
           * @param {RequestConfig & { params: V1TenantTenantsUsersPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
      },
      tenantservices: {
        /**
         * GET /v1/tenant/tenantservices
         * @deprecated
         * @summary Returns list of all AnyConnect services along with subscription info about the calling tenant
         * @param {RequestConfig & { params: V1TenantTenantservicesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ListOfTenantServices>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v1/tenant/tenantservices
         * @deprecated
         * @summary Posts a new AnyConnect Services
         * @param {RequestConfig & { data: Service, params: V1TenantTenantservicesPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v1/tenant/tenantservices
         * @deprecated
         * @summary Updates an existing AnyConnect Services
         * @param {RequestConfig & { data: Service, params: V1TenantTenantservicesPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v1/tenant/tenantservices
         * @deprecated
         * @summary Deletes an existing AnyConnect Services
         * @param {RequestConfig & { params: V1TenantTenantservicesDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      tenantsubscribe: {
        /**
         * POST /v1/tenant/tenantsubscribe
         * @deprecated
         * @summary A tenant subscribes to a service under a package
         * @param {RequestConfig & { params: V1TenantTenantsubscribePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      tenantunsubscribe: {
        /**
         * POST /v1/tenant/tenantunsubscribe
         * @deprecated
         * @summary A tenant unsubscribes from a service
         * @param {RequestConfig & { params: V1TenantTenantunsubscribePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantSuccessResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      trigger: {
        assign: {
          /**
           * GET /v1/tenant/trigger/assign
           * @deprecated
           * @summary Get trigger assign
           * @param {RequestConfig & { params: V1TenantTriggerAssignGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TriggerList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        classification: {
          /**
           * GET /v1/tenant/trigger/classification
           * @summary Get trigger classification details
           * @param {RequestConfig & { params: V1TenantTriggerClassificationGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TriggerClassification>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/tenant/trigger/classification
           * @summary Create new trigger classification
           * @param {RequestConfig & { data: TriggerClassification, params: V1TenantTriggerClassificationPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * PUT /v1/tenant/trigger/classification
           * @summary Modify a trigger classification
           * @param {RequestConfig & { data: TriggerClassification, params: V1TenantTriggerClassificationPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v1/tenant/trigger/classification
           * @summary Delete a trigger classification
           * @param {RequestConfig & { params: V1TenantTriggerClassificationDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TriggerClassification>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          discontinue: {
            /**
             * POST /v1/tenant/trigger/classification/discontinue
             * @summary Update trigger classification status to discontinued state
             * @param {RequestConfig & { params: V1TenantTriggerClassificationDiscontinuePostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          release: {
            /**
             * POST /v1/tenant/trigger/classification/release
             * @summary Update trigger classification status to released state
             * @param {RequestConfig & { params: V1TenantTriggerClassificationReleasePostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
        classifications: {
          /**
           * GET /v1/tenant/trigger/classifications
           * @summary Get all trigger classification
           * @param {RequestConfig & { params: V1TenantTriggerClassificationsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TriggerClassificationList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/tenant/trigger/classifications
           * @deprecated
           * @summary Create multiple trigger classification from a list
           * @param {RequestConfig & { data: Array<TriggerClassification>, params: V1TenantTriggerClassificationsPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        composite: {
          /**
           * GET /v1/tenant/trigger/composite
           * @summary Get a specific trigger
           * @param {RequestConfig & { params: V1TenantTriggerCompositeGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TriggerDetailsV2>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v1/tenant/trigger/composite
           * @summary Create a new trigger
           * @param {RequestConfig & { data: CompositeTriggerBody, params: V1TenantTriggerCompositePostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<object>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * PUT /v1/tenant/trigger/composite
           * @summary Update a trigger
           * @param {RequestConfig & { data: CompositeTriggerBody, params: V1TenantTriggerCompositePutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v1/tenant/trigger/composite
           * @summary Delete a trigger
           * @param {RequestConfig & { params: V1TenantTriggerCompositeDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          assign: {
            /**
             * GET /v1/tenant/trigger/composite/assign
             * @summary Get list of trigger for a tenant
             * @param {RequestConfig & { params: V1TenantTriggerCompositeAssignGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CompositeTriggerList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v1/tenant/trigger/composite/assign
             * @summary Assign a trigger to a tenant
             * @param {RequestConfig & { data: Array<AITenant>, params: V1TenantTriggerCompositeAssignPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
            /**
             * DELETE /v1/tenant/trigger/composite/assign
             * @summary Remove a tenant assignment from a trigger
             * @param {RequestConfig & { params: V1TenantTriggerCompositeAssignDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
            self: {
              /**
               * POST /v1/tenant/trigger/composite/assign/self
               * @summary Self Assign composite trigger
               * @param {RequestConfig & { params: V1TenantTriggerCompositeAssignSelfPostParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
            },
            tenant: {
              /**
               * GET /v1/tenant/trigger/composite/assign/tenant
               * @summary Get list of assigned tenants of a composite trigger
               * @param {RequestConfig & { params: V1TenantTriggerCompositeAssignTenantGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<AssignedTenantList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          checkstatus: {
            deployment: {
              /**
               * GET /v1/tenant/trigger/composite/checkstatus/deployment
               * @summary Return composite trigger deployment status
               * @param {RequestConfig & { params: V1TenantTriggerCompositeCheckstatusDeploymentGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CheckEventConfigurationDeploymentResponse>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          deploy: {
            group: {
              /**
               * POST /v1/tenant/trigger/composite/deploy/group
               * @summary Add a composite trigger to a list of groups
               * @param {RequestConfig & { data: StringObj, params: V1TenantTriggerCompositeDeployGroupPostParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
            },
          },
          deployed: {
            endpoint: {
              /**
               * GET /v1/tenant/trigger/composite/deployed/endpoint
               * @summary Returns a list of deployed composite triggers for an endpoint
               * @param {RequestConfig & { params: V1TenantTriggerCompositeDeployedEndpointGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeTriggerList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
            group: {
              /**
               * GET /v1/tenant/trigger/composite/deployed/group
               * @summary Get list of deployed composite triggers associated with a specific group
               * @param {RequestConfig & { params: V1TenantTriggerCompositeDeployedGroupGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<Array<CompositeTriggerDeploymentListWithLastUpdatedTime>>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
              /**
               * DELETE /v1/tenant/trigger/composite/deployed/group
               * @summary Delete a deployed composite trigger from a specific group
               * @param {RequestConfig & { params: V1TenantTriggerCompositeDeployedGroupDeleteParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
               */
              $delete: (config) => null,
              tenant: {
                /**
                 * GET /v1/tenant/trigger/composite/deployed/group/tenant
                 * @summary Get list of deployed composite triggers associated with a specific tenant
                 * @param {RequestConfig & { params: V1TenantTriggerCompositeDeployedGroupTenantGetParams }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<Array<CompositeTriggerDeploymentList>>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
              },
            },
          },
          discontinue: {
            /**
             * POST /v1/tenant/trigger/composite/discontinue
             * @summary Update composite trigger status to discontinued state
             * @param {RequestConfig & { params: V1TenantTriggerCompositeDiscontinuePostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<object>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          list: {
            /**
             * GET /v1/tenant/trigger/composite/list
             * @summary Get all trigger
             * @param {RequestConfig & { params: V1TenantTriggerCompositeListGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CompositeTriggerList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          product: {
            /**
             * GET /v1/tenant/trigger/composite/product
             * @summary Returns a list of composite triggers for a product
             * @param {RequestConfig & { params: V1TenantTriggerCompositeProductGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CompositeTriggerList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          release: {
            /**
             * POST /v1/tenant/trigger/composite/release
             * @summary Update composite trigger status to released state
             * @param {RequestConfig & { params: V1TenantTriggerCompositeReleasePostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<object>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
      },
    },
  },
  'v2': {
    'endpoint': {
      apn: {
        count: {
          /**
           * GET /v2/endpoint/apn/count
           * @summary APN list count
           * @param {RequestConfig & { headers: V2EndpointApnCountGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<APNInfoCount>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        create: {
          /**
           * POST /v2/endpoint/apn/create
           * @summary Post apn settings for a device group
           * @param {RequestConfig & { data: APNInfo, headers: V2EndpointApnCreatePostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        group: {
          list: {
            /** @param {!number} groupId A unique Group ID */
            $groupId: (groupId) => ({
              /**
               * GET /v2/endpoint/apn/group/list/{groupId}
               * @summary Get apn settings for a device group
               * @param {RequestConfig & { headers: V2EndpointApnGroupListGroupIdGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<APNInfoResponseList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            }),
          },
          remove: {
            /**
             * DELETE /v2/endpoint/apn/group/remove
             * @summary Remove apn settings from a device group
             * @param {RequestConfig & { data: APNGroupMapping, headers: V2EndpointApnGroupRemoveDeleteHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          },
        },
        list: {
          /**
           * GET /v2/endpoint/apn/list
           * @summary Get all APN list
           * @param {RequestConfig & { params: V2EndpointApnListGetParams, headers: V2EndpointApnListGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<APNInfoResponseList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      dashboard: {
        session: {
          create: {
            /**
             * POST /v2/endpoint/dashboard/session/create
             * @summary Create a new dashboard session
             * @param {RequestConfig & { params: V2EndpointDashboardSessionCreatePostParams, headers: V2EndpointDashboardSessionCreatePostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DashboardSession>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          end: {
            /** @param {!number} sessionId Unique id for dashboard session */
            $sessionId: (sessionId) => ({
              /**
               * DELETE /v2/endpoint/dashboard/session/end/{sessionId}
               * @summary Delete a dashboard session
               * @param {RequestConfig & { headers: V2EndpointDashboardSessionEndSessionIdDeleteHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
               */
              $delete: (config) => null,
            }),
          },
          refresh: {
            /** @param {!number} sessionId Unique id for dashboard session */
            $sessionId: (sessionId) => ({
              /**
               * PUT /v2/endpoint/dashboard/session/refresh/{sessionId}
               * @summary Delete a dashboard session
               * @param {RequestConfig & { headers: V2EndpointDashboardSessionRefreshSessionIdPutHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
               */
              $put: (config) => null,
            }),
          },
        },
      },
      devicegroups: {
        /** @param {!number} groupId A unique Group ID */
        $groupId: (groupId) => ({
          firmwares: {
            /**
             * GET /v2/endpoint/devicegroups/{groupId}/firmwares
             * @summary Get firmware deployment of a device group
             * @param {RequestConfig & { params: V2EndpointDevicegroupsGroupIdFirmwaresGetParams, headers: V2EndpointDevicegroupsGroupIdFirmwaresGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareDeploymentList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v2/endpoint/devicegroups/{groupId}/firmwares
             * @summary Post firmware deployment for a device group
             * @param {RequestConfig & { params: V2EndpointDevicegroupsGroupIdFirmwaresPostParams, headers: V2EndpointDevicegroupsGroupIdFirmwaresPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          otamessages: {
            /**
             * POST /v2/endpoint/devicegroups/{groupId}/otamessages
             * @summary Post ota messages to all devices of a group
             * @param {RequestConfig & { data: {[key: string]: string}, params: V2EndpointDevicegroupsGroupIdOtamessagesPostParams, headers: V2EndpointDevicegroupsGroupIdOtamessagesPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        }),
      },
      devicepools: {
        /** @param {!string} poolId A unique device pool ID */
        $poolId: (poolId) => ({
          firmwares: {
            /**
             * GET /v2/endpoint/devicepools/{poolId}/firmwares
             * @summary Get firmware deployment of a device pool
             * @param {RequestConfig & { params: V2EndpointDevicepoolsPoolIdFirmwaresGetParams, headers: V2EndpointDevicepoolsPoolIdFirmwaresGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareDeploymentList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v2/endpoint/devicepools/{poolId}/firmwares
             * @summary Post firmware deployment for a device pool
             * @param {RequestConfig & { params: V2EndpointDevicepoolsPoolIdFirmwaresPostParams, headers: V2EndpointDevicepoolsPoolIdFirmwaresPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        }),
      },
      devices: {
        /** @param {!string} deviceSerialNumber Uniquely identifiable device serial number */
        $deviceSerialNumber: (deviceSerialNumber) => ({
          firmwares: {
            /**
             * GET /v2/endpoint/devices/{deviceSerialNumber}/firmwares
             * @summary Get firmware deployment of a device
             * @param {RequestConfig & { headers: V2EndpointDevicesDeviceSerialNumberFirmwaresGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareDeploymentListV0>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            summary: {
              /**
               * GET /v2/endpoint/devices/{deviceSerialNumber}/firmwares/summary
               * @summary Get firmware deployment summary of a device
               * @param {RequestConfig & { headers: V2EndpointDevicesDeviceSerialNumberFirmwaresSummaryGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<FirmwareDeploymentListV0>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          otamessages: {
            /**
             * GET /v2/endpoint/devices/{deviceSerialNumber}/otamessages
             * @summary Retrieve list of ota messages of a device
             * @param {RequestConfig & { params: V2EndpointDevicesDeviceSerialNumberOtamessagesGetParams, headers: V2EndpointDevicesDeviceSerialNumberOtamessagesGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeviceOtaMessagesResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v2/endpoint/devices/{deviceSerialNumber}/otamessages
             * @summary Post ota messages to deviceSerialNumber
             * @param {RequestConfig & { data: {[key: string]: string}, params: V2EndpointDevicesDeviceSerialNumberOtamessagesPostParams, headers: V2EndpointDevicesDeviceSerialNumberOtamessagesPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          otastatus: {
            /**
             * GET /v2/endpoint/devices/{deviceSerialNumber}/otastatus
             * @summary Get OTA status of a device
             * @param {RequestConfig & { headers: V2EndpointDevicesDeviceSerialNumberOtastatusGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OtaStatusResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v2/endpoint/devices/{deviceSerialNumber}/otastatus
             * @summary Set OTA status of a device
             * @param {RequestConfig & { params: V2EndpointDevicesDeviceSerialNumberOtastatusPostParams, headers: V2EndpointDevicesDeviceSerialNumberOtastatusPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        }),
      },
      list: {
        /**
         * GET /v2/endpoint/list
         * @summary Returns a list of Endpoints of specific tenant ID.
         * @param {RequestConfig & { params: V2EndpointListGetParams, headers: V2EndpointListGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetEndpointListResponse>} An ApiRequest; to get result call 'process'.
         */
        '$get': (config) => null,
        'parking-status': {
          /**
           * GET /v2/endpoint/list/parking-status
           * @summary Get endpoint parking status list
           * @param {RequestConfig & { params: V2EndpointListParkingStatusGetParams, headers: V2EndpointListParkingStatusGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EndpointParkingStatusList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      shell: {
        execute: {
          /**
           * POST /v2/endpoint/shell/execute
           * @summary Execute shell for a endpoint
           * @param {RequestConfig & { data: ShellCommandExecuteDto, headers: V2EndpointShellExecutePostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        response: {
          /**
           * POST /v2/endpoint/shell/response
           * @summary Notify shell execution response
           * @param {RequestConfig & { data: ShellCommandExecuteDto, headers: V2EndpointShellResponsePostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      /** @param {!number} endpointId endpointId */
      $endpointId: (endpointId) => ({
        'input-sources': {
          /**
           * GET /v2/endpoint/{endpointId}/input-sources
           * @summary Return a list of input sources of the endpoint from product input list
           * @param {RequestConfig & { headers: V2EndpointEndpointIdInputSourcesGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EndpointInputSources>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      }),
    },
    'event-messaging': {
      'event': {
        count: {
          /**
           * GET /v2/event-messaging/event/count
           * @summary getEventCount
           * @param {RequestConfig & { params: V2EventMessagingEventCountGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EventCountResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'events': {
        /**
         * GET /v2/event-messaging/events
         * @summary GetEvents
         * @param {RequestConfig & { params: V2EventMessagingEventsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetEventsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v2/event-messaging/events
         * @summary PostEvent
         * @param {RequestConfig & { params: V2EventMessagingEventsPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<PostEventResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /** @param {!number} eventId eventId */
        $eventId: (eventId) => ({
          /**
           * GET /v2/event-messaging/events/{eventId}
           * @summary GetEvent
           * @param {RequestConfig & { params: V2EventMessagingEventsEventIdGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetEventResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * PATCH /v2/event-messaging/events/{eventId}
           * @summary PatchEvent
           * @param {RequestConfig & { params: V2EventMessagingEventsEventIdPatchParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<PatchEventResponse>} An ApiRequest; to get result call 'process'.
           */
          $patch: (config) => null,
          snapshots: {
            /**
             * PATCH /v2/event-messaging/events/{eventId}/snapshots
             * @summary PatchEventSnapshot
             * @param {RequestConfig & { params: V2EventMessagingEventsEventIdSnapshotsPatchParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<PatchEventSnapshotResponse>} An ApiRequest; to get result call 'process'.
             */
            $patch: (config) => null,
          },
        }),
      },
      'health': {
        /**
         * GET /v2/event-messaging/health
         * @summary Health Check
         * @param {RequestConfig & { params: V2EventMessagingHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'negotiate': {
        /**
         * POST /v2/event-messaging/negotiate
         * @summary Negotiate
         * @param {RequestConfig & { params: V2EventMessagingNegotiatePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<NegotiateResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'push-event-listener': {
        /**
         * POST /v2/event-messaging/push-event-listener
         * @summary Push event listener for testing.
         * @param {RequestConfig & { data: string, params: V2EventMessagingPushEventListenerPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<PushEventResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'pushed-event-list': {
        /**
         * GET /v2/event-messaging/pushed-event-list
         * @summary Get last pushed events.
         * @param {RequestConfig & { params: V2EventMessagingPushedEventListGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetPushEventListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'register-push-url': {
        /**
         * GET /v2/event-messaging/register-push-url
         * @summary Get register push url
         * @param {RequestConfig & { params: V2EventMessagingRegisterPushUrlGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetRegisterPushUrlResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v2/event-messaging/register-push-url
         * @summary Register push event url.
         * @param {RequestConfig & { params: V2EventMessagingRegisterPushUrlPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddRegisterPushUrlResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v2/event-messaging/register-push-url
         * @summary Register push event url.
         * @param {RequestConfig & { params: V2EventMessagingRegisterPushUrlPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UpdateRegisterPushUrlResponse>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v2/event-messaging/register-push-url
         * @summary Delete register push url
         * @param {RequestConfig & { params: V2EventMessagingRegisterPushUrlDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeleteRegisterPushUrlResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      'trigger': {
        report: {
          /**
           * GET /v2/event-messaging/trigger/report
           * @summary GetTriggerReport
           * @param {RequestConfig & { params: V2EventMessagingTriggerReportGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetTriggerReportResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v2/event-messaging/trigger/report
           * @summary PostTriggerReport
           * @param {RequestConfig & { data: PostTriggerReportBody, params: V2EventMessagingTriggerReportPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<PostTriggerReportResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          result: {
            /**
             * GET /v2/event-messaging/trigger/report/result
             * @summary GetTriggerReportResult
             * @param {RequestConfig & { params: V2EventMessagingTriggerReportResultGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GetTriggerReportResultResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
    },
    'media': {
      recordings: {
        /**
         * GET /v2/media/recordings
         * @summary Get range event recordings.
         * @param {RequestConfig & { params: V2MediaRecordingsGetParams, headers: V2MediaRecordingsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetMediaEventRecordingsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v2/media/recordings
         * @summary Request for an adding media data.
         * @param {RequestConfig & { params: V2MediaRecordingsPostParams, headers: V2MediaRecordingsPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AddMediaEventRecordingResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
    },
    'report': {
      'allconnections': {
        /**
         * GET /v2/report/allconnections
         * @deprecated
         * @summary List of all connections of a Tenant
         * @param {RequestConfig & { params: V2ReportAllconnectionsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'connectiondetails': {
        /**
         * GET /v2/report/connectiondetails
         * @deprecated
         * @summary getConnectionDetails
         * @param {RequestConfig & { params: V2ReportConnectiondetailsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'connectiondiagnosticreports': {
        /**
         * GET /v2/report/connectiondiagnosticreports
         * @deprecated
         * @summary getConnectionDiagnosticReport
         * @param {RequestConfig & { params: V2ReportConnectiondiagnosticreportsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'connectionevents': {
        /**
         * GET /v2/report/connectionevents
         * @deprecated
         * @summary getConnectionEvents
         * @param {RequestConfig & { params: V2ReportConnectioneventsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'device': {
        events: {
          /**
           * GET /v2/report/device/events
           * @deprecated
           * @summary Returns a list of Events for an Endpoint
           * @param {RequestConfig & { params: V2ReportDeviceEventsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<EventsDTO>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'deviceconnections': {
        /**
         * GET /v2/report/deviceconnections
         * @deprecated
         * @summary getDeviceConnections
         * @param {RequestConfig & { params: V2ReportDeviceconnectionsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'devices': {
        /**
         * GET /v2/report/devices
         * @deprecated
         * @summary Returns a list of all onboarded Devices
         * @param {RequestConfig & { params: V2ReportDevicesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<DevicesReportDTO>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'diagnosticreports': {
        /**
         * GET /v2/report/diagnosticreports
         * @deprecated
         * @summary Returns diagnostic report for particular user
         * @param {RequestConfig & { params: V2ReportDiagnosticreportsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<DiagnosticInfo>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'events': {
        /**
         * GET /v2/report/events
         * @deprecated
         * @summary Returns a list of Events for an Endpoint
         * @param {RequestConfig & { params: V2ReportEventsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<EventsDTO>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'getloglevel': {
        /**
         * GET /v2/report/getloglevel
         * @deprecated
         * @summary get log level
         * @param {RequestConfig & { params: V2ReportGetloglevelGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'lastevent': {
        /**
         * GET /v2/report/lastevent
         * @deprecated
         * @summary Returns list of events
         * @param {RequestConfig & { params: V2ReportLasteventGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<EventDTO>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'lastpresence': {
        /**
         * GET /v2/report/lastpresence
         * @deprecated
         * @summary getLastPresence
         * @param {RequestConfig & { params: V2ReportLastpresenceGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'live': {
        devices: {
          /**
           * GET /v2/report/live/devices
           * @deprecated
           * @summary Returns list of devices
           * @param {RequestConfig & { params: V2ReportLiveDevicesGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<DevicesReportDTO>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        users: {
          /**
           * GET /v2/report/live/users
           * @deprecated
           * @summary getLiveUsers
           * @param {RequestConfig & { params: V2ReportLiveUsersGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'pool': {
        /**
         * GET /v2/report/pool
         * @deprecated
         * @summary poolReport
         * @param {RequestConfig & { params: V2ReportPoolGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'presence': {
        /**
         * GET /v2/report/presence
         * @deprecated
         * @summary getPresenceHistory
         * @param {RequestConfig & { params: V2ReportPresenceGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'provision': {
        check: {
          /**
           * GET /v2/report/provision/check
           * @deprecated
           * @summary isValidAProvisionKey
           * @param {RequestConfig & { params: V2ReportProvisionCheckGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'reportlog': {
        /**
         * POST /v2/report/reportlog
         * @deprecated
         * @summary postReportLog
         * @param {RequestConfig & { params: V2ReportReportlogPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'reports': {
        /**
         * POST /v2/report/reports
         * @deprecated
         * @summary Post a diagnostic report
         * @param {RequestConfig & { data: Report, params: V2ReportReportsPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'sensorreport': {
        /**
         * GET /v2/report/sensorreport
         * @deprecated
         * @summary getSensorReportV2
         * @param {RequestConfig & { params: V2ReportSensorreportGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<SensorReportDTO>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v2/report/sensorreport
         * @deprecated
         * @summary postSensorReportV2
         * @param {RequestConfig & { data: Array<SensorReport>, params: V2ReportSensorreportPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'servicestatus': {
        /**
         * GET /v2/report/servicestatus
         * @deprecated
         * @summary get service status
         * @param {RequestConfig & { params: V2ReportServicestatusGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'setloglevel': {
        /**
         * POST /v2/report/setloglevel
         * @deprecated
         * @summary set log level
         * @param {RequestConfig & { params: V2ReportSetloglevelPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'statistics': {
        /**
         * GET /v2/report/statistics
         * @deprecated
         * @summary getStatistics
         * @param {RequestConfig & { params: V2ReportStatisticsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'statistics-range': {
        /**
         * GET /v2/report/statistics-range
         * @deprecated
         * @summary getStatisticsByRange
         * @param {RequestConfig & { params: V2ReportStatisticsRangeGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'streamreport': {
        /**
         * GET /v2/report/streamreport
         * @deprecated
         * @summary getStreamReport
         * @param {RequestConfig & { params: V2ReportStreamreportGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<StreamReportDTO>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v2/report/streamreport
         * @deprecated
         * @summary postStreamReport
         * @param {RequestConfig & { data: Array<StreamQualityProfile>, params: V2ReportStreamreportPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'tenant': {
        events: {
          /**
           * GET /v2/report/tenant/events
           * @deprecated
           * @summary Returns a list of Events for a Tenant
           * @param {RequestConfig & { params: V2ReportTenantEventsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<EventsDTO>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'user': {
        events: {
          /**
           * GET /v2/report/user/events
           * @deprecated
           * @summary Returns a list of Events for a User
           * @param {RequestConfig & { params: V2ReportUserEventsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<EventsDTO>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
    },
    'repository': {
      aicontainer: {
        /**
         * GET /v2/repository/aicontainer
         * @summary Get existing AI Container
         * @param {RequestConfig & { params: V2RepositoryAicontainerGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AIContainerV2>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v2/repository/aicontainer
         * @summary Add new AI Container
         * @param {RequestConfig & { data: AIContainerV2ForPost, params: V2RepositoryAicontainerPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v2/repository/aicontainer
         * @summary Update AI Container
         * @param {RequestConfig & { data: AIContainerV2ForPost, params: V2RepositoryAicontainerPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v2/repository/aicontainer
         * @summary Delete an existing AI Container
         * @param {RequestConfig & { params: V2RepositoryAicontainerDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        aimodel: {
          /**
           * GET /v2/repository/aicontainer/aimodel
           * @summary Returns List of deployed AIModel for a AI Container with a continuation token
           * @param {RequestConfig & { params: V2RepositoryAicontainerAimodelGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModelV0List>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v2/repository/aicontainer/aimodel
           * @summary addAIModelToContainer
           * @param {RequestConfig & { data: Array<InputMapping>, params: V2RepositoryAicontainerAimodelPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * PUT /v2/repository/aicontainer/aimodel
           * @summary updateAIModelToContainer
           * @param {RequestConfig & { data: Array<InputMapping>, params: V2RepositoryAicontainerAimodelPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v2/repository/aicontainer/aimodel
           * @summary deleteAIModelFromContainer
           * @param {RequestConfig & { params: V2RepositoryAicontainerAimodelDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
        assign: {
          /**
           * GET /v2/repository/aicontainer/assign
           * @summary Get List of Container of a tenant
           * @param {RequestConfig & { params: V2RepositoryAicontainerAssignGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIContainerList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v2/repository/aicontainer/assign
           * @summary Add Container with tenant
           * @param {RequestConfig & { data: Array<AITenant>, params: V2RepositoryAicontainerAssignPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /v2/repository/aicontainer/assign
           * @summary Delete Container of a tenant
           * @param {RequestConfig & { params: V2RepositoryAicontainerAssignDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          self: {
            /**
             * POST /v2/repository/aicontainer/assign/self
             * @summary Self assign AI Containers
             * @param {RequestConfig & { params: V2RepositoryAicontainerAssignSelfPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AIContainerList>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          tenant: {
            /**
             * GET /v2/repository/aicontainer/assign/tenant
             * @summary Get list of assigned tenants of an ai container
             * @param {RequestConfig & { params: V2RepositoryAicontainerAssignTenantGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AssignedTenantList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        checkstatus: {
          deployment: {
            /**
             * GET /v2/repository/aicontainer/checkstatus/deployment
             * @summary Return ai container deployment status
             * @param {RequestConfig & { params: V2RepositoryAicontainerCheckstatusDeploymentGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CheckAiContainerDeploymentResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        deploy: {
          /**
           * POST /v2/repository/aicontainer/deploy
           * @summary deployAIContainer
           * @param {RequestConfig & { data: StringObj, params: V2RepositoryAicontainerDeployPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        deployed: {
          /**
           * DELETE /v2/repository/aicontainer/deployed
           * @summary deleteDeployedAIContainer
           * @param {RequestConfig & { params: V2RepositoryAicontainerDeployedDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          device: {
            list: {
              /**
               * GET /v2/repository/aicontainer/deployed/device/list
               * @deprecated
               * @summary Returns a list of devices for deployed aicontainer
               * @param {RequestConfig & { params: V2RepositoryAicontainerDeployedDeviceListGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<DeviceIdList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          endpoint: {
            /**
             * GET /v2/repository/aicontainer/deployed/endpoint
             * @summary Returns a list of deployed aicontainers for an endpoint
             * @param {RequestConfig & { params: V2RepositoryAicontainerDeployedEndpointGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AIContainerV0List>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          group: {
            /**
             * GET /v2/repository/aicontainer/deployed/group
             * @summary Returns a list of deployed ai containers for a group
             * @param {RequestConfig & { params: V2RepositoryAicontainerDeployedGroupGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AIContainerV0ListDeployedWithLastUpdatedTime>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            history: {
              /**
               * GET /v2/repository/aicontainer/deployed/group/history
               * @summary Returns a history of deployed ai containers for a group
               * @param {RequestConfig & { params: V2RepositoryAicontainerDeployedGroupHistoryGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<AIContainerDeploymentList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          tenant: {
            /**
             * GET /v2/repository/aicontainer/deployed/tenant
             * @summary getDeployedAIContainerForTenant
             * @param {RequestConfig & { params: V2RepositoryAicontainerDeployedTenantGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AIContainerTenantList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        discontinue: {
          /**
           * POST /v2/repository/aicontainer/discontinue
           * @summary Update status of AI Container to discontinued state
           * @param {RequestConfig & { params: V2RepositoryAicontainerDiscontinuePostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        product: {
          /**
           * GET /v2/repository/aicontainer/product
           * @summary Returns a list of ai containers for a product
           * @param {RequestConfig & { params: V2RepositoryAicontainerProductGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIContainerList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        redeploy: {
          /**
           * POST /v2/repository/aicontainer/redeploy
           * @deprecated
           * @summary Redeploy an aicontainer
           * @param {RequestConfig & { params: V2RepositoryAicontainerRedeployPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        release: {
          /**
           * POST /v2/repository/aicontainer/release
           * @summary Update status of AI Container to released state
           * @param {RequestConfig & { params: V2RepositoryAicontainerReleasePostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      aimodel: {
        /**
         * GET /v2/repository/aimodel
         * @summary Get existing AI Model entry
         * @param {RequestConfig & { params: V2RepositoryAimodelGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AIModelV2WithAccelerator>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v2/repository/aimodel
         * @summary Add new AI Model entry
         * @param {RequestConfig & { data: AIModelV2ForPost, params: V2RepositoryAimodelPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v2/repository/aimodel
         * @summary Update existing AI Model entry
         * @param {RequestConfig & { data: AIModelV2, params: V2RepositoryAimodelPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<object>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v2/repository/aimodel
         * @summary Delete existing AI Model entry
         * @param {RequestConfig & { params: V2RepositoryAimodelDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        assign: {
          /**
           * GET /v2/repository/aimodel/assign
           * @summary Get list of all AI Models accessible by a Tenant
           * @param {RequestConfig & { params: V2RepositoryAimodelAssignGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModelListWithAccelerators>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v2/repository/aimodel/assign
           * @summary Set access to AI Model for a Tenant
           * @param {RequestConfig & { data: Array<AITenant>, params: V2RepositoryAimodelAssignPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /v2/repository/aimodel/assign
           * @summary Revoke access to a AI Model for a Tenant
           * @param {RequestConfig & { params: V2RepositoryAimodelAssignDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          self: {
            /**
             * POST /v2/repository/aimodel/assign/self
             * @summary Self assign AI Models
             * @param {RequestConfig & { params: V2RepositoryAimodelAssignSelfPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AIModelListWithAccelerators>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          tenant: {
            /**
             * GET /v2/repository/aimodel/assign/tenant
             * @summary Get list of assigned tenants of an ai model
             * @param {RequestConfig & { params: V2RepositoryAimodelAssignTenantGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AssignedTenantList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        checkstatus: {
          aicontainer: {
            association: {
              /**
               * GET /v2/repository/aimodel/checkstatus/aicontainer/association
               * @summary Return association status with any ai container
               * @param {RequestConfig & { params: V2RepositoryAimodelCheckstatusAicontainerAssociationGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CheckAiModelAssociationResponse>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
        },
        discontinue: {
          /**
           * POST /v2/repository/aimodel/discontinue
           * @summary Update AI Model status to discontinued state
           * @param {RequestConfig & { params: V2RepositoryAimodelDiscontinuePostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        release: {
          /**
           * POST /v2/repository/aimodel/release
           * @summary Update AI Model status to released state
           * @param {RequestConfig & { params: V2RepositoryAimodelReleasePostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        supported: {
          product: {
            /**
             * GET /v2/repository/aimodel/supported/product
             * @summary Returns List of product of a Tenant support given AIModel
             * @param {RequestConfig & { params: V2RepositoryAimodelSupportedProductGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<Product>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      firmware: {
        /**
         * GET /v2/repository/firmware
         * @summary Get firmware
         * @param {RequestConfig & { params: V2RepositoryFirmwareGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<FirmwareV2>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v2/repository/firmware
         * @summary Add a firmware
         * @param {RequestConfig & { data: FirmwareV2ForPost, params: V2RepositoryFirmwarePostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v2/repository/firmware
         * @summary Update firmware
         * @param {RequestConfig & { data: FirmwareV2, params: V2RepositoryFirmwarePutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v2/repository/firmware
         * @summary deleteFirmware
         * @param {RequestConfig & { params: V2RepositoryFirmwareDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        assign: {
          /**
           * GET /v2/repository/firmware/assign
           * @summary Get firmware list
           * @param {RequestConfig & { params: V2RepositoryFirmwareAssignGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<FirmwareList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v2/repository/firmware/assign
           * @summary Add firmware assign
           * @param {RequestConfig & { data: Array<AITenant>, params: V2RepositoryFirmwareAssignPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /v2/repository/firmware/assign
           * @summary deleteFirmwareAssign
           * @param {RequestConfig & { params: V2RepositoryFirmwareAssignDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          self: {
            /**
             * POST /v2/repository/firmware/assign/self
             * @summary Self assign firmwares
             * @param {RequestConfig & { params: V2RepositoryFirmwareAssignSelfPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareList>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          tenant: {
            /**
             * GET /v2/repository/firmware/assign/tenant
             * @summary Get list of assigned tenants of a firmware
             * @param {RequestConfig & { params: V2RepositoryFirmwareAssignTenantGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AssignedTenantList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        checkstatus: {
          deployment: {
            /**
             * GET /v2/repository/firmware/checkstatus/deployment
             * @summary Return firmware deployment status
             * @param {RequestConfig & { params: V2RepositoryFirmwareCheckstatusDeploymentGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CheckFirmwareDeploymentResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        deploy: {
          group: {
            /**
             * POST /v2/repository/firmware/deploy/group
             * @summary Add a Firmware to Groups
             * @param {RequestConfig & { data: StringObj, params: V2RepositoryFirmwareDeployGroupPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          product: {
            /**
             * POST /v2/repository/firmware/deploy/product
             * @summary Add a Firmware to Product List
             * @param {RequestConfig & { data: StringObj, params: V2RepositoryFirmwareDeployProductPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
        deployed: {
          endpoint: {
            /**
             * GET /v2/repository/firmware/deployed/endpoint
             * @summary Returns a list of deployed firmwares for an endpoint
             * @param {RequestConfig & { params: V2RepositoryFirmwareDeployedEndpointGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareListV0>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          group: {
            /**
             * GET /v2/repository/firmware/deployed/group
             * @summary Returns a list of deployed firmware for a group
             * @param {RequestConfig & { params: V2RepositoryFirmwareDeployedGroupGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareListWithLastUpdatedTime>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * DELETE /v2/repository/firmware/deployed/group
             * @summary Delete a Firmware from a Group
             * @param {RequestConfig & { params: V2RepositoryFirmwareDeployedGroupDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
            tenant: {
              /**
               * GET /v2/repository/firmware/deployed/group/tenant
               * @summary Returns a list of deployed firmware for a group
               * @param {RequestConfig & { params: V2RepositoryFirmwareDeployedGroupTenantGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<FirmwareList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          product: {
            /**
             * GET /v2/repository/firmware/deployed/product
             * @summary Returns a list of deployed firmware for a product
             * @param {RequestConfig & { params: V2RepositoryFirmwareDeployedProductGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * DELETE /v2/repository/firmware/deployed/product
             * @summary Delete a Firmware from a Product
             * @param {RequestConfig & { params: V2RepositoryFirmwareDeployedProductDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
            list: {
              /**
               * GET /v2/repository/firmware/deployed/product/list
               * @summary Returns a list of deployed firmware for a product latest order
               * @param {RequestConfig & { params: V2RepositoryFirmwareDeployedProductListGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<FirmwareList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
            rollback: {
              /**
               * DELETE /v2/repository/firmware/deployed/product/rollback
               * @summary Rollback the latest Firmware from a Product
               * @param {RequestConfig & { params: V2RepositoryFirmwareDeployedProductRollbackDeleteParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
               */
              $delete: (config) => null,
            },
            tenant: {
              /**
               * GET /v2/repository/firmware/deployed/product/tenant
               * @summary Returns a list of deployed firmware for a product
               * @param {RequestConfig & { params: V2RepositoryFirmwareDeployedProductTenantGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<FirmwareList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
        },
        redeploy: {
          group: {
            /**
             * POST /v2/repository/firmware/redeploy/group
             * @summary Redeploy a Firmware to a group
             * @param {RequestConfig & { params: V2RepositoryFirmwareRedeployGroupPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
      },
      health: {
        /**
         * GET /v2/repository/health
         * @summary Health Check
         * @param {RequestConfig & { params: V2RepositoryHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      label: {
        deployed: {
          endpoint: {
            /**
             * GET /v2/repository/label/deployed/endpoint
             * @summary Get deployed labels for an endpoint.
             * @param {RequestConfig & { params: V2RepositoryLabelDeployedEndpointGetParams, headers: V2RepositoryLabelDeployedEndpointGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<AiModelLabelList>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      process: {
        aimodel: {
          management: {
            /**
             * DELETE /v2/repository/process/aimodel/management
             * @deprecated
             * @summary Delete full AIModel management
             * @param {RequestConfig & { params: V2RepositoryProcessAimodelManagementDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          },
        },
        container: {
          deployment: {
            /**
             * DELETE /v2/repository/process/container/deployment
             * @deprecated
             * @summary Delete full container deployment
             * @param {RequestConfig & { params: V2RepositoryProcessContainerDeploymentDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          },
          management: {
            /**
             * DELETE /v2/repository/process/container/management
             * @deprecated
             * @summary Delete full container management
             * @param {RequestConfig & { params: V2RepositoryProcessContainerManagementDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          },
        },
        firmware: {
          deployment: {
            /**
             * DELETE /v2/repository/process/firmware/deployment
             * @deprecated
             * @summary Delete full Firmware deployment
             * @param {RequestConfig & { params: V2RepositoryProcessFirmwareDeploymentDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          },
          management: {
            /**
             * DELETE /v2/repository/process/firmware/management
             * @deprecated
             * @summary Delete full Firmware management
             * @param {RequestConfig & { params: V2RepositoryProcessFirmwareManagementDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          },
        },
      },
      product: {
        checkstatus: {
          deployment: {
            /**
             * GET /v2/repository/product/checkstatus/deployment
             * @summary Return product deployment status
             * @param {RequestConfig & { params: V2RepositoryProductCheckstatusDeploymentGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CheckProductDeploymentResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      raw: {
        video: {
          /**
           * GET /v2/repository/raw/video
           * @deprecated
           * @summary Get gateway raw video
           * @param {RequestConfig & { params: V2RepositoryRawVideoGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GatewayRawVideo>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v2/repository/raw/video
           * @deprecated
           * @summary Add a gateway raw video
           * @param {RequestConfig & { data: GatewayRawVideoForPost, params: V2RepositoryRawVideoPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * PUT /v2/repository/raw/video
           * @deprecated
           * @summary Update gateway raw video
           * @param {RequestConfig & { data: GatewayRawVideo, params: V2RepositoryRawVideoPutParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          /**
           * DELETE /v2/repository/raw/video
           * @deprecated
           * @summary deleteGatewayRawVideo
           * @param {RequestConfig & { params: V2RepositoryRawVideoDeleteParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          deploy: {
            /**
             * POST /v2/repository/raw/video/deploy
             * @deprecated
             * @summary Add a Raw Video to Endpoints
             * @param {RequestConfig & { data: StringObj, params: V2RepositoryRawVideoDeployPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          deployed: {
            /**
             * GET /v2/repository/raw/video/deployed
             * @deprecated
             * @summary Returns a list of deployed raw video for an endpoint
             * @param {RequestConfig & { params: V2RepositoryRawVideoDeployedGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GatewayRawVideoDeployList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * DELETE /v2/repository/raw/video/deployed
             * @deprecated
             * @summary Delete a Raw Video from an Endpoint
             * @param {RequestConfig & { params: V2RepositoryRawVideoDeployedDeleteParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
            tenant: {
              /**
               * GET /v2/repository/raw/video/deployed/tenant
               * @deprecated
               * @summary Returns a list of deployed raw video for a tenant
               * @param {RequestConfig & { params: V2RepositoryRawVideoDeployedTenantGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<GatewayRawVideoTenantList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          tenant: {
            /**
             * GET /v2/repository/raw/video/tenant
             * @deprecated
             * @summary Get raw videos of a tenant
             * @param {RequestConfig & { params: V2RepositoryRawVideoTenantGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GatewayRawVideoList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
    },
  },
  'v3': {
    geolocation: {
      fence: {
        /**
         * GET /v3/geolocation/fence
         * @summary getGeofenceListOfTenant
         * @param {RequestConfig & { params: V3GeolocationFenceGetParams, headers: V3GeolocationFenceGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<GeofenceResponseModel>>} An ApiRequest; to get result call 'process'.
         */
        '$get': (config) => null,
        /**
         * POST /v3/geolocation/fence
         * @summary createGeofenceWithScheduleAndDeploymentDetails
         * @param {RequestConfig & { data: CreateGeofenceWithScheduleAndDeploymentDetails, headers: V3GeolocationFencePostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GeofenceWithScheduleAndDeploymentDetails>} An ApiRequest; to get result call 'process'.
         */
        '$post': (config) => null,
        'device': {
          history: {
            /**
             * GET /v3/geolocation/fence/device/history
             * @summary getDeviceGeofenceHistory
             * @param {RequestConfig & { params: V3GeolocationFenceDeviceHistoryGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<DeviceGeofenceHistoryEntity>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        'endpoint': {
          /** @param {!number} endpointId endpointId */
          $endpointId: (endpointId) => ({
            gps: {
              /**
               * POST /v3/geolocation/fence/endpoint/{endpointId}/gps
               * @summary postEndpointGpsData
               * @param {RequestConfig & { data: GpsWithTimestamp, params: V3GeolocationFenceEndpointEndpointIdGpsPostParams, headers: V3GeolocationFenceEndpointEndpointIdGpsPostHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
            },
          }),
        },
        'stat': {
          tenant: {
            /** @param {!string} tenantId tenantId */
            $tenantId: (tenantId) => ({
              /**
               * GET /v3/geolocation/fence/stat/tenant/{tenantId}
               * @summary getGeofenceCount
               * @param {RequestConfig & { headers: V3GeolocationFenceStatTenantTenantIdGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            }),
          },
        },
        'vin-list': {
          /**
           * GET /v3/geolocation/fence/vin-list
           * @summary getGeofenceVinLists
           * @param {RequestConfig & { params: V3GeolocationFenceVinListGetParams, headers: V3GeolocationFenceVinListGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<GeofenceVinListResponse>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v3/geolocation/fence/vin-list
           * @summary createGeofenceVinList
           * @param {RequestConfig & { data: CreateGeofenceVinListRequest, headers: V3GeolocationFenceVinListPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GeofenceVinListResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /** @param {!string} vinListId vinListId */
          $vinListId: (vinListId) => ({
            /**
             * DELETE /v3/geolocation/fence/vin-list/{vinListId}
             * @summary deleteGeofenceVinList
             * @param {RequestConfig & { headers: V3GeolocationFenceVinListVinListIdDeleteHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
            /**
             * PATCH /v3/geolocation/fence/vin-list/{vinListId}
             * @summary updateGeofenceVinList
             * @param {RequestConfig & { data: UpdateGeofenceVinListRequest, headers: V3GeolocationFenceVinListVinListIdPatchHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GeofenceVinListResponse>} An ApiRequest; to get result call 'process'.
             */
            $patch: (config) => null,
            vin: {
              /**
               * GET /v3/geolocation/fence/vin-list/{vinListId}/vin
               * @summary getVinFromGeofenceVinList
               * @param {RequestConfig & { params: V3GeolocationFenceVinListVinListIdVinGetParams, headers: V3GeolocationFenceVinListVinListIdVinGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<Array<string>>} An ApiRequest; to get result call 'process'.
               */
              '$get': (config) => null,
              /**
               * POST /v3/geolocation/fence/vin-list/{vinListId}/vin
               * @summary addVinToGeofenceVinList
               * @param {RequestConfig & { data: StringList, headers: V3GeolocationFenceVinListVinListIdVinPostHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<Array<AddVinToVinListResponse>>} An ApiRequest; to get result call 'process'.
               */
              '$post': (config) => null,
              'bulk-delete': {
                /**
                 * POST /v3/geolocation/fence/vin-list/{vinListId}/vin/bulk-delete
                 * @summary removeVinFromGeofenceVinList
                 * @param {RequestConfig & { data: StringList, headers: V3GeolocationFenceVinListVinListIdVinBulkDeletePostHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<Array<RemoveVinFromVinListResponse>>} An ApiRequest; to get result call 'process'.
                 */
                $post: (config) => null,
              },
            },
          }),
        },
        /** @param {!string} fenceId fenceId */
        '$fenceId': (fenceId) => ({
          schedule: {
            /** @param {!string} scheduleId scheduleId */
            $scheduleId: (scheduleId) => ({
              'aicontainer-deployment': {
                /**
                 * GET /v3/geolocation/fence/{fenceId}/schedule/{scheduleId}/aicontainer-deployment
                 * @summary getDeployedAiContainerForGeofence
                 * @param {RequestConfig & { headers: V3GeolocationFenceFenceIdScheduleScheduleIdAicontainerDeploymentGetHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<AIContainerV2>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
                /**
                 * POST /v3/geolocation/fence/{fenceId}/schedule/{scheduleId}/aicontainer-deployment
                 * @summary deployAiContainerToGeofence
                 * @param {RequestConfig & { data: AiContainerGeofenceDeploymentRequest, headers: V3GeolocationFenceFenceIdScheduleScheduleIdAicontainerDeploymentPostHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
                 */
                $post: (config) => null,
                /**
                 * DELETE /v3/geolocation/fence/{fenceId}/schedule/{scheduleId}/aicontainer-deployment
                 * @summary removeAiContainerDeploymentFromGeofence
                 * @param {RequestConfig & { headers: V3GeolocationFenceFenceIdScheduleScheduleIdAicontainerDeploymentDeleteHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
                 */
                $delete: (config) => null,
                history: {
                  /**
                   * GET /v3/geolocation/fence/{fenceId}/schedule/{scheduleId}/aicontainer-deployment/history
                   * @summary getDeployedAiContainerForGeofenceLog
                   * @param {RequestConfig & { params: V3GeolocationFenceFenceIdScheduleScheduleIdAicontainerDeploymentHistoryGetParams, headers: V3GeolocationFenceFenceIdScheduleScheduleIdAicontainerDeploymentHistoryGetHeaders }} config Axios Request config
                   * @returns {import('@/api').ApiRequest<AiContainerGeofenceDeploymentList>} An ApiRequest; to get result call 'process'.
                   */
                  $get: (config) => null,
                },
              },
              'trigger-deployment': {
                /**
                 * GET /v3/geolocation/fence/{fenceId}/schedule/{scheduleId}/trigger-deployment
                 * @summary getDeployedTriggersForGeofence
                 * @param {RequestConfig & { params: V3GeolocationFenceFenceIdScheduleScheduleIdTriggerDeploymentGetParams, headers: V3GeolocationFenceFenceIdScheduleScheduleIdTriggerDeploymentGetHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<CompositeTriggerList>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
                /**
                 * POST /v3/geolocation/fence/{fenceId}/schedule/{scheduleId}/trigger-deployment
                 * @summary deployTriggerToGeofence
                 * @param {RequestConfig & { data: TriggerGeofenceDeploymentRequest, headers: V3GeolocationFenceFenceIdScheduleScheduleIdTriggerDeploymentPostHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
                 */
                $post: (config) => null,
                /** @param {!string} triggerId triggerId */
                $triggerId: (triggerId) => ({
                  /**
                   * DELETE /v3/geolocation/fence/{fenceId}/schedule/{scheduleId}/trigger-deployment/{triggerId}
                   * @summary removeTriggerDeploymentFromGeofence
                   * @param {RequestConfig & { headers: V3GeolocationFenceFenceIdScheduleScheduleIdTriggerDeploymentTriggerIdDeleteHeaders }} config Axios Request config
                   * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
                   */
                  $delete: (config) => null,
                }),
              },
            }),
          },
        }),
        /** @param {!string} id id */
        '$id': (id) => ({
          /**
           * GET /v3/geolocation/fence/{id}
           * @summary getGeofenceWithScheduleAndDeploymentDetails
           * @param {RequestConfig & { params: V3GeolocationFenceIdGetParams, headers: V3GeolocationFenceIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GeofenceWithScheduleAndDeploymentDetails>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * PATCH /v3/geolocation/fence/{id}
           * @summary updateGeofenceWithScheduleAndDeploymentDetails
           * @param {RequestConfig & { data: UpdateGeofenceWithScheduleAndDeploymentDetailsRequest, headers: V3GeolocationFenceIdPatchHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GeofenceWithScheduleAndDeploymentDetails>} An ApiRequest; to get result call 'process'.
           */
          $patch: (config) => null,
          activate: {
            /**
             * POST /v3/geolocation/fence/{id}/activate
             * @summary activeGeofence
             * @param {RequestConfig & { headers: V3GeolocationFenceIdActivatePostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          deactivate: {
            /**
             * POST /v3/geolocation/fence/{id}/deactivate
             * @summary deactivateGeofence
             * @param {RequestConfig & { headers: V3GeolocationFenceIdDeactivatePostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          discontinue: {
            /**
             * POST /v3/geolocation/fence/{id}/discontinue
             * @summary discontinueGeofence
             * @param {RequestConfig & { headers: V3GeolocationFenceIdDiscontinuePostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          publish: {
            /**
             * POST /v3/geolocation/fence/{id}/publish
             * @summary publishGeofence
             * @param {RequestConfig & { headers: V3GeolocationFenceIdPublishPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          schedule: {
            /**
             * GET /v3/geolocation/fence/{id}/schedule
             * @summary getSchedulerList
             * @param {RequestConfig & { params: V3GeolocationFenceIdScheduleGetParams, headers: V3GeolocationFenceIdScheduleGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<GeofenceScheduleResponseModel>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v3/geolocation/fence/{id}/schedule
             * @summary addScheduler
             * @param {RequestConfig & { data: GeofenceScheduleCreateRequest, headers: V3GeolocationFenceIdSchedulePostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GeofenceScheduleResponseModel>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
            /** @param {!string} scheduleId scheduleId */
            $scheduleId: (scheduleId) => ({
              /**
               * PATCH /v3/geolocation/fence/{id}/schedule/{scheduleId}
               * @summary updateScheduler
               * @param {RequestConfig & { data: GeofenceScheduleEditRequest, headers: V3GeolocationFenceIdScheduleScheduleIdPatchHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<GeofenceScheduleResponseModel>} An ApiRequest; to get result call 'process'.
               */
              $patch: (config) => null,
              discontinue: {
                /**
                 * POST /v3/geolocation/fence/{id}/schedule/{scheduleId}/discontinue
                 * @summary discontinueScheduler
                 * @param {RequestConfig & { headers: V3GeolocationFenceIdScheduleScheduleIdDiscontinuePostHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<GeofenceScheduleResponseModel>} An ApiRequest; to get result call 'process'.
                 */
                $post: (config) => null,
              },
              publish: {
                /**
                 * POST /v3/geolocation/fence/{id}/schedule/{scheduleId}/publish
                 * @summary publishScheduler
                 * @param {RequestConfig & { headers: V3GeolocationFenceIdScheduleScheduleIdPublishPostHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<GeofenceScheduleResponseModel>} An ApiRequest; to get result call 'process'.
                 */
                $post: (config) => null,
              },
            }),
          },
        }),
      },
      search: {
        /**
         * POST /v3/geolocation/search
         * @summary Returns geolocation based on request body
         * @param {RequestConfig & { data: object, params: V3GeolocationSearchPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GeolocationResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
    },
    media: {
      'device': {
        recordings: {
          request: {
            /** @param {!string} requestId Media Request Id. */
            $requestId: (requestId) => ({
              /**
               * DELETE /v3/media/device/recordings/request/{requestId}
               * @summary Delete media get request to stop upload notification.
               * @param {RequestConfig & { headers: V3MediaDeviceRecordingsRequestRequestIdDeleteHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
               */
              '$delete': (config) => null,
              'notify-segments': {
                /**
                 * POST /v3/media/device/recordings/request/{requestId}/notify-segments
                 * @summary Notify about available media segment.
                 * @param {RequestConfig & { data: Array<DeviceMediaSegment>, params: V3MediaDeviceRecordingsRequestRequestIdNotifySegmentsPostParams, headers: V3MediaDeviceRecordingsRequestRequestIdNotifySegmentsPostHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
                 */
                $post: (config) => null,
              },
            }),
          },
        },
        /** @param {!string} deviceId Uniquely identifiable device serial number */
        $deviceId: (deviceId) => ({
          recordings: {
            /**
             * POST /v3/media/device/{deviceId}/recordings
             * @summary Post any media recording.
             * @param {RequestConfig & { data: DeviceMedia, headers: V3MediaDeviceDeviceIdRecordingsPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
          thumbnails: {
            /**
             * POST /v3/media/device/{deviceId}/thumbnails
             * @summary Report thumbnail pack.
             * @param {RequestConfig & { data: ThumbnailPackReportBody, headers: V3MediaDeviceDeviceIdThumbnailsPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        }),
      },
      'endpoint': {
        thumbnail: {
          latest: {
            /**
             * GET /v3/media/endpoint/thumbnail/latest
             * @summary Get thumbnail packs tarball file list with download url.
             * @param {RequestConfig & { params: V3MediaEndpointThumbnailLatestGetParams, headers: V3MediaEndpointThumbnailLatestGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<ThumbnailPackWithEndpointId>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        /** @param {!number} endpointId EndpointId. */
        $endpointId: (endpointId) => ({
          thumbnail: {
            latest: {
              /**
               * GET /v3/media/endpoint/{endpointId}/thumbnail/latest
               * @summary Get thumbnail packs tarball file list with download url.
               * @param {RequestConfig & { headers: V3MediaEndpointEndpointIdThumbnailLatestGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<ThumbnailPack>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          thumbnails: {
            /**
             * GET /v3/media/endpoint/{endpointId}/thumbnails
             * @summary Get thumbnail packs tarball file list with download url.
             * @param {RequestConfig & { params: V3MediaEndpointEndpointIdThumbnailsGetParams, headers: V3MediaEndpointEndpointIdThumbnailsGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<GetThumbnailPackResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      },
      'recording-request': {
        /** @param {!string} requestId Request Id */
        $requestId: (requestId) => ({
          /**
           * GET /v3/media/recording-request/{requestId}
           * @summary Get status of recording request.
           * @param {RequestConfig & { headers: V3MediaRecordingRequestRequestIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<RecordingRequestDetails>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      'recordings': {
        /**
         * GET /v3/media/recordings
         * @summary Get range device media recordings.
         * @param {RequestConfig & { params: V3MediaRecordingsGetParams, headers: V3MediaRecordingsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetMediaEventRecordingsResponseV3>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    monitoring: {
      'endpoint': {
        /** @param {!number} endpointId endpointId */
        $endpointId: (endpointId) => ({
          'event-media-stat': {
            /**
             * GET /v3/monitoring/endpoint/{endpointId}/event-media-stat
             * @summary getDeviceEventAndMediaStat
             * @param {RequestConfig & { params: V3MonitoringEndpointEndpointIdEventMediaStatGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      },
      'frequently-on-off-devices': {
        /**
         * GET /v3/monitoring/frequently-on-off-devices
         * @summary getAllFrequentlyOnOffDevices
         * @param {RequestConfig & { params: V3MonitoringFrequentlyOnOffDevicesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<FrequentOnOffDevice>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'suspected-permanent-offline-devices': {
        /**
         * GET /v3/monitoring/suspected-permanent-offline-devices
         * @summary getAllSuspectedPermanentOfflineDevices
         * @param {RequestConfig & { params: V3MonitoringSuspectedPermanentOfflineDevicesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<SuspectedPermanentOfflineDevice>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      /** @param {!string} tenantId tenantId */
      '$tenantId': (tenantId) => ({
        'frequently-on-off-devices': {
          /**
           * GET /v3/monitoring/{tenantId}/frequently-on-off-devices
           * @summary getFrequentlyOnOffDevices
           * @param {RequestConfig & { params: V3MonitoringTenantIdFrequentlyOnOffDevicesGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<FrequentOnOffDevice>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'suspected-permanent-offline-devices': {
          /**
           * GET /v3/monitoring/{tenantId}/suspected-permanent-offline-devices
           * @summary getSuspectedPermanentOfflineDevices
           * @param {RequestConfig & { params: V3MonitoringTenantIdSuspectedPermanentOfflineDevicesGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<SuspectedPermanentOfflineDevice>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        /** @param {!number} endpointId endpointId */
        '$endpointId': (endpointId) => ({
          'bandwidth-history': {
            /**
             * GET /v3/monitoring/{tenantId}/{endpointId}/bandwidth-history
             * @summary getBandwidthHistory
             * @param {RequestConfig & { params: V3MonitoringTenantIdEndpointIdBandwidthHistoryGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<BandwidthHistory>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      }),
    },
    report: {
      'allconnections': {
        /**
         * GET /v3/report/allconnections
         * @deprecated
         * @summary List of all connections of a Tenant
         * @param {RequestConfig & { params: V3ReportAllconnectionsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ConnectionsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'connection': {
        details: {
          /**
           * GET /v3/report/connection/details
           * @deprecated
           * @summary getConnectionDetails
           * @param {RequestConfig & { params: V3ReportConnectionDetailsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionDetails>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        diagnostics: {
          /**
           * GET /v3/report/connection/diagnostics
           * @summary getConnectionDiagnostics
           * @param {RequestConfig & { params: V3ReportConnectionDiagnosticsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionDiagnostics>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          filter: {
            /**
             * GET /v3/report/connection/diagnostics/filter
             * @summary Returns diagnostic report for particular connection
             * @param {RequestConfig & { params: V3ReportConnectionDiagnosticsFilterGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DiagnosticListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          search: {
            /**
             * GET /v3/report/connection/diagnostics/search
             * @summary Search diagnostic report for particular connection
             * @param {RequestConfig & { params: V3ReportConnectionDiagnosticsSearchGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DiagnosticListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        events: {
          /**
           * GET /v3/report/connection/events
           * @deprecated
           * @summary Returns List of ConnectionEvents with a continuation token
           * @param {RequestConfig & { params: V3ReportConnectionEventsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionEventList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'connectiondiagnosticreports': {
        /**
         * GET /v3/report/connectiondiagnosticreports
         * @deprecated
         * @summary getConnectionDiagnosticReport
         * @param {RequestConfig & { params: V3ReportConnectiondiagnosticreportsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DiagnosticReportsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'connections': {
        /**
         * POST /v3/report/connections
         * @summary Create or update a connection
         * @param {RequestConfig & { data: PostStreamDTO, params: V3ReportConnectionsPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'custominfo': {
        /**
         * GET /v3/report/custominfo
         * @summary Get custom info
         * @param {RequestConfig & { params: V3ReportCustominfoGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CustomInfo>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'device': {
        connections: {
          /**
           * GET /v3/report/device/connections
           * @deprecated
           * @summary getEndPointConnections
           * @param {RequestConfig & { params: V3ReportDeviceConnectionsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          search: {
            /**
             * GET /v3/report/device/connections/search
             * @deprecated
             * @summary Search connections of a device
             * @param {RequestConfig & { params: V3ReportDeviceConnectionsSearchGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<ConnectionListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        diagnostics: {
          /**
           * GET /v3/report/device/diagnostics
           * @summary Returns diagnostic report for particular device
           * @param {RequestConfig & { params: V3ReportDeviceDiagnosticsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DiagnosticsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          filter: {
            /**
             * GET /v3/report/device/diagnostics/filter
             * @summary Returns diagnostic report for particular device
             * @param {RequestConfig & { params: V3ReportDeviceDiagnosticsFilterGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DiagnosticListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          search: {
            /**
             * GET /v3/report/device/diagnostics/search
             * @summary Searches diagnostic report of particular device
             * @param {RequestConfig & { params: V3ReportDeviceDiagnosticsSearchGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DiagnosticListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        events: {
          /**
           * GET /v3/report/device/events
           * @deprecated
           * @summary Returns a list of Events for an Endpoint
           * @param {RequestConfig & { params: V3ReportDeviceEventsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeviceEvents>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          tmp: {
            /**
             * GET /v3/report/device/events/tmp
             * @deprecated
             * @summary Returns a list of Events for an Endpoint
             * @param {RequestConfig & { params: V3ReportDeviceEventsTmpGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeviceEvents>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        presence: {
          history: {
            /**
             * GET /v3/report/device/presence/history
             * @summary List of all presence of an endpoint
             * @param {RequestConfig & { params: V3ReportDevicePresenceHistoryGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DevicePresenceHistoryResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        records: {
          /**
           * GET /v3/report/device/records
           * @summary Retrieve list of device record for an endpoint
           * @param {RequestConfig & { params: V3ReportDeviceRecordsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeviceRecordEntityList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v3/report/device/records
           * @summary Post a list of device record
           * @param {RequestConfig & { data: Array<DeviceRecordRequestBody>, params: V3ReportDeviceRecordsPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        streams: {
          /**
           * GET /v3/report/device/streams
           * @summary getEndPointStreams
           * @param {RequestConfig & { params: V3ReportDeviceStreamsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },

        $deviceId: (deviceId) => ({
          'log-request': {
            /**
             * GET /v3/report/device/{deviceId}/log-request
             * @summary Retrieve a list of log requests for a specific device id
             * @param {RequestConfig & { params: V3ReportDeviceDeviceIdLogRequestGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<LogRequestListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v3/report/device/{deviceId}/log-request
             * @summary Post a diagnostic log download request
             * @param {RequestConfig & { data: LogRequestCreateModel, params: V3ReportDeviceDeviceIdLogRequestPostParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<object>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,

            $requestId: (requestId) => ({
              /**
               * GET /v3/report/device/{deviceId}/log-request/{requestId}
               * @summary Retrieve a specific log request
               * @param {RequestConfig & { params: V3ReportDeviceDeviceIdLogRequestRequestIdGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<LogRequest>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
              /**
               * PUT /v3/report/device/{deviceId}/log-request/{requestId}
               * @summary Update a diagnostic log download request
               * @param {RequestConfig & { data: LogRequestUpdateModel, params: V3ReportDeviceDeviceIdLogRequestRequestIdPutParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
               */
              $put: (config) => null,
            }),
          },
        }),
      },
      'deviceconnections': {
        /**
         * GET /v3/report/deviceconnections
         * @deprecated
         * @summary getDeviceConnections
         * @param {RequestConfig & { params: V3ReportDeviceconnectionsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<UserConnectionsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'devices': {
        /**
         * GET /v3/report/devices
         * @deprecated
         * @summary Returns a list of all onboarded Devices
         * @param {RequestConfig & { params: V3ReportDevicesGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DevicesResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /** @param {!string} deviceSerialNumber deviceSerialNumber */
        $deviceSerialNumber: (deviceSerialNumber) => ({
          logs: {
            /**
             * GET /v3/report/devices/{deviceSerialNumber}/logs
             * @summary Get device log list
             * @param {RequestConfig & { params: V3ReportDevicesDeviceSerialNumberLogsGetParams, headers: V3ReportDevicesDeviceSerialNumberLogsGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeviceLogListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v3/report/devices/{deviceSerialNumber}/logs
             * @summary Post device log
             * @param {RequestConfig & { data: DeviceLogPost, headers: V3ReportDevicesDeviceSerialNumberLogsPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        }),
      },
      'diagnosticreports': {
        /**
         * GET /v3/report/diagnosticreports
         * @deprecated
         * @summary Returns diagnostic report for particular user
         * @param {RequestConfig & { params: V3ReportDiagnosticreportsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<DiagnosticReportsResponse>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'diagnostics': {
        /**
         * POST /v3/report/diagnostics
         * @summary Post multiple diagnostic reports for an endpoint, Only for legacy devices
         * @param {RequestConfig & { data: Array<Report>, params: V3ReportDiagnosticsPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'endpoint': {
        geolocation: {
          /**
           * POST /v3/report/endpoint/geolocation
           * @deprecated
           * @summary Post geolocation of an endpoint
           * @param {RequestConfig & { data: MapPoint, params: V3ReportEndpointGeolocationPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      'endpoints': {
        summary: {
          /**
           * GET /v3/report/endpoints/summary
           * @summary Return total active, online and offline endpoints(device and app) count
           * @param {RequestConfig & { params: V3ReportEndpointsSummaryGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantStats>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'event': {
        log: {
          /**
           * POST /v3/report/event/log
           * @summary Post an event log
           * @param {RequestConfig & { data: EventLogPost, params: V3ReportEventLogPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          count: {
            /**
             * GET /v3/report/event/log/count
             * @summary Get event log statistics
             * @param {RequestConfig & { params: V3ReportEventLogCountGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OnboardLogCountResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          list: {
            /**
             * GET /v3/report/event/log/list
             * @summary Get event logs
             * @param {RequestConfig & { params: V3ReportEventLogListGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<EventLogListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          user: {
            acl: {
              list: {
                /**
                 * GET /v3/report/event/log/user/acl/list
                 * @summary Get event logs from user acl endpoint list
                 * @param {RequestConfig & { params: V3ReportEventLogUserAclListGetParams }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<EventLogListResponse>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
              },
            },
          },
        },
      },
      'events': {
        /**
         * GET /v3/report/events
         * @deprecated
         * @summary Returns a list of Events for an Endpoint
         * @param {RequestConfig & { params: V3ReportEventsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EventsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'geolocation': {
        /**
         * GET /v3/report/geolocation
         * @deprecated
         * @summary getGeoLocationV3
         * @param {RequestConfig & { params: V3ReportGeolocationGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'health': {
        /**
         * GET /v3/report/health
         * @summary Health Check
         * @param {RequestConfig & { params: V3ReportHealthGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'job': {
        /**
         * GET /v3/report/job
         * @deprecated
         * @summary Get next pending job
         * @param {RequestConfig & { params: V3ReportJobGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Job>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v3/report/job
         * @deprecated
         * @summary Post a job
         * @param {RequestConfig & { data: Job, params: V3ReportJobPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PUT /v3/report/job
         * @deprecated
         * @summary Update a job
         * @param {RequestConfig & { data: Job, params: V3ReportJobPutParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $put: (config) => null,
        /**
         * DELETE /v3/report/job
         * @deprecated
         * @summary Delete a job
         * @param {RequestConfig & { params: V3ReportJobDeleteParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      'latestsensorreport': {
        /**
         * GET /v3/report/latestsensorreport
         * @summary getLatsetSensorReportV3
         * @param {RequestConfig & { params: V3ReportLatestsensorreportGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SensorReport>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'live': {
        devices: {
          /**
           * GET /v3/report/live/devices
           * @deprecated
           * @summary Returns list of devices
           * @param {RequestConfig & { params: V3ReportLiveDevicesGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<LiveDevices>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        users: {
          /**
           * GET /v3/report/live/users
           * @deprecated
           * @summary getLiveUsers
           * @param {RequestConfig & { params: V3ReportLiveUsersGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<LiveUsersResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'loglevel': {
        /**
         * GET /v3/report/loglevel
         * @summary get log level
         * @param {RequestConfig & { params: V3ReportLoglevelGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<LogLevelDTO>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v3/report/loglevel
         * @summary set log level
         * @param {RequestConfig & { params: V3ReportLoglevelPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'mapview': {
        endpoint: {
          list: {
            /**
             * GET /v3/report/mapview/endpoint/list
             * @summary Returns ednpoint list of a map view
             * @param {RequestConfig & { params: V3ReportMapviewEndpointListGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<EndpointOnMap>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      'onboard': {
        log: {
          /**
           * POST /v3/report/onboard/log
           * @summary Post an onboard log
           * @param {RequestConfig & { data: OnboardLog, params: V3ReportOnboardLogPostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          count: {
            /**
             * GET /v3/report/onboard/log/count
             * @summary Get onboard log statistics
             * @param {RequestConfig & { params: V3ReportOnboardLogCountGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OnboardLogCountResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          list: {
            /**
             * GET /v3/report/onboard/log/list
             * @summary Get onboard logs of devices
             * @param {RequestConfig & { params: V3ReportOnboardLogListGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OnboardLogListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      'play-back-click-to-screen': {
        /**
         * POST /v3/report/play-back-click-to-screen
         * @summary Post Event Playback Click to Screen Statistics
         * @param {RequestConfig & { data: ClickToScreenStats, headers: V3ReportPlayBackClickToScreenPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'pool': {
        /**
         * GET /v3/report/pool
         * @deprecated
         * @summary poolReport
         * @param {RequestConfig & { params: V3ReportPoolGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'presence': {
        history: {
          /**
           * GET /v3/report/presence/history
           * @deprecated
           * @summary List of all presence history of an user or endopint depending on parameter type
           * @param {RequestConfig & { params: V3ReportPresenceHistoryGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<PresenceResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'reportlog': {
        /**
         * POST /v3/report/reportlog
         * @deprecated
         * @summary postReportLog
         * @param {RequestConfig & { params: V3ReportReportlogPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'reports': {
        /**
         * POST /v3/report/reports
         * @summary Post a diagnostic report for an endpoint
         * @param {RequestConfig & { data: Report, params: V3ReportReportsPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'sensorreport': {
        /**
         * POST /v3/report/sensorreport
         * @summary Post list of sensor data for an endpoint
         * @param {RequestConfig & { data: Array<SensorReport>, params: V3ReportSensorreportPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV2>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'sensorreports': {
        /**
         * GET /v3/report/sensorreports
         * @summary Returns a list of sensor information for an Endpoint
         * @param {RequestConfig & { params: V3ReportSensorreportsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SensorReportsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        tmp: {
          /**
           * GET /v3/report/sensorreports/tmp
           * @deprecated
           * @summary Returns a list of sensor information for an Endpoint
           * @param {RequestConfig & { params: V3ReportSensorreportsTmpGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<SensorReportsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'servicestatus': {
        /**
         * GET /v3/report/servicestatus
         * @summary get service status
         * @param {RequestConfig & { params: V3ReportServicestatusGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'statistics': {
        /**
         * GET /v3/report/statistics
         * @deprecated
         * @summary getStatistics
         * @param {RequestConfig & { params: V3ReportStatisticsGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'statistics-range': {
        /**
         * GET /v3/report/statistics-range
         * @deprecated
         * @summary getStatisticsByRange
         * @param {RequestConfig & { params: V3ReportStatisticsRangeGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'stream': {
        details: {
          /**
           * GET /v3/report/stream/details
           * @summary getStreamDetails
           * @param {RequestConfig & { params: V3ReportStreamDetailsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionDetails>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        events: {
          /**
           * GET /v3/report/stream/events
           * @summary Returns List of ConnectionEvents with a continuation token
           * @param {RequestConfig & { params: V3ReportStreamEventsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionEventList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'streamreport': {
        /**
         * GET /v3/report/streamreport
         * @deprecated
         * @summary getStreamReport
         * @param {RequestConfig & { params: V3ReportStreamreportGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<StreamReportDTO>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v3/report/streamreport
         * @summary Post a stream report for an endpoint
         * @param {RequestConfig & { data: Array<StreamQualityProfile>, params: V3ReportStreamreportPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'streamstat': {
        /**
         * GET /v3/report/streamstat
         * @summary getStreamStat
         * @param {RequestConfig & { params: V3ReportStreamstatGetParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<StreamStat>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        list: {
          /**
           * GET /v3/report/streamstat/list
           * @summary getStreamStatList
           * @param {RequestConfig & { params: V3ReportStreamstatListGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<StreamList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'tenant': {
        connections: {
          /**
           * GET /v3/report/tenant/connections
           * @deprecated
           * @summary List of all connections of a Tenant
           * @param {RequestConfig & { params: V3ReportTenantConnectionsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          search: {
            /**
             * GET /v3/report/tenant/connections/search
             * @deprecated
             * @summary Search connections of a Tenant
             * @param {RequestConfig & { params: V3ReportTenantConnectionsSearchGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<ConnectionListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        events: {
          /**
           * GET /v3/report/tenant/events
           * @deprecated
           * @summary Returns a list of Events for a Tenant
           * @param {RequestConfig & { params: V3ReportTenantEventsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantEvents>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        streams: {
          /**
           * GET /v3/report/tenant/streams
           * @summary List of all connections of a Tenant
           * @param {RequestConfig & { params: V3ReportTenantStreamsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'user': {
        connections: {
          /**
           * GET /v3/report/user/connections
           * @deprecated
           * @summary getUserConnections
           * @param {RequestConfig & { params: V3ReportUserConnectionsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          search: {
            /**
             * GET /v3/report/user/connections/search
             * @deprecated
             * @summary Search connections of a user
             * @param {RequestConfig & { params: V3ReportUserConnectionsSearchGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<ConnectionListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        diagnostics: {
          /**
           * GET /v3/report/user/diagnostics
           * @summary Returns diagnostic report for particular user
           * @param {RequestConfig & { params: V3ReportUserDiagnosticsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DiagnosticsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          filter: {
            /**
             * GET /v3/report/user/diagnostics/filter
             * @summary Returns diagnostic report of a particular user
             * @param {RequestConfig & { params: V3ReportUserDiagnosticsFilterGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DiagnosticListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
          search: {
            /**
             * GET /v3/report/user/diagnostics/search
             * @summary Searches diagnostic report of a particular device
             * @param {RequestConfig & { params: V3ReportUserDiagnosticsSearchGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DiagnosticListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        events: {
          /**
           * GET /v3/report/user/events
           * @deprecated
           * @summary Returns a list of Events for a User
           * @param {RequestConfig & { params: V3ReportUserEventsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<UserEvents>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        presence: {
          /**
           * GET /v3/report/user/presence
           * @summary User's presence status ( OnlineAt or OfflineAt time )
           * @param {RequestConfig & { params: V3ReportUserPresenceGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<PresenceHistoryDTO>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          history: {
            /**
             * GET /v3/report/user/presence/history
             * @summary List of all presence of a user
             * @param {RequestConfig & { params: V3ReportUserPresenceHistoryGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DevicePresenceHistoryResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        streams: {
          /**
           * GET /v3/report/user/streams
           * @summary getUserStreams
           * @param {RequestConfig & { params: V3ReportUserStreamsGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ConnectionsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
    },
    repository: {
      aicontainer: {
        aimodel: {
          /**
           * GET /v3/repository/aicontainer/aimodel
           * @summary Returns List of deployed AIModel for a AI Container
           * @param {RequestConfig & { params: V3RepositoryAicontainerAimodelGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModelV0ListWithoutPagination>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        deployed: {
          endpoint: {
            /**
             * GET /v3/repository/aicontainer/deployed/endpoint
             * @summary Returns a list of deployed aicontainers for an endpoint
             * @param {RequestConfig & { params: V3RepositoryAicontainerDeployedEndpointGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<AiContainerCameraListDto>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      firmware: {
        deployed: {
          group: {
            /**
             * GET /v3/repository/firmware/deployed/group
             * @summary Returns a list of deployed firmware for a group
             * @param {RequestConfig & { params: V3RepositoryFirmwareDeployedGroupGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareListWithLastUpdatedTime>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      trigger: {
        composite: {
          deployed: {
            endpoint: {
              /**
               * GET /v3/repository/trigger/composite/deployed/endpoint
               * @summary Returns a list of deployed composite triggers for an endpoint
               * @param {RequestConfig & { params: V3RepositoryTriggerCompositeDeployedEndpointGetParams }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeEventTriggerList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
        },
      },
    },
  },
  'v5': {
    'aiaccelerator': {
      /**
       * GET /v5/aiaccelerator
       * @summary Get Ai accelerator list
       * @param {RequestConfig & { headers: V5AiacceleratorGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<AiAcceleratorListResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      count: {
        /**
         * GET /v5/aiaccelerator/count
         * @summary Get ai accelerator count
         * @param {RequestConfig & { headers: V5AiacceleratorCountGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      /** @param {!string} code AI Accelerator Code */
      $code: (code) => ({
        /**
         * GET /v5/aiaccelerator/{code}
         * @summary Get Ai accelerator
         * @param {RequestConfig & { headers: V5AiacceleratorCodeGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AiAcceleratorEntity>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      }),
    },
    'aicontainer': {
      /**
       * GET /v5/aicontainer
       * @summary Get Ai Container list
       * @param {RequestConfig & { params: V5AicontainerGetParams, headers: V5AicontainerGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<AiContainerListResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      count: {
        /**
         * GET /v5/aicontainer/count
         * @summary Get ai container count
         * @param {RequestConfig & { params: V5AicontainerCountGetParams, headers: V5AicontainerCountGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      endpoint: {
        /** @param {!number} endpointId Endpoint Id */
        $endpointId: (endpointId) => ({
          /**
           * GET /v5/aicontainer/endpoint/{endpointId}
           * @summary Get effective AI Container(s) for endpoint level
           * @param {RequestConfig & { headers: V5AicontainerEndpointEndpointIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AiContainerDeployedListDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      filter: {
        /**
         * GET /v5/aicontainer/filter
         * @summary Get ai container list with filter
         * @param {RequestConfig & { params: V5AicontainerFilterGetParams, headers: V5AicontainerFilterGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AiContainersDto>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        count: {
          /**
           * GET /v5/aicontainer/filter/count
           * @summary Get ai container count with filter
           * @param {RequestConfig & { params: V5AicontainerFilterCountGetParams, headers: V5AicontainerFilterCountGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      group: {
        /** @param {!number} groupId Group Id */
        $groupId: (groupId) => ({
          /**
           * GET /v5/aicontainer/group/{groupId}
           * @summary Get effective AI Container(s) for group level
           * @param {RequestConfig & { headers: V5AicontainerGroupGroupIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AiContainerDeployedListDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      scheme: {
        /** @param {!number} schemeId Scheme Id */
        $schemeId: (schemeId) => ({
          /**
           * GET /v5/aicontainer/scheme/{schemeId}
           * @summary Get list of AI Container configured in Scheme
           * @param {RequestConfig & { headers: V5AicontainerSchemeSchemeIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AiContainersDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      tenant: {
        /** @param {!string} tenantId Tenant Id */
        $tenantId: (tenantId) => ({
          /**
           * GET /v5/aicontainer/tenant/{tenantId}
           * @summary Get effective AI Container(s) for tenant level
           * @param {RequestConfig & { headers: V5AicontainerTenantTenantIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AiContainerDeployedListDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      /** @param {!string} aiContainerId Ai Container Id */
      $aiContainerId: (aiContainerId) => ({
        scheme: {
          /** @param {!number} schemeId Scheme Id */
          $schemeId: (schemeId) => ({
            /**
             * POST /v5/aicontainer/{aiContainerId}/scheme/{schemeId}
             * @summary Assign Ai Container to a Scheme
             * @param {RequestConfig & { headers: V5AicontainerAiContainerIdSchemeSchemeIdPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
            /**
             * DELETE /v5/aicontainer/{aiContainerId}/scheme/{schemeId}
             * @summary Remove Ai Container from a Scheme
             * @param {RequestConfig & { headers: V5AicontainerAiContainerIdSchemeSchemeIdDeleteHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          }),
        },
      }),
    },
    'aimodel': {
      /**
       * GET /v5/aimodel
       * @summary Get list of all AI Models accessible by a Tenant
       * @param {RequestConfig & { params: V5AimodelGetParams, headers: V5AimodelGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<Array<object>>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      container: {
        /** @param {!string} aiContainerId AI Container ID */
        $aiContainerId: (aiContainerId) => ({
          /**
           * GET /v5/aimodel/container/{aiContainerId}
           * @summary Get AI Models present in Container
           * @param {RequestConfig & { headers: V5AimodelContainerAiContainerIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AIModelWithInputMappingListDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      count: {
        /**
         * GET /v5/aimodel/count
         * @summary Get ai model count
         * @param {RequestConfig & { params: V5AimodelCountGetParams, headers: V5AimodelCountGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      /** @param {!string} aiModelId AI Model ID */
      $aiModelId: (aiModelId) => ({
        /**
         * GET /v5/aimodel/{aiModelId}
         * @summary Get AI Model for specific id
         * @param {RequestConfig & { headers: V5AimodelAiModelIdGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AIModelDto>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      }),
    },
    'annotation': {
      /**
       * GET /v5/annotation
       * @summary getAnnotations
       * @param {RequestConfig & { params: V5AnnotationGetParams, headers: V5AnnotationGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<AnnotationListWithContinuationToken>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/annotation
       * @summary createAnnotation
       * @param {RequestConfig & { data: AnnotationAddRequest, headers: V5AnnotationPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<AnnotationAddResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      count: {
        /**
         * GET /v5/annotation/count
         * @summary getAnnotationCount
         * @param {RequestConfig & { params: V5AnnotationCountGetParams, headers: V5AnnotationCountGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AnnotationCountResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      /** @param {!string} annotationId annotationId */
      $annotationId: (annotationId) => ({
        /**
         * PATCH /v5/annotation/{annotationId}
         * @summary updateAnnotation
         * @param {RequestConfig & { data: AnnotationUpdateRequest, headers: V5AnnotationAnnotationIdPatchHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $patch: (config) => null,
      }),
    },
    'annotation-category': {
      /**
       * GET /v5/annotation-category
       * @summary getAnnotationCategories
       * @param {RequestConfig & { params: V5AnnotationCategoryGetParams, headers: V5AnnotationCategoryGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<AnnotationCategoryGetListResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/annotation-category
       * @summary createAnnotationCategory
       * @param {RequestConfig & { data: AnnotationCategoryAddRequest, headers: V5AnnotationCategoryPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<AnnotationCategoryAddResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      /** @param {!string} id id */
      $id: (id) => ({
        /**
         * DELETE /v5/annotation-category/{id}
         * @summary deleteAnnotationCategory
         * @param {RequestConfig & { headers: V5AnnotationCategoryIdDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        /**
         * PATCH /v5/annotation-category/{id}
         * @summary updateAnnotationCategory
         * @param {RequestConfig & { data: AnnotationCategoryUpdateRequest, headers: V5AnnotationCategoryIdPatchHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $patch: (config) => null,
      }),
    },
    'auth': {
      'account': {
        /**
         * POST /v5/auth/account
         * @summary Will create account and user in tenant with specified role, will return list of user of given email on success
         * @param {RequestConfig & { data: CreateAccountRequest, headers: V5AuthAccountPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CreateAccountResponse>} An ApiRequest; to get result call 'process'.
         */
        '$post': (config) => null,
        'password-reset-request': {
          /**
           * POST /v5/auth/account/password-reset-request
           * @summary On success will send a password reset code in email
           * @param {RequestConfig & { data: PasswordResetEmailRequest }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EmptyModel>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        'reset-password': {
          /**
           * POST /v5/auth/account/reset-password
           * @summary Will return success or fail. on success it will send a email with password reset instructions
           * @param {RequestConfig & { data: ResetPasswordRequest }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EmptyModel>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        'update-password': {
          /**
           * POST /v5/auth/account/update-password
           * @summary Will change password on success
           * @param {RequestConfig & { data: ChangePasswordRequest, headers: V5AuthAccountUpdatePasswordPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EmptyModel>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        'verify-password-reset-code': {
          /**
           * POST /v5/auth/account/verify-password-reset-code
           * @summary On success will send a password reset code in email
           * @param {RequestConfig & { data: VerifyPasswordResetCodeRequest }} config Axios Request config
           * @returns {import('@/api').ApiRequest<VerifyPasswordResetCodeResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        /** @param {!string} id id */
        '$id': (id) => ({
          /**
           * PATCH /v5/auth/account/{id}
           * @summary Update Account Information
           * @param {RequestConfig & { data: UpdateAccountRequest, headers: V5AuthAccountIdPatchHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<UpdateAccountResponse>} An ApiRequest; to get result call 'process'.
           */
          $patch: (config) => null,
        }),
      },
      'device': {
        credentials: {
          /**
           * GET /v5/auth/device/credentials
           * @summary Return array of credentials base 64 encoded json object encrypted with rsa with public key provided in query
           * @param {RequestConfig & { params: V5AuthDeviceCredentialsGetParams, headers: V5AuthDeviceCredentialsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeviceCredentialList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'login': {
        /**
         * POST /v5/auth/login
         * @summary Will return a refresh token for newly created session with a list of user from various tenants
         * @param {RequestConfig & { data: AccountLoginRequest }} config Axios Request config
         * @returns {import('@/api').ApiRequest<AccountLoginResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'sign-in': {
        /**
         * POST /v5/auth/sign-in
         * @summary Sign-in to AnyConnect system.
         * @param {RequestConfig & { params: V5AuthSignInPostParams }} config Axios Request config
         * @returns {import('@/api').ApiRequest<LoginUserResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'tenant-access': {
        /**
         * POST /v5/auth/tenant-access
         * @summary Will return access token and refresh token for the session and will renew session automatically
         * @param {RequestConfig & { data: GetAccessTokenRequest }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetAccessTokenResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        batch: {
          /**
           * POST /v5/auth/tenant-access/batch
           * @summary Will return bulk access token and refresh token for the session and will renew session automatically
           * @param {RequestConfig & { data: Array<GetAccessTokenRequest> }} config Axios Request config
           * @returns {import('@/api').ApiRequest<BatchAccessTokenResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      'tenant-selection': {
        /**
         * GET /v5/auth/tenant-selection
         * @summary Will return a list of tenant with user role from email in token
         * @param {RequestConfig & { headers: V5AuthTenantSelectionGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<TenantIdWithName>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'token': {
        tenant: {
          /**
           * POST /v5/auth/token/tenant
           * @summary Get access token based on tenant info
           * @param {RequestConfig & { params: V5AuthTokenTenantPostParams, headers: V5AuthTokenTenantPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AccessTokenResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      'user': {
        logout: {
          /**
           * POST /v5/auth/user/logout
           * @summary Will end the user session, refresh token with this session wont be able to generate new access token
           * @param {RequestConfig & { data: EndSessionRequest }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EmptyModel>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
    },
    'billing': {
      seconds: {
        /**
         * GET /v5/billing/seconds
         * @summary Return seconds to consume
         * @param {RequestConfig & { params: V5BillingSecondsGetParams, headers: V5BillingSecondsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SecondsResponseDTO>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      stat: {
        /**
         * GET /v5/billing/stat
         * @summary Return seconds consumption statistics for a tenant
         * @param {RequestConfig & { params: V5BillingStatGetParams, headers: V5BillingStatGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EndpointSecConsumptionStatDTO>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      tenant: {
        /** @param {!string} tenantId Tenant ID */
        $tenantId: (tenantId) => ({
          'seconds-pool': {
            /**
             * PUT /v5/billing/tenant/{tenantId}/seconds-pool
             * @summary Create a new seconds pool for a tenant
             * @param {RequestConfig & { data: BillingSecondsPoolCreateRequest, headers: V5BillingTenantTenantIdSecondsPoolPutHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
             */
            $put: (config) => null,
            /** @param {!string} poolId Pool ID */
            $poolId: (poolId) => ({
              /**
               * DELETE /v5/billing/tenant/{tenantId}/seconds-pool/{poolId}
               * @summary Delete requested seconds pool
               * @param {RequestConfig & { headers: V5BillingTenantTenantIdSecondsPoolPoolIdDeleteHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<any>} An ApiRequest; to get result call 'process'.
               */
              $delete: (config) => null,
            }),
          },
          'seconds-pool-list': {
            /**
             * GET /v5/billing/tenant/{tenantId}/seconds-pool-list
             * @summary Return whole list of active second pools assigned to a tenant
             * @param {RequestConfig & { headers: V5BillingTenantTenantIdSecondsPoolListGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<BillingSecondsPool>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      },
    },
    'can-bus-data': {
      endpoint: {
        /** @param {!number} endpoint Endpoint Id */
        $endpoint: (endpoint) => ({
          label: {
            /** @param {!string} label Label */
            $label: (label) => ({
              /**
               * GET /v5/can-bus-data/endpoint/{endpoint}/label/{label}
               * @summary Get Can Bus Data Download Urls List by Endpoint and Time Range
               * @param {RequestConfig & { params: V5CanBusDataEndpointEndpointLabelLabelGetParams, headers: V5CanBusDataEndpointEndpointLabelLabelGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CanBusDataResponse>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            }),
          },
        }),
      },
      trip: {
        /** @param {!string} trip Trip Id */
        $trip: (trip) => ({
          label: {
            /** @param {!string} label Label */
            $label: (label) => ({
              /**
               * GET /v5/can-bus-data/trip/{trip}/label/{label}
               * @summary Get Can Bus Data Download Urls List by Trip
               * @param {RequestConfig & { headers: V5CanBusDataTripTripLabelLabelGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CanBusDataResponse>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            }),
          },
        }),
      },
    },
    'coaching': {
      sessions: {
        /**
         * GET /v5/coaching/sessions
         * @summary Get List of Coaching Session
         * @param {RequestConfig & { params: V5CoachingSessionsGetParams, headers: V5CoachingSessionsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CoachingSessionListDto>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /** @param {!'ENDPOINT'|'DRIVER'} coachingByTarget Coaching Session Id */
        $coachingByTarget: (coachingByTarget) => ({
          /** @param {!string} targetId Target Id */
          $targetId: (targetId) => ({
            /**
             * POST /v5/coaching/sessions/{coachingByTarget}/{targetId}
             * @summary Initiate the Coaching Session for Target by giving Target Id
             * @param {RequestConfig & { params: V5CoachingSessionsCoachingByTargetTargetIdPostParams, headers: V5CoachingSessionsCoachingByTargetTargetIdPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CoachingSessionDto>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          }),
        }),
        /** @param {!number} sessionId Coaching Session Id */
        $sessionId: (sessionId) => ({
          /**
           * GET /v5/coaching/sessions/{sessionId}
           * @summary Get Coaching Session by ID
           * @param {RequestConfig & { headers: V5CoachingSessionsSessionIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CoachingSessionDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * PATCH /v5/coaching/sessions/{sessionId}
           * @summary Patch the Coaching Session by Coaching Session Id
           * @param {RequestConfig & { params: V5CoachingSessionsSessionIdPatchParams, headers: V5CoachingSessionsSessionIdPatchHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
           */
          $patch: (config) => null,
          events: {
            /** @param {!number} eventId Event Id */
            $eventId: (eventId) => ({
              notes: {
                /**
                 * GET /v5/coaching/sessions/{sessionId}/events/{eventId}/notes
                 * @summary Get list of Coaching Notes for Session and Event
                 * @param {RequestConfig & { headers: V5CoachingSessionsSessionIdEventsEventIdNotesGetHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<CoachingNotesDtoList>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
                /**
                 * POST /v5/coaching/sessions/{sessionId}/events/{eventId}/notes
                 * @summary Add the notes on coachable event
                 * @param {RequestConfig & { data: string, params: V5CoachingSessionsSessionIdEventsEventIdNotesPostParams, headers: V5CoachingSessionsSessionIdEventsEventIdNotesPostHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
                 */
                $post: (config) => null,
                /** @param {!string} noteId Notes Id */
                $noteId: (noteId) => ({
                  /**
                   * PUT /v5/coaching/sessions/{sessionId}/events/{eventId}/notes/{noteId}
                   * @summary Update the notes on coachable event
                   * @param {RequestConfig & { data: string, headers: V5CoachingSessionsSessionIdEventsEventIdNotesNoteIdPutHeaders }} config Axios Request config
                   * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
                   */
                  $put: (config) => null,
                  /**
                   * DELETE /v5/coaching/sessions/{sessionId}/events/{eventId}/notes/{noteId}
                   * @summary Delete the notes on coachable event
                   * @param {RequestConfig & { headers: V5CoachingSessionsSessionIdEventsEventIdNotesNoteIdDeleteHeaders }} config Axios Request config
                   * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
                   */
                  $delete: (config) => null,
                }),
              },
            }),
          },
        }),
      },
    },
    'dashboard': {
      auth: {
        'refresh-token': {
          /**
           * GET /v5/dashboard/auth/refresh-token
           * @summary Refresh token with tenant id.
           * @param {RequestConfig & { params: V5DashboardAuthRefreshTokenGetParams, headers: V5DashboardAuthRefreshTokenGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<RefreshTokenResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'sign-in': {
          /**
           * POST /v5/dashboard/auth/sign-in
           * @summary Sign-in to dashboard.
           * @param {RequestConfig & { data: DashboardLoginRequest }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DashboardLoginUserResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        'user': {
          list: {
            /**
             * GET /v5/dashboard/auth/user/list
             * @summary Returns a list of users of specific tenant ID.
             * @param {RequestConfig & { params: V5DashboardAuthUserListGetParams, headers: V5DashboardAuthUserListGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<UserInfoDto>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
    },
    'dbc': {
      '.': {
        /**
         * GET /v5/dbc/
         * @summary Return a list of DBC files matching search criterion
         * @param {RequestConfig & { params: V5DbcGetParams, headers: V5DbcGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DbcFileListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v5/dbc/
         * @summary Upload a dbc file.
         * @param {RequestConfig & { data: DbcCreateRequest, params: V5DbcPostParams, headers: V5DbcPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DdbCreateResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      /** @param {!string} id id */
      '$id': (id) => ({
        /**
         * DELETE /v5/dbc/{id}
         * @summary Delete the DBC file by Id
         * @param {RequestConfig & { headers: V5DbcIdDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        /**
         * PATCH /v5/dbc/{id}
         * @summary Associate a new DBC file with existing DBC entry
         * @param {RequestConfig & { params: V5DbcIdPatchParams, headers: V5DbcIdPatchHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
         */
        $patch: (config) => null,
      }),
    },
    'device': {
      /**
       * POST /v5/device
       * @summary addDevices
       * @param {RequestConfig & { data: DeviceAddRequest, headers: V5DevicePostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<DeviceAddResponse>} An ApiRequest; to get result call 'process'.
       */
      '$post': (config) => null,
      'endpoint': {
        /** @param {!number} endpointId endpointId */
        $endpointId: (endpointId) => ({
          /**
           * GET /v5/device/endpoint/{endpointId}
           * @summary getDeviceByEndpointId
           * @param {RequestConfig & { headers: V5DeviceEndpointEndpointIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeviceDetailsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      'lease-token': {
        /**
         * GET /v5/device/lease-token
         * @summary Lease a token for device onboarding
         * @param {RequestConfig & { headers: V5DeviceLeaseTokenGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<LeaseTokenResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'non-onboarded-device': {
        /**
         * GET /v5/device/non-onboarded-device
         * @summary Returns the list of non onboarded device
         * @param {RequestConfig & { headers: V5DeviceNonOnboardedDeviceGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<NonOnboardedDeviceList>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'parking-mode-status': {
        /**
         * POST /v5/device/parking-mode-status
         * @summary Save parking mode status
         * @param {RequestConfig & { data: ParkingModeStatusPostRequest, headers: V5DeviceParkingModeStatusPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'product': {
        /**
         * GET /v5/device/product
         * @summary Returns the list of devices with product details
         * @param {RequestConfig & { params: V5DeviceProductGetParams, headers: V5DeviceProductGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeviceProductListDto>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'properties': {
        /**
         * GET /v5/device/properties
         * @summary Returns a list of properties with device serial number
         * @param {RequestConfig & { params: V5DevicePropertiesGetParams, headers: V5DevicePropertiesGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeviceCapabilities>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v5/device/properties
         * @summary Save device properties values
         * @param {RequestConfig & { data: DeviceCapabilities, headers: V5DevicePropertiesPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'records': {
        'device-uptime': {
          search: {
            /**
             * GET /v5/device/records/device-uptime/search
             * @summary Search DeviceUptime data
             * @param {RequestConfig & { params: V5DeviceRecordsDeviceUptimeSearchGetParams, headers: V5DeviceRecordsDeviceUptimeSearchGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeviceRecordResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        'network-info': {
          search: {
            /**
             * GET /v5/device/records/network-info/search
             * @summary Search NetworkInfo data
             * @param {RequestConfig & { params: V5DeviceRecordsNetworkInfoSearchGetParams, headers: V5DeviceRecordsNetworkInfoSearchGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeviceRecordResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        'transition-stat': {
          search: {
            /**
             * GET /v5/device/records/transition-stat/search
             * @summary Search DeviceUptime data
             * @param {RequestConfig & { params: V5DeviceRecordsTransitionStatSearchGetParams, headers: V5DeviceRecordsTransitionStatSearchGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeviceRecordResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
      'search': {
        /**
         * GET /v5/device/search
         * @summary searchDevice
         * @param {RequestConfig & { params: V5DeviceSearchGetParams, headers: V5DeviceSearchGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeviceSearchResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'search-advanced': {
        /**
         * GET /v5/device/search-advanced
         * @summary advancedDeviceSearch
         * @param {RequestConfig & { params: V5DeviceSearchAdvancedGetParams, headers: V5DeviceSearchAdvancedGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeviceSearchResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'serial-request': {
        /**
         * POST /v5/device/serial-request
         * @summary createDeviceSerial
         * @param {RequestConfig & { data: DeviceSerialCreateRequest, headers: V5DeviceSerialRequestPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeviceSerialCreateResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'transfer': {
        /**
         * POST /v5/device/transfer
         * @summary Move listed devices to a target toPool in a toTenant ( optional, may be omitted ). If toPool is empty, the default pool for a toTenant will be used
         * @param {RequestConfig & { data: DeviceTransferRequest, headers: V5DeviceTransferPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeviceTransferResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      /** @param {!string} deviceId deviceId */
      '$deviceId': (deviceId) => ({
        'convert-token': {
          /**
           * GET /v5/device/{deviceId}/convert-token
           * @summary Convert a legacy access token to a v5 access token
           * @param {RequestConfig & { params: V5DeviceDeviceIdConvertTokenGetParams, headers: V5DeviceDeviceIdConvertTokenGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OnboardResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'device-pool': {
          /**
           * GET /v5/device/{deviceId}/device-pool
           * @summary Returns the device pool by device serial number
           * @param {RequestConfig & { params: V5DeviceDeviceIdDevicePoolGetParams, headers: V5DeviceDeviceIdDevicePoolGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DevicePoolInfoForTenant>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'onboard': {
          /**
           * POST /v5/device/{deviceId}/onboard
           * @summary Zero touch onboarding of a device.
           * @param {RequestConfig & { data: OnboardRequest }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OnboardResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        'onboard-with-token': {
          /**
           * POST /v5/device/{deviceId}/onboard-with-token
           * @summary Onboard endpoint with a token.
           * @param {RequestConfig & { params: V5DeviceDeviceIdOnboardWithTokenPostParams, headers: V5DeviceDeviceIdOnboardWithTokenPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OnboardResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        'renew-token': {
          /**
           * GET /v5/device/{deviceId}/renew-token
           * @summary Renew a access token for device
           * @param {RequestConfig & { headers: V5DeviceDeviceIdRenewTokenGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OnboardResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'reset': {
          /**
           * POST /v5/device/{deviceId}/reset
           * @summary resetDevice
           * @param {RequestConfig & { headers: V5DeviceDeviceIdResetPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        'vin': {
          /**
           * POST /v5/device/{deviceId}/vin
           * @summary updateVin
           * @param {RequestConfig & { data: DeviceVinUpdateRequest, headers: V5DeviceDeviceIdVinPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<VinUpdateResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      }),
    },
    'device-batch': {
      /**
       * GET /v5/device-batch
       * @summary getDeviceBatchList
       * @param {RequestConfig & { params: V5DeviceBatchGetParams, headers: V5DeviceBatchGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<Array<DeviceBatchResponse>>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/device-batch
       * @summary postDeviceBatch
       * @param {RequestConfig & { data: DeviceBatchRequest, headers: V5DeviceBatchPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<DeviceBatchResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      /** @param {!string} id id */
      $id: (id) => ({
        /**
         * GET /v5/device-batch/{id}
         * @summary getDeviceBatch
         * @param {RequestConfig & { headers: V5DeviceBatchIdGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeviceBatchResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * PATCH /v5/device-batch/{id}
         * @summary patchDeviceBatch
         * @param {RequestConfig & { data: DeviceBatchRequest, headers: V5DeviceBatchIdPatchHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DeviceBatchResponse>} An ApiRequest; to get result call 'process'.
         */
        $patch: (config) => null,
      }),
    },
    'device-pool': {
      list: {
        /**
         * GET /v5/device-pool/list
         * @summary Returns a list of Device pool of specific tenant ID.
         * @param {RequestConfig & { params: V5DevicePoolListGetParams, headers: V5DevicePoolListGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<DevicePoolInfoForTenant>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      /** @param {!string} poolId poolId */
      $poolId: (poolId) => ({
        /**
         * GET /v5/device-pool/{poolId}
         * @summary Returns a device pool details
         * @param {RequestConfig & { headers: V5DevicePoolPoolIdGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DevicePoolInfoForTenant>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * DELETE /v5/device-pool/{poolId}
         * @summary deleteDevicesPool
         * @param {RequestConfig & { headers: V5DevicePoolPoolIdDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        /**
         * PATCH /v5/device-pool/{poolId}
         * @summary updateDevicesPool
         * @param {RequestConfig & { data: DevicePoolUpdateRequest, headers: V5DevicePoolPoolIdPatchHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $patch: (config) => null,
        device: {
          /**
           * GET /v5/device-pool/{poolId}/device
           * @summary getDevicesInPool
           * @param {RequestConfig & { params: V5DevicePoolPoolIdDeviceGetParams, headers: V5DevicePoolPoolIdDeviceGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeviceListResponseV2>} An ApiRequest; to get result call 'process'.
           */
          '$get': (config) => null,
          'bulk-remove': {
            /**
             * POST /v5/device-pool/{poolId}/device/bulk-remove
             * @summary deleteDevicesFromDevicePool
             * @param {RequestConfig & { data: DeviceBulkDeleteRequest, headers: V5DevicePoolPoolIdDeviceBulkRemovePostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
      }),
    },
    'driver': {
      /**
       * GET /v5/driver
       * @summary Get all driver list of a tenant
       * @param {RequestConfig & { params: V5DriverGetParams, headers: V5DriverGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<DriverListResponse>} An ApiRequest; to get result call 'process'.
       */
      '$get': (config) => null,
      /**
       * POST /v5/driver
       * @summary addDriver
       * @param {RequestConfig & { data: DriverAddRequest, headers: V5DriverPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SuccessResponseDriverAddResponse>} An ApiRequest; to get result call 'process'.
       */
      '$post': (config) => null,
      'file-credential': {
        /**
         * GET /v5/driver/file-credential
         * @summary Get file upload credential
         * @param {RequestConfig & { params: V5DriverFileCredentialGetParams, headers: V5DriverFileCredentialGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ListResponseFileUploadCredential>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'list': {
        /**
         * GET /v5/driver/list
         * @summary Get all driver list of a tenant
         * @param {RequestConfig & { params: V5DriverListGetParams, headers: V5DriverListGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DriverListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'listByIds': {
        /**
         * GET /v5/driver/listByIds
         * @summary Get all driver list by Driver Ids
         * @param {RequestConfig & { params: V5DriverListByIdsGetParams, headers: V5DriverListByIdsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DriverListResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'rank-list': {
        /**
         * GET /v5/driver/rank-list
         * @summary Get driver rank list
         * @param {RequestConfig & { params: V5DriverRankListGetParams, headers: V5DriverRankListGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ListResponseDriver>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      /** @param {!number} driverId driverId */
      '$driverId': (driverId) => ({
        /**
         * GET /v5/driver/{driverId}
         * @summary Get driver by id
         * @param {RequestConfig & { headers: V5DriverDriverIdGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SuccessResponseDriver>} An ApiRequest; to get result call 'process'.
         */
        '$get': (config) => null,
        /**
         * DELETE /v5/driver/{driverId}
         * @summary update driver
         * @param {RequestConfig & { headers: V5DriverDriverIdDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; to get result call 'process'.
         */
        '$delete': (config) => null,
        /**
         * PATCH /v5/driver/{driverId}
         * @summary update driver
         * @param {RequestConfig & { data: DriverUpdateRequest, headers: V5DriverDriverIdPatchHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; to get result call 'process'.
         */
        '$patch': (config) => null,
        'monthly-score': {
          /**
           * GET /v5/driver/{driverId}/monthly-score
           * @summary Get driver score
           * @param {RequestConfig & { params: V5DriverDriverIdMonthlyScoreGetParams, headers: V5DriverDriverIdMonthlyScoreGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<SuccessResponseDriver>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'score': {
          /**
           * GET /v5/driver/{driverId}/score
           * @summary Get driver score list
           * @param {RequestConfig & { params: V5DriverDriverIdScoreGetParams, headers: V5DriverDriverIdScoreGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DriverTripScoreList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      }),
    },
    'driver-tag': {
      /**
       * GET /v5/driver-tag
       * @summary getDriverTags
       * @param {RequestConfig & { params: V5DriverTagGetParams, headers: V5DriverTagGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<DriverTagGetListResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/driver-tag
       * @summary createDriverTag
       * @param {RequestConfig & { data: DriverTagAddRequest, headers: V5DriverTagPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<DriverTagAddResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      /** @param {!string} id id */
      $id: (id) => ({
        /**
         * DELETE /v5/driver-tag/{id}
         * @summary deleteDriverTag
         * @param {RequestConfig & { headers: V5DriverTagIdDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        /**
         * PATCH /v5/driver-tag/{id}
         * @summary updateDriverTag
         * @param {RequestConfig & { data: DriverTagUpdateRequest, headers: V5DriverTagIdPatchHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $patch: (config) => null,
      }),
    },
    'endpoint': {
      'acl': {
        associatedendpoints: {
          /**
           * GET /v5/endpoint/acl/associatedendpoints
           * @summary Returns the list of associated endpoints for the calling user ID.
           * @param {RequestConfig & { params: V5EndpointAclAssociatedendpointsGetParams, headers: V5EndpointAclAssociatedendpointsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<AssociatedEndpointsV2>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'aggregated': {
        /**
         * GET /v5/endpoint/aggregated
         * @summary Returns endpoint details aggregated for multiple endpoints.
         * @param {RequestConfig & { params: V5EndpointAggregatedGetParams, headers: V5EndpointAggregatedGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<EndpointDetailsResponse>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'apn': {
        $apnId: (apnId) => ({
          /**
           * GET /v5/endpoint/apn/{apnId}
           * @summary Get apn by ID
           * @param {RequestConfig & { headers: V5EndpointApnApnIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<APNInfo>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      'device': {
        /** @param {!string} serialNumber serialNumber */
        $serialNumber: (serialNumber) => ({
          dbc: {
            /**
             * GET /v5/endpoint/device/{serialNumber}/dbc
             * @summary Get a DBC file associated with the device
             * @param {RequestConfig & { headers: V5EndpointDeviceSerialNumberDbcGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DbcFileResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * PUT /v5/endpoint/device/{serialNumber}/dbc
             * @summary Link a device with a DBC file
             * @param {RequestConfig & { params: V5EndpointDeviceSerialNumberDbcPutParams, headers: V5EndpointDeviceSerialNumberDbcPutHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $put: (config) => null,
          },
        }),
      },
      'latest-event-data': {
        /**
         * POST /v5/endpoint/latest-event-data
         * @summary getLatestEventsByEndpointIds
         * @param {RequestConfig & { data: RequestedEndpointList, headers: V5EndpointLatestEventDataPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EventListDto>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'list': {
        /**
         * GET /v5/endpoint/list
         * @summary Returns a list of Endpoints of specific tenant ID.
         * @param {RequestConfig & { params: V5EndpointListGetParams, headers: V5EndpointListGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetEndpointListResponseAggregated>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'listByIds': {
        /**
         * GET /v5/endpoint/listByIds
         * @summary Returns a list of Endpoints of specific EndpointIds.
         * @param {RequestConfig & { params: V5EndpointListByIdsGetParams, headers: V5EndpointListByIdsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<GetEndpointListResponseAggregated>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'parking-mode-status': {
        /**
         * GET /v5/endpoint/parking-mode-status
         * @summary Get a list of parking mode status for a specific endpoint
         * @param {RequestConfig & { params: V5EndpointParkingModeStatusGetParams, headers: V5EndpointParkingModeStatusGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<ParkingModeStatusModel>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'wakeup': {
        statistics: {
          /**
           * POST /v5/endpoint/wakeup/statistics
           * @summary post device wake up statistics
           * @param {RequestConfig & { data: Array<WakeUpStatistics>, headers: V5EndpointWakeupStatisticsPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      /** @param {!number} endpointId endpointId */
      '$endpointId': (endpointId) => ({
        'aggregated': {
          /**
           * GET /v5/endpoint/{endpointId}/aggregated
           * @summary Returns endpoint details aggregated. Currently, this API is used by the current version of the UI. Please use v5/endpoint/{endpointId}/details instead.
           * @param {RequestConfig & { headers: V5EndpointEndpointIdAggregatedGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EndpointDetailsResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'awake': {
          /**
           * POST /v5/endpoint/{endpointId}/awake
           * @summary Awake device endpoint with endpoint id
           * @param {RequestConfig & { params: V5EndpointEndpointIdAwakePostParams, headers: V5EndpointEndpointIdAwakePostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        'details': {
          /**
           * GET /v5/endpoint/{endpointId}/details
           * @summary Returns endpoint details aggregated.
           * @param {RequestConfig & { headers: V5EndpointEndpointIdDetailsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EndpointDetailsResponseV4>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'effective-settings': {
          /**
           * GET /v5/endpoint/{endpointId}/effective-settings
           * @summary Returns effective settings for Endpoint
           * @param {RequestConfig & { headers: V5EndpointEndpointIdEffectiveSettingsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GroupSettings>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'latest-event-data': {
          /**
           * GET /v5/endpoint/{endpointId}/latest-event-data
           * @summary getLatestEventsForEndpointId
           * @param {RequestConfig & { headers: V5EndpointEndpointIdLatestEventDataGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EventV5>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'share': {
          /**
           * POST /v5/endpoint/{endpointId}/share
           * @summary Share endpoint with a user
           * @param {RequestConfig & { data: EndpointShareRequest, headers: V5EndpointEndpointIdSharePostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EmptyModel>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        'subscription': {
          /**
           * POST /v5/endpoint/{endpointId}/subscription
           * @summary Create PubSub subscription for endpoint
           * @param {RequestConfig & { headers: V5EndpointEndpointIdSubscriptionPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /v5/endpoint/{endpointId}/subscription
           * @summary Delete PubSub subscription for endpoint
           * @param {RequestConfig & { headers: V5EndpointEndpointIdSubscriptionDeleteHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
        'transfer-ownership': {
          /** @param {!number} newOwnerUserId newOwnerUserId */
          $newOwnerUserId: (newOwnerUserId) => ({
            /**
             * PUT /v5/endpoint/{endpointId}/transfer-ownership/{newOwnerUserId}
             * @summary transferOwnership
             * @param {RequestConfig & { headers: V5EndpointEndpointIdTransferOwnershipNewOwnerUserIdPutHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HttpClientResponse>} An ApiRequest; to get result call 'process'.
             */
            $put: (config) => null,
          }),
        },
      }),
    },
    'events': {
      /**
       * GET /v5/events
       * @summary Return event list based on filter and search text
       * @param {RequestConfig & { params: V5EventsGetParams, headers: V5EventsGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<EventListV5>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      annotation: {
        filter: {
          /**
           * GET /v5/events/annotation/filter
           * @summary Return event list based on annotation
           * @param {RequestConfig & { params: V5EventsAnnotationFilterGetParams, headers: V5EventsAnnotationFilterGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<EventV5>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      count: {
        /**
         * GET /v5/events/count
         * @summary Return event count based on filter and search text
         * @param {RequestConfig & { params: V5EventsCountGetParams, headers: V5EventsCountGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EventCountV5>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      filter: {
        /**
         * GET /v5/events/filter
         * @summary Return event list based on filter only
         * @param {RequestConfig & { params: V5EventsFilterGetParams, headers: V5EventsFilterGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EventListV5>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        count: {
          /**
           * GET /v5/events/filter/count
           * @summary Return event count based on filter only
           * @param {RequestConfig & { params: V5EventsFilterCountGetParams, headers: V5EventsFilterCountGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EventCountV5>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      occurred: {
        driver: {
          /** @param {!number} driverId Driver Id */
          $driverId: (driverId) => ({
            trigger: {
              /**
               * GET /v5/events/occurred/driver/{driverId}/trigger
               * @summary Count of Events occurred by driver for each day per trigger category
               * @param {RequestConfig & { params: V5EventsOccurredDriverDriverIdTriggerGetParams, headers: V5EventsOccurredDriverDriverIdTriggerGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<EventCountByTriggerCategoryList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          }),
        },
        endpoint: {
          /** @param {!number} endpointId Endpoint Id */
          $endpointId: (endpointId) => ({
            trigger: {
              /**
               * GET /v5/events/occurred/endpoint/{endpointId}/trigger
               * @summary Count of Events occurred by endpoint for each day per trigger category
               * @param {RequestConfig & { params: V5EventsOccurredEndpointEndpointIdTriggerGetParams, headers: V5EventsOccurredEndpointEndpointIdTriggerGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<EventCountByTriggerCategoryList>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
            triggerCategoryId: {
              /** @param {!number} triggerCategoryId Endpoint Id */
              $triggerCategoryId: (triggerCategoryId) => ({
                /**
                 * GET /v5/events/occurred/endpoint/{endpointId}/triggerCategoryId/{triggerCategoryId}
                 * @summary getEventsCountsByTriggerCategoryOfEndpointByDay
                 * @param {RequestConfig & { params: V5EventsOccurredEndpointEndpointIdTriggerCategoryIdTriggerCategoryIdGetParams, headers: V5EventsOccurredEndpointEndpointIdTriggerCategoryIdTriggerCategoryIdGetHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<EventCountByTriggerCategory>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
              }),
            },
          }),
        },
      },
      recordings: {
        /**
         * GET /v5/events/recordings
         * @summary Return media recordings for the device
         * @param {RequestConfig & { params: V5EventsRecordingsGetParams, headers: V5EventsRecordingsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<EventMediaRecordingListV5>} An ApiRequest; to get result call 'process'.
         */
        '$get': (config) => null,
        'clip-merge-operation': {
          /**
           * POST /v5/events/recordings/clip-merge-operation
           * @summary Event recordings clipping and merging
           * @param {RequestConfig & { data: VideoClipMergeRequest, headers: V5EventsRecordingsClipMergeOperationPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<VideoClipMergeResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          async: {
            /**
             * POST /v5/events/recordings/clip-merge-operation/async
             * @summary Event recordings clipping and merging non-blocking way
             * @param {RequestConfig & { data: VideoClipMergeRequestAsync, headers: V5EventsRecordingsClipMergeOperationAsyncPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        },
        'modified-video-url': {
          /**
           * GET /v5/events/recordings/modified-video-url
           * @summary Recordings file after clipping and merging non-blocking way
           * @param {RequestConfig & { params: V5EventsRecordingsModifiedVideoUrlGetParams, headers: V5EventsRecordingsModifiedVideoUrlGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      snapshot: {
        statistics: {
          /**
           * POST /v5/events/snapshot/statistics
           * @summary Post Snapshot Loading Statistics
           * @param {RequestConfig & { data: Array<SnapshotStatistics>, headers: V5EventsSnapshotStatisticsPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      /** @param {!number} eventId Event Id */
      $eventId: (eventId) => ({
        coaching: {
          /**
           * PATCH /v5/events/{eventId}/coaching
           * @summary Marks Event with the specific Coaching Status
           * @param {RequestConfig & { params: V5EventsEventIdCoachingPatchParams, headers: V5EventsEventIdCoachingPatchHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
           */
          $patch: (config) => null,
        },
        recordings: {
          /**
           * GET /v5/events/{eventId}/recordings
           * @summary Return event's media files
           * @param {RequestConfig & { params: V5EventsEventIdRecordingsGetParams, headers: V5EventsEventIdRecordingsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EventMediaRecordingListV5>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * PUT /v5/events/{eventId}/recordings
           * @summary Event recording successful upload notification
           * @param {RequestConfig & { params: V5EventsEventIdRecordingsPutParams, headers: V5EventsEventIdRecordingsPutHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<string>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        },
      }),
    },
    'file': {
      'ai-model': {
        /** @param {!string} modelId modelId */
        $modelId: (modelId) => ({
          /** @param {!string} filename filename */
          $filename: (filename) => ({
            /**
             * GET /v5/file/ai-model/{modelId}/{filename}
             * @summary Will return signed upload url for firmware and aimodel upload
             * @param {RequestConfig & { params: V5FileAiModelModelIdFilenameGetParams, headers: V5FileAiModelModelIdFilenameGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<any>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          }),
        }),
      },
      'download-url': {
        /**
         * GET /v5/file/download-url
         * @summary Will return signed download url
         * @param {RequestConfig & { params: V5FileDownloadUrlGetParams, headers: V5FileDownloadUrlGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SuccessResponseUrlDto>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'media-url': {
        /**
         * GET /v5/file/media-url
         * @summary Get media upload and download urls for given extensions
         * @param {RequestConfig & { params: V5FileMediaUrlGetParams, headers: V5FileMediaUrlGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<MediaUrlListDto>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'upload-url': {
        /**
         * GET /v5/file/upload-url
         * @summary Will return signed upload url for firmware and aimodel upload
         * @param {RequestConfig & { params: V5FileUploadUrlGetParams, headers: V5FileUploadUrlGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SuccessResponseUrlDto>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    'firmware': {
      /**
       * GET /v5/firmware
       * @summary Get firmware list
       * @param {RequestConfig & { params: V5FirmwareGetParams, headers: V5FirmwareGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<FirmwareDtoList>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/firmware
       * @summary Create firmware
       * @param {RequestConfig & { data: CreateFirmwareRequest, headers: V5FirmwarePostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<FirmwareDto>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      count: {
        /**
         * GET /v5/firmware/count
         * @summary Get firmware count
         * @param {RequestConfig & { params: V5FirmwareCountGetParams, headers: V5FirmwareCountGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      endpoint: {
        /** @param {!number} endpointId Endpoint Id */
        $endpointId: (endpointId) => ({
          /**
           * GET /v5/firmware/endpoint/{endpointId}
           * @summary Get effective firmware information for endpoint level
           * @param {RequestConfig & { headers: V5FirmwareEndpointEndpointIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<FirmwareDeployedInfoList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * PUT /v5/firmware/endpoint/{endpointId}
           * @summary Update firmware working on device
           * @param {RequestConfig & { params: V5FirmwareEndpointEndpointIdPutParams, headers: V5FirmwareEndpointEndpointIdPutHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          running: {
            /**
             * GET /v5/firmware/endpoint/{endpointId}/running
             * @summary Get list of Firmwares running on device
             * @param {RequestConfig & { headers: V5FirmwareEndpointEndpointIdRunningGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareDtoList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      },
      scheme: {
        /** @param {!number} schemeId Scheme Id */
        $schemeId: (schemeId) => ({
          /**
           * GET /v5/firmware/scheme/{schemeId}
           * @summary Get list of firmware(s) configured in Scheme
           * @param {RequestConfig & { headers: V5FirmwareSchemeSchemeIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<FirmwareInfoList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      type: {
        product: {
          /** @param {!string} productId Product Id */
          $productId: (productId) => ({
            /**
             * GET /v5/firmware/type/product/{productId}
             * @summary Get firmware type list for product
             * @param {RequestConfig & { headers: V5FirmwareTypeProductProductIdGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareTypeList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          }),
        },
        tenant: {
          /** @param {!string} tenantId Tenant Id */
          $tenantId: (tenantId) => ({
            /**
             * GET /v5/firmware/type/tenant/{tenantId}
             * @summary Get firmware type list for tenant
             * @param {RequestConfig & { headers: V5FirmwareTypeTenantTenantIdGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<FirmwareTypeList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          }),
        },
      },
      /** @param {!string} firmwareId Firmware id */
      $firmwareId: (firmwareId) => ({
        /**
         * GET /v5/firmware/{firmwareId}
         * @summary Get firmware by id
         * @param {RequestConfig & { headers: V5FirmwareFirmwareIdGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<FirmwareDto>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        scheme: {
          /** @param {!number} schemeId Scheme Id */
          $schemeId: (schemeId) => ({
            /**
             * POST /v5/firmware/{firmwareId}/scheme/{schemeId}
             * @summary Assign firmware to a Scheme
             * @param {RequestConfig & { headers: V5FirmwareFirmwareIdSchemeSchemeIdPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
            /**
             * DELETE /v5/firmware/{firmwareId}/scheme/{schemeId}
             * @summary Remove firmware to a Scheme
             * @param {RequestConfig & { headers: V5FirmwareFirmwareIdSchemeSchemeIdDeleteHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          }),
        },
      }),
    },
    'fleet': {
      'monthly-score': {
        /**
         * GET /v5/fleet/monthly-score
         * @summary Get fleet score
         * @param {RequestConfig & { params: V5FleetMonthlyScoreGetParams, headers: V5FleetMonthlyScoreGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SuccessResponseFleetScoreModel>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'score-list': {
        /**
         * GET /v5/fleet/score-list
         * @summary Get score
         * @param {RequestConfig & { params: V5FleetScoreListGetParams, headers: V5FleetScoreListGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ListResponseFleetScoreModel>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'start-tracking': {
        /**
         * POST /v5/fleet/start-tracking
         * @summary Create a new tracking session
         * @param {RequestConfig & { headers: V5FleetStartTrackingPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SuccessResponselong>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'tracking': {
        /**
         * GET /v5/fleet/tracking
         * @summary Get the latest tracking data
         * @param {RequestConfig & { params: V5FleetTrackingGetParams, headers: V5FleetTrackingGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ListResponseEndpointInfoAggregated>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    'group': {
      device: {
        list: {
          /**
           * GET /v5/group/device/list
           * @summary Returns a list of Device group of specific tenant ID.
           * @param {RequestConfig & { params: V5GroupDeviceListGetParams, headers: V5GroupDeviceListGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<DeviceGroupInfo>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
    },
    'health': {
      'assigned-health-tag': {
        /**
         * GET /v5/health/assigned-health-tag
         * @summary Get filtered assigned health tag
         * @param {RequestConfig & { params: V5HealthAssignedHealthTagGetParams, headers: V5HealthAssignedHealthTagGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<AssignedHealthTagResponse>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'tag': {
        /**
         * GET /v5/health/tag
         * @summary Get all health tags
         * @param {RequestConfig & { headers: V5HealthTagGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<HealthTagResponse>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v5/health/tag
         * @summary Create a health tag
         * @param {RequestConfig & { params: V5HealthTagPostParams, headers: V5HealthTagPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HealthTagResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /** @param {!number} tagId tagId */
        $tagId: (tagId) => ({
          /**
           * GET /v5/health/tag/{tagId}
           * @summary Get a health tag
           * @param {RequestConfig & { params: V5HealthTagTagIdGetParams, headers: V5HealthTagTagIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HealthTagResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * DELETE /v5/health/tag/{tagId}
           * @summary Discontinue health tags
           * @param {RequestConfig & { params: V5HealthTagTagIdDeleteParams, headers: V5HealthTagTagIdDeleteHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HealthTagResponse>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          endpoint: {
            /** @param {!number} endpointId endpointId */
            $endpointId: (endpointId) => ({
              /**
               * POST /v5/health/tag/{tagId}/endpoint/{endpointId}
               * @summary Assign a health tag to a device
               * @param {RequestConfig & { data: AssignHealthTag, params: V5HealthTagTagIdEndpointEndpointIdPostParams, headers: V5HealthTagTagIdEndpointEndpointIdPostHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<AssignedHealthTagResponse>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
            }),
          },
        }),
      },
      'tenant': {
        /** @param {!string} tenantId Tenant id */
        $tenantId: (tenantId) => ({
          'assigned-health-tag': {
            /**
             * GET /v5/health/tenant/{tenantId}/assigned-health-tag
             * @summary Get filtered assigned health tag of a tenant
             * @param {RequestConfig & { params: V5HealthTenantTenantIdAssignedHealthTagGetParams, headers: V5HealthTenantTenantIdAssignedHealthTagGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<AssignedHealthTagResponse>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /** @param {!number} id id */
            $id: (id) => ({
              /**
               * GET /v5/health/tenant/{tenantId}/assigned-health-tag/{id}
               * @summary Get a device health tag by id
               * @param {RequestConfig & { headers: V5HealthTenantTenantIdAssignedHealthTagIdGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<AssignedHealthTagResponse>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
              /**
               * PUT /v5/health/tenant/{tenantId}/assigned-health-tag/{id}
               * @summary Update an assigned health tag by id
               * @param {RequestConfig & { data: UpdateAssignedHealthTag, headers: V5HealthTenantTenantIdAssignedHealthTagIdPutHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<AssignedHealthTagResponse>} An ApiRequest; to get result call 'process'.
               */
              $put: (config) => null,
            }),
          },
          'endpoint': {
            /** @param {!number} endpointId endpointId */
            $endpointId: (endpointId) => ({
              'assigned-health-tag': {
                /** @param {!number} id id */
                $id: (id) => ({
                  /**
                   * DELETE /v5/health/tenant/{tenantId}/endpoint/{endpointId}/assigned-health-tag/{id}
                   * @summary Remove assigned health tag from endpoint
                   * @param {RequestConfig & { headers: V5HealthTenantTenantIdEndpointEndpointIdAssignedHealthTagIdDeleteHeaders }} config Axios Request config
                   * @returns {import('@/api').ApiRequest<AssignedHealthTagResponse>} An ApiRequest; to get result call 'process'.
                   */
                  $delete: (config) => null,
                }),
              },
            }),
          },
          'tag': {
            /**
             * GET /v5/health/tenant/{tenantId}/tag
             * @summary Get all health tags of a tenant
             * @param {RequestConfig & { headers: V5HealthTenantTenantIdTagGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<HealthTagResponse>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      },
    },
    'media': {
      device: {
        /** @param {!string} deviceId Uniquely identifiable device serial number */
        $deviceId: (deviceId) => ({
          recordings: {
            /**
             * POST /v5/media/device/{deviceId}/recordings
             * @summary Post multiple media recordings.
             * @param {RequestConfig & { data: Array<DeviceMedia>, headers: V5MediaDeviceDeviceIdRecordingsPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        }),
      },
      snapshots: {
        latest: {
          /**
           * GET /v5/media/snapshots/latest
           * @summary Get latest available snapshots for requested endpoint list
           * @param {RequestConfig & { params: V5MediaSnapshotsLatestGetParams, headers: V5MediaSnapshotsLatestGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<SnapshotsList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
    },
    'nfc-card': {
      /**
       * GET /v5/nfc-card
       * @summary getNfcCardList
       * @param {RequestConfig & { params: V5NfcCardGetParams, headers: V5NfcCardGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SuccessResponseListResponseNfcCardModel>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/nfc-card
       * @summary addNfcCards
       * @param {RequestConfig & { data: NfcCardAddRequest, headers: V5NfcCardPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SuccessResponseNfcCardAddResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      /** @param {!string} nfcId nfcId */
      $nfcId: (nfcId) => ({
        /**
         * DELETE /v5/nfc-card/{nfcId}
         * @summary removeNfcCard
         * @param {RequestConfig & { headers: V5NfcCardNfcIdDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        user: {
          /**
           * POST /v5/nfc-card/{nfcId}/user
           * @summary updateNfcUser
           * @param {RequestConfig & { data: UpdateNfcUserRequest, headers: V5NfcCardNfcIdUserPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /**
           * DELETE /v5/nfc-card/{nfcId}/user
           * @summary removeNfcUser
           * @param {RequestConfig & { headers: V5NfcCardNfcIdUserDeleteHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
        },
      }),
    },
    'notification': {
      /**
       * GET /v5/notification
       * @summary Read All Notifications
       * @param {RequestConfig & { headers: V5NotificationGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<Array<NotificationInfo>>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/notification
       * @summary Create or update Notification
       * @param {RequestConfig & { data: Array<NotificationInfo>, headers: V5NotificationPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      /**
       * DELETE /v5/notification
       * @summary Delete Notification
       * @param {RequestConfig & { data: Array<UserNotificationEntityId>, headers: V5NotificationDeleteHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
       */
      $delete: (config) => null,
      /** @param {!number} userId User Id */
      $userId: (userId) => ({
        /**
         * GET /v5/notification/{userId}
         * @summary Read notification List by userId
         * @param {RequestConfig & { headers: V5NotificationUserIdGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<NotificationInfo>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      }),
    },
    'persona': {
      /**
       * GET /v5/persona
       * @summary Get Persona List
       * @param {RequestConfig & { headers: V5PersonaGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<PersonaDtoList>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/persona
       * @summary Create a Persona
       * @param {RequestConfig & { params: V5PersonaPostParams, headers: V5PersonaPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<PersonaEntity>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      weight: {
        /**
         * POST /v5/persona/weight
         * @summary Create a Persona Weight Settings
         * @param {RequestConfig & { params: V5PersonaWeightPostParams, headers: V5PersonaWeightPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<PersonaScoreCoefficient>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      /** @param {!number} personaId Persona Id */
      $personaId: (personaId) => ({
        trigger: {
          categories: {
            /**
             * GET /v5/persona/{personaId}/trigger/categories
             * @summary Get all trigger category by Persona Id
             * @param {RequestConfig & { headers: V5PersonaPersonaIdTriggerCategoriesGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<TriggerCategoryDetails>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      }),
    },
    'product': {
      /**
       * GET /v5/product
       * @summary Get Product List
       * @param {RequestConfig & { headers: V5ProductGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<ProductListDto>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      tenant: {
        /** @param {!string} tenantId Tenant Id */
        $tenantId: (tenantId) => ({
          /**
           * GET /v5/product/tenant/{tenantId}
           * @summary Get Product List of tenant
           * @param {RequestConfig & { headers: V5ProductTenantTenantIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ProductListDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      /** @param {!string} productId Product Id */
      $productId: (productId) => ({
        /**
         * GET /v5/product/{productId}
         * @summary Get Product Details
         * @param {RequestConfig & { headers: V5ProductProductIdGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ProductListDto>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        tenant: {
          /** @param {!string} tenantId Tenant Id */
          $tenantId: (tenantId) => ({
            /**
             * POST /v5/product/{productId}/tenant/{tenantId}
             * @summary Assign Product to Tenant
             * @param {RequestConfig & { headers: V5ProductProductIdTenantTenantIdPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          }),
        },
      }),
    },
    'report': {
      'client-activity-latency': {
        /**
         * POST /v5/report/client-activity-latency
         * @summary Save Client Activity latency
         * @param {RequestConfig & { data: Array<ClientActivityLatencyStatistics>, headers: V5ReportClientActivityLatencyPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<HTTPResponseV5>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      'diagnostics': {
        search: {
          /**
           * GET /v5/report/diagnostics/search
           * @summary Searches diagnostic report of particular device
           * @param {RequestConfig & { params: V5ReportDiagnosticsSearchGetParams, headers: V5ReportDiagnosticsSearchGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DiagnosticListResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      'sensorreports': {
        /**
         * GET /v5/report/sensorreports
         * @summary Returns a list of sensor information for an Endpoint
         * @param {RequestConfig & { params: V5ReportSensorreportsGetParams, headers: V5ReportSensorreportsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SensorReportsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'stream': {
        status: {
          /**
           * GET /v5/report/stream/status
           * @summary Get stream list
           * @param {RequestConfig & { params: V5ReportStreamStatusGetParams, headers: V5ReportStreamStatusGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<HTTPResponseV5>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v5/report/stream/status
           * @summary Post streaming status
           * @param {RequestConfig & { data: PostStreamStatus, params: V5ReportStreamStatusPostParams, headers: V5ReportStreamStatusPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          count: {
            /**
             * GET /v5/report/stream/status/count
             * @summary Return Stream Count
             * @param {RequestConfig & { params: V5ReportStreamStatusCountGetParams, headers: V5ReportStreamStatusCountGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<HTTPResponseV5>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      },
    },
    'repository': {
      oem: {
        'base-version': {
          /**
           * GET /v5/repository/oem/base-version
           * @summary Returns a list of base version of a particular OEM image
           * @param {RequestConfig & { params: V5RepositoryOemBaseVersionGetParams, headers: V5RepositoryOemBaseVersionGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OEMImageBaseVersionList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v5/repository/oem/base-version
           * @summary Save OEM base version values for an OEM image
           * @param {RequestConfig & { data: OEMImageBaseVersionList, headers: V5RepositoryOemBaseVersionPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
    },
    'road-data': {
      'connected-road': {
        /**
         * GET /v5/road-data/connected-road
         * @summary searchConnectedRoad
         * @param {RequestConfig & { params: V5RoadDataConnectedRoadGetParams, headers: V5RoadDataConnectedRoadGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RoadDataResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      'search': {
        /**
         * GET /v5/road-data/search
         * @summary searchRoadData
         * @param {RequestConfig & { params: V5RoadDataSearchGetParams, headers: V5RoadDataSearchGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RoadDataResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
    },
    'role-manager': {
      rights: {
        /**
         * GET /v5/role-manager/rights
         * @summary Get all rights
         * @param {RequestConfig & { params: V5RoleManagerRightsGetParams, headers: V5RoleManagerRightsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Rights>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      roles: {
        /**
         * POST /v5/role-manager/roles
         * @summary Create role
         * @param {RequestConfig & { data: RoleRequest, params: V5RoleManagerRolesPostParams, headers: V5RoleManagerRolesPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RoleModel>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /**
         * PATCH /v5/role-manager/roles
         * @summary Update role
         * @param {RequestConfig & { params: V5RoleManagerRolesPatchParams, headers: V5RoleManagerRolesPatchHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<RoleModel>} An ApiRequest; to get result call 'process'.
         */
        $patch: (config) => null,
        /** @param {!number} roleId Role id */
        $roleId: (roleId) => ({
          rights: {
            /**
             * GET /v5/role-manager/roles/{roleId}/rights
             * @summary Get all rights by role
             * @param {RequestConfig & { params: V5RoleManagerRolesRoleIdRightsGetParams, headers: V5RoleManagerRolesRoleIdRightsGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<RoleModel>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      },
      tenant: {
        /** @param {!string} tenantId Tenant id */
        $tenantId: (tenantId) => ({
          roles: {
            /**
             * GET /v5/role-manager/tenant/{tenantId}/roles
             * @summary Get all roles
             * @param {RequestConfig & { headers: V5RoleManagerTenantTenantIdRolesGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<RoleModel>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      },
      users: {
        /** @param {!number} userId User id */
        $userId: (userId) => ({
          /**
           * PUT /v5/role-manager/users/{userId}
           * @summary Update user role
           * @param {RequestConfig & { params: V5RoleManagerUsersUserIdPutParams, headers: V5RoleManagerUsersUserIdPutHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<RoleModel>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          rights: {
            /**
             * GET /v5/role-manager/users/{userId}/rights
             * @summary Get all rights by user
             * @param {RequestConfig & { params: V5RoleManagerUsersUserIdRightsGetParams, headers: V5RoleManagerUsersUserIdRightsGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<RoleModel>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      },
    },
    'scheme': {
      /**
       * GET /v5/scheme
       * @summary Returns the list of created and assigned scheme
       * @param {RequestConfig & { params: V5SchemeGetParams, headers: V5SchemeGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SchemeList>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/scheme
       * @summary Returns the scheme id upon successful creation
       * @param {RequestConfig & { params: V5SchemePostParams, headers: V5SchemePostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<SchemeInfo>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      assign: {
        /**
         * POST /v5/scheme/assign
         * @summary Assigns created scheme to a tenant.
         * @param {RequestConfig & { params: V5SchemeAssignPostParams, headers: V5SchemeAssignPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      deploy: {
        /**
         * POST /v5/scheme/deploy
         * @summary Returns the scheme id upon successful deploy
         * @param {RequestConfig & { params: V5SchemeDeployPostParams, headers: V5SchemeDeployPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SchemeInfo>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        endpoint: {
          /** @param {!number} endpointId Endpoint Id */
          $endpointId: (endpointId) => ({
            /**
             * GET /v5/scheme/deploy/endpoint/{endpointId}
             * @summary Get effective scheme name for endpoint level
             * @param {RequestConfig & { headers: V5SchemeDeployEndpointEndpointIdGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeployedSchemeInfoListDto>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          }),
        },
        group: {
          /** @param {!number} groupId Group Id */
          $groupId: (groupId) => ({
            /**
             * GET /v5/scheme/deploy/group/{groupId}
             * @summary Get effective scheme name for group level
             * @param {RequestConfig & { headers: V5SchemeDeployGroupGroupIdGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeployedSchemeInfoListDto>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          }),
        },
        tenant: {
          /** @param {!string} tenantId Target Tenant id */
          $tenantId: (tenantId) => ({
            /**
             * GET /v5/scheme/deploy/tenant/{tenantId}
             * @summary Get effective scheme name for tenant level
             * @param {RequestConfig & { headers: V5SchemeDeployTenantTenantIdGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DeployedSchemeInfoListDto>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          }),
        },
      },
      unassign: {
        /**
         * DELETE /v5/scheme/unassign
         * @summary Un-assigns a scheme from a tenant.
         * @param {RequestConfig & { params: V5SchemeUnassignDeleteParams, headers: V5SchemeUnassignDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      undeploy: {
        /**
         * DELETE /v5/scheme/undeploy
         * @summary Returns success on un-deploy
         * @param {RequestConfig & { params: V5SchemeUndeployDeleteParams, headers: V5SchemeUndeployDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      },
      /** @param {!string} schemeId Scheme Id */
      $schemeId: (schemeId) => ({
        deploy: {
          /**
           * GET /v5/scheme/{schemeId}/deploy
           * @summary Get information of target levels on which scheme is deployed
           * @param {RequestConfig & { headers: V5SchemeSchemeIdDeployGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DeployedSchemeInfoListDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      }),
    },
    'score': {
      driver: {
        /** @param {!number} driverId Driver Id */
        $driverId: (driverId) => ({
          /**
           * GET /v5/score/driver/{driverId}
           * @summary Get List of Scores for a Driver
           * @param {RequestConfig & { params: V5ScoreDriverDriverIdGetParams, headers: V5ScoreDriverDriverIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<PersonaScoreForDriverDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      endpoint: {
        /** @param {!number} endpointId Endpoint Id */
        $endpointId: (endpointId) => ({
          /**
           * GET /v5/score/endpoint/{endpointId}
           * @summary Get List of Score for Endpoint
           * @param {RequestConfig & { params: V5ScoreEndpointEndpointIdGetParams, headers: V5ScoreEndpointEndpointIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EndpointScoreListResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      persona: {
        /** @param {!number} personaId Persona Id */
        $personaId: (personaId) => ({
          driver: {
            /**
             * GET /v5/score/persona/{personaId}/driver
             * @summary Get All Driver List Group By Score
             * @param {RequestConfig & { params: V5ScorePersonaPersonaIdDriverGetParams, headers: V5ScorePersonaPersonaIdDriverGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DriverScoreListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            raw: {
              /**
               * GET /v5/score/persona/{personaId}/driver/raw
               * @summary Get List of Score for all Driver for a given persona
               * @param {RequestConfig & { params: V5ScorePersonaPersonaIdDriverRawGetParams, headers: V5ScorePersonaPersonaIdDriverRawGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<DriverScoreForPersonaDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          endpoint: {
            /**
             * GET /v5/score/persona/{personaId}/endpoint
             * @summary Get All Endpoint List Group By Score
             * @param {RequestConfig & { params: V5ScorePersonaPersonaIdEndpointGetParams, headers: V5ScorePersonaPersonaIdEndpointGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<EndpointScoreListResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            raw: {
              /**
               * GET /v5/score/persona/{personaId}/endpoint/raw
               * @summary Get List of Score for all Endpoint for a given persona
               * @param {RequestConfig & { params: V5ScorePersonaPersonaIdEndpointRawGetParams, headers: V5ScorePersonaPersonaIdEndpointRawGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<EndpointScoreForPersonaDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
          tenant: {
            /** @param {!string} tenantId Tenant Id */
            $tenantId: (tenantId) => ({
              driver: {
                aggregated: {
                  /**
                   * GET /v5/score/persona/{personaId}/tenant/{tenantId}/driver/aggregated
                   * @summary Get daily average score for drivers present in the selected tenant
                   * @param {RequestConfig & { params: V5ScorePersonaPersonaIdTenantTenantIdDriverAggregatedGetParams, headers: V5ScorePersonaPersonaIdTenantTenantIdDriverAggregatedGetHeaders }} config Axios Request config
                   * @returns {import('@/api').ApiRequest<ScoreForDateList>} An ApiRequest; to get result call 'process'.
                   */
                  $get: (config) => null,
                },
                details: {
                  /**
                   * GET /v5/score/persona/{personaId}/tenant/{tenantId}/driver/details
                   * @summary Get list of driver details with score present in a tenant with score for a persona
                   * @param {RequestConfig & { params: V5ScorePersonaPersonaIdTenantTenantIdDriverDetailsGetParams, headers: V5ScorePersonaPersonaIdTenantTenantIdDriverDetailsGetHeaders }} config Axios Request config
                   * @returns {import('@/api').ApiRequest<DriverScoreListWithDriverDetailDto>} An ApiRequest; to get result call 'process'.
                   */
                  $get: (config) => null,
                },
              },
              endpoint: {
                aggregated: {
                  /**
                   * GET /v5/score/persona/{personaId}/tenant/{tenantId}/endpoint/aggregated
                   * @summary Get daily average score for endpoints present in the selected tenant
                   * @param {RequestConfig & { params: V5ScorePersonaPersonaIdTenantTenantIdEndpointAggregatedGetParams, headers: V5ScorePersonaPersonaIdTenantTenantIdEndpointAggregatedGetHeaders }} config Axios Request config
                   * @returns {import('@/api').ApiRequest<ScoreForDateList>} An ApiRequest; to get result call 'process'.
                   */
                  $get: (config) => null,
                },
                details: {
                  /**
                   * GET /v5/score/persona/{personaId}/tenant/{tenantId}/endpoint/details
                   * @summary Get list of endpoint details with score present in a tenant with score for a persona
                   * @param {RequestConfig & { params: V5ScorePersonaPersonaIdTenantTenantIdEndpointDetailsGetParams, headers: V5ScorePersonaPersonaIdTenantTenantIdEndpointDetailsGetHeaders }} config Axios Request config
                   * @returns {import('@/api').ApiRequest<EndpointScoreListWithEndpointDetailDto>} An ApiRequest; to get result call 'process'.
                   */
                  $get: (config) => null,
                },
              },
            }),
          },
        }),
      },
    },
    'shell-command': {
      /**
       * GET /v5/shell-command
       * @summary getShellCommandList
       * @param {RequestConfig & { headers: V5ShellCommandGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/shell-command
       * @summary postShellCommand
       * @param {RequestConfig & { data: ShellCommandDto, headers: V5ShellCommandPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      /**
       * PUT /v5/shell-command
       * @summary updateShellCommand
       * @param {RequestConfig & { data: ShellCommandDto, headers: V5ShellCommandPutHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
       */
      $put: (config) => null,
      device: {
        response: {
          /**
           * POST /v5/shell-command/device/response
           * @summary Notify shell execution response
           * @param {RequestConfig & { data: ShellCommandExecuteDto, params: V5ShellCommandDeviceResponsePostParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CommonResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        /** @param {!string} deviceKey deviceKey */
        $deviceKey: (deviceKey) => ({
          /**
           * GET /v5/shell-command/device/{deviceKey}
           * @summary getShellCommandsUsingDeviceKey
           * @param {RequestConfig & { params: V5ShellCommandDeviceDeviceKeyGetParams }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      group: {
        /**
         * POST /v5/shell-command/group
         * @summary postShellCommandsForGroup
         * @param {RequestConfig & { data: ShellCommandsRequestForGroup, headers: V5ShellCommandGroupPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /** @param {!number} groupId groupId */
        $groupId: (groupId) => ({
          /**
           * GET /v5/shell-command/group/{groupId}
           * @summary getShellCommandsForGroup
           * @param {RequestConfig & { headers: V5ShellCommandGroupGroupIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        }),
      },
      log: {
        /**
         * POST /v5/shell-command/log
         * @summary postShellCommandsLog
         * @param {RequestConfig & { data: ShellResponseModel, headers: V5ShellCommandLogPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      /** @param {!number} commandId commandId */
      $commandId: (commandId) => ({
        /**
         * DELETE /v5/shell-command/{commandId}
         * @summary deleteShellCommand
         * @param {RequestConfig & { headers: V5ShellCommandCommandIdDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ResponseEntity>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
      }),
    },
    'tenant': {
      descendants: {
        /**
         * GET /v5/tenant/descendants
         * @summary Get Descendant tenant tree
         * @param {RequestConfig & { headers: V5TenantDescendantsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<DescendantTenant>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      subtenants: {
        /**
         * POST /v5/tenant/subtenants
         * @summary Create a child tenant
         * @param {RequestConfig & { data: SubTenantCreateRequest, headers: V5TenantSubtenantsPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<SubTenantResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
      },
      /** @param {!string} tenantId tenantId */
      $tenantId: (tenantId) => ({
        /**
         * GET /v5/tenant/{tenantId}
         * @summary Get tenant details
         * @param {RequestConfig & { headers: V5TenantTenantIdGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantInfo>} An ApiRequest; to get result call 'process'.
         */
        '$get': (config) => null,
        /**
         * DELETE /v5/tenant/{tenantId}
         * @summary Delete a child tenant
         * @param {RequestConfig & { headers: V5TenantTenantIdDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<ErrorResponse>} An ApiRequest; to get result call 'process'.
         */
        '$delete': (config) => null,
        /**
         * PATCH /v5/tenant/{tenantId}
         * @summary Change tenant Name
         * @param {RequestConfig & { data: TenantUpdateNameRequest, headers: V5TenantTenantIdPatchHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TenantInfo>} An ApiRequest; to get result call 'process'.
         */
        '$patch': (config) => null,
        'child': {
          'change-data-residency-setting': {
            /**
             * PATCH /v5/tenant/{tenantId}/child/change-data-residency-setting
             * @summary Change child tenant data residency setting
             * @param {RequestConfig & { data: UpdateChildTenantDataResidencySetting, headers: V5TenantTenantIdChildChangeDataResidencySettingPatchHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<TenantInfo>} An ApiRequest; to get result call 'process'.
             */
            $patch: (config) => null,
          },
          'update-data-residency-region': {
            /**
             * PATCH /v5/tenant/{tenantId}/child/update-data-residency-region
             * @summary Update child tenant data residency region
             * @param {RequestConfig & { data: UpdateChildTenantDataResidencyRegion, headers: V5TenantTenantIdChildUpdateDataResidencyRegionPatchHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DataResidencyRegionList>} An ApiRequest; to get result call 'process'.
             */
            $patch: (config) => null,
          },
        },
        'data-residency-region': {
          /**
           * PATCH /v5/tenant/{tenantId}/data-residency-region
           * @summary Change tenant data residency region
           * @param {RequestConfig & { data: UpdateTenantResidencyRegionRequest, headers: V5TenantTenantIdDataResidencyRegionPatchHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<TenantInfo>} An ApiRequest; to get result call 'process'.
           */
          $patch: (config) => null,
        },
        'data-residency': {
          'allowed-region': {
            /**
             * GET /v5/tenant/{tenantId}/data-residency/allowed-region
             * @summary Get allowed region for tenant's data residency
             * @param {RequestConfig & { headers: V5TenantTenantIdDataResidencyAllowedRegionGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DataResidencyRegionList>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        'device-endpoints-with-uptime': {
          /**
           * GET /v5/tenant/{tenantId}/device-endpoints-with-uptime
           * @summary Returns a list of endpoints with uptime of specific tenant ID.
           * @param {RequestConfig & { params: V5TenantTenantIdDeviceEndpointsWithUptimeGetParams, headers: V5TenantTenantIdDeviceEndpointsWithUptimeGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<DeviceEndpointUptimeResponse>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'endpoint-reset-log': {
          /**
           * GET /v5/tenant/{tenantId}/endpoint-reset-log
           * @summary Will return a endpoint reset log for this tenant
           * @param {RequestConfig & { params: V5TenantTenantIdEndpointResetLogGetParams, headers: V5TenantTenantIdEndpointResetLogGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EndpointResetLogList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'hierarchy': {
          /**
           * GET /v5/tenant/{tenantId}/hierarchy
           * @summary Get tenant details
           * @param {RequestConfig & { headers: V5TenantTenantIdHierarchyGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<TenantRelation>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'onboard-log': {
          /**
           * GET /v5/tenant/{tenantId}/onboard-log
           * @summary getOnboardLogs
           * @param {RequestConfig & { params: V5TenantTenantIdOnboardLogGetParams, headers: V5TenantTenantIdOnboardLogGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<DeviceOnboardLog>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          count: {
            /**
             * GET /v5/tenant/{tenantId}/onboard-log/count
             * @summary getOnboardLogsCount
             * @param {RequestConfig & { params: V5TenantTenantIdOnboardLogCountGetParams, headers: V5TenantTenantIdOnboardLogCountGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<CountResponseModel>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
        'service-entity-count': {
          /**
           * POST /v5/tenant/{tenantId}/service-entity-count
           * @summary Returns a list with count of entities specified in the request
           * @param {RequestConfig & { data: ServiceEntitiesCountRequest, headers: V5TenantTenantIdServiceEntityCountPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<ServiceEntityCountResponse>>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        'subtenants': {
          /**
           * GET /v5/tenant/{tenantId}/subtenants
           * @summary Get child tenant list
           * @param {RequestConfig & { headers: V5TenantTenantIdSubtenantsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<TenantInfo>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'target-pool': {
          /**
           * GET /v5/tenant/{tenantId}/target-pool
           * @summary getTargetPools
           * @param {RequestConfig & { headers: V5TenantTenantIdTargetPoolGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<PoolListInfo>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        'user': {
          /**
           * GET /v5/tenant/{tenantId}/user
           * @summary Will return a list of tenant user with account details
           * @param {RequestConfig & { params: V5TenantTenantIdUserGetParams, headers: V5TenantTenantIdUserGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<GetTenantUserListResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /** @param {!number} userId userId */
          $userId: (userId) => ({
            /**
             * GET /v5/tenant/{tenantId}/user/{userId}
             * @summary Will return a user details
             * @param {RequestConfig & { headers: V5TenantTenantIdUserUserIdGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<UserWithAccountDetails>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          }),
        },
      }),
    },
    'trigger': {
      category: {
        /**
         * POST /v5/trigger/category
         * @summary Create a trigger category
         * @param {RequestConfig & { data: TriggerCategoryRequest, headers: V5TriggerCategoryPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TriggerCategoryDetails>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        all: {
          /**
           * GET /v5/trigger/category/all
           * @summary Get all trigger category
           * @param {RequestConfig & { headers: V5TriggerCategoryAllGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<TriggerCategoryDetails>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        count: {
          /**
           * GET /v5/trigger/category/count
           * @summary Get trigger category count
           * @param {RequestConfig & { headers: V5TriggerCategoryCountGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CountResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      composite: {
        /**
         * POST /v5/trigger/composite
         * @summary Create Trigger
         * @param {RequestConfig & { data: CompositeTriggerRequest, headers: V5TriggerCompositePostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CompositeTriggerDto>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        config: {
          /**
           * GET /v5/trigger/composite/config
           * @summary Get trigger configuration details
           * @param {RequestConfig & { headers: V5TriggerCompositeConfigGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<VariableDefinitionDto>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
        endpoint: {
          /** @param {!number} endpointId Endpoint Id */
          $endpointId: (endpointId) => ({
            detail: {
              /**
               * GET /v5/trigger/composite/endpoint/{endpointId}/detail
               * @summary Get effective triggers for endpoint level
               * @param {RequestConfig & { headers: V5TriggerCompositeEndpointEndpointIdDetailGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeTriggersDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
            info: {
              /**
               * GET /v5/trigger/composite/endpoint/{endpointId}/info
               * @summary Get effective trigger information for endpoint level
               * @param {RequestConfig & { headers: V5TriggerCompositeEndpointEndpointIdInfoGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeTriggerInfoListDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          }),
        },
        group: {
          /** @param {!number} groupId Group Id */
          $groupId: (groupId) => ({
            detail: {
              /**
               * GET /v5/trigger/composite/group/{groupId}/detail
               * @summary Get effective triggers for group level
               * @param {RequestConfig & { headers: V5TriggerCompositeGroupGroupIdDetailGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeTriggersDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
            info: {
              /**
               * GET /v5/trigger/composite/group/{groupId}/info
               * @summary Get effective trigger information for group level
               * @param {RequestConfig & { headers: V5TriggerCompositeGroupGroupIdInfoGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeTriggerInfoListDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          }),
        },
        scheme: {
          /** @param {!string} schemeId Scheme ID */
          $schemeId: (schemeId) => ({
            filter: {
              /**
               * GET /v5/trigger/composite/scheme/{schemeId}/filter
               * @summary Get triggers assigned to scheme with filter
               * @param {RequestConfig & { params: V5TriggerCompositeSchemeSchemeIdFilterGetParams, headers: V5TriggerCompositeSchemeSchemeIdFilterGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeTriggersDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
              count: {
                /**
                 * GET /v5/trigger/composite/scheme/{schemeId}/filter/count
                 * @summary Get trigger count assigned to scheme with filter
                 * @param {RequestConfig & { params: V5TriggerCompositeSchemeSchemeIdFilterCountGetParams, headers: V5TriggerCompositeSchemeSchemeIdFilterCountGetHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
              },
            },
          }),
        },
        tenant: {
          /** @param {!string} tenantId Target Tenant id */
          $tenantId: (tenantId) => ({
            detail: {
              /**
               * GET /v5/trigger/composite/tenant/{tenantId}/detail
               * @summary Get effective trigger details for tenant level
               * @param {RequestConfig & { headers: V5TriggerCompositeTenantTenantIdDetailGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeTriggersDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
            filter: {
              /**
               * GET /v5/trigger/composite/tenant/{tenantId}/filter
               * @summary Get trigger list owned by the tenant with filter
               * @param {RequestConfig & { params: V5TriggerCompositeTenantTenantIdFilterGetParams, headers: V5TriggerCompositeTenantTenantIdFilterGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeTriggersDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
              count: {
                /**
                 * GET /v5/trigger/composite/tenant/{tenantId}/filter/count
                 * @summary Get trigger count owned by the tenant with filter
                 * @param {RequestConfig & { params: V5TriggerCompositeTenantTenantIdFilterCountGetParams, headers: V5TriggerCompositeTenantTenantIdFilterCountGetHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<number>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
              },
            },
            info: {
              /**
               * GET /v5/trigger/composite/tenant/{tenantId}/info
               * @summary Get effective trigger information for tenant level
               * @param {RequestConfig & { headers: V5TriggerCompositeTenantTenantIdInfoGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeTriggerInfoListDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          }),
        },
        /** @param {!number} triggerCategoryId Trigger Category id */
        $triggerCategoryId: (triggerCategoryId) => ({
          config: {
            /**
             * PUT /v5/trigger/composite/{triggerCategoryId}/config
             * @summary Update trigger configuration for trigger category
             * @param {RequestConfig & { data: CompositeTriggerConfigUpdateRequest, headers: V5TriggerCompositeTriggerCategoryIdConfigPutHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $put: (config) => null,
          },
        }),
        /** @param {!string} triggerId Trigger unique id */
        $triggerId: (triggerId) => ({
          /**
           * GET /v5/trigger/composite/{triggerId}
           * @summary Get trigger details
           * @param {RequestConfig & { params: V5TriggerCompositeTriggerIdGetParams, headers: V5TriggerCompositeTriggerIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CompositeTriggerDto>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * PUT /v5/trigger/composite/{triggerId}
           * @summary Update Trigger
           * @param {RequestConfig & { data: CompositeTriggerRequest, headers: V5TriggerCompositeTriggerIdPutHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CompositeTriggerDto>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
          discontinue: {
            /**
             * PATCH /v5/trigger/composite/{triggerId}/discontinue
             * @summary Discontinue a trigger
             * @param {RequestConfig & { headers: V5TriggerCompositeTriggerIdDiscontinuePatchHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $patch: (config) => null,
          },
          endpoint: {
            /** @param {!number} endpointId Endpoint Id */
            $endpointId: (endpointId) => ({
              /**
               * GET /v5/trigger/composite/{triggerId}/endpoint/{endpointId}
               * @summary Get trigger details for endpoint (used by camera)
               * @param {RequestConfig & { headers: V5TriggerCompositeTriggerIdEndpointEndpointIdGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CompositeTriggerForCameraDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            }),
          },
          release: {
            /**
             * PATCH /v5/trigger/composite/{triggerId}/release
             * @summary Release a trigger
             * @param {RequestConfig & { headers: V5TriggerCompositeTriggerIdReleasePatchHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $patch: (config) => null,
          },
          scheme: {
            /** @param {!string} schemeId Scheme to assign the trigger */
            $schemeId: (schemeId) => ({
              /**
               * POST /v5/trigger/composite/{triggerId}/scheme/{schemeId}
               * @summary Assign trigger to a Scheme
               * @param {RequestConfig & { headers: V5TriggerCompositeTriggerIdSchemeSchemeIdPostHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
              /**
               * DELETE /v5/trigger/composite/{triggerId}/scheme/{schemeId}
               * @summary Remove trigger from a scheme
               * @param {RequestConfig & { headers: V5TriggerCompositeTriggerIdSchemeSchemeIdDeleteHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
               */
              $delete: (config) => null,
              exists: {
                /**
                 * GET /v5/trigger/composite/{triggerId}/scheme/{schemeId}/exists
                 * @summary Check trigger is assigned to Scheme
                 * @param {RequestConfig & { headers: V5TriggerCompositeTriggerIdSchemeSchemeIdExistsGetHeaders }} config Axios Request config
                 * @returns {import('@/api').ApiRequest<boolean>} An ApiRequest; to get result call 'process'.
                 */
                $get: (config) => null,
              },
            }),
          },
        }),
      },
      variable: {
        definition: {
          /**
           * GET /v5/trigger/variable/definition
           * @summary Get Trigger Variable Definitions referenced in Trigger Category
           * @param {RequestConfig & { params: V5TriggerVariableDefinitionGetParams, headers: V5TriggerVariableDefinitionGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<VariableDefinitionDto>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v5/trigger/variable/definition
           * @summary Get Trigger Variable Definitions referenced in list of Trigger Category
           * @param {RequestConfig & { data: Array<number>, headers: V5TriggerVariableDefinitionPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<VariableDefinitionsByTriggerCategoryIdListDto>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /** @param {!string} variableName Variable Name */
          $variableName: (variableName) => ({
            /**
             * GET /v5/trigger/variable/definition/{variableName}
             * @summary Get Trigger Variable Definition
             * @param {RequestConfig & { headers: V5TriggerVariableDefinitionVariableNameGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<VariableDefinitionDto>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /**
             * POST /v5/trigger/variable/definition/{variableName}
             * @summary Create Trigger Variable Definition
             * @param {RequestConfig & { data: CompositeTriggerVariableDefinitionRequest, headers: V5TriggerVariableDefinitionVariableNamePostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<VariableDefinitionDto>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
            /**
             * PUT /v5/trigger/variable/definition/{variableName}
             * @summary Update Trigger Variable Definition
             * @param {RequestConfig & { data: CompositeTriggerVariableDefinitionRequest, headers: V5TriggerVariableDefinitionVariableNamePutHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<VariableDefinitionDto>} An ApiRequest; to get result call 'process'.
             */
            $put: (config) => null,
          }),
        },
        override: {
          /**
           * POST /v5/trigger/variable/override
           * @summary Get Trigger Variable Override mapping for list of variables
           * @param {RequestConfig & { data: Array<string>, params: V5TriggerVariableOverridePostParams, headers: V5TriggerVariableOverridePostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<object>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /** @param {!string} variableName Variable Name */
          $variableName: (variableName) => ({
            /**
             * PUT /v5/trigger/variable/override/{variableName}
             * @summary Update Trigger Variable Override
             * @param {RequestConfig & { data: CompositeTriggerVariableOverrideRequest, headers: V5TriggerVariableOverrideVariableNamePutHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $put: (config) => null,
          }),
        },
      },
    },
    'trips': {
      /**
       * GET /v5/trips
       * @summary Get trip list of tenant
       * @param {RequestConfig & { params: V5TripsGetParams, headers: V5TripsGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<TripListResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/trips
       * @summary Post trip event
       * @param {RequestConfig & { data: TripEvent, params: V5TripsPostParams, headers: V5TripsPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<TripCommonResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      /**
       * PATCH /v5/trips
       * @summary update trip's gps data
       * @param {RequestConfig & { data: TripGPS, params: V5TripsPatchParams, headers: V5TripsPatchHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<TripCommonResponse>} An ApiRequest; to get result call 'process'.
       */
      $patch: (config) => null,
      distance: {
        list: {
          /**
           * GET /v5/trips/distance/list
           * @summary getTripDistanceList
           * @param {RequestConfig & { params: V5TripsDistanceListGetParams, headers: V5TripsDistanceListGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DistanceList>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
        },
      },
      driver: {
        authorization: {
          /**
           * POST /v5/trips/driver/authorization
           * @summary Authorize driver for a trip
           * @param {RequestConfig & { data: DriverAuthorizationRequest, headers: V5TripsDriverAuthorizationPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<DriverAuthorizationResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      },
      endpoint: {
        /** @param {!number} endpointId endpointId */
        $endpointId: (endpointId) => ({
          generate: {
            score: {
              /**
               * GET /v5/trips/endpoint/{endpointId}/generate/score
               * @summary Generate All Trip Score for specific endpoint
               * @param {RequestConfig & { params: V5TripsEndpointEndpointIdGenerateScoreGetParams, headers: V5TripsEndpointEndpointIdGenerateScoreGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<PersonaScoreForEndpointDto>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            },
          },
        }),
      },
      generate: {
        /** @param {!string} tripId tripId */
        $tripId: (tripId) => ({
          score: {
            /**
             * GET /v5/trips/generate/{tripId}/score
             * @summary Generate Trip Score
             * @param {RequestConfig & { headers: V5TripsGenerateTripIdScoreGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<DriverAuthorizationResponse>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        }),
      },
      /** @param {!string} tripId Trip id */
      $tripId: (tripId) => ({
        /**
         * GET /v5/trips/{tripId}
         * @summary Get trip details
         * @param {RequestConfig & { params: V5TripsTripIdGetParams, headers: V5TripsTripIdGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<TripDetailsResponse>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        events: {
          /**
           * GET /v5/trips/{tripId}/events
           * @summary Get details of the Events occurred in a Trip
           * @param {RequestConfig & { headers: V5TripsTripIdEventsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<object>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          trigger: {
            /**
             * GET /v5/trips/{tripId}/events/trigger
             * @summary Get details of the Events occurred in a Trip
             * @param {RequestConfig & { headers: V5TripsTripIdEventsTriggerGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<object>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          },
        },
      }),
    },
    'user': {
      /** @param {!number} userId userId */
      $userId: (userId) => ({
        activate: {
          /**
           * POST /v5/user/{userId}/activate
           * @summary Activate a tenant user
           * @param {RequestConfig & { headers: V5UserUserIdActivatePostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EmptyModel>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
        deactivate: {
          /**
           * POST /v5/user/{userId}/deactivate
           * @summary De activate a tenant user
           * @param {RequestConfig & { headers: V5UserUserIdDeactivatePostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<EmptyModel>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
        },
      }),
    },
    'video-minutes': {
      tenants: {
        /** @param {!string} tenantId tenantId */
        $tenantId: (tenantId) => ({
          /**
           * GET /v5/video-minutes/tenants/{tenantId}
           * @summary Sum of the Video minutes consumed by the Tenant’s and child Tenant’s Cameras grouped by Events, Recording playback, and Live-Streaming
           * @param {RequestConfig & { params: V5VideoMinutesTenantsTenantIdGetParams, headers: V5VideoMinutesTenantsTenantIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<Array<TenantVideoMinuteUsages>>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          endpoints: {
            /**
             * GET /v5/video-minutes/tenants/{tenantId}/endpoints
             * @summary List of Endpoints under given the Tenant with the sum of the Video minutes consumed by each of the Endpoints grouped by Events, Recording playback, and Live-Streaming
             * @param {RequestConfig & { params: V5VideoMinutesTenantsTenantIdEndpointsGetParams, headers: V5VideoMinutesTenantsTenantIdEndpointsGetHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<EndpointVideoMinuteUsages>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
            /** @param {!string} endpointId endpointId */
            $endpointId: (endpointId) => ({
              /**
               * GET /v5/video-minutes/tenants/{tenantId}/endpoints/{endpointId}
               * @summary Video minute transactions for the given Endpoint
               * @param {RequestConfig & { params: V5VideoMinutesTenantsTenantIdEndpointsEndpointIdGetParams, headers: V5VideoMinutesTenantsTenantIdEndpointsEndpointIdGetHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<EndpointVideoMinuteTransactions>} An ApiRequest; to get result call 'process'.
               */
              $get: (config) => null,
            }),
          },
        }),
      },
    },
    'vin-groups': {
      /**
       * GET /v5/vin-groups
       * @summary getVinGroups
       * @param {RequestConfig & { params: V5VinGroupsGetParams, headers: V5VinGroupsGetHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<VinGroupGetListResponse>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /v5/vin-groups
       * @summary createVinGroup
       * @param {RequestConfig & { data: VinGroupAddRequest, headers: V5VinGroupsPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<VinGroupAddResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
      count: {
        /**
         * GET /v5/vin-groups/count
         * @summary countVinGroupByTenantId
         * @param {RequestConfig & { params: V5VinGroupsCountGetParams, headers: V5VinGroupsCountGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<CountResponseModel>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      list: {
        /**
         * GET /v5/vin-groups/list
         * @summary Returns a list of vin groups of specific tenant ID.
         * @param {RequestConfig & { params: V5VinGroupsListGetParams, headers: V5VinGroupsListGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<VinGroupInfoAggregated>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
      },
      /** @param {!string} id id */
      $id: (id) => ({
        /**
         * GET /v5/vin-groups/{id}
         * @summary getVinGroup
         * @param {RequestConfig & { headers: V5VinGroupsIdGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<VinGroup>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * DELETE /v5/vin-groups/{id}
         * @summary deleteVinGroup
         * @param {RequestConfig & { headers: V5VinGroupsIdDeleteHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $delete: (config) => null,
        /**
         * PATCH /v5/vin-groups/{id}
         * @summary updateVinGroup
         * @param {RequestConfig & { data: VinGroupUpdateRequest, headers: V5VinGroupsIdPatchHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
         */
        $patch: (config) => null,
        vin: {
          /** @param {!string} vinNumber vinNumber */
          $vinNumber: (vinNumber) => ({
            transfer: {
              /**
               * POST /v5/vin-groups/{id}/vin/{vinNumber}/transfer
               * @summary transferVinToGroup
               * @param {RequestConfig & { data: VinTransferRequest, headers: V5VinGroupsIdVinVinNumberTransferPostHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
            },
          }),
        },
        vins: {
          /**
           * GET /v5/vin-groups/{id}/vins
           * @summary getVinsInGroup
           * @param {RequestConfig & { params: V5VinGroupsIdVinsGetParams, headers: V5VinGroupsIdVinsGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<VinGetListResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * POST /v5/vin-groups/{id}/vins
           * @summary addVinsToGroup
           * @param {RequestConfig & { data: VinAddRequest, headers: V5VinGroupsIdVinsPostHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<VinAddResponse>} An ApiRequest; to get result call 'process'.
           */
          $post: (config) => null,
          /** @param {!string} vin vin */
          $vin: (vin) => ({
            /**
             * DELETE /v5/vin-groups/{id}/vins/{vin}
             * @summary deleteVinFromGroup
             * @param {RequestConfig & { headers: V5VinGroupsIdVinsVinDeleteHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<OkResponse>} An ApiRequest; to get result call 'process'.
             */
            $delete: (config) => null,
          }),
        },
      }),
    },
    'vin-groups-test': {
      /**
       * POST /v5/vin-groups-test
       * @summary createVinGroup
       * @param {RequestConfig & { data: VinGroupAddRequest, headers: V5VinGroupsTestPostHeaders }} config Axios Request config
       * @returns {import('@/api').ApiRequest<VinGroupAddResponse>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    'webhook-manager': {
      tenant: {
        /** @param {!string} tenantId Tenant Id */
        $tenantId: (tenantId) => ({
          webhooks: {
            /**
             * POST /v5/webhook-manager/tenant/{tenantId}/webhooks
             * @summary Enroll a new webhook with url
             * @param {RequestConfig & { data: WebhookRequest, headers: V5WebhookManagerTenantTenantIdWebhooksPostHeaders }} config Axios Request config
             * @returns {import('@/api').ApiRequest<WebhookResponse>} An ApiRequest; to get result call 'process'.
             */
            $post: (config) => null,
          },
        }),
      },
      tenants: {
        /**
         * POST /v5/webhook-manager/tenants
         * @summary Enroll a tenant to webhook system
         * @param {RequestConfig & { data: WebhookTenantRequest, headers: V5WebhookManagerTenantsPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<WebhookTenantResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /** @param {!string} tenantId Tenant Id */
        $tenantId: (tenantId) => ({
          /**
           * GET /v5/webhook-manager/tenants/{tenantId}
           * @summary Get a tenant's webhook list with all topics
           * @param {RequestConfig & { headers: V5WebhookManagerTenantsTenantIdGetHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<WebhookTenantResponse>} An ApiRequest; to get result call 'process'.
           */
          $get: (config) => null,
          /**
           * DELETE /v5/webhook-manager/tenants/{tenantId}
           * @summary Disable a webhook tenant
           * @param {RequestConfig & { headers: V5WebhookManagerTenantsTenantIdDeleteHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CommonWebhookResponse>} An ApiRequest; to get result call 'process'.
           */
          $delete: (config) => null,
          webhooks: {
            /** @param {!number} webhookId Webhook Id */
            $webhookId: (webhookId) => ({
              /**
               * DELETE /v5/webhook-manager/tenants/{tenantId}/webhooks/{webhookId}
               * @summary Disable a webhook tenant
               * @param {RequestConfig & { headers: V5WebhookManagerTenantsTenantIdWebhooksWebhookIdDeleteHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CommonWebhookResponse>} An ApiRequest; to get result call 'process'.
               */
              $delete: (config) => null,
            }),
          },
        }),
      },
      topics: {
        /**
         * GET /v5/webhook-manager/topics
         * @summary Get all topics
         * @param {RequestConfig & { headers: V5WebhookManagerTopicsGetHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<Array<WebhookTopicResponse>>} An ApiRequest; to get result call 'process'.
         */
        $get: (config) => null,
        /**
         * POST /v5/webhook-manager/topics
         * @summary Add a new webhook topic
         * @param {RequestConfig & { data: WebhookTopicRequest, headers: V5WebhookManagerTopicsPostHeaders }} config Axios Request config
         * @returns {import('@/api').ApiRequest<WebhookTopicResponse>} An ApiRequest; to get result call 'process'.
         */
        $post: (config) => null,
        /** @param {!number} topicId Topic Id */
        $topicId: (topicId) => ({
          /**
           * PUT /v5/webhook-manager/topics/{topicId}
           * @summary Update an existing webhook topic
           * @param {RequestConfig & { data: WebhookTopicRequest, headers: V5WebhookManagerTopicsTopicIdPutHeaders }} config Axios Request config
           * @returns {import('@/api').ApiRequest<CommonWebhookResponse>} An ApiRequest; to get result call 'process'.
           */
          $put: (config) => null,
        }),
      },
      webhooks: {
        /** @param {!number} webhookId Webhook Id */
        $webhookId: (webhookId) => ({
          topics: {
            /** @param {!number} topicId Topic Id */
            $topicId: (topicId) => ({
              /**
               * POST /v5/webhook-manager/webhooks/{webhookId}/topics/{topicId}
               * @summary Assign a topic to a webhook
               * @param {RequestConfig & { headers: V5WebhookManagerWebhooksWebhookIdTopicsTopicIdPostHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CommonWebhookResponse>} An ApiRequest; to get result call 'process'.
               */
              $post: (config) => null,
              /**
               * DELETE /v5/webhook-manager/webhooks/{webhookId}/topics/{topicId}
               * @summary Revoke a topic from a webhook
               * @param {RequestConfig & { headers: V5WebhookManagerWebhooksWebhookIdTopicsTopicIdDeleteHeaders }} config Axios Request config
               * @returns {import('@/api').ApiRequest<CommonWebhookResponse>} An ApiRequest; to get result call 'process'.
               */
              $delete: (config) => null,
            }),
          },
        }),
      },
    },
  },
  'vision': {
    search: {
      /** @param {!string} keyword Keyword */
      $keyword: (keyword) => ({
        page: {
          /** @param {!string} pageId Page ID. */
          $pageId: (pageId) => ({
            /**
             * GET /vision/search/{keyword}/page/{pageId}
             *
             * Get operation will return a list of vision event entry with all the meta data sorted by timestamp. It also supports pagination.Pagination will always return at most 10 entries.   *Note that,* 1. if the keyword field contains the word \"person\" then the API will only return the list of person identified from the specific endpoint.  2. if the keyword field contains  the word \"things\" then the API will return the list of things identified from the specific endpoint. 3. if the pageid filed contains the number \"1\" then the API will reset the list to the first 10 vision entry.
             * @summary This API will be used to search the historical vision events
             * @param {RequestConfig & { params: VisionSearchKeywordPagePageIdGetParams }} config Axios Request config
             * @returns {import('@/api').ApiRequest<Array<VisionSearchResult>>} An ApiRequest; to get result call 'process'.
             */
            $get: (config) => null,
          }),
        },
      }),
    },
    snapshots: {
      /**
       * POST /vision/snapshots
       *
       * This API will upload jpeg images to the vision microservice for analysis. Successful analysis will generate event to the ACL endpoints using the event microservice.
       * @summary Upload images as formdata
       * @param {RequestConfig & { data: VisionSnapshotsPostBody, params: VisionSnapshotsPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<Array<IdentifyVal>>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    tracking: {
      /**
       * GET /vision/tracking
       * @summary Operation will return the list of tracking entry with the pagination.
       * @param {RequestConfig & { params: VisionTrackingGetParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<Array<TrackingResult>>} An ApiRequest; to get result call 'process'.
       */
      $get: (config) => null,
      /**
       * POST /vision/tracking
       *
       * This API will upload jpeg images to the vision service for person re-identification or object tracking. Successful analysis will generate an ID.
       * @summary Upload images as form-data to track a person or an object
       * @param {RequestConfig & { data: VisionTrackingPostBody, params: VisionTrackingPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<Array<Identity>>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
    trackperson: {
      /**
       * POST /vision/trackperson
       *
       * This API will upload jpeg images to the vision service for person re-identification. Successful analysis will generate a person ID.
       * @summary Upload images as form-data to track a person
       * @param {RequestConfig & { data: VisionTrackpersonPostBody, params: VisionTrackpersonPostParams }} config Axios Request config
       * @returns {import('@/api').ApiRequest<Array<Personidentity>>} An ApiRequest; to get result call 'process'.
       */
      $post: (config) => null,
    },
  },
};
