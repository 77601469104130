import { USER_FALLBACK_ICON } from '@/assets/constants/images';
import { store } from '@/store';
import { Auth, selectCurrentTenantName, selectUserAvatar } from '@/store/auth';
import { takeElementSnapshot } from '@/utils/canvas';
import { BoxImage } from '@/web/@components/BoxImage';
import { useSelector } from 'react-redux';

export function UserAvatar() {
  const userAvatar = useSelector(selectUserAvatar);
  const tenantName = useSelector(selectCurrentTenantName);

  /** @param {import('react').SyntheticEvent<HTMLImageElement>} e */
  const handleLoad = (e) => {
    const img = e.currentTarget;
    if (img.src === USER_FALLBACK_ICON) return;
    if (img.naturalWidth === 96 || img.naturalHeight === 96) return;
    const dataUrl = takeElementSnapshot(img, 96, 96);
    if (dataUrl) {
      store.dispatch(Auth.setAvatar(dataUrl));
    }
  };

  return (
    <BoxImage
      width="100%"
      height="100%"
      borderRadius="50%"
      crossOrigin="anonymous"
      referrerPolicy="no-referrer"
      src={userAvatar}
      title={tenantName}
      fallbackSrc={USER_FALLBACK_ICON}
      onLoad={handleLoad}
    />
  );
}
