import { CenterBox } from '@/web/@components/CenterBox';
import { Box } from '@mui/material';
import { GuidePageContainer } from '../GuidePageContainer';
import { GuidePowerAndInterfacesTable } from './GuidePowerAndInterfacesTable';
import { GuideRFInterfacesTable } from './GuideRFInterfacesTable';

export function GuideQuickSpecificationsArea() {
  return (
    <CenterBox id="quick-specifications-area" textAlign="left">
      <GuidePageContainer flexDirection="column" alignItems={null}>
        <Box fontSize="1.5rem" fontWeight="600" mt={3} mb={2}>
          Smarter AI Dashcam Quick Specifications
        </Box>
        <GuidePowerAndInterfacesTable />
        <Box py={1} />
        <GuideRFInterfacesTable />
        <Box py={2} />
      </GuidePageContainer>
    </CenterBox>
  );
}
