import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { convertTimeToSeconds } from '@/utils/geofence';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GeofenceForm } from '../../@components/GeoFenceForm';

export function UpdateGeofencePage() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();
  const params = useParams();

  const geofenceId = useMemo(() => params.id, [params]);

  const [loading, setLoading] = useState(false);

  /** @type {import('../../@components/GeoFenceForm').GeofenceFormProps['onSubmitData']} */
  const updateGeofence = async (form) => {
    try {
      setLoading(true);
      /** @type {UpdateGeofenceWithScheduleAndDeploymentDetailsRequest} */
      const data = {
        geofenceEditRequest: {
          active: true,
          description: form.description,
          latitude: form.latitude,
          longitude: form.longitude,
          name: form.name,
          radius: form.radius,
          tenantId: tenantId,
          timezone: form.timezone?.value,
        },
        scheduleAndDeploymentDetailsRequest: {
          aiContainerIdToDeploy: form.aiContainer?.containerId,
          attachedVinList: form.vins,
          geofenceScheduleEditRequest: {
            id: form.scheduleId,
            dayOfMonthStart: 1,
            dayOfMonthEnd: 31,
            dayOfWeekStart: 0,
            dayOfWeekEnd: 6,
            daySecondStart: convertTimeToSeconds(form.daySecondStart), //sec
            daySecondEnd: convertTimeToSeconds(form.daySecondEnd), // sec
            startTimestamp: form.startTimestamp, //range in epoch milli
            endTimestamp: form.endTimestamp, //range in epoch milli
            monthStart: 1,
            monthEnd: 12,
            name: `schedule-${form.name}`,
            yearStart: 2000,
            yearEnd: 3000,
          },
          triggerIdListToDeploy: form.triggers.map((item) => item.id),
        },
      };

      const req = await api.ac.v3.geolocation.fence.$id(geofenceId).$patch({
        headers: {
          Authorization: secretToken,
        },
        data,
      });
      await req.process();
      toastSuccess('Success', 'Geofence Update Successfully');
      navigate('/operations/geofences');
    } catch (err) {
      toastWarning('Error', 'Failed to update geofence');
    } finally {
      setLoading(false);
    }
  };

  // Navigate Geofence list page while tenant change
  useEffect(() => {
    return () => navigate('/operations/geofences');
  }, [tenantId, navigate]);

  return (
    <Box m={2.5}>
      <GeofenceForm onSubmitData={updateGeofence} loading={loading} />
    </Box>
  );
}
