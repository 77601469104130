import { ErrorBoundary } from '@/web/@components/ErrorBoundary';
import { Outlet } from 'react-router-dom';

export function EmptyLayout() {
  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
}
