export const SELECTION_TYPE = {
  DATE: 1,
  CAMERA: 2,
  DRIVER: 3,
  SCORE: 4,
  PERSONA: 5,
};

//Here hide for will used to hide filter dropdown item for pages
export const SCORING_FILTER_MENU_ITEMS = [
  {
    type: SELECTION_TYPE.DATE,
    label: 'Date',
  },
  {
    type: SELECTION_TYPE.SCORE,
    label: 'Score',
    hideDropDownFor: 4,
    hideCloseButtonFor: 104,
  },
  {
    type: SELECTION_TYPE.PERSONA,
    label: 'Persona',
    hideDropDownFor: 3,
    hideCloseButtonFor: 103,
    hideFilteredItem: 203,
  },
  {
    type: SELECTION_TYPE.CAMERA,
    label: 'Camera',
    hideDropDownFor: 1,
  },
  {
    type: SELECTION_TYPE.DRIVER,
    label: 'Driver',
    hideDropDownFor: 2,
  },
];

export const MULTI_FILED_ITEMS = [SELECTION_TYPE.DATE, SELECTION_TYPE.SCORE];

export const MULTI_SELECTION_ITEMS = [
  SELECTION_TYPE.CAMERA,
  SELECTION_TYPE.DRIVER,
  SELECTION_TYPE.PERSONA,
];
