import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiAcceleratorForm } from '../@components/AiAcceleratorForm';

export function CreateAiAcceleratorPage() {
  const navigate = useNavigate();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [submitting, setSubmitting] = useState(false);

  /** @param {import('../@components/AiAcceleratorForm').AIAcceleratorFields} form */
  const handleSubmit = async (form) => {
    const request = api.ac.v1.tenant.aiaccelerator.$post({
      params: {
        tenantId,
        secretToken,
      },
      data: {
        ...form,
      },
    });
    try {
      setSubmitting(true);
      await request.process();
      toastSuccess('Success', 'New AI Accelerator created');
      navigate('..');
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Could not create the AI Accelerator');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Create AI Accelerator
      </Typography>
      <Box height={'20px'} />
      <AiAcceleratorForm onSubmit={handleSubmit} disabled={submitting} />
    </Box>
  );
}
