import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { isCameraInParkingMode } from '@/utils/cameras';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ACTION_ITEMS } from './actions';
import { CAMERA_TABLE_COLUMNS } from './columns';

/** @type {SmartCache<Array<EndpointInfoAggregated>>} */
const cameraList = new SmartCache('v5.endpoint.list');

export function CameraAssociatesFirmwares() {
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [cameras, setCameras] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCameraFirmwares = useCallback(
    async (camera) => {
      const request = api.ac.v1.endpoint.devices
        .$deviceSerialNumber(camera.deviceSerialNo)
        .firmwareversion.$get({
          headers: {
            Authorization: secretToken,
          },
        });

      const data = await request.process();

      const result = {};
      data?.firmwareVersions.forEach((item) => {
        result[item.firmwareType] = item.firmwareVersion;
      });
      return result;
    },
    [secretToken]
  );

  useEffect(() => {
    setLoading(true);
    cameraList.getItem(tenantId).then((items) => {
      setCameras(items);
      return;
    });

    (async () => {
      let offset = 0;
      const limit = 100;
      /** @type {Array<EndpointInfoAggregated>} */
      const results = [];
      while (true) {
        const request = api.ac.v5.endpoint.list.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            type: 'DEVICE',
            status: 'ACTIVE',
            limit,
            offset,
            tenantId,
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result.endpointInfoList;
        result.forEach((item) => {
          item.deviceOnlineStatus &&= !isCameraInParkingMode(item.parkingStatus);
        });
        results.push(...result);
        if (result.length < limit) break;
        offset += limit;
      }
      results.sort((a, b) => {
        if (a.deviceOnlineStatus === b.deviceOnlineStatus) {
          if (a.deviceOnlineStatus || a.parkingStatus === b.parkingStatus) {
            return a.deviceLabel.localeCompare(b.deviceLabel);
          }
          if (a.parkingStatus && b.parkingStatus) {
            return a.parkingStatus.localeCompare(b.parkingStatus);
          }
          return a.parkingStatus ? -1 : 1;
        }
        return a.deviceOnlineStatus ? -1 : 1;
      });

      await Promise.all(
        results.map(async (item) => {
          const firmwares = await getCameraFirmwares(item);
          Object.entries(firmwares).forEach(([key, value]) => {
            item[key] = value;
          });
        })
      );

      setCameras(results);
    })()
      .catch((err) => {
        console.error('Failed to load camera', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [tenantId, secretToken, getCameraFirmwares]);

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={'Cameras'}
          // @ts-ignore
          actions={ACTION_ITEMS}
        />
        <ContinuationTokenTable
          loading={loading}
          results={cameras}
          columns={CAMERA_TABLE_COLUMNS}
          //dataSortKey="deviceLabel"
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
