import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

export function GuideCableKitECUTable() {
  return (
    <Box width="100%" style={{ overflowX: 'auto' }}>
      <Typography fontSize="1.125rem" fontWeight="600" py={1}>
        ECU Cable Kit
      </Typography>
      <Table padding="none">
        <TableHead
          sx={{
            '& .MuiTableCell-root': {
              px: 2,
              py: 1,
              color: '#fff',
              fontWeight: '600',
              border: 'none',
            },
          }}
        >
          <TableRow style={{ background: '#596A81' }}>
            <TableCell>Wire</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Connect to</TableCell>
            <TableCell>Connection</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-root': {
              px: 2,
              py: 1,
              border: '1px solid #DCE7F5',
              borderTop: 'none',
            },
          }}
        >
          <TableRow>
            <TableCell>ACC</TableCell>
            <TableCell style={{ background: '#AD4343', borderColor: '#AD4343', color: '#fff' }}>
              Red
            </TableCell>
            <TableCell>Accessory/Ignition</TableCell>
            <TableCell rowSpan={2}>Mandatory</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>GND</TableCell>
            <TableCell style={{ background: '#000000', borderColor: '#000000', color: '#fff' }}>
              Black
            </TableCell>
            <TableCell>Ground</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>REVERSE</TableCell>
            <TableCell style={{ background: '#CE7428', borderColor: '#CE7428', color: '#fff' }}>
              Orange
            </TableCell>
            <TableCell>Reverse</TableCell>
            <TableCell rowSpan={2}>Dead Reckoning</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SPEED PULSE</TableCell>
            <TableCell style={{ background: '#32649D', borderColor: '#32649D', color: '#fff' }}>
              Blue
            </TableCell>
            <TableCell>Speed Pulse</TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={3}>INPUT</TableCell>
            <TableCell style={{ background: '#DED342', borderColor: '#DED342' }}>Yellow</TableCell>
            <TableCell rowSpan={3}>Digital Input (5V)</TableCell>
            <TableCell rowSpan={6}>Optional</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ background: '#7A61B2', borderColor: '#7A61B2', color: '#fff' }}>
              Purple
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ background: '#624E36', borderColor: '#624E36', color: '#fff' }}>
              Brown
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>OUTPUT</TableCell>
            <TableCell style={{ background: '#899398', borderColor: '#899398', color: '#fff' }}>
              Grey
            </TableCell>
            <TableCell>Digital Output (3.3V)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>RS232 TX OUT</TableCell>
            <TableCell style={{ background: '#397C41', borderColor: '#397C41', color: '#fff' }}>
              Green
            </TableCell>
            <TableCell>RS232 TX OUT</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>RS232 RX IN</TableCell>
            <TableCell style={{ background: '#ffffff', borderTopColor: '#ffffff' }}>
              White
            </TableCell>
            <TableCell>RS232 RX In (from vehicle)</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
