import { selectTenantId } from '@/store/auth';
import { formatCameraName } from '@/utils/cameras';
import { ShareUrlButton } from '@/web/@components/ShareUrlButton';
import { Box, Typography } from '@mui/material';
import qs from 'qs';
import { useSelector } from 'react-redux';

/** @param {{item: Trip}} props */
export function TripListViewItemHeader(props) {
  const { item } = props;

  const tenantId = useSelector(selectTenantId);
  const tripShareUrl = `${window.location.origin}/trips?${qs.stringify({
    tenantId,
    id: item.id,
    from: item.startTimestamp - 1,
    to: item.startTimestamp + 1,
  })}`;

  return (
    <Box
      px={2}
      py={1}
      display="flex"
      justifyContent="space-between"
      pr={item.tripStatus === 'STARTED' ? 3 : 1}
    >
      <Typography component="span" fontSize={'0.875rem'} fontWeight="medium">
        {formatCameraName(item.endpointName)}
      </Typography>
      <ShareUrlButton
        url={tripShareUrl}
        className="hover-item"
        title="Share Trip"
        size="small"
        sx={{
          opacity: 0,
          ml: '10px',
          mt: '-2px',
          width: '24px',
          height: '24px',
          transition: 'opacity 0.2s ease-in',
        }}
      />
    </Box>
  );
}
