import api from '@/api';
import { useFetchTriggerCategories } from '@/hooks/useFetchTriggerCategories';
import { selectSecretToken } from '@/store/auth';
import { flatten, uniq } from 'lodash';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const TriggerConfigurationContext = createContext(null);

export const TriggerConfigurationProvider = ({ children }) => {
  const secretToken = useSelector(selectSecretToken);

  const { result: triggerCategories, loading: categoryLoading } = useFetchTriggerCategories();

  const [variablesDefinition, setVariableDefinition] = useState([]);
  const [selectionType, setSelectionType] = useState(null);
  const [parentLoading, setParentLoading] = useState(true);
  const [triggers, setTriggers] = useState(true);

  const triggerCategoryIds = useMemo(
    () => uniq(triggerCategories?.map((i) => i?.id)),
    [triggerCategories]
  );

  const getVariableConstantsDetails = useCallback(
    async (signal) => {
      try {
        if (!triggerCategoryIds?.length || categoryLoading) return;
        const variableDefinitionRequests = api.ac.v5.trigger.variable.definition.$post({
          signal,
          headers: {
            Authorization: secretToken,
          },
          data: triggerCategoryIds,
        });
        const variableDefinitionResponse = await variableDefinitionRequests.process();

        const customerLabelVariables = flatten(
          variableDefinitionResponse.values?.map((i) =>
            i.variableDefinitions
              .filter((y) => y.customerFacing)
              .map((y) => ({ ...y, triggerCategoryId: i.triggerCategoryId }))
          )
        );
        setVariableDefinition(customerLabelVariables);
        setParentLoading(false);
      } catch (ex) {}
    },
    [secretToken, triggerCategoryIds, categoryLoading]
  );

  useEffect(() => {
    const abortController = new AbortController();
    getVariableConstantsDetails(abortController.signal);
    return () => abortController.abort();
  }, [getVariableConstantsDetails]);

  return (
    <TriggerConfigurationContext.Provider
      value={{
        triggerCategories,
        variablesDefinition,
        setSelectionType,
        selectionType,
        parentLoading: parentLoading || categoryLoading,
        triggers,
        setTriggers,
      }}
    >
      {children}
    </TriggerConfigurationContext.Provider>
  );
};
