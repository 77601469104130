import { CustomSwitch } from '@/web/@components/CustomForm';
import { Box, Popover, Typography } from '@mui/material';
import { useState } from 'react';

export function TriggerActivateSwitch({ trigger }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <CustomSwitch
        name={`isEnabled-${trigger.id}`}
        readOnlyValue={trigger?.publicationStatus === 'RELEASED'}
        label=""
        SwitchProps={{ disabled: true }}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(open && trigger?.publicationStatus !== 'RELEASED')}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box m={1} display="grid">
          <Typography variant="caption">Enable this trigger from the Scheme</Typography>
        </Box>
      </Popover>
    </Box>
  );
}
