import { isExactMatch } from '@/utils/routing';
import { isFunction, startCase } from 'lodash';
import { matchRoutes } from 'react-router-dom';

/**
 * @typedef {object} BreadcrumbItem
 * @property {string} name The name of the breadcrumb
 * @property {string} pathname The pathname to forward to
 * @property {boolean} active Whether this is the current page
 */

/**
 * Get a list of breadcrumb items to display.
 * To recognize a path as a breadcrumb, it must contain breadcrumb field.
 * @param {Array<import('@/types').ExtendedRoute>} routes
 * @param {string} currentPathname
 * @returns {Array<BreadcrumbItem>}
 */
export function getBreadcrumbs(routes, currentPathname) {
  const paths = (currentPathname || '/').substring(1).split('/');

  /** @type {Array<BreadcrumbItem>} */
  let results = [];

  let path = '';
  let hasActive = false;
  for (const part of paths) {
    path += '/' + part;

    let match = null;
    for (const m of matchRoutes(routes, path) || []) {
      if (m.pathname === path) {
        match = m;
        break;
      }
    }
    if (!match) continue;

    /** @type {import('@/types').ExtendedRoute} */
    const route = match.route;

    let name = '';
    if (isFunction(route.breadcrumb)) {
      name = route.breadcrumb();
    } else if (route.breadcrumb) {
      name = '' + route.breadcrumb;
    }
    if (!name) continue;

    const active = isExactMatch(currentPathname, match.pathname);
    if (active) hasActive = true;

    results.push({
      name,
      active,
      pathname: match.pathname,
    });
  }

  if (!hasActive) {
    const namedPaths = paths.filter(Boolean);
    let name = startCase(namedPaths[namedPaths.length - 1]) || 'Home';
    results.push({
      name,
      active: true,
      pathname: currentPathname,
    });
  }

  return results;
}
