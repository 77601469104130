import { isDev } from '@/config';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { Box } from '@mui/material';
import { HubConnectionProvider } from '../@components/HubConnectionContext';
import { TripContextProvider } from './@components/TripContext';
import { TripFilteringArea } from './@components/TripFilterArea';
import { TripFilterContextProvider } from './@components/TripFilterContext';
import { TripListContainer } from './@components/TripListContainer';
import { TripReportButton } from './@components/TripReportButton';
import { TripsCountHeader } from './@components/TripsCountHeader';

export function TripsPage() {
  const { query } = useRouteQuery();

  if (query.layout === 'empty') {
    return (
      <HubConnectionProvider>
        <TripFilterContextProvider>
          <TripContextProvider>
            <TripListContainer />
          </TripContextProvider>
        </TripFilterContextProvider>
      </HubConnectionProvider>
    );
  }

  return (
    <HubConnectionProvider>
      <TripFilterContextProvider>
        <TripContextProvider>
          <Box px={2.5} mt={1} display="flex" gap={1} alignItems="center">
            <TripsCountHeader />
            <Box flex={1} />
            {isDev && <TripReportButton />}
          </Box>
          <Box px={2} mt={0.5}>
            <TripFilteringArea />
          </Box>
          <TripListContainer />
        </TripContextProvider>
      </TripFilterContextProvider>
    </HubConnectionProvider>
  );
}
