import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CustomInput } from '@/web/@components/CustomForm';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} AnnotationFormProps
 * @property {AnnotationCategory} [item]
 * @property  {() => any} requestRefresh
 * @property  {() => any} [onHide]
 */

/**
 * @param {AnnotationFormProps} props
 */
export function AnnotationForm(props) {
  const { item, requestRefresh, onHide } = props;

  const { t } = useTranslation();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const defaultValues = useMemo(
    () => ({
      name: item?.name || '',
      description: item?.description || '',
    }),
    [item]
  );

  const form = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  /** @param {typeof defaultValues} data */
  const handleAdd = async (data) => {
    try {
      const request = api.ac.v5['annotation-category'].$post({
        headers: {
          Authorization: `Bearer ${secretToken}`,
        },
        data: {
          tenantId,
          ...data,
        },
      });
      await request.process();
      toastSuccess('Success', 'Annotation category added');
      requestRefresh();
      onHide && onHide();
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Could not add the annotation category');
    }
  };

  /** @param {typeof defaultValues} data */
  const handleEdit = async (data) => {
    try {
      const request = api.ac.v5['annotation-category'].$id(item.id).$patch({
        headers: {
          Authorization: `Bearer ${secretToken}`,
        },
        data: {
          ...item,
          ...data,
        },
      });
      await request.process();
      toastSuccess('Success', 'Annotation category updated');
      requestRefresh();
      onHide && onHide();
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Could not update the annotation category');
    }
  };

  /** @param {typeof defaultValues} data */
  const handelOnSubmit = (data) => {
    data.name = trimString(data.name);
    data.description = trimString(data.description);
    if (!item) {
      handleAdd(data);
    } else {
      handleEdit(data);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onHide}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handelOnSubmit)} autoComplete="off" noValidate>
          <DialogTitle id="alert-dialog-title">{item ? 'Update' : 'Create'} Annotation</DialogTitle>
          <Divider />
          <DialogContent>
            <CustomInput
              name="name"
              label="Name *"
              placeholder="Please enter a name"
              rules={{ required: 'This is required' }}
            />
            <CustomInput
              name="description"
              label="Description"
              placeholder="Please enter a description"
              minRows={3}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={onHide}>Cancel</Button>
            <Button variant="contained" type="submit">
              {item ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
