import { BOX_MODIFY_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<AIAccelerator>>} */
export const ACTION_ITEMS = [
  // {
  //   type: 'DISCONTINUE',
  //   label: T['button.delete'],
  //   component: 'BUTTON',
  //   iconImage: BOX_DELETE_ICON,
  //   variant: 'outlined',
  //   confirmationMessage: 'Are you sure to delete this AI Accelerator?',
  //   hidden: (selected) => !selected.length,
  // },
  {
    type: 'MODIFY',
    label: T['button.modify'],
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    link: (selectedItem) => `/smarter-ai/ai-accelerator/${selectedItem.codeName}/edit`,
    hidden: (selected) => !selected.length || selected.length > 1,
  },
  {
    type: 'CREATE',
    label: 'AI Accelerator',
    component: 'BUTTON',
    variant: 'contained',
    icon: AddOutlined,
    link: '/smarter-ai/ai-accelerator/create',
  },
];
