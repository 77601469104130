import { T } from '@/assets/locales';
import { trimString } from '@/utils/formatting';
import { CustomInput } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {object} AIAcceleratorFields
 * @property {string} codeName
 * @property {string} description
 * @property {string} name
 * @property {string} type
 * @property {string} vendor
 */

/**
 * @typedef {object} AiAcceleratorFormProps
 * @property {boolean} [disabled]
 * @property {AIAccelerator} [item]
 * @property {import('react-hook-form').SubmitHandler<AIAcceleratorFields>} onSubmit
 */

/**
 * @param {AiAcceleratorFormProps} props
 */
export function AiAcceleratorForm(props) {
  const { onSubmit, disabled, item } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  /** @type {import('react-hook-form').UseFormReturn<AIAcceleratorFields>} */
  const form = useForm({
    mode: 'all',
    defaultValues: {
      codeName: item?.codeName || '',
      description: item?.description || '',
      name: item?.name || '',
      type: item?.type || 'default',
      vendor: item?.vendor || '',
    },
  });

  useEffect(() => {
    if (!item) return;
    form.setValue('codeName', item?.codeName || '');
    form.setValue('description', item?.description || '');
    form.setValue('name', item?.name || '');
    form.setValue('type', item?.type || '');
    form.setValue('vendor', item?.vendor || '');
  }, [item, form]);

  const handleCancel = () => {
    navigate('/smarter-ai/ai-accelerator');
  };

  /** @param {AIAcceleratorFields} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmit) return;
    data.codeName = trimString(data.codeName);
    data.description = trimString(data.description);
    data.name = trimString(data.name);
    data.type = trimString(data.type);
    data.vendor = trimString(data.vendor);
    onSubmit(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="codeName"
              label={'Code Name *'}
              disabled={Boolean(item)}
              placeholder={'Enter code name'}
              rules={{ required: 'Code name is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="name"
              label={'Name *'}
              placeholder={'Enter name'}
              rules={{ required: 'Name is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="type"
              label={'Type *'}
              placeholder={'Enter type'}
              rules={{ required: 'Type is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="vendor"
              label={'Vendor *'}
              placeholder={'Enter vendor'}
              rules={{ required: 'Vendor is required' }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <CustomInput
              name="description"
              label={'Description'}
              placeholder={'Enter Product description'}
              minRows={3}
            />
          </Grid>
        </Grid>
        <Box height="30px" />
        <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'}>
          <Button variant="text" onClick={handleCancel}>
            {t(T['button.cancel'])}
          </Button>
          <Button disabled={disabled} variant="contained" type="submit">
            {disabled && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
            {item ? t(T['button.update']) : t(T['button.save'])}
          </Button>
        </Box>
        <Box height="50px" />
      </form>
    </FormProvider>
  );
}
