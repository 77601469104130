import { isObject } from 'lodash';

/**
 * Searches scheme items in a JSON array based on a given search term.
 * @param {Array} json - The JSON array containing scheme items.
 * @param {string} searchTerm - The term to search for.
 * @returns {Array} - An array of matching scheme items.
 */
export function searchSchemeItems(json, searchTerm) {
  const searchTermLower = searchTerm?.toLowerCase();
  const results = [];

  for (const field of json) {
    const labelsSet = new Set(field.item.map((i) => i.label.toLowerCase()));
    const value = (isObject(field.value) ? field.value.label : field.value) + '';
    const lowercaseValue = value?.toLowerCase();

    if (
      Array.from(labelsSet).some((label) => label.includes(searchTermLower)) ||
      lowercaseValue.includes(searchTermLower)
    ) {
      results.push(field);
    }
  }

  return results;
}
