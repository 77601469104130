/**
 * @typedef {(data?: any) => any} Handler
 */

/**
 * @template T
 * @type {Listenable<T>}
 */
export class Listenable {
  /** @type {{[key: string]: Array<Handler>}} */
  #listeners = {};

  /**
   * Add an event handler
   * @param {T} name
   * @param {Handler} handler
   */
  on(name, handler) {
    const event = name + '';
    this.#listeners[event] ||= [];
    this.#listeners[event].push(handler);
  }

  /**
   * Remove an event handler
   * @param {T} name
   * @param {Handler} [handler]
   */
  off(name, handler) {
    const event = name + '';
    this.#listeners[event] = this.#listeners[event]?.filter((x) => x !== handler);
  }

  /**
   * Prepare the event and propagate it to all listeners
   * @param {T} name
   * @param {any} [data]
   */
  $invoke = (name, data) => {
    const event = name + '';
    this.#listeners[event]?.forEach((handler, index) => {
      setTimeout(() => handler(data), index + 1);
    });
  };
}
