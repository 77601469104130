import { CenterBox } from '@/web/@components/CenterBox';

/** @param {import('@mui/material').BoxProps} props */
export function GuidePageContainer(props) {
  return (
    <CenterBox
      p={2.5}
      gap={1}
      maxWidth="1300px"
      position="relative"
      flexDirection={{ xs: 'column', md: 'row' }}
      {...props}
    />
  );
}
