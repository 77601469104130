import { USER_FALLBACK_ICON } from '@/assets/constants/images';
import { formatDateTimeWeek } from '@/utils/datetime';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Typography } from '@mui/material';

/**
 * @typedef {object} UserNoteBoxProps
 * @property {string} avatar
 * @property {string} username
 * @property {string} note
 * @property {number} time
 */

/** @param {UserNoteBoxProps} props */
export function UserNoteBox(props) {
  const { avatar, username, note, time } = props;
  return (
    <Box
      p={2}
      borderRadius={2}
      border="1px solid #C5D9F0"
      display="flex"
      alignItems="center"
      gap={1.5}
      position="relative"
    >
      <Box>
        <BoxImage
          size="48px"
          height="100%"
          borderRadius="50%"
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
          src={avatar}
          fallbackSrc={USER_FALLBACK_ICON}
        />
      </Box>
      <Box flex={1}>
        <Typography variant="body1" fontWeight={500}>
          {username}
        </Typography>
        <Typography variant="subtitle2">{note}</Typography>
      </Box>
      <Typography variant="subtitle1" position="absolute" top={'5px'} right={'15px'}>
        {formatDateTimeWeek(time)}
      </Typography>
    </Box>
  );
}
