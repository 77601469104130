import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DriverInfo } from '../@components/DriverInfo';
import { ScoreByPersonaWidget } from '../@components/ScoreByPersonaWidget';
import { TriggerDetailsByType } from '../@components/TriggerDetailsByType';
import { ScoringFilterContext } from '../@context/ScoringFilterContext';
import { differenceInDays, parseISO, subDays } from 'date-fns';

export function DriverDetails() {
  const { id } = useParams();

  const { setBreadcrumbTitle } = useContext(MainContext);
  const { filteredData, setSelectedFilterTypes } = useContext(ScoringFilterContext);

  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<Driver>} */
  const [driver, setDriver] = useState(null);
  /** @type {StateVariable<Array<DriverScoreDto & {prevScore?: number}>>} */
  const [driverPersonaScores, setDriverPersonaScores] = useState([]);
  /** @type {StateVariable<number>} */
  const [selectedPersonaId, setSelectedPersonaId] = useState(1);

  const handleSelectPersona = (personaId) => {
    setSelectedPersonaId(personaId);
  };

  const fetchDriverDetails = useCallback(
    async (signal) => {
      try {
        if (!id) return;
        const request = api.ac.v5.driver.$driverId(Number(id)).$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
        });
        const result = await request.process();
        setDriver(result?.result);
      } catch (ex) {
        console.error(ex);
      }
    },
    [secretToken, id]
  );

  const fetchDriverScoreByPersona = useCallback(
    async (signal, isPrevious = false) => {
      try {
        if (!id) return;

        const daysDiff = isPrevious
          ? differenceInDays(
              endOfTheDay(filteredData?.dateRange?.to),
              startOfTheDay(filteredData?.dateRange?.from)
            )
          : 0;

        const fromDate = parseISO(filteredData?.dateRange?.from);

        const startDate = isPrevious
          ? startOfTheDay(subDays(fromDate, daysDiff + 1))
          : startOfTheDay(fromDate);

        const endDate = isPrevious
          ? endOfTheDay(subDays(fromDate, 1))
          : endOfTheDay(filteredData?.dateRange?.to);

        if (!startDate || !endDate) return;

        const request = api.ac.v5.score.driver.$driverId(Number(id)).$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            fromTimestamp: startDate,
            toTimestamp: endDate,
          },
        });

        const result = await request.process();
        // @ts-ignore
        const scores = result?.scores || [];

        if (isPrevious) {
          setDriverPersonaScores((prevScores) =>
            prevScores.map((prevScore) => ({
              ...prevScore,
              prevScore: scores.find((score) => score.personaId === prevScore.personaId)?.score,
            }))
          );
        } else {
          setDriverPersonaScores(scores);
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    [secretToken, id, filteredData]
  );

  useEffect(() => {
    if (!driver?.firstName) return;
    setBreadcrumbTitle(`Driver - ${driver?.firstName} ${driver?.lastName}`);
    setSelectedFilterTypes([-1, 4, 3, 203]);
  }, [setBreadcrumbTitle, driver?.firstName, driver?.lastName, setSelectedFilterTypes]);

  useEffect(() => {
    const aborter = new AbortController();
    const fetchData = async () => {
      await fetchDriverDetails(aborter?.signal);
      await fetchDriverScoreByPersona(aborter?.signal);
      await fetchDriverScoreByPersona(aborter?.signal, true);
    };
    fetchData();
    return () => aborter?.abort();
  }, [fetchDriverDetails, fetchDriverScoreByPersona]);

  return (
    <Box mx={2.5}>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} mt={2}>
        <DriverInfo driver={driver} />
        <Grid container spacing={1} sx={{ alignItems: 'baseline' }}>
          {driverPersonaScores?.map((driverPersonaScore, index) => (
            <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
              <Box
                onClick={() => handleSelectPersona(driverPersonaScore?.personaId)}
                sx={{ cursor: 'pointer' }}
              >
                <ScoreByPersonaWidget
                  isSelected={selectedPersonaId === driverPersonaScore?.personaId}
                  score={driverPersonaScore?.score}
                  prevScore={driverPersonaScore?.prevScore}
                  personaId={driverPersonaScore?.personaId}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={3}>
        <TriggerDetailsByType type="DRIVER" entityId={id} personaId={selectedPersonaId} />
      </Box>
    </Box>
  );
}
