import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiAcceleratorDetailsContext } from '../../@components/AiAcceleratorDetailsLayout';
import { AiAcceleratorForm } from '../../@components/AiAcceleratorForm';

export function EditAiAcceleratorPage() {
  const navigate = useNavigate();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { aiAccelerator } = useContext(AiAcceleratorDetailsContext);

  const [submitting, setSubmitting] = useState(false);

  /** @type {import('../../@components/AiAcceleratorForm').AiAcceleratorFormProps['onSubmit']} */
  const handleSubmit = async (form) => {
    const request = api.ac.v1.tenant.aiaccelerator.$put({
      params: {
        tenantId,
        secretToken,
      },
      data: {
        ...form,
      },
    });
    try {
      setSubmitting(true);
      await request.process();
      toastSuccess('Success', 'Updated AI Accelerator');
      navigate('/smarter-ai/ai-accelerator');
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Could not update the AI Accelerator');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Edit AI Accelerator
      </Typography>
      <Box height={'20px'} />
      <AiAcceleratorForm onSubmit={handleSubmit} disabled={submitting} item={aiAccelerator} />
    </Box>
  );
}
