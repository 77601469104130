/**
 * Removes spaces from a given string.
 * @param {string} string - The input string with spaces.
 * @returns {string} - The input string with spaces removed.
 */
export function trimSpaceFromString(string) {
  return string?.replace(/ /g, '');
}

/**
 * Filters an array of objects to keep only unique items based on a specified key.
 * @param {Array<object>} items - The array of objects to filter.
 * @param {string} key - The key used to determine uniqueness.
 * @returns {Array<object>} - An array containing unique items based on the specified key.
 */
export function uniqueArrayByKey(items, key) {
  /**
   * @typedef {object} Item
   * @property {*} [key] - The value of the specified key.
   */

  /** @type {Set<*>} */
  const uniqueKeys = new Set();

  /**
   * @type {Array<Item>}
   */
  const uniqueArray = items?.filter((item) => {
    if (!uniqueKeys.has(item[key])) {
      uniqueKeys.add(item[key]);
      return true;
    }
    return false;
  });

  return uniqueArray;
}

export const deployedSchemesArraysDifferent = (deployedItems, updatedItem, availableSchemes) => {
  const availableDeployedItems = availableSchemes.filter((availableScheme) =>
    deployedItems.some((deployedItem) => deployedItem?.schemeId === availableScheme?.id)
  );

  if (!availableDeployedItems?.length && !updatedItem?.length) return false;
  if (availableDeployedItems?.length !== updatedItem?.length) return true;

  const schemeIds1 = (availableDeployedItems || []).map((obj) => obj?.id || obj?.schemeId);
  const schemeIds2 = (updatedItem || []).map((obj) => obj?.id || obj?.schemeId);

  const difference = schemeIds1
    .filter((id) => !schemeIds2.includes(id))
    .concat(schemeIds2.filter((id) => !schemeIds1.includes(id)));

  return !!difference?.length;
};
