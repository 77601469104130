import { BOX_DISCONTINUE, BOX_MODIFY_ICON, BOX_RELEASE_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { store } from '@/store';
import { selectTeamList } from '@/store/auth';
import { AddOutlined, ContentCopyOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<CompositeTriggerDto>>} */
export const ACTIONS_ITEMS = [
  {
    label: T['triggers.table.button.clone'],
    icon: ContentCopyOutlined,
    component: 'BUTTON',
    variant: 'outlined',
    type: 'CLONE',
    useCustomDialog: true,
    hidden: (selected) => selected.length !== 1 || selected[0]?.type !== 'EXPRESSION',
  },
  {
    label: T['button.release'],
    iconImage: BOX_RELEASE_ICON,
    component: 'BUTTON',
    type: 'RELEASE',
    variant: 'outlined',
    hidden: (selected) => !selected.length || selected[0]?.publicationStatus === 'RELEASED',
    confirmationMessage: T['triggers.confirmations.release'],
  },
  {
    label: T['button.discontinue'],
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    hidden: (selected) => !selected.length || selected[0]?.publicationStatus === 'DISCONTINUED',
    confirmationMessage: T['triggers.confirmations.discontinue'],
  },
  {
    label: T['button.modify'],
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    link: (selected) => `/smarter-ai/triggers/${selected.id}/edit`,
    hidden: (selected) => {
      const state = store.getState();
      const teamList = selectTeamList(state);
      /** @type {AccountLoginResponse['teamList']} */
      const priorityUsers = ['FRONT_END_TEAM', 'PRODUCT_MANAGER'];
      if (priorityUsers.some((x) => teamList.includes(x))) {
        return selected.length !== 1 || selected[0]?.type !== 'EXPRESSION';
      }
      return (
        selected.length !== 1 ||
        selected[0]?.publicationStatus !== 'DRAFT' ||
        selected[0]?.type !== 'EXPRESSION'
      );
    },
  },
  {
    label: T['button.search'],
    placeholder: T['triggers.table.search.placeholder'],
    component: 'SEARCH',
    options: [
      { value: 'name', label: 'Name' },
      // { value: 'triggerType', label: 'Type' },
      { value: 'publicationStatus', label: 'Status' },
    ],
  },
  {
    label: T['triggers.table.button.add'],
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/smarter-ai/triggers/create',
  },
];
