import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { DeviceBatchForm } from '@/web/smarter-ai/device-batches/@components/DeviceBatchForm';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function CreateDeviceBatchPage() {
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const createBatch = async (form) => {
    const { description, packageId, secret } = form;
    try {
      setLoading(true);
      const req = api.ac.v5['device-batch'].$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          description,
          packageId,
          secret,
        },
      });
      await req.process();
      toastSuccess('Success', t(T['device-batch.create.form.response.success']));
      navigate('/smarter-ai/device-batches');
    } catch (err) {
      toastWarning('Error', t(T['device-batch.create.form.response.error']));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['device-batch.form.create.title'])}
      </Typography>
      <DeviceBatchForm onSubmitData={createBatch} isLoading={loading} />
    </Box>
  );
}
