import { DRIVER_ON_ICON } from '@/assets/constants/images';
import { collapseDecimal } from '@/utils/sensors/extractor';
import { CircleBox } from '@/web/@components/CircleBox';
import { DriverScoreCircleSvg } from '@/web/@components/DriverScoreCircleSvg';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { PageLink } from '@/web/@components/PageLink';
import { Box, IconButton, Typography } from '@mui/material';

/**
 * @typedef {object} DriverPopperContentProps
 * @property {Driver} driver
 * @property {import('react').EventHandler<import('react').SyntheticEvent<HTMLElement>>} onClose
 */

/** @param {DriverPopperContentProps} props */
export function DriverPopperContent(props) {
  const { driver, onClose } = props;
  return (
    <Box
      px={2}
      pr={3}
      py={0.5}
      gap={2}
      position="relative"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <IconLabelItem
        src={DRIVER_ON_ICON}
        iconSize="18px"
        fontWeight="medium"
        fontSize="0.875rem"
        title={
          <PageLink to={`/fleets/drivers/${driver.driverId}/details`}>
            {driver.firstName} {driver.lastName}
          </PageLink>
        }
      />

      <Box width="48px" height="48px" position="relative">
        <DriverScoreCircleSvg value={driver.globalScore / 100} />
        <Typography
          position="absolute"
          left={5}
          right={5}
          top={5}
          bottom={5}
          textAlign={'center'}
          borderRadius="50%"
          lineHeight="38px"
        >
          {collapseDecimal(driver.globalScore, 0)}
        </Typography>
      </Box>

      <IconButton size="small" onClick={onClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
        <CircleBox size="16px" fontSize="1.25rem" bgcolor="transparent">
          &times;
        </CircleBox>
      </IconButton>
    </Box>
  );
}
