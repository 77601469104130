import { useCameraList } from '@/hooks/useCameraList';
import { useDriverList } from '@/hooks/useDriverList';
import { PageFilteringArea } from '@/web/@components/PageFilteringArea';
import { sortedUniq } from 'lodash';
import { useContext, useMemo } from 'react';
import { SetTripFilterContext, TripFilterContext } from '../TripFilterContext';

export function TripFilteringArea() {
  const setTripFilter = useContext(SetTripFilterContext);
  const { startTime, endTime, driverName, endpointName } = useContext(TripFilterContext);

  const { result: cameras } = useCameraList();
  const { result: drivers } = useDriverList();

  const cameraNames = useMemo(
    () =>
      sortedUniq(
        (cameras || [])
          .map((x) => x.deviceLabel)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [cameras]
  );

  const driverNames = useMemo(
    () =>
      sortedUniq(
        (drivers || [])
          .map((x) => `${x.firstName} ${x.lastName}`)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [drivers]
  );

  return (
    <PageFilteringArea
      value={{
        dateRange: [startTime, endTime],
        endpointName,
        driverName,
      }}
      onChange={(data) => {
        setTripFilter({
          startTime: data.dateRange[0],
          endTime: data.dateRange[1],
          endpointName: data.endpointName,
          driverName: data.driverName,
        });
      }}
      filters={[
        {
          type: 'CHOICE',
          key: 'endpointName',
          title: 'Camera',
          submenu: cameraNames,
          partialSearch: true,
        },
        {
          type: 'CHOICE',
          key: 'driverName',
          title: 'Driver',
          submenu: driverNames,
          partialSearch: true,
        },
        {
          type: 'DATE',
          key: 'dateRange',
          title: 'Date',
          required: true,
        },
      ]}
    />
  );
}
