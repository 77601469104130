import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

/**
 * Fetch list of all Drivers
 */
export function useDriverList() {
  const fetcher = async ({ signal, secretToken, tenantId }) => {
    let offset = 0;
    const limit = 500;
    /** @type {Array<Driver>} */
    const results = [];
    while (true) {
      const request = api.ac.v5.driver.list.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          limit,
          offset,
          tenantId,
        },
      });
      await request.process();
      if (!request.result) break;
      const result = request.result.drivers;
      results.push(...result);
      if (result.length < limit) break;
      offset += limit;
    }
    return results;
  };
  return useFutureLoader(fetcher, [], { cache: true });
}
