import { SORT_ASC_ICON, SORT_DES_ICON } from '@/assets/constants/images';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { trimString } from '@/utils/formatting';
import { BoxImage } from '@/web/@components/BoxImage';
import { SearchField } from '@/web/@components/SearchField';
import { SelectField } from '@/web/@components/SelectField';
import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CameraHealthContext } from '../..';
import { useTranslation } from 'react-i18next';
import { T } from '@/assets/locales';
import { TAG_TITLES } from '../../mapping';

export function CameraHealthHeader() {
  const { t } = useTranslation();
  const { query, updateQuery } = useRouteQuery();
  const fetcher = useContext(CameraHealthContext);

  const currentView = useMemo(() => query.view + '', [query.view]);
  const ascSorting = useMemo(() => query.sort === 'asc', [query.sort]);
  const searchText = useMemo(() => trimString(query.search), [query.search]);
  const grouping = useMemo(() => trimString(query.group) || 'TENANT', [query.group]);

  const [currentViewTitle, setCurrentViewTitle] = useState('');

  useEffect(() => {
    if (!currentView) {
      setCurrentViewTitle('');
      return;
    }
    const update = () => {
      if (grouping === 'TENANT') {
        const names = fetcher.tenants.find((x) => '' + x.tenantId === currentView)?.tenantName;
        if (names?.length) {
          setCurrentViewTitle(names[names.length - 1]);
        }
      } else {
        const tagName = fetcher.tags.find((x) => '' + x.id === currentView)?.tagName;
        setCurrentViewTitle(TAG_TITLES[tagName] ?? tagName);
      }
    };
    update();
    fetcher.on('tag-list', update);
    return () => {
      fetcher.off('tag-list', update);
    };
  }, [currentView, grouping, fetcher]);

  /** @param {string} search */
  const setSearchText = (search) => {
    updateQuery({ search, view: null });
  };

  /** @param {string} group */
  const setGrouping = (group) => {
    updateQuery({ group, view: null });
  };

  /** @param {boolean} asc */
  const setAscSorting = (asc) => {
    updateQuery({ sort: asc ? 'asc' : undefined, view: null });
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
      <Box width={{ xs: '100%', sm: 'fit-content' }}>
        <Typography variant="body2" fontSize="1.125rem" fontWeight="500">
          {currentViewTitle || t(T['monitoring.camera.health.page.title'])}
        </Typography>
      </Box>
      <Box
        gap="8px"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="flex-end"
        width={{ xs: '100%', sm: 'fit-content' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <SearchField
          clearable
          value={searchText}
          placeholder={'Search'}
          onSearch={setSearchText}
          searchOnEnter={true}
        />
        <SelectField
          options={[
            { label: 'By Tenant', value: 'TENANT' },
            { label: 'By Observation', value: 'OBSERVATION' },
          ]}
          value={grouping}
          onChange={setGrouping}
          sx={{
            width: { xs: '100%', sm: 'calc(25% - 4px)', md: 'fit-content' },
          }}
        />
        <Button variant="outlined" onClick={() => setAscSorting(!ascSorting)}>
          {ascSorting ? (
            <BoxImage alt="sort" src={SORT_ASC_ICON} width="16px" height="16px" />
          ) : (
            <BoxImage alt="sort" src={SORT_DES_ICON} width="16px" height="16px" />
          )}
          <Box sx={{ pl: 1 }}>{t(T['sort'])}</Box>
        </Button>
      </Box>
    </Box>
  );
}
