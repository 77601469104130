import api from '@/api';
import { FIRMWARE_TYPES } from '@/assets/ota';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken } from '@/store/auth';
import { compareVersions } from '@/utils/firmwares';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ACTION_ITEMS } from './actions';
import { TENANT_TABLE_COLUMNS } from './columns';

export function TenantsAssociateFirmwares() {
  const secretToken = useSelector(selectSecretToken);

  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);

  const { result = [], loading: tenantLoading } = useFutureLoader(
    async ({ signal, secretToken }) => {
      const request = api.ac.v1.tenant.tenants.$get({
        signal,
        params: {
          secretToken,
          approval: '',
          userId: '0',
        },
      });
      await request.process();
      const filteredData = request.result.tenantDTOList.filter(
        (item) => item.approvalStatus === 'APPROVED'
      );
      return sortBy(filteredData, ['approvalStatus', 'tenantName']);
    },
    []
  );

  const getTenantFirmwares = useCallback(
    async (item) => {
      const limit = 100;
      let offset = 0;
      /** @type {Array<FirmwareDto>} */
      const results = [];
      while (true) {
        const request = api.ac.v5.firmware.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit: limit,
            offset: offset,
            tenantId: item.tenantUuid,
          },
        });
        await request.process();
        const result = request.result.firmwares;
        results.push(...result);
        offset += limit;
        if (result.length < limit) break;
      }
      return results;
    },
    [secretToken]
  );

  useEffect(() => {
    if (tenantLoading) return;
    (async () => {
      await Promise.all(
        result.map(async (item) => {
          const firmwares = await getTenantFirmwares(item);
          console.log('firmwares', firmwares);
          // Sort the array in descending order based on version for each firmware type
          const sortedByVersion = firmwares?.reduce((acc, item) => {
            if (FIRMWARE_TYPES[item.type]) {
              if (!acc[item.type] || compareVersions(item.version, acc[item.type].version) > 0) {
                acc[item.type] = item;
              }
            }
            return acc;
          }, {});
          // Create an object to store the type and corresponding top version
          const resultObject = {};
          // Iterate through the sortedByVersion object and construct the result object
          for (const type in sortedByVersion) {
            resultObject[type] = sortedByVersion[type].version;
          }
          Object.entries(resultObject)?.forEach(([key, value]) => {
            item[key] = value;
          });
        })
      );
      setTenants(result);
    })()
      .catch((err) => {
        console.error('Failed to load camera', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [result, tenantLoading, getTenantFirmwares]);

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={'Tenants'}
          // @ts-ignore
          actions={ACTION_ITEMS}
        />
        <ContinuationTokenTable
          loading={loading}
          results={tenants}
          columns={TENANT_TABLE_COLUMNS}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
