import { T } from '@/assets/locales';
import { UnderDevelop } from '@/web/@components/UnderDevelop';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <UnderDevelop />,
    breadcrumb: T['review.page.title'],
  },
];

export default routes;
