import { useAnnotationCategories } from '@/hooks/useAnnotationCategories';
import { useCameraList } from '@/hooks/useCameraList';
import { useAffectiveTriggers } from '@/hooks/useFetchTriggers';
import { FilterContext, SetFilterContext } from '@/web/@components/FilterContext';
import { PageFilteringArea } from '@/web/@components/PageFilteringArea';
import { sortedUniq } from 'lodash';
import { useContext, useMemo } from 'react';
import {
  CoachingFilterContext,
  SetCoachingFilterContext,
} from '../../@context/CoachingFilterContext';

export function CoachableEventsFilteringArea() {
  const setFilter = useContext(SetFilterContext);
  const setCoachingFilter = useContext(SetCoachingFilterContext);
  const { startTime, endTime } = useContext(FilterContext);
  const { annotationName, deviceName, deviceSerialNumber, triggerName } =
    useContext(CoachingFilterContext);

  const { result: cameras } = useCameraList();
  const { result: triggers } = useAffectiveTriggers();
  const { result: annotations } = useAnnotationCategories();

  const cameraNames = useMemo(
    () =>
      sortedUniq(
        (cameras || [])
          .map((x) => x.deviceLabel)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [cameras]
  );

  const cameraSerials = useMemo(
    () =>
      sortedUniq(
        (cameras || [])
          .map((x) => x.deviceSerialNo)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [cameras]
  );

  const triggerNames = useMemo(
    () =>
      sortedUniq(
        (triggers || [])
          .map((x) => x.name)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [triggers]
  );

  const annotationNames = useMemo(
    () =>
      sortedUniq(
        (annotations || [])
          .map((x) => x.name)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [annotations]
  );

  return (
    <PageFilteringArea
      value={{
        dateRange: [startTime, endTime],
        deviceName,
        annotationName,
        deviceSerialNumber,
        triggerName,
      }}
      onChange={(data) => {
        setFilter({
          startTime: data.dateRange[0],
          endTime: data.dateRange[1],
        });
        setCoachingFilter({
          deviceName: data.deviceName,
          annotationName: data.annotationName,
          deviceSerialNumber: data.deviceSerialNumber,
          triggerName: data.triggerName,
        });
      }}
      filters={[
        {
          type: 'CHOICE',
          key: 'deviceName',
          title: 'Camera',
          submenu: cameraNames,
          partialSearch: true,
        },
        {
          type: 'CHOICE',
          key: 'deviceSerialNumber',
          title: 'Serial',
          submenu: cameraSerials,
        },
        {
          type: 'CHOICE',
          key: 'triggerName',
          title: 'Trigger',
          submenu: triggerNames,
          partialSearch: true,
        },
        {
          type: 'CHOICE',
          key: 'annotationName',
          title: 'Annotation',
          submenu: annotationNames,
          partialSearch: true,
        },
        {
          type: 'DATE',
          key: 'dateRange',
          title: 'Date',
          required: true,
        },
      ]}
    />
  );
}
