import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { ErrorBoundary } from '@/web/@components/ErrorBoundary';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { CircularProgress } from '@mui/material';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * @typedef {object} AiAcceleratorDetailsData
 * @property {string} code
 * @property {() => any} refreshDetails
 * @property {AIAccelerator} aiAccelerator
 */

/** @type {import('react').Context<AiAcceleratorDetailsData>} */
export const AiAcceleratorDetailsContext = createContext(null);

export function AiAcceleratorDetailsLayout() {
  const { code } = useParams();
  const { setBreadcrumbTitle } = useContext(MainContext);

  const navigate = useNavigate();
  const location = useLocation();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [initialTenantId] = useState(tenantId);
  /** @type {StateVariable<Error>} */
  const [error, setError] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<AIAccelerator>} */
  const [aiAccelerator, setAiAccelerator] = useState(null);
  /** @type {StateVariable<number>} */
  const [refresh, setRefresh] = useState(0);

  const refreshDetails = useCallback(() => {
    setRefresh((v) => v + 1);
  }, []);

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      navigate('../');
    }
  }, [navigate, initialTenantId, tenantId]);

  useEffect(() => {
    if (!code) return;
    setError(null);
    setLoading(true);
    const request = api.ac.v1.tenant.aiaccelerator.$get({
      params: {
        secretToken,
        tenantId,
        codeName: code,
      },
    });
    request
      .process()
      // @ts-ignore TODO: fix type
      .then(setAiAccelerator)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [code, secretToken, tenantId, refresh]);

  useEffect(() => {
    setBreadcrumbTitle(aiAccelerator?.name || code || 'Loading...', (path) => path.endsWith(code));
  }, [code, aiAccelerator?.name, setBreadcrumbTitle]);

  if (loading || !aiAccelerator) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error) {
    return (
      <CenterBox>
        <IconMessageBox
          src={NO_SEARCH_RESULT_IMG}
          size="256px"
          message="Could not get Product details"
        />
      </CenterBox>
    );
  }

  return (
    <AiAcceleratorDetailsContext.Provider
      value={{
        code,
        aiAccelerator,
        refreshDetails,
      }}
    >
      <ErrorBoundary key={location.pathname}>
        <Outlet />
      </ErrorBoundary>
    </AiAcceleratorDetailsContext.Provider>
  );
}
