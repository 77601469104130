import { T } from '@/assets/locales';
import { endOfTheDay, minusOneMonth } from '@/utils/datetime';
import { FilterContextProvider } from '@/web/@components/FilterContext';
import { Navigate } from 'react-router-dom';
import { TriggerReports } from './trigger-report';
import { TriggerReportDetailsGraph } from './trigger-report/_id';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <Navigate to={'./tenant-deployment'} replace />,
  },
  {
    path: 'trigger-report',
    breadcrumb: T['navigation.triggers.report'],
    children: [
      {
        path: '',
        element: (
          <FilterContextProvider
            children={<TriggerReports />}
            defaults={{
              startTime: minusOneMonth(),
              endTime: endOfTheDay(Date.now()),
            }}
          />
        ),
      },
      {
        path: ':triggerId',
        element: <TriggerReportDetailsGraph />,
        breadcrumb: 'Trend',
      },
    ],
  },
];

export default routes;
