import { FROM_TYPES } from '@/assets/schemes/constants';
import { Add, Close } from '@mui/icons-material';
import { Box, Button, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ScoringFilterContext } from '../../@context/ScoringFilterContext';
import {
  MULTI_FILED_ITEMS,
  MULTI_SELECTION_ITEMS,
  SCORING_FILTER_MENU_ITEMS,
  SELECTION_TYPE,
} from '../../assets';
import { formatDate } from '../../utils';
import { ScoreDateRangePicker } from '../ScoreDateRangePicker';
import { ScoringMultiSelectFilter } from '../ScoringMultiSelectFilter';
import { ScoringNumberRange } from '../ScoringNumberRange';

export function ScoringFilterSection() {
  const {
    selectedCameras,
    setSelectedCameras,
    selectedDrivers,
    setSelectedDrivers,
    selectedFromDate,
    selectedToDate,
    selectedPersonaList,
    setSelectedPersonaList,
    selectedScoreFrom,
    selectedScoreTo,
    setSubmitCount,
    selectedFilterTypes,
    setSelectedScoreFrom,
    setSelectedScoreTo,
  } = useContext(ScoringFilterContext);

  const shouldCallSubmitRef = useRef(false);

  /** @type {StateVariable<any>} */
  const [showMenu, setShowMenu] = useState();
  /** @type {StateVariable<number>} */
  const [selectionNo, setSelectionNo] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [showSubMenu, setSubShowMenu] = useState(false);

  const selectFirstLabelMenu = (id) => {
    setSelectionNo(id);
    setSubShowMenu(true);
  };

  const shouldOpenParentPopover = useMemo(() => showMenu && !showSubMenu, [showMenu, showSubMenu]);

  const shouldShowSubMenu = useMemo(() => {
    const isDisabledClicking =
      SELECTION_TYPE.DATE !== selectionNo && selectedFilterTypes.includes(-1);
    return showSubMenu && !isDisabledClicking;
  }, [showSubMenu, selectionNo, selectedFilterTypes]);

  const getStateData = (selectionItemNo) => {
    switch (selectionItemNo || selectionNo) {
      case SELECTION_TYPE.CAMERA:
        return selectedCameras || [];
      case SELECTION_TYPE.DRIVER:
        return selectedDrivers || [];
      case SELECTION_TYPE.DATE:
        return { from: selectedFromDate, to: selectedToDate };
      case SELECTION_TYPE.SCORE:
        return { from: selectedScoreFrom, to: selectedScoreTo };
      case SELECTION_TYPE.PERSONA:
        return selectedPersonaList || [];
      default:
        return null;
    }
  };

  const getMenuTitle = (selectionItemNo) => {
    switch (selectionItemNo || selectionNo) {
      case SELECTION_TYPE.CAMERA:
        return 'Camera';
      case SELECTION_TYPE.DRIVER:
        return 'Driver';
      case SELECTION_TYPE.DATE:
        return 'Date';
      case SELECTION_TYPE.SCORE:
        return 'Score';
      case SELECTION_TYPE.PERSONA:
        return 'Persona';
      default:
        return '';
    }
  };

  const getInputType = (selectionItemNo) => {
    switch (selectionItemNo || selectionNo) {
      case SELECTION_TYPE.CAMERA:
        return FROM_TYPES.MULTISELECT;
      case SELECTION_TYPE.DRIVER:
        return FROM_TYPES.MULTISELECT;
      case SELECTION_TYPE.DATE:
        return FROM_TYPES.DATE_TIME;
      case SELECTION_TYPE.SCORE:
        return FROM_TYPES.NUMBER_RANGE;
      case SELECTION_TYPE.PERSONA:
        return FROM_TYPES.MULTISELECT;
      default:
        return '';
    }
  };

  const handleSubmit = () => {
    setShowMenu(null);
    setSubShowMenu(false);
    setSubmitCount(2);
  };

  const handleCancel = () => {
    setSubShowMenu(false);
    setShowMenu(null);
  };

  useEffect(() => {
    if (shouldCallSubmitRef.current) {
      handleSubmit();
      shouldCallSubmitRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCallSubmitRef.current]);

  const handleCloseIconButtonClick = () => {
    shouldCallSubmitRef.current = true;
  };

  const handleItemToggle = (item, selectedItemNo) => {
    const idKey = (() => {
      switch (selectedItemNo) {
        case SELECTION_TYPE.CAMERA:
          return 'endpointId';
        case SELECTION_TYPE.DRIVER:
          return 'driverId';
        case SELECTION_TYPE.PERSONA:
          return 'id';
        default:
          return '';
      }
    })();

    const toggleSelectedItems = (prev) => {
      const isItemInList = prev?.some((selectedItem) => selectedItem[idKey] === item[idKey]);
      if (isItemInList) {
        return prev?.filter((selectedItem) => selectedItem[idKey] !== item[idKey]);
      } else {
        return [...prev, item];
      }
    };

    switch (selectedItemNo) {
      case SELECTION_TYPE.CAMERA:
        setSelectedCameras((prev) => toggleSelectedItems(prev));
        break;
      case SELECTION_TYPE.DRIVER:
        setSelectedDrivers((prev) => toggleSelectedItems(prev));
        break;
      case SELECTION_TYPE.PERSONA:
        setSelectedPersonaList((prev) => toggleSelectedItems(prev));
        break;
      default:
        break;
    }
  };

  /**
   * Gets the label for the item based on the selected item type.
   * @param {number} selectedItemNo - The selected item type.
   * @param {object} item - The data item.
   * @returns {string} - The label for the item.
   */
  const getItemLabel = (selectedItemNo, item) => {
    switch (selectedItemNo) {
      case SELECTION_TYPE.CAMERA:
        return item?.deviceLabel;
      case SELECTION_TYPE.DRIVER:
        return `${item?.firstName} ${item?.lastName}`;
      case SELECTION_TYPE.PERSONA:
        return item?.name;
      default:
        return '';
    }
  };

  const isEnabledCloseIcon = (filterItem) => {
    return (
      !selectedFilterTypes.includes(-1) &&
      ![SELECTION_TYPE.DATE, SELECTION_TYPE.SCORE].includes(filterItem?.type) &&
      !selectedFilterTypes?.includes(filterItem?.hideCloseButtonFor)
    );
  };

  const renderIconButton = (item, selectedItemNo) => {
    return (
      <IconButton
        size="small"
        onClick={() => {
          if (item) {
            handleItemToggle(item, selectedItemNo);
          } else {
            if (selectedItemNo === SELECTION_TYPE?.SCORE) {
              setSelectedScoreFrom(0);
              setSelectedScoreTo(100);
            }
          }
          handleCloseIconButtonClick();
        }}
      >
        <Close style={{ fontSize: '16px' }} />
      </IconButton>
    );
  };

  const renderFilterBox = (filterItem) => {
    const selectedItemNo = filterItem?.type;

    const data = getStateData(selectedItemNo);

    if (!data || (MULTI_FILED_ITEMS.includes(selectedItemNo) && !data?.to)) {
      return null;
    }

    const origin = getStateData(selectedItemNo);
    const title = getMenuTitle(selectedItemNo);

    if (SELECTION_TYPE.SCORE === selectedItemNo && selectedFilterTypes?.includes(4)) return null;

    if (!MULTI_SELECTION_ITEMS.includes(selectedItemNo)) {
      const scoreText = `${data?.from || 0} - ${data?.to || 100}`;
      const dateText = `${formatDate(data?.from)} -${formatDate(data?.to)}`;

      const dataText =
        selectedItemNo === SELECTION_TYPE.SCORE
          ? scoreText
          : selectedItemNo === SELECTION_TYPE.DATE
            ? dateText
            : origin;

      return renderFilterBoxItem(filterItem, title, dataText);
    }

    return data?.map((item, index) => (
      <Box key={index}>
        {renderFilterBoxItem(filterItem, title, getItemLabel(selectedItemNo, item), item)}
      </Box>
    ));
  };

  const renderFilterBoxItem = (filterItem, title, dataText, item) => {
    const selectedItemNo = filterItem?.type;

    const showCloseIcon = isEnabledCloseIcon(filterItem);

    return (
      <React.Fragment key={selectedItemNo}>
        <Box
          key={selectedItemNo}
          border="1px solid #C5D9F0"
          borderRadius="20px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          fontSize="0.875rem"
          p="0 5px"
          minHeight="28px"
        >
          <Box
            p="0 5px"
            onClick={(e) => {
              if (selectedFilterTypes?.includes(filterItem?.hideCloseButtonFor)) {
                return;
              }
              e.preventDefault();
              setShowMenu(e.currentTarget);
              setSelectionNo(selectedItemNo);
              setSubShowMenu(true);
            }}
          >
            <Typography variant="subtitle2" sx={{ width: 'max-content' }}>
              {` ${title} : ${dataText} `}
            </Typography>
          </Box>
          {showCloseIcon && renderIconButton(item, selectedItemNo)}
        </Box>
      </React.Fragment>
    );
  };

  return (
    <Box
      p={1}
      display="flex"
      alignItems={'center'}
      gap="10px"
      border="1px solid #C5D9F0"
      borderRadius="6px"
      mb={1}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={'center'}
        width="100%"
        height={'26px'}
      >
        <Box
          display="flex"
          gap={0.5}
          sx={{
            'maxHeight': '120%',
            'overflowX': 'auto',
            'msOverflowStyle': 'none' /* For Internet Explorer and Edge */,
            '&::-webkit-scrollbar': {
              display: 'none' /* Hide the scrollbar for WebKit browsers */,
            },
            'scrollbarWidth': 'thin' /* For Firefox */,
            'scrollbarColor': 'transparent transparent' /* For Firefox */,
          }}
        >
          {SCORING_FILTER_MENU_ITEMS?.filter(
            (item) => !selectedFilterTypes.includes(item?.hideFilteredItem)
          )?.map((item, index) => (
            <Fragment key={index}>{renderFilterBox(item)}</Fragment>
          ))}

          {!selectedFilterTypes?.includes(-1) && (
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => setShowMenu(e.currentTarget)}
              style={{
                color: '#4E76BA',
                borderRadius: '20px',
                padding: '1.5px 10px',
                border: '1px dashed #4E76BA',
                minWidth: '110px',
              }}
              startIcon={<Add />}
            >
              Add Filter
            </Button>
          )}
        </Box>
      </Box>

      {shouldOpenParentPopover && (
        <Menu
          open
          anchorEl={showMenu}
          elevation={1}
          onClose={() => setShowMenu(null)}
          PaperProps={{
            style: {
              marginTop: '5px',
              minWidth: '125px',
            },
            sx: {
              '.MuiMenuItem-root': {
                fontSize: '0.875rem',
              },
            },
          }}
        >
          {SCORING_FILTER_MENU_ITEMS?.filter(
            (item) => !selectedFilterTypes.includes(item?.hideDropDownFor)
          )?.map((menuItem) => (
            <MenuItem key={menuItem.type} onClick={() => selectFirstLabelMenu(menuItem.type)}>
              {menuItem.label}
            </MenuItem>
          ))}
        </Menu>
      )}

      {shouldShowSubMenu && (
        <Popover
          open={Boolean(showSubMenu)}
          elevation={1}
          anchorEl={showMenu}
          onClose={() => {
            setShowMenu(null);
            setSubShowMenu(false);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {/* Fixed Header */}
          <MenuItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography fontWeight="bold" variant="h3" fontSize="1rem" pt={1}>
              {getMenuTitle()}
            </Typography>
          </MenuItem>

          {getInputType() === FROM_TYPES.MULTISELECT ? (
            <ScoringMultiSelectFilter
              onApply={handleSubmit}
              onCancel={handleCancel}
              selectionNo={selectionNo}
            />
          ) : getInputType() === FROM_TYPES.DATE_TIME ? (
            <ScoreDateRangePicker onApply={handleSubmit} onCancel={handleCancel} />
          ) : getInputType() === FROM_TYPES.NUMBER_RANGE ? (
            <ScoringNumberRange onApply={handleSubmit} onCancel={handleCancel} />
          ) : null}
        </Popover>
      )}
    </Box>
  );
}
