import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { InfiniteScrollListView } from '@/web/@components/InfiniteScrollView/InfiniteScrollListView';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import { TripContext } from '../TripContext';
import { TripDetailsBox } from '../TripDetailsBox';
import { SetTripFilterContext, TripFilterContext } from '../TripFilterContext';
import { TripListViewItem } from '../TripListViewItem';

export function TripListView() {
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));

  const { tripId } = useContext(TripFilterContext);
  const { tableKey, fetcher } = useContext(TripContext);
  const updateTripFilter = useContext(SetTripFilterContext);

  return (
    <InfiniteScrollView
      key={tableKey}
      fetcher={fetcher}
      initialToken={0}
      itemsPerPage={20}
      renderEmpty={() => (
        <CenterBox fullView>
          <IconMessageBox
            size="256px"
            src="/images/pages/trips/no-trips-icon.svg"
            message="No Trips available for the selected dates"
          />
        </CenterBox>
      )}
      renderList={(state) => (
        <InfiniteScrollListView
          state={state}
          sx={{
            p: 1.5,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
          renderItem={(item) => (
            <>
              <TripListViewItem
                item={item}
                onClick={() =>
                  updateTripFilter({
                    tripId: !mdAndUp && tripId === item.id ? null : item.id,
                  })
                }
                margin={tripId === item.id ? 0 : '2px'}
                border={tripId === item.id ? '2px solid #608AC3B2' : null}
                sx={
                  tripId === item.id
                    ? null
                    : {
                        '&:hover': {
                          margin: 0,
                          border: '2px solid #608ac34f',
                        },
                      }
                }
              />
              {tripId === item.id && !mdAndUp && (
                <Box height="calc(100vh - 450px)" minHeight="350px">
                  <TripDetailsBox />
                </Box>
              )}
            </>
          )}
        />
      )}
    />
  );
}
