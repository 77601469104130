/**
 * Function that formats seconds into a "min:sec" format.
 * @param {number} seconds - The total number of seconds to format.
 * @returns {string} The formatted time in "min:sec" format.
 */
export function formatSecondsToMinutesAndSeconds(seconds) {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  const formattedMins = mins < 10 ? `0${mins}` : mins;
  const formattedSecs = secs < 10 ? `0${secs}` : secs;
  return `${formattedMins}:${formattedSecs}`;
}

export function getMonthNumberOfCurrentYear() {
  const currentDate = new Date();
  let currentMonth = (currentDate.getMonth() + 1).toString();
  currentMonth = currentMonth.padStart(2, '0');
  return currentMonth;
}
