import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SchemeSelection } from '../SchemeSelection';

export function AssignSchemeModalPage(props) {
  const { onModalClose, childTenantId, currentSelectedTenant, assignedSchemes, availableSchemes } =
    props;
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<Array<SchemeInfo>>} */
  const [selectedSchemeItems, setSelectedSchemeItems] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);

  const handleAssignSchemes = async () => {
    setLoading(true);

    // Find newly added items in selectedSchemeItems
    const addedItems = selectedSchemeItems?.filter(
      (item) => !assignedSchemes?.some((scheme) => scheme.id === item.id)
    );

    // Find deleted items from assignedSchemes
    const deletedItems = assignedSchemes?.filter(
      (item) => !selectedSchemeItems?.some((scheme) => scheme.id === item.id)
    );

    try {
      await Promise.all([
        Promise.all(deletedItems?.map(handleUnAssignScheme)),
        Promise.all(addedItems?.map(handleAssignScheme)),
      ]);
      onModalClose(true);
    } catch (err) {
      onModalClose(false);
    } finally {
      setLoading(false);
    }
  };

  const handleAssignScheme = async (selectedSchemeItem) => {
    try {
      const { id } = selectedSchemeItem;
      const request = api.ac.v5.scheme.assign.$post({
        headers: {
          Authorization: secretToken,
        },
        params: {
          schemeId: id,
          tenantId: childTenantId,
        },
      });
      await request.process();
    } catch (error) {
      toastWarning(`Failed to assign Scheme.`);
    }
  };

  const handleUnAssignScheme = async (selectedSchemeItem) => {
    try {
      const { id } = selectedSchemeItem;
      const request = api.ac.v5.scheme.unassign.$delete({
        headers: {
          Authorization: secretToken,
        },
        params: {
          schemeId: id,
          tenantId: childTenantId,
        },
      });
      await request.process();
    } catch (error) {
      toastWarning(`Failed to unassign Scheme.`);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose(false);
      }}
      maxWidth={'lg'}
      fullWidth={true}
    >
      <DialogTitle>Schemes Deployment</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="subtitle2" fontWeight={600} fontSize={'1rem'}>
          {currentSelectedTenant?.tenantName}
        </Typography>
        <SchemeSelection
          assignedSchemes={assignedSchemes}
          availableSchemes={availableSchemes}
          onItemChange={(items) => {
            setSelectedSchemeItems(items);
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          size="small"
          onClick={() => {
            onModalClose(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleAssignSchemes}
          autoFocus
          variant="contained"
          disabled={loading}
          size="small"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
