import { BOX_MODIFY_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<DeviceGroupInfo>>} */
export const ACTION_ITEMS = [
  {
    label: T['button.modify'],
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => selected.length !== 1,
    link: (selectedItem) => `/smarter-ai/scheme-management/schemes/${selectedItem.id}/edit`,
  },
  {
    label: 'Search',
    placeholder: 'Search',
    component: 'SEARCH',
  },
  {
    label: T['scheme.create.button'],
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/smarter-ai/scheme-management/schemes/create',
  },
];
