import '@fontsource/fira-code';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { theme } from './theme';
import { Pages } from './web';
import { ErrorBoundary } from './web/@components/ErrorBoundary';
import { AuthListener } from './web/@utils/AuthListener';
import { EmbedWorker } from './web/@utils/EmbedWorker';
import { Preload } from './web/@utils/Preload';

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <CssBaseline />
        <AuthListener />
        <Preload />
        <BrowserRouter>
          <EmbedWorker>
            <Pages />
          </EmbedWorker>
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
