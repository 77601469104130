import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { SmartCache } from '@/utils/caching/smart-cache';
import { endOfTheDay, minusOneMonth } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Close } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventGridListItem } from '../EventGridListItem';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { IconMessageBox } from '@/web/@components/IconMessageBox';

const coachingCache = new SmartCache('coaching_session', 30 * 1000);

export function CoachableEventList() {
  const navigate = useNavigate();

  const { query } = useRouteQuery();
  const { driverId } = query;
  const { setBreadcrumbTitle } = useContext(MainContext);

  /** @type {StateVariable<number>} */
  const [hoverItem, setHoverItem] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [dataUpdateCount, setDataUpdateCount] = useState(0);

  const { result: events, loading } = useFutureLoader(async ({ signal, secretToken, tenantId }) => {
    const request = api.ac.v5.events.filter.$get({
      signal,
      headers: {
        Authorization: secretToken,
      },
      params: {
        tenantId,
        pageSize: 100,
        startTimestamp: minusOneMonth(),
        endTimestamp: endOfTheDay(Date.now()),
      },
    });
    await request.process();
    return request.result.events;
  }, []);

  useEffect(() => {
    if (!query?.driverName) return;
    setBreadcrumbTitle(query?.driverName?.toString());
  }, [query?.driverName, setBreadcrumbTitle]);

  const showButton = (e, item) => {
    e.preventDefault();
    setHoverItem(item);
  };

  const hideButton = (e) => {
    e.preventDefault();
    setHoverItem(null);
  };

  const handleRemoveEvent = async (event) => {
    // @ts-ignore
    const cachedData = await coachingCache.getItem(driverId);
    if (!cachedData) {
      // @ts-ignore
      coachingCache.setItem(driverId, [event?.id]);
      setDataUpdateCount((prev) => prev + 1);
    } else if (!cachedData?.includes(event?.id)) {
      const newData = [...cachedData, event?.id];
      // @ts-ignore
      coachingCache.setItem(driverId, newData);
      setDataUpdateCount((prev) => prev + 1);
    }
  };

  const handleSaveCoachableEvents = () => {
    navigate('/safety/coaching');
  };

  useEffect(() => {
    const newEvents = events?.slice(0, 12);
    // @ts-ignore
    coachingCache.getItem(driverId)?.then((data) => {
      const filteredArray = newEvents?.filter((i) => !data?.includes(i.id));
      setFilteredData(filteredArray);
    });
  }, [events, driverId, dataUpdateCount]);

  return (
    <Box px={2.5} mb={10}>
      {loading ? (
        <CenterBox style={{ height: '280px' }}>
          <CircularProgress />
        </CenterBox>
      ) : !loading && !filteredData?.length ? (
        <CenterBox fullView>
          <IconMessageBox size="256px" src={NO_SEARCH_RESULT_IMG} message="No Data Found." />
        </CenterBox>
      ) : (
        <>
          <Typography pb={3}>
            <span style={{ fontWeight: 500 }}>{filteredData?.length}</span> Events
          </Typography>
          <Typography variant="subtitle2" pb={2}>
            Remove the events that you don't want to coach.
          </Typography>

          <Grid container spacing={2}>
            {(filteredData || [])?.map((event, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <Box
                  display="grid"
                  position="relative"
                  gap="5px"
                  onMouseEnter={(e) => showButton(e, index)}
                  onMouseLeave={hideButton}
                >
                  <EventGridListItem item={event} />

                  <Box
                    display={hoverItem === index ? 'grid' : 'none'}
                    gap={1}
                    sx={{
                      'position': 'absolute',
                      'top': '5px',
                      'right': '5px',
                      'zIndex': 100,
                      '& .MuiIconButton-root': {
                        border: '1px solid white',
                        bgcolor: 'white',
                        height: '30px',
                        width: '30px',
                      },
                    }}
                  >
                    <IconButton
                      size="small"
                      aria-haspopup="true"
                      onClick={() => handleRemoveEvent(event)}
                    >
                      <Close style={{ fontSize: '18px' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box display="flex" justifyContent="flex-end" py={3} px={2.5}>
            <Button size="small" color="primary" onClick={() => navigate('/safety/coaching')}>
              Cancel
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => handleSaveCoachableEvents()}
            >
              Coachable Events
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
