/** @param {{value: number}} props */
export function ScoreMiniWidget(props) {
  const { value } = props;

  const innerRadius = 82;
  const innerPerimeter = 2 * innerRadius * Math.PI;
  const innerValues = [0.84 * 1, 0.84 * (value / 100)];
  const innerColors = ['#A9B5C6', '#2680EB'];

  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <g>
        {innerValues.map((x, i) => (
          <ellipse
            key={i}
            cx="100"
            cy="100"
            rx={innerRadius}
            ry={innerRadius}
            fill="none"
            strokeWidth="16"
            strokeLinecap="round"
            stroke={innerColors[i]}
            strokeDasharray={`${x * innerPerimeter} ${(1 - x) * innerPerimeter}`}
            transform="translate(236 62) rotate(119)"
          />
        ))}
      </g>
    </svg>
  );
}
