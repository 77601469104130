import { DELETE_ICON } from '@/assets/constants/images';
import { useFetchDeviceBatches } from '@/hooks/useFetchDeviceBatches';
import { BoxImage } from '@/web/@components/BoxImage';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Card, Divider, IconButton, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

/**
 * @typedef {object} CreateDeviceInformationProps
 * @property {boolean} [disabled]
 */

/** @param {CreateDeviceInformationProps} props */
export function CreateDeviceInformation(props) {
  const { disabled } = props;
  const { control } = useFormContext();
  const { fields, remove, prepend } = useFieldArray({
    control,
    name: 'devices',
  });

  const { result: batches, loading: batchLoading } = useFetchDeviceBatches();

  return (
    <Card sx={{ m: '15px' }}>
      <Box display="flex" justifyContent="space-between" m="10px">
        <Typography variant="body2">Device Information</Typography>
        <Button
          size="small"
          variant="contained"
          startIcon={<AddOutlined />}
          onClick={() => prepend({ batch: batches[0], deviceId: '', imei: '', skuNumber: '' })}
        >
          Add New Device Information
        </Button>
      </Box>
      <Divider />
      <Box
        display="grid"
        gridTemplateColumns={{ md: '1fr 1fr 1fr 1fr .15fr', sm: '1fr' }}
        gap="10px"
        px="10px"
        pb="20px"
      >
        {fields.map((item, index) => (
          <Fragment key={index}>
            <CustomSelect
              name={`devices[${index}].batch`}
              label="Select Batch *"
              loading={batchLoading}
              disabled={disabled}
              options={batches}
              getKey="id"
              getLabel="description"
              placeholder="Select a batch"
              rules={{ required: 'Batch is required' }}
            />

            <CustomInput
              label="Device Id *"
              placeholder="Enter a Device Id"
              name={`devices[${index}].deviceId`}
              rules={{ required: 'Please provide a valid device Id' }}
            />
            <CustomInput
              label="SKU Number *"
              placeholder="Enter SKU Number"
              name={`devices[${index}].skuNumber`}
              rules={{ required: 'Please provide a valid SKU Number' }}
            />

            <CustomInput
              label="IMEI list (Comma Separate) *"
              placeholder="Enter IMEIs"
              name={`devices[${index}].imei`}
              rules={{ required: 'Please provide valid device IMEI' }}
              InputProps={{
                multiline: true,
              }}
            />

            <IconButton
              disabled={fields.length <= 1}
              onClick={() => remove(index)}
              color="error"
              sx={{
                borderRadius: '50%',
                border: '1px solid #596A81',
                width: '30px',
                height: '30px',
                lineHeight: '38px',
                mt: '40px',
              }}
            >
              <BoxImage src={DELETE_ICON} size="20px" />
            </IconButton>
          </Fragment>
        ))}
      </Box>
    </Card>
  );
}
