import { AiAcceleratorListPage } from '.';
import { AiAcceleratorDetailsLayout } from './@components/AiAcceleratorDetailsLayout';
import { CreateAiAcceleratorPage } from './create';
import { EditAiAcceleratorPage } from './_code/edit';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <AiAcceleratorListPage />,
  },
  {
    path: 'create',
    element: <CreateAiAcceleratorPage />,
    breadcrumb: 'Create',
  },
  {
    path: ':code',
    element: <AiAcceleratorDetailsLayout />,
    breadcrumb: 'AI Accelerator Details',
    children: [
      {
        path: 'edit',
        element: <EditAiAcceleratorPage />,
        breadcrumb: 'Edit',
      },
    ],
  },
];

export default routes;
