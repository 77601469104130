import { toastInfo } from '@/utils/toaster';
import { EmailOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import qs from 'qs';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BoxImage } from '../BoxImage';

/** @param {{url: string} & import('@mui/material').IconButtonProps} props */
export function ShareUrlButton(props) {
  const { url, ...extra } = props;

  const [show, setShow] = useState(false);

  /** @type {import('react').MouseEventHandler} */
  const handleShare = (e) => {
    e.stopPropagation();
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  return (
    <>
      <IconButton onClick={handleShare} {...extra}>
        <BoxImage src="/images/navigations/share-icon.svg" size="100%" />
      </IconButton>
      <Dialog open={show} onClose={handleHide} onClick={(e) => e.stopPropagation()}>
        <DialogTitle>Share</DialogTitle>
        <DialogContent
          sx={{
            minWidth: { md: '500px' },
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <TextField
            fullWidth
            value={url}
            variant="outlined"
            InputProps={{
              readOnly: true,
              sx: { pr: '4px' },
              endAdornment: (
                <CopyToClipboard text={url} onCopy={() => toastInfo('Link copied to clipboard')}>
                  <IconButton title="Copy">
                    <BoxImage src="/images/commons/link-icon.svg" size="20px" />
                  </IconButton>
                </CopyToClipboard>
              ),
            }}
          />
          <Box my={1} />
          <Button
            variant="outlined"
            sx={{ gap: 1, height: '44px' }}
            target="_blank"
            title="Email"
            href={
              'mailto:?' +
              qs.stringify({
                subject: 'Smarter AI Share',
                body: url,
              })
            }
          >
            <EmailOutlined />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHide}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
