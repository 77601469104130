import { useDriverList } from '@/hooks/useDriverList';
import { FilterContext, SetFilterContext } from '@/web/@components/FilterContext';
import { PageFilteringArea } from '@/web/@components/PageFilteringArea';
import { sortedUniq } from 'lodash';
import { useContext, useMemo } from 'react';
import {
  CoachingFilterContext,
  SetCoachingFilterContext,
} from '../../@context/CoachingFilterContext';

export function CoachingFilteringArea() {
  const setFilter = useContext(SetFilterContext);
  const setCoachingFilter = useContext(SetCoachingFilterContext);
  const { startTime, endTime } = useContext(FilterContext);
  const { driverName, scoreFrom, scoreTo } = useContext(CoachingFilterContext);

  const { result: drivers } = useDriverList();

  const driverNames = useMemo(
    () =>
      sortedUniq(
        (drivers || [])
          .map((x) => `${x.firstName} ${x.lastName}`)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [drivers]
  );

  return (
    <PageFilteringArea
      value={{
        dateRange: [startTime, endTime],
        driverName,
        scoreRange: [scoreFrom, scoreTo],
      }}
      onChange={(data) => {
        setFilter({
          startTime: data.dateRange[0],
          endTime: data.dateRange[1],
        });
        setCoachingFilter({
          driverName: data.driverName,
          scoreFrom: data.scoreRange[0],
          scoreTo: data.scoreRange[1],
        });
      }}
      filters={[
        {
          type: 'CHOICE',
          key: 'driverName',
          title: 'Driver',
          submenu: driverNames,
          partialSearch: true,
        },
        {
          type: 'DATE',
          key: 'dateRange',
          title: 'Date',
          required: true,
        },
        {
          type: 'SCORE',
          key: 'scoreRange',
          title: 'Score',
          disableClose: true,
        },
      ]}
    />
  );
}
