import { SliderArrow } from '@/web/@components/SnapshotImageSlider/SliderArrow';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { throttle } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BoxImage } from '../BoxImage';
import { ImageNotAvailable } from './ImageNotAvailable';

/**
 * @typedef {object} ImageItem
 * @property {string} [id]
 * @property {string} src
 * @property {any} [overlay]
 */

/**
 * @typedef {object} ImageSliderPropBase
 * @property {boolean} [loading] Default: 2
 * @property {number} [perPageItem] Default: 2
 * @property {Array<string | ImageItem>} [images] Image items
 */

/**
 * @typedef {ImageSliderPropBase & import('@mui/material').BoxProps} ImageSliderProps
 */

/** @param {ImageSliderProps} props */
export function SnapshotImageSlider(props) {
  const { images, loading: parentLoading, perPageItem = 2, children, ...extraProps } = props;

  /** @type {import('react').MutableRefObject<HTMLDivElement>} */
  const container = useRef(null);

  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);

  const items = useMemo(() => {
    const arr = [];
    for (const image of images || []) {
      if (!image) continue;
      if (typeof image === 'string') {
        arr.push({ src: image });
      } else if (typeof image?.src === 'string') {
        arr.push(image);
      }
    }
    while (arr.length < perPageItem) {
      arr.push({ src: null });
    }
    return arr;
  }, [images, perPageItem]);

  useEffect(() => {
    const updateMetrics = () => {
      // reset position on resize
      container.current?.scrollTo({ left: 0 });
      // update current positional info
      setClientWidth(container.current?.clientWidth || 0);
      setScrollWidth(container.current?.scrollWidth || 0);
    };
    updateMetrics();
    const tid = setInterval(() => {
      if (!container.current) return;
      updateMetrics();
      clearInterval(tid);
    }, 100);
    return () => clearTimeout(tid);
  }, []);

  /**
   * @param {import('react').SyntheticEvent<HTMLElement>} e
   * @param {number} direction
   */
  const goToSlide = (e, direction) => {
    e.stopPropagation();
    const left = scrollLeft + (direction * clientWidth) / perPageItem;
    container.current?.scrollTo({ behavior: 'smooth', left });
  };

  return (
    <Box
      className="item-slider-component"
      width="100%"
      {...extraProps}
      position="relative"
      height="fit-content"
    >
      <Box
        ref={container}
        width="100%"
        display="flex"
        overflow="hidden"
        flexWrap="nowrap"
        bgcolor="#F5F5F5"
        style={{ aspectRatio: (perPageItem * 16) / 9 }}
        onScroll={throttle((e) => setScrollLeft(e.target.scrollLeft), 100)}
      >
        {items?.map((item, index) => (
          <Box key={item.id || `${item?.src}-${index}`} position="relative">
            <BoxImage
              alt={item.id || `snapshot-${index}`}
              src={item?.src}
              objectFit="cover"
              objectPosition="center"
              style={{
                width: (clientWidth || 0) / perPageItem,
                aspectRatio: 16 / 9,
              }}
              fallback={
                <ImageNotAvailable
                  children={parentLoading ? <CircularProgress color="secondary" /> : null}
                  style={{
                    width: (clientWidth || 0) / perPageItem,
                    aspectRatio: 16 / 9,
                  }}
                />
              }
            />
            {item?.overlay}
          </Box>
        ))}
      </Box>

      {children}

      <SliderArrow
        sx={{ left: '10px', zIndex: 99 }}
        visible={scrollLeft > 1}
        onClick={(e) => goToSlide(e, -1)}
      >
        <ChevronLeft />
      </SliderArrow>

      <SliderArrow
        sx={{ right: '10px', zIndex: 99 }}
        visible={
          items?.length > perPageItem && scrollWidth > 0 && scrollLeft + clientWidth < scrollWidth
        }
        onClick={(e) => goToSlide(e, 1)}
      >
        <ChevronRight />
      </SliderArrow>
    </Box>
  );
}
