import { ASSIGN_ICON, BOX_MODIFY_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<import('@/types').ProductProperties>>} */
export const ACTION_ITEMS = [
  {
    type: 'ASSIGN',
    label: T['product.assign.tenant'],
    iconImage: ASSIGN_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    useCustomDialog: true,
    hidden: (selected) => selected.length !== 1,
  },
  // TODO: should not be available for now
  // {
  //   type: 'DISCONTINUE',
  //   label: 'Delete',
  //   iconImage: BOX_DELETE_ICON,
  //   component: 'BUTTON',
  //   variant: 'outlined',
  //   hidden: (selected) => !selected.length,
  //   confirmationMessage: T['product.confirmations.discontinue'],
  // },
  {
    type: 'MODIFY',
    label: 'Modify',
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => selected.length !== 1,
    link: (selectedItem) => `/smarter-ai/products/${selectedItem.productId}/edit`,
  },
  {
    label: 'Search',
    placeholder: 'Search Product',
    component: 'SEARCH',
  },
  {
    label: 'Product',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/smarter-ai/products/create',
  },
];
