import { toastWarning } from '@/utils/toaster';
import { Box, CircularProgress } from '@mui/material';
import { sortBy } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CameraHealthContext } from '../..';
import { HealthTagItem } from './HealthTagItem';

/** @param {{endpoint: EndpointHealthInfo}} props */
export function HealthTagList(props) {
  const { endpoint } = props;

  /** @type {import('react').Ref<HTMLElement>} */
  const containerRef = useRef();

  const fetcher = useContext(CameraHealthContext);
  /** @type {StateVariable<number>} */
  const [loading, setLoading] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [moveMove, setMoveMode] = useState(false);
  /** @type {StateVariable<boolean>} */
  const [showLeft, setShowLeft] = useState(false);
  /** @type {StateVariable<boolean>} */
  const [showRight, setShowRight] = useState(false);
  /** @type {StateVariable<AssignedHealthTagResponse[]>} */
  const [latestTags, setLatestTags] = useState([]);

  useEffect(() => {
    const processLatestTags = () => {
      const camera = fetcher.endpointInfo[endpoint.endpointId];
      if (!camera?.tags?.length) return setLatestTags([]);
      /** @type {{[key: string]: AssignedHealthTagResponse}} */
      const tags = {};
      for (const tag of camera.tags) {
        if (tags[tag.tagId] && tag.reportedTimestamp < tags[tag.tagId].reportedTimestamp) continue;
        tags[tag.tagId] = tag;
      }
      const tagList = sortBy(Object.values(tags), 'reportedTimestamp').reverse();
      setLatestTags(tagList);
    };
    processLatestTags();
    fetcher.on('tags', processLatestTags);
    return () => fetcher.off('tags', processLatestTags);
  }, [endpoint.endpointId, fetcher]);

  const handleScroll = useCallback(() => {
    try {
      const div = containerRef.current;
      if (!div) return;
      const left = div.scrollLeft;
      const right = div.scrollWidth - (left + div.clientWidth);
      setShowLeft(left > 1);
      setShowRight(right > 1);
    } catch {}
  }, []);

  useEffect(() => {
    const tid = setTimeout(() => {
      window.requestAnimationFrame(handleScroll);
    }, 100);
    return () => clearTimeout(tid);
  }, [latestTags, handleScroll]);

  useEffect(() => {
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll);
  }, [handleScroll]);

  /** @type {import('react').PointerEventHandler} */
  const handleMouseMove = (e) => {
    if (!moveMove) return;
    const div = containerRef.current;
    div.scrollLeft -= e.movementX;
  };

  /** @param {AssignedHealthTagResponse} tag */
  const handleRemove = async (tag) => {
    if (loading) return;
    try {
      setLoading(tag.tagId);
      await fetcher.removeTag(endpoint, tag.tagId);
    } catch (err) {
      console.error(err);
      toastWarning('Oops', 'Could not remove the Tag');
    } finally {
      setLoading(null);
    }
  };

  if (!endpoint.tags) {
    return (
      <CircularProgress
        size="24px"
        sx={{
          bgcolor: 'rgba(0,0,0,0.15)',
          color: '#fff',
          p: '4px',
          borderRadius: '50%',
        }}
      />
    );
  }

  if (!latestTags.length) {
    return null;
  }

  return (
    <Box
      width="100%"
      position="relative"
      onPointerMove={handleMouseMove}
      onPointerDown={() => setMoveMode(true)}
      onPointerLeave={() => setMoveMode(false)}
      onPointerUp={() => setMoveMode(false)}
      style={{
        cursor: 'grab',
        userSelect: 'none',
      }}
    >
      <Box
        ref={containerRef}
        onScroll={handleScroll}
        overflow="scroll visible"
        display="flex"
        alignItems="center"
        fontSize="0.675rem"
        fontWeight="500"
        width="100%"
        sx={{
          '&::-webkit-scrollbar': {
            height: 0,
            width: 0,
          },
        }}
      >
        {latestTags.map((tag) => (
          <HealthTagItem
            key={tag.id}
            tag={tag}
            groupId={endpoint.groupId}
            onRemove={() => handleRemove(tag)}
            loading={loading === tag.tagId}
          />
        ))}
      </Box>
      {showLeft && (
        <Box
          style={{
            color: 'white',
            position: 'absolute',
            left: 0,
            top: '10px',
            bottom: '10px',
            width: '10px',
            background: 'linear-gradient(90deg, #07070740 0%, #FFFFFF00 100%) no-repeat',
          }}
        />
      )}
      {showRight && (
        <Box
          style={{
            color: 'white',
            position: 'absolute',
            right: 0,
            top: '10px',
            bottom: '10px',
            width: '10px',
            background: 'linear-gradient(270deg, #07070740 0%, #FFFFFF00 100%) no-repeat',
          }}
        />
      )}
    </Box>
  );
}
