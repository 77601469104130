import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { convertTimeToSeconds } from '@/utils/geofence';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GeofenceForm } from '../@components/GeoFenceForm';

export function CreateGeofencePage() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  /** @type {import('../@components/GeoFenceForm').GeofenceFormProps['onSubmitData']} */
  const createGeoFence = async (form) => {
    /** @type {CreateGeofenceWithScheduleAndDeploymentDetails} */
    const data = {
      geofence: {
        active: true,
        description: form.description,
        latitude: form.latitude,
        longitude: form.longitude,
        name: form.name,
        radius: form.radius,
        tenantId: tenantId,
        timezone: form.timezone?.value,
      },
      scheduleWithGivenVinListAndDeploymentDetails: {
        aiContainerIdToDeploy: form.aiContainer?.containerId,
        attachedVinList: form.vins,
        geofenceSchedule: {
          active: true,
          dayOfMonthStart: 1,
          dayOfMonthEnd: 31,
          dayOfWeekStart: 0,
          dayOfWeekEnd: 6,
          daySecondStart: convertTimeToSeconds(form.daySecondStart), //sec
          daySecondEnd: convertTimeToSeconds(form.daySecondEnd), // sec
          startTimestamp: form.startTimestamp, //range in epoch milli
          endTimestamp: form.endTimestamp, //range in epoch milli
          monthStart: 1,
          monthEnd: 12,
          name: `schedule-${form.name}`,
          yearStart: 2000,
          yearEnd: 3000,
        },
        triggerIdListToDeploy: form.triggers.map((item) => item.id),
      },
    };
    try {
      setLoading(true);
      const req = await api.ac.v3.geolocation.fence.$post({
        headers: {
          Authorization: secretToken,
        },
        data,
      });
      await req.process();
      toastSuccess('Success', 'Geofence Created Successfully');
      navigate('/operations/geofences/');
    } catch (err) {
      toastWarning('Error', 'Failed to create geofence');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2.5}>
      <GeofenceForm onSubmitData={createGeoFence} loading={loading} />
    </Box>
  );
}
