import { Box, Popover, Typography } from '@mui/material';
import { useState } from 'react';

export function CoolDownTimeBar(props) {
  const { isEnable, cooldownTimer = 0, min = 0, max = 100 } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Normalize the value to be between 0 and 100
  const normalizedValue = Math.min(max, Math.max(min, cooldownTimer));

  const lineStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '90px',
    height: '4.6px',
    backgroundColor: '#608ac3',
    position: 'relative',
    borderRadius: '50px !important',
  };

  const circleStyle = {
    width: '12px',
    height: '12px',
    borderRadius: '70%',
    border: '2px solid white',
    backgroundColor: '#608ac3',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  const redLineStyle = {
    flexGrow: 1,
    height: '100%',
    backgroundColor: '#e32a28',
    margin: `0 ${100 - normalizedValue - 4}% 0 0`,
    borderRadius: '50px !important',
  };

  if (!Boolean(isEnable)) {
    return (
      <Box fontSize="0.875rem" alignItems="center">
        <Typography fontSize="0.875rem" color="#b6c4d5">
          Turned Off
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      height="15px"
      minWidth="100px"
      width="100px"
      margin="auto"
      sx={{ ...lineStyle, mt: -1 }}
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Box sx={redLineStyle}></Box>
      <Box sx={{ ...circleStyle, left: `${normalizedValue}%` }}>
        <Box pt={0.4}></Box>
        <Typography
          variant="caption"
          sx={{ whiteSpace: 'nowrap', ml: !cooldownTimer ? 0 : '-11px' }}
        >
          {cooldownTimer} sec
        </Typography>
      </Box>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(open)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box m={1} display="grid">
          <Typography variant="caption">Cool Down Time: {cooldownTimer} sec</Typography>
        </Box>
      </Popover>
    </Box>
  );
}
