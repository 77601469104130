import {
  ASSIGN_CHILD_ICON_ON,
  BOX_CREATE_ICON,
  BOX_LIST_ICON,
  SCHEME_LIST_ICON_ON,
  TENANTS_ICON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';

/** @type {Array<PageCardItem>} */
export const SCHEME_MANAGEMENT_ITEMS = [
  {
    name: T['navigation.view.scheme'],
    image: SCHEME_LIST_ICON_ON,
    color: '#EFF3F7',
    countHide: true,
    link: '/smarter-ai/scheme-management/schemes',
    components: [
      {
        name: 'Browse Schemes',
        image: BOX_LIST_ICON,
        link: '/smarter-ai/scheme-management/schemes',
      },
      {
        name: 'Create Scheme',
        image: BOX_CREATE_ICON,
        link: '/smarter-ai/scheme-management/schemes/create',
      },
    ],
  },
  {
    name: T['page.card.tenant.deployment'],
    image: ASSIGN_CHILD_ICON_ON,
    color: '#F4F7EF',
    countHide: true,
    accessLevel: 'SMARTER_AI_ADMIN',
    components: [
      {
        name: T['page.card.tenant.deployment'],
        image: TENANTS_ICON,
        link: '/smarter-ai/scheme-management/tenant-deployment',
      },
    ],
  },
];
