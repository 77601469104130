import { T } from '@/assets/locales';
import { TriggerListView } from '.';
import { ExpressionTriggerEdit } from './_id/edit';
import { ExpressionTriggerCreate } from './create';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <TriggerListView />,
  },
  {
    path: 'create',
    element: <ExpressionTriggerCreate />,
    breadcrumb: T['triggers.create.button'],
  },
  {
    path: ':id',
    children: [
      {
        path: 'edit',
        element: <ExpressionTriggerEdit />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
