import {
  formatDuration,
  formatDurationIgnoringSec,
  getHumanReadableDateDiff,
} from '@/utils/datetime';
import { collapseDecimal } from '@/utils/sensors/extractor';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

/**
 * @param {{item: Trip, stats: import('.').TripStats}} props
 */
export function TripListViewItemFooter(props) {
  const { item, stats } = props;

  const [distance, setDistance] = useState(0);
  const [idleTime, setIdleTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!item) return;
    setIdleTime(stats?.idleTime);
    setDistance(item.distance / 1000 || stats?.totalDistance || 0); // KM
    const endTimestamp = item.endTimestamp || Date.now();
    if (item.tripStatus === 'STARTED' || item.startTimestamp > endTimestamp) {
      setDuration(0);
    } else {
      setDuration(endTimestamp - item.startTimestamp);
    }
  }, [item, stats]);

  if (!item) {
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        textAlign="center"
        px={2}
        py={1}
        sx={{
          '& .MuiTypography-root': {
            lineHeight: '14px',
          },
          'height': '40px',
        }}
      >
        <Box>
          <Typography fontSize={'0.75rem'} variant="subtitle2">
            Distance
          </Typography>
          <Typography fontSize={'0.75rem'} fontWeight="medium">
            {distance ? `${collapseDecimal(distance, 2)} km` : 'Not Available'}
          </Typography>
        </Box>
        {idleTime ? (
          <Box title={formatDuration(idleTime, { short: false })}>
            <Typography fontSize={'0.75rem'} variant="subtitle2">
              Idle Time
            </Typography>
            <Typography fontSize={'0.75rem'} fontWeight="medium">
              {!idleTime ? '0s' : formatDurationIgnoringSec(idleTime)}
            </Typography>
          </Box>
        ) : null}
        <Box title={formatDuration(duration, { short: false })}>
          <Typography fontSize={'0.75rem'} variant="subtitle2">
            Duration
          </Typography>
          <Typography fontSize={'0.75rem'} fontWeight="medium">
            {!duration ? '0s' : formatDurationIgnoringSec(duration)}
          </Typography>
        </Box>
      </Box>
      {item?.tripStatus !== 'ENDED' ? (
        <Typography
          display="flex"
          justifyContent="flex-end"
          pb="2px"
          px="5px"
          variant="subtitle2"
          fontStyle="italic"
          fontSize="10px"
          fontWeight="400"
        >
          Last Update{' '}
          {getHumanReadableDateDiff(
            stats?.lastUpdateTime || item.endTimestamp || item.startTimestamp
          )}
        </Typography>
      ) : null}
    </>
  );
}
