import { DELETE_ICON } from '@/assets/constants/images';
import { trimString } from '@/utils/formatting';
import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { SearchField } from '@/web/@components/SearchField';
import { AddOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SCHEME_PROPERTY_FIELDS } from '../../assets';
import { searchSchemeItems } from '../../utils/search';
import { SchemeFormItemContext } from '../SchemeForm';
import { MemoizedSchemePropertyFormControl } from './SchemePropertyFormControl';

export function SchemePropertiesForm(props) {
  const { isReadOnly } = props;

  const { control } = useFormContext();
  const { productInputs } = useContext(SchemeFormItemContext);

  const { fields, remove, append } = useFieldArray({ control, name: 'properties' });

  const [filteredResults, setFilteredResults] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchEnable, setSearchEnable] = useState(false);

  const wrapperRef = useRef();

  const mapFieldsToIndexedItems = useMemo(() => {
    return fields.map((item, index) => ({ ...item, originalIndex: index }));
  }, [fields]);

  const filedItems = useMemo(() => {
    return isEmpty(filter) ? mapFieldsToIndexedItems : filteredResults;
  }, [mapFieldsToIndexedItems, filter, filteredResults]);

  // Handle Click outside for bigger display
  const handleMenuCloseWhileClickOutside = useCallback(
    (e) => {
      // @ts-ignore
      if (wrapperRef.current && searchEnable && !wrapperRef.current?.contains(e.target)) {
        setTimeout(() => {
          setSearchEnable(false);
        }, 100);
      }
    },
    [searchEnable]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMenuCloseWhileClickOutside);
    return () => {
      document.removeEventListener('visibilitychange', handleMenuCloseWhileClickOutside);
    };
  }, [handleMenuCloseWhileClickOutside]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isEmpty(filter)) {
          setFilteredResults([]);
          return;
        }

        setLoading(true);
        const searchBy = trimString(filter).toLowerCase();
        const items = searchSchemeItems(mapFieldsToIndexedItems, searchBy);
        // @ts-ignore
        const newItems = mapFieldsToIndexedItems.filter((i) => i.item.length === 0);
        const filteredItems = await Promise.all(
          items.map(async (item) => {
            const originalIndex = mapFieldsToIndexedItems.findIndex(
              // @ts-ignore
              (field) => field.item === item.item
            );
            return { ...item, originalIndex };
          })
        );
        setFilteredResults([...filteredItems, ...newItems]);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [mapFieldsToIndexedItems, filter, setLoading, setFilteredResults]);

  if (loading) {
    return (
      <CenterBox style={{ height: '300px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  const SearchComponent = () => (
    <Box
      sx={{
        display: 'flex',
        textAlign: 'end',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={() => setSearchEnable(true)}
    >
      <IconButton sx={{ mr: '-5px' }}>
        <BoxImage src="/images/commons/search-icon.svg" size="16px" />
      </IconButton>
      <Typography variant="subtitle2" color="#99a9bd">
        Search
      </Typography>
    </Box>
  );

  return (
    <Box mb="15px" mt={2}>
      {/* Search Box Render */}
      {fields?.length > 0 && (
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" mb="10px">
          <Grid container>
            <Grid
              item
              xs={12}
              md={11}
              lg={10}
              minHeight="42px"
              display="flex"
              justifyContent="flex-end"
            >
              {searchEnable ? (
                <Box
                  ref={wrapperRef}
                  sx={{
                    'width': '100%',
                    'display': 'flex',
                    '& .MuiInputBase-root': {
                      'padding': '5px !important',
                      'borderRadius': '20px',
                      '& input::placeholder': {
                        fontSize: '16px',
                      },
                      '& fieldset': {
                        display: 'none',
                      },
                    },
                  }}
                >
                  <SearchField
                    clearable
                    ignoreCase
                    placeholder="Search"
                    onSearch={setFilter}
                    InputProps={{
                      onInput: (event) => {
                        // @ts-ignore
                        setFilter(event.target.value);
                      },
                    }}
                    sx={{ width: '100%', height: '42px' }}
                  />
                </Box>
              ) : (
                <SearchComponent />
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      {/* Search Box Render End */}

      {/* Field Items Render */}
      {filedItems?.map((itemField, index) => (
        <Box
          display="flex"
          justifyContent="space-between"
          key={itemField.id + index + itemField.originalIndex}
        >
          <Grid container spacing="10px" mb="10px">
            {SCHEME_PROPERTY_FIELDS.map((item, index) => (
              <MemoizedSchemePropertyFormControl
                key={index + itemField.originalIndex}
                item={item}
                fieldIndex={itemField.originalIndex}
                isReadOnly={isReadOnly}
              />
            ))}

            {!isReadOnly && (
              <IconButton
                onClick={() => remove(itemField.originalIndex)}
                color="secondary"
                sx={{
                  'mt': '10px',
                  'ml': '10px',
                  'height': 40,
                  'width': 40,
                  '&:hover': {
                    background: 'rgba(211, 47, 47, 0.15)',
                  },
                }}
              >
                <BoxImage src={DELETE_ICON} size="16px" />
              </IconButton>
            )}
          </Grid>
        </Box>
      ))}
      {/* Field Items Render End*/}

      {/* Add Button for new Items Render*/}
      {!isReadOnly && (
        <Box display="flex" justifyContent="flex-start" alignItems="flex-start" mb="5px">
          <Button
            size="small"
            disabled={!productInputs?.length}
            sx={{ color: '#2c69bc' }}
            onClick={() => append({ item: [], value: '' })}
            startIcon={<AddOutlined />}
          >
            Add
          </Button>
        </Box>
      )}
      {/*End of  Add Button for new Items Render*/}
    </Box>
  );
}
