import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { endOfTheDay, formatDateForTriggerReport, formatDateWithWeek } from '@/utils/datetime';
import { formatTriggerName } from '@/utils/events';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomDateRangePicker } from '@/web/@components/CustomDateRangePicker';
import LightTooltip from '@/web/@components/LightTooltip';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export function TriggerReportDetailsGraph() {
  const { triggerId } = useParams();
  const { setBreadcrumbTitle } = useContext(MainContext);
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  /** @type {SmartCache<CompositeTriggerDto>} */
  const triggerReportCache = useMemo(
    () => new SmartCache(`trigger-report-${tenantId}`, 120 * 24 * 3600 * 1000),
    [tenantId]
  );

  /** @type {StateVariable<number>} */
  const [loading, setLoading] = useState(0);
  /** @type {StateVariable<number>} */
  const [startTime, setStartTime] = useState(endOfTheDay() - 30 * 24 * 3600 * 1000 + 1);
  /** @type {StateVariable<number>} */
  const [endTime, setEndTime] = useState(endOfTheDay());
  /** @type {StateVariable<string>} */
  const [triggerName, setTriggerName] = useState(null);
  /** @type {StateVariable<Array<{time: number, value: number}>>} */
  const [results, setResults] = useState([]);

  useEffect(() => {
    setLoading((v) => v + 1);
    triggerReportCache
      .getItem(triggerId)
      .then((v) => v?.name)
      .then(formatTriggerName)
      .then(setTriggerName)
      .catch(console.error)
      .finally(() => setLoading((v) => v - 1));
  }, [triggerId, triggerReportCache]);

  useEffect(() => {
    if (!triggerName) return;
    setBreadcrumbTitle(triggerName, (path) => path.endsWith(triggerId));
  }, [triggerId, triggerName, setBreadcrumbTitle]);

  useEffect(() => {
    if (!startTime || !endTime || !triggerId) return;
    setLoading((v) => v + 1);
    const aborter = new AbortController();
    const step = 24 * 3600 * 1000;
    const promises = [];
    for (let time = startTime; time < endTime; time += step) {
      const request = api.ac.v2['event-messaging'].trigger.report.result.$get({
        signal: aborter.signal,
        params: {
          fromTimestamp: time,
          toTimestamp: time + step - 1,
          secretToken,
          triggerId,
        },
      });
      const process = async () => {
        const result = await request.process();
        if (!result) return { time, value: 0 };
        const tp = result?.correctTotal ?? 0;
        const reviewed = result?.eventsTotal ?? 0;
        const fp = reviewed - tp;
        const precision = 1 - fp / (fp + tp);
        const value = Math.floor(100 * precision);
        return { time, value };
      };
      promises.push(process());
    }
    Promise.all(promises)
      .then(setResults)
      .catch((err) => console.error('Failed to fetch trigger reports', err))
      .finally(() => setLoading((v) => v - 1));
    return () => aborter.abort();
  }, [triggerId, startTime, endTime, secretToken]);

  if (loading) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box px={2.5} py={2}>
      <Box
        gap={1}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'space-between' }}
      >
        <Typography variant="subtitle2" fontWeight="500" fontSize="1.125rem">
          {triggerName} - Trend
        </Typography>
        <CustomDateRangePicker
          startTime={startTime}
          endTime={endTime}
          maxDate={new Date(endOfTheDay())}
          minDate={new Date(endOfTheDay() - 30 * 24 * 3600 * 1000 + 1)}
          onChange={(range) => {
            setStartTime(range.startTime);
            setEndTime(range.endTime);
          }}
        />
      </Box>

      <Box mt={1.5} pb="20px" position="relative">
        {/* Draw Y axis */}
        {[0, 20, 40, 60, 80, 100].reverse().map((value) => (
          <Box key={value} display="flex" gap="5px" height="50px">
            <Typography
              component="div"
              variant="subtitle1"
              textAlign="right"
              width="30px"
              lineHeight="20px"
              fontSize="0.75rem"
              children={value}
            />
            <Box width="100%" mt="10px" borderTop="1px dashed #DCE1EA" />
          </Box>
        ))}
        {/* Draw X axis and Bars */}
        <Box
          display="flex"
          justifyContent="space-around"
          gap="5px"
          position="absolute"
          top={10}
          left={35}
          right={0}
          bottom={26}
          pb="10px"
        >
          {results.map(({ time, value }) => (
            <Box
              key={time}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              gap="5px"
            >
              <LightTooltip
                arrow
                followCursor
                placement="right"
                title={
                  <Box minWidth="150px" textAlign="center">
                    <Box display="grid" gap={0}>
                      <Typography
                        variant="body1"
                        lineHeight="12px"
                        fontSize="0.875rem"
                        pt={1}
                        fontWeight={500}
                      >
                        {triggerName}
                      </Typography>
                      <Typography variant="caption" fontSize="0.68rem">
                        {formatDateWithWeek(time)}
                      </Typography>
                    </Box>

                    <Box pt={0.8}>
                      <Typography variant="h6">{value || 0}%</Typography>
                    </Box>
                  </Box>
                }
              >
                <Box
                  bgcolor="#E4ECF4"
                  borderRadius="8px"
                  width="10px"
                  height="100%"
                  position="relative"
                >
                  {Boolean(value) && (
                    <Box
                      bgcolor="#2680EB"
                      borderRadius="8px"
                      position="absolute"
                      left={0}
                      right={0}
                      top={`${100 - value}%`}
                      bottom={0}
                    />
                  )}
                </Box>
              </LightTooltip>
              <Typography
                component="div"
                variant="subtitle1"
                textAlign="center"
                lineHeight="18px"
                fontSize="0.75rem"
              >
                {formatDateForTriggerReport(time)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
