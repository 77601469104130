import { SORTED_LABEL_UNITS, STATIC_SOURCE_LIST, THRESHOLD_INPUT_TYPE } from '@/assets/labels';
import { T } from '@/assets/locales';
import { trimString } from '@/utils/formatting';
import { toastWarning } from '@/utils/toaster';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const defaultValues = {
  type: null,
  unit: null,
  name: '',
  displayName: '',
  triggerThresholdInputType: null,
  inputStepSize: 1,
  min: 0,
  max: 100,
  description: '',
};

/**
 * @typedef {object} LabelFormPropsBase
 * @property {(data: typeof defaultValues) => any} onSubmitData
 * @property {boolean} isLoading
 */

/**
 * @typedef {LabelFormPropsBase & import('@mui/material').BoxProps} LabelFormProps
 */

/**
 * @param {LabelFormProps} props
 */
export function LabelForm(props) {
  const { onSubmitData, isLoading } = props;

  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const labelId = useMemo(() => params.id, [params]);

  const methods = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  const { handleSubmit } = methods;

  //NOTE:: No Edit for this feature, because all newly created labels status is RELEASED

  const onSubmit = (data) => {
    const { min, max, inputStepSize } = data;
    if (Number(min) >= Number(max)) {
      toastWarning('Min cannot be greater or equal to max');
      return;
    }
    if (Number(inputStepSize) <= 0 || Number(inputStepSize) > Number(max) - Number(min)) {
      toastWarning('Invalid step size');
      return;
    }
    data.name = trimString(data.name);
    data.displayName = trimString(data.displayName);
    data.description = trimString(data.description);
    onSubmitData(data);
  };

  const handleCancel = () => {
    navigate('/smarter-ai/labels');
  };

  return (
    <Box>
      <Typography
        variant="body2"
        fontSize="1rem"
        mt={'20px'}
        mb={'10px'}
        sx={{ color: ' #0B2547', opacity: 0.68, fontSize: '16px' }}
      >
        {t(T['label.form.text.secondary'])}
      </Typography>
      <Divider />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
          <Box
            display="grid"
            gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr 1fr' }}
            gap="10px"
            padding="10px"
          >
            <CustomInput
              name="name"
              label="Label *"
              placeholder="Please enter a label name"
              rules={{ required: 'This is required' }}
            />
            <CustomInput
              name="displayName"
              label="Display Name *"
              placeholder="Please Enter display name"
              rules={{ required: 'This is required' }}
            />
            <CustomSelect
              name="unit"
              label="Unit *"
              options={SORTED_LABEL_UNITS}
              placeholder="Please select a unit"
              rules={{ required: 'This is required' }}
            />
            <CustomSelect
              name="type"
              label="Source *"
              options={STATIC_SOURCE_LIST}
              placeholder="Please select a source"
              rules={{ required: 'This is required' }}
            />
            <CustomSelect
              name="triggerThresholdInputType"
              label="Threshold Input Type *"
              options={THRESHOLD_INPUT_TYPE}
              placeholder="Please select a threshold input type"
              rules={{ required: 'This is required' }}
            />
            <CustomInput
              name="min"
              label="Min *"
              placeholder="Threshold"
              type="number"
              rules={{ required: 'This is required' }}
            />
            <CustomInput
              name="max"
              label="Max *"
              placeholder="Threshold"
              type="number"
              rules={{ required: 'This is required' }}
            />
            <CustomInput
              name="inputStepSize"
              label="Input Step Size *"
              placeholder="Step Size"
              type="number"
              rules={{ required: 'This is required' }}
            />
            <CustomInput
              name="description"
              label="Description"
              placeholder="Enter a description"
              minRows={3}
            />
          </Box>

          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={10}>
            <Button variant="text" onClick={handleCancel}>
              {t(T['button.cancel'])}
            </Button>
            <Button disabled={isLoading} variant="contained" type="submit">
              {isLoading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              {labelId ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
