import { BOX_DISCONTINUE, BOX_MODIFY_ICON, BOX_RELEASE_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<GeofenceResponseModel>>} */
export const ACTIONS_ITEMS = [
  {
    label: T['button.release'],
    iconImage: BOX_RELEASE_ICON,
    component: 'BUTTON',
    type: 'RELEASE',
    variant: 'outlined',
    confirmationMessage: T['geofence.confirmations.release'],
    hidden: (selected) =>
      !selected.length || selected.length > 1 || selected[0]?.publicationStatus === 'RELEASED',
  },
  {
    label: 'Discontinue',
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    confirmationMessage: T['geofence.confirmations.discontinue'],
    hidden: (selected) => !selected.length || selected[0]?.publicationStatus === 'DISCONTINUED',
  },
  {
    label: 'Modify',
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    link: (selectedItem) => `/operations/geofences/${selectedItem.id}/edit`,
    hidden: (selected) =>
      selected.length !== 1 ||
      ['RELEASED', 'DISCONTINUED'].includes(selected[0]?.publicationStatus),
  },
  {
    label: 'Search',
    placeholder: 'Search Geofence',
    component: 'SEARCH',
    options: [
      { value: 'geoFenceName', label: 'Name' },
      { value: 'publicationStatus', label: 'Status' },
    ],
  },
  {
    label: 'Geofence',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/operations/geofences/create',
  },
];
