import { T } from '@/assets/locales';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<DeviceBatchResponse>>} */
export const DEVICE_BATCH_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: '1%',
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'description',
    label: T['device-batch.columns.name'],
    align: 'left',
    width: '30%',
    isPrimary: true,
    searchable: true,
  },
  {
    id: 'packageId',
    label: T['device-batch.columns.package'],
    align: 'left',
    width: '30%',
    searchable: true,
    format: (value) => value,
  },
];
