import { T } from '@/assets/locales';
import { OperationPage } from '.';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <OperationPage />,
  },
  {
    path: 'annotations',
    children: require('./annotations/routes').default,
    breadcrumb: T['navigation.annotations'],
  },
  {
    path: 'geofences',
    children: require('./geofences/routes').default,
    breadcrumb: T['navigation.operations.geofences'],
  },
];

export default routes;
