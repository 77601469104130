import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { formatCameraName } from '@/utils/cameras';
import { formatQuery, testMatch } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { Box, CircularProgress, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CameraHealthContext } from '../..';
import { EndpointListSlider } from '../EndpointListSlider';
import { TAG_TITLES } from '../../mapping';

export function TagWiseGroupedList() {
  const { query, updateQuery } = useRouteQuery();
  const fetcher = useContext(CameraHealthContext);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<HealthTagResponse[]>} */
  const [tags, setTags] = useState(undefined);
  /** @type {StateVariable<{[key: string]: EndpointHealthInfo[]}>} */
  const [endpoints, setEndpoints] = useState(undefined);

  const searchText = useMemo(() => formatQuery(query.search), [query.search]);

  useEffect(() => {
    if (!fetcher) return;
    const process = () => {
      try {
        /** @type {HealthTagResponse[]} */
        const tags = [];
        /** @type {{[key: string]: EndpointHealthInfo[]}} */
        const endpoints = {};
        for (const tag of fetcher.tags) {
          const tagMatch = testMatch(tag.tagName, searchText);
          for (const endpointId of fetcher.tagEndpoints[tag.id] || []) {
            const endpoint = fetcher.endpointInfo[endpointId];
            let endpointMatch = testMatch(formatCameraName(endpoint.deviceLabel), searchText);
            if (!endpointMatch) {
              const tenant = fetcher.tenants.find((x) => x.tenantId === endpoint.tenantId);
              if (tenant?.tenantName?.length) {
                endpointMatch = testMatch(
                  tenant.tenantName[tenant.tenantName.length - 1],
                  searchText
                );
              }
            }
            if (tagMatch || endpointMatch) {
              endpoints[tag.id] ||= [];
              endpoints[tag.id].push(endpoint);
            }
          }
          if (endpoints[tag.id]?.length) {
            tags.push(tag);
          }
        }
        setEndpoints(endpoints);
        setTags(sortBy(tags, 'tagName'));
        if (tags?.length) setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    const start = () => {
      setTags(undefined);
      setEndpoints(undefined);
      setLoading(fetcher.loading);
    };
    const finish = () => {
      setLoading(false);
    };
    process();
    fetcher.on('started', start);
    fetcher.on('tags', process);
    fetcher.on('finish', finish);
    return () => {
      fetcher.off('started', start);
      fetcher.off('tags', process);
      fetcher.off('finish', finish);
    };
  }, [searchText, fetcher]);

  if (loading) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (!tags?.length) {
    return (
      <CenterBox fullView>
        <IconMessageBox src={NO_SEARCH_RESULT_IMG} size="256px" message="No items" />
      </CenterBox>
    );
  }

  /** @param {string} id */
  const handleViewAll = (id) => {
    updateQuery({ view: query.view === id ? null : id });
  };

  return (
    <Box>
      {tags.map((tag) => (
        <EndpointListSlider
          key={tag.id}
          endpoints={endpoints[tag.id]}
          onViewAll={() => handleViewAll(tag.id + '')}
          display={query.view && query.view !== tag.id + '' ? 'none' : 'block'}
          headerTitle={
            <Typography variant="body2" fontWeight={500}>
              {TAG_TITLES[tag.tagName] || tag.tagName}
            </Typography>
          }
        />
      ))}
    </Box>
  );
}
