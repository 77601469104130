import { Box, Popover, Typography } from '@mui/material';
import { useState } from 'react';

export function BufferInfo(props) {
  const { preBuffer = 0, postBuffer = 0 } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const lineStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '4.6px',
    backgroundColor: '#608ac3',
    position: 'relative',
    borderRadius: '13px',
  };

  const circleStyle = {
    width: '10px',
    height: '10px',
    borderRadius: '70%',
    border: '2px solid white',
    backgroundColor: '#608ac3',
    position: 'absolute',
    padding: '4px',
  };

  const redLineStyle = {
    flexGrow: 1,
    height: '100%',
    backgroundColor: '#e32a28',
    margin: `0 33px 0 33px`,
  };

  return (
    <>
      <Box
        height="15px"
        minWidth="100px"
        width="100px"
        textAlign="center"
        margin="auto"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Box sx={lineStyle}>
          <Box sx={{ ...circleStyle, left: `${21 - Math.min(preBuffer * 1.5, 20)}px` }}>
            <Typography
              variant="caption"
              sx={{ whiteSpace: 'nowrap', ml: preBuffer < 0.01 ? '-25px' : '-15px' }}
            >
              {preBuffer} sec
            </Typography>
          </Box>
          <Box sx={redLineStyle}></Box>
          <Box sx={{ ...circleStyle, right: `${21 - Math.min(postBuffer * 1.5, 20)}px` }}>
            <Typography
              variant="caption"
              sx={{ whiteSpace: 'nowrap', ml: postBuffer < 0.01 ? '-9px' : '-13px' }}
            >
              {postBuffer} sec
            </Typography>
          </Box>
        </Box>
      </Box>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(open)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box m={1} display="grid">
          <Typography variant="caption">Pre Buffer: {preBuffer || 0} sec</Typography>
          <Typography variant="caption">Post Buffer: {postBuffer || 0} sec</Typography>
        </Box>
      </Popover>
    </>
  );
}
