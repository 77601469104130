import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

export function GuideCableKitStandardTable() {
  return (
    <Box width="100%" style={{ overflowX: 'auto' }}>
      <Typography fontSize="1.125rem" fontWeight="600" py={1}>
        Standard Cable Kit
      </Typography>
      <Table padding="none">
        <TableHead
          sx={{
            '& .MuiTableCell-root': {
              px: 2,
              py: 1,
              color: '#fff',
              fontWeight: '600',
              border: 'none',
            },
          }}
        >
          <TableRow style={{ background: '#596A81' }}>
            <TableCell>Wire</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Connect to</TableCell>
            <TableCell>Connection</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-root': {
              px: 2,
              py: 1,
              border: '1px solid #DCE7F5',
              borderTop: 'none',
            },
          }}
        >
          <TableRow>
            <TableCell>ACC</TableCell>
            <TableCell style={{ background: '#AD4343', borderColor: '#AD4343', color: '#fff' }}>
              Red
            </TableCell>
            <TableCell>Accessory/Ignition</TableCell>
            <TableCell rowSpan={3}>Mandatory</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>B+</TableCell>
            <TableCell style={{ background: '#DED342', borderColor: '#DED342' }}>Yellow</TableCell>
            <TableCell>Power (12-24VDC)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>GND</TableCell>
            <TableCell style={{ background: '#000000', borderTopColor: '#000000', color: '#fff' }}>
              Black
            </TableCell>
            <TableCell>Ground</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
