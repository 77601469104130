import { store } from '@/store';
import { PageView } from '@/store/page-view';
import { LogoImage } from '@/web/@components/LogoImage';
import { MasterHeaderSearch } from '@/web/@components/MasterHeaderSearch';
import { PageBreadcrumb } from '@/web/@components/PageBreadcrumb';
import { UserMenu } from '@/web/@components/UserMenu';
import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { TOOLBAR_HEIGHT } from '../config';

/** @param {import('@mui/material').AppBarProps} props */
export function MainLayoutAppBar(props) {
  const toggleSidebar = () => {
    store.dispatch(PageView.toggleSidebarVisibility());
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      variant="outlined"
      {...props}
      style={{
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: 'white',
        ...props?.style,
      }}
    >
      <Toolbar
        style={{
          position: 'relative',
          height: `${TOOLBAR_HEIGHT}px`,
          padding: '0 20px',
        }}
      >
        <IconButton
          onClick={toggleSidebar}
          color="primary"
          sx={{
            mr: 2,
            ml: '-12px',
            display: { lg: 'none' },
          }}
        >
          <MenuIcon style={{ fontSize: '24px' }} />
        </IconButton>
        <Box
          position={{ xs: 'absolute', md: 'relative' }}
          left={{ xs: 'calc(50% - 22px)', md: 0 }}
          display={{ lg: 'none' }}
        >
          <LogoImage href="/" size="42px" />
        </Box>
        <Box flex={1} display={{ md: 'none' }} />
        <Box flexGrow={1} display={{ xs: 'none', md: 'flex' }}>
          <PageBreadcrumb />
        </Box>
        <Box display="flex" pr={1}>
          <MasterHeaderSearch />
        </Box>
        <UserMenu sx={{ ml: 1 }} />
      </Toolbar>
    </AppBar>
  );
}
