import { NoteAltOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { CoachingDriverDetailsContext } from '../../@context/DriverDetailsContext';
import { UserNoteBox } from './UserNoteBox';
import { UserNoteCreateBox } from './UserNoteCreateBox';

/**
 * @typedef {object} CoachingCommentAreaProps
 * @property {HTMLElement} player
 * @property {import('react').MouseEventHandler<HTMLElement>} [onFinish]
 */

/** @param {CoachingCommentAreaProps} props */
export function CoachingCommentArea(props) {
  const { player, onFinish } = props;
  const { visitedCount, startIndex, totalCount, updateSelection } = useContext(
    CoachingDriverDetailsContext
  );

  /** @type {StateVariable<Element>} */
  const [reportArea, setReportArea] = useState();
  /** @type {StateVariable<boolean>} */
  const [createNote, setCreateNote] = useState(false);

  useEffect(() => {
    const update = () => {
      setReportArea(player.querySelector('#report-area'));
    };
    update();
    const iid = setInterval(update, 100);
    return () => clearInterval(iid);
  }, [player]);

  if (!reportArea) {
    return null;
  }

  const hasNext = startIndex + 1 < totalCount;
  const hasPrev = startIndex > 0;

  return createPortal(
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        <Button variant="outlined" onClick={() => setCreateNote(true)}>
          <NoteAltOutlined /> Create Note
        </Button>
        <Box flex={1} textAlign="center" fontStyle="italic">
          Coachable Event {startIndex + 1} of {totalCount}
        </Box>
        <Button
          variant="contained"
          disabled={!hasPrev}
          onClick={() => updateSelection(startIndex - 1)}
        >
          Previous
        </Button>
        {visitedCount < totalCount || startIndex + 1 < totalCount ? (
          <Button
            variant="contained"
            disabled={!hasNext}
            onClick={() => updateSelection(startIndex + 1)}
          >
            Next
          </Button>
        ) : (
          <Button variant="contained" onClick={onFinish}>
            Finish Coaching
          </Button>
        )}
      </Box>
      <Box pt={1} sx={{ '&> *': { mb: 1 } }}>
        {createNote && <UserNoteCreateBox onAdd={() => setCreateNote(false)} />}
        <UserNoteBox
          avatar={null}
          username="Abu Talha"
          note="Thank you for the session."
          time={Date.now() - 13904239}
        />
        <UserNoteBox
          avatar={null}
          username="Sudipto Chandra"
          note="I have selected coachable events for you please proceed with self coaching."
          time={Date.now() - 23904239}
        />
      </Box>
    </Box>,
    reportArea
  );
}
