import { useFetchDeviceBatches } from '@/hooks/useFetchDeviceBatches';
import { KnownUserError } from '@/utils/errors';
import { toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/smarter-ai/device-batches/actions';
import { DEVICE_BATCH_TABLE_COLUMNS } from '@/web/smarter-ai/device-batches/columns';
import { Box } from '@mui/material';

export function DeviceBatchListView() {
  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<DeviceBatchResponse>['onAction']} */
  const onActionHandle = (type, selectedItems, setTableReload) => {
    //TODO:: Do whatever you want to do with your actions
    toastWarning('Unavailable', 'This feature is under development');
    setTableReload(true);
  };

  const { result, loading, error } = useFetchDeviceBatches();

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch Device Batches');
  }

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={'Device Batches'}
          actions={ACTION_ITEMS}
          onAction={onActionHandle}
        />
        <ContinuationTokenTable
          loading={loading}
          results={result}
          columns={DEVICE_BATCH_TABLE_COLUMNS}
          dataSortKey={'description'}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
