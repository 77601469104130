import { toastWarning } from '@/utils/toaster';
import { Add as AddIcon, CheckBox as CheckBoxIcon } from '@mui/icons-material';
import { CircularProgress, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { CameraHealthContext } from '../..';

/** @param {{endpoint: EndpointHealthInfo}} props */
export function AddHealthTagButton(props) {
  const { endpoint } = props;

  const fetcher = useContext(CameraHealthContext);

  /** @type {StateVariable<HTMLElement>} */
  const [anchorEl, setAnchorEl] = useState(null);
  /** @type {StateVariable<HealthTagResponse[]>} */
  const [tags, setTags] = useState([]);
  /** @type {StateVariable<number>} */
  const [loading, setLoading] = useState(null);
  /** @type {StateVariable<Set<number>>} */
  const [selected, setSelected] = useState(new Set());

  useEffect(() => {
    const processSelectedTags = () => {
      const camera = fetcher.endpointInfo[endpoint.endpointId];
      setSelected(new Set((camera?.tags || []).map((x) => x.tagId)));
    };
    processSelectedTags();
    fetcher.on('tags', processSelectedTags);
    return () => fetcher.off('tags', processSelectedTags);
  }, [endpoint.endpointId, fetcher]);

  useEffect(() => {
    if (!fetcher) return;
    const updateTagList = () => {
      setTags(sortBy(fetcher.tags || [], 'tagName'));
    };
    updateTagList();
    fetcher.on('tag-list', updateTagList);
    return () => fetcher.off('tag-list', updateTagList);
  }, [fetcher]);

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const handleAdd = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleDone = () => {
    setAnchorEl(null);
  };

  /** @param {HealthTagResponse} tag */
  const handleToggle = async (tag) => {
    if (loading) return;
    try {
      setLoading(tag.id);
      if (selected.has(tag.id)) {
        await fetcher.removeTag(endpoint, tag.id);
      } else {
        await fetcher.addTag(endpoint, tag.id);
      }
    } catch (err) {
      console.error(err);
      toastWarning('Oops', 'Could not toggle the Tag');
    } finally {
      setLoading(null);
    }
  };

  if (!tags.length) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={handleAdd}
        title="Add Tags"
        style={{
          width: '30px',
          height: '30px',
          background: '#fff',
        }}
      >
        <AddIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleDone}
        style={{ maxHeight: 300 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: '3px',
            ml: '10px',
          },
        }}
      >
        {tags.map((tag) => (
          <MenuItem key={tag.id} disabled={Boolean(loading)} onClick={() => handleToggle(tag)}>
            {loading === tag.id ? (
              <CircularProgress size="24px" />
            ) : selected.has(tag.id) ? (
              <CheckBoxIcon htmlColor="#4EA5F1" />
            ) : (
              <CheckBoxIcon htmlColor="#EBEBEB" />
            )}
            <Typography fontSize="0.875rem" pl={1}>
              {tag.tagName}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
