import { resources } from '@/assets/locales';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isProduction } from '@/config';

export function setupI18n() {
  i18n
    // https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // https://www.i18next.com/overview/configuration-options
    .init({
      debug: false,
      lng: isProduction ? 'en' : '',
      fallbackLng: 'en',
      resources,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'],
      },
    })
    .catch(console.error);
}
