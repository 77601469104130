// @ts-nocheck
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import * as d3 from 'd3';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const LineChart = (props) => {
  const { variables, onChange } = props;

  const svgRef = useRef(null);
  const theme = useTheme();
  const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'));

  const margin = { top: 8, right: 12, bottom: 20, left: 25 };
  const verticalLines = [0, 30, 60, 90, 120, 150];
  const horizontalLines = useMemo(() => [0, 0.5, 1, 1.5, 2], []);

  const [isDragging, setIsDragging] = useState(false);
  const [yAxisData, setYAxisData] = useState([]);
  const [xAxisData] = useState([30, 60, 90, 120, 150]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const defaultValues = variables.map((item) => item?.value || item.default);
    setYAxisData(defaultValues);
  }, [variables]);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 100);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const maxValue = variables.reduce((max, item) => Math.max(max, item.maxValue), -Infinity);
  const minValue = variables.reduce((max, item) => Math.max(max, item.minValue), -Infinity);

  const width = useMemo(() => {
    const minWidth = Math.min(windowWidth, 1740);
    const gridWidth = (68 * minWidth) / 100;

    return lgAndUp
      ? gridWidth - margin.left - margin.right - 325
      : gridWidth - margin.left - margin.right - 70;
  }, [windowWidth, margin.left, margin.right, lgAndUp]);

  const height = 230 - margin.top - margin.bottom;

  const minAxisValue = minValue;
  const maxAxisValue = maxValue;

  const xScale = d3.scaleLinear().domain([28, 152]).range([0, width]);
  const yScale = d3.scaleLinear().domain([minAxisValue, maxAxisValue]).range([height, 0]);

  const xAxis = d3.axisBottom(xScale).tickValues(verticalLines);
  const yAxis = d3.axisLeft(yScale).tickValues(horizontalLines);

  const line = d3
    .line()
    .x((d, i) => xScale(xAxisData[i]))
    .y((d) => yScale(d));

  const updateChart = useCallback(() => {
    if (yAxisData?.length === 0) return;
    const svg = d3.select(svgRef.current);

    //Line Draw
    const lines = svg.selectAll('.line').data([yAxisData]); // Wrap yAxisData in an array to bind it as a single data point
    lines
      .enter()
      .append('path')
      .attr('class', 'line')
      .merge(lines)
      .attr('d', line)
      .attr('stroke', '#3e95cd')
      .attr('fill', 'none')
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '4')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    //Dot Draw
    const dots = svg.selectAll('.dot').data(yAxisData.map((d, i) => ({ value: d, index: i })));
    dots
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .merge(dots) // Merge enter and update selections
      .attr('cx', (d) => xScale(xAxisData[d.index]))
      .attr('cy', (d) => yScale(d.value))
      .attr('r', 7)
      .style('cursor', 'pointer')
      .attr('stroke', '#4d7fc2')
      .attr('fill', '#4d7fc2')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .call(
        d3
          .drag()
          .on('start', (event, d) => {
            setIsDragging(true);
          })
          .on('drag', (event, d) => {
            const newY = yScale.invert(event.y);
            const newYAxisData = Math.max(minAxisValue, Math.min(maxAxisValue, newY));
            setYAxisData((prev) => {
              const newVal = Number(newYAxisData?.toFixed(2));
              const updatedData = [...prev];
              updatedData[d.index] = newVal;
              return updatedData;
            });
          })
          .on('end', (event, d) => {
            const updatedY = yScale.invert(event.y);
            const newYAxisData = Math.max(minAxisValue, Math.min(maxAxisValue, updatedY));
            onChange(variables[d?.index]?.key, Number(newYAxisData?.toFixed(2)));
            setIsDragging(false);
          })
      );
  }, [
    yAxisData,
    line,
    maxAxisValue,
    minAxisValue,
    xAxisData,
    xScale,
    yScale,
    onChange,
    margin.top,
    margin.left,
    variables,
  ]);

  useEffect(() => {
    if (!yAxisData?.length || isInitialized) return;
    let svg = d3
      .select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .style('overflow', 'visible')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .attr('stroke', '#a6afba');

    horizontalLines.forEach((value) => {
      svg
        .append('line')
        .attr('class', 'horizontal-line')
        .attr('x1', 0)
        .attr('y1', yScale(value))
        .attr('x2', width)
        .attr('y2', yScale(value))
        .attr('stroke', '#ecf0f4')
        .attr('stroke-width', 6);
    });

    xAxisData.forEach((value) => {
      svg
        .append('line')
        .attr('class', 'vertical-line')
        .attr('x1', xScale(value))
        .attr('y1', 0)
        .attr('x2', xScale(value))
        .attr('y2', height)
        .attr('stroke', '#ecf0f4')
        .attr('stroke-width', 2)
        .attr('stroke-dasharray', '4');
    });

    //=== X Axis ======
    const xAxisGroup = svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis)
      .attr('stroke', '#a6afba')
      .attr('stroke-width', 1);

    xAxisGroup.selectAll('.tick line').attr('stroke', 'transparent');
    xAxisGroup.select('.domain').attr('stroke', '#ecf0f4').attr('stroke-width', 0);

    xAxisGroup
      .selectAll('.tick')
      .append('text')
      .attr('dy', '1.6em')
      .attr('dx', '10px')
      .style('text-anchor', 'start')
      .attr('font-weight', '100')
      .attr('fill', '#a6afba')
      .text('km/h');

    //=== Y Axis ======
    const yAxisGroup = svg
      .append('g')
      .attr('class', 'y-axis')
      .attr('stroke', '#a6afba')
      .attr('stroke-width', 1)
      .call(yAxis);

    yAxisGroup.selectAll('.tick line').attr('stroke', 'transparent');
    yAxisGroup
      .select('.domain')
      .attr('stroke', '#ecf0f4')
      .attr('stroke-width', 0)
      .attr('stroke-dasharray', '4');

    yAxisGroup
      .selectAll('.tick')
      .append('text')
      .attr('dy', '0.3em')
      .attr('dx', '-8px')
      .style('text-anchor', 'start')
      .attr('font-weight', '100')
      .attr('fill', '#a6afba')
      .text('s');

    updateChart();
    setIsInitialized(true);
  }, [
    xAxisData,
    yAxisData,
    width,
    height,
    margin.top,
    margin.bottom,
    margin.left,
    margin.right,
    isInitialized,
    xAxis,
    xScale,
    yAxis,
    yScale,
    updateChart,
    horizontalLines,
  ]);

  useEffect(() => {
    if (isDragging) {
      updateChart();
    }
  }, [isDragging, updateChart]);

  return (
    <div id={windowWidth}>
      <div className="wrapper">
        <svg ref={svgRef}></svg>
      </div>
    </div>
  );
};

export default LineChart;
