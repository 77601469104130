import { Box, Button, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ScoringFilterContext } from '../../@context/ScoringFilterContext';

/**
 * @typedef {object} ScoringNumberRangeProps
 * @property {() => any} [onApply]
 * @property {() => any} [onCancel]
 */

/**
 * @param {ScoringNumberRangeProps} props
 */
export function ScoringNumberRange(props) {
  const { onApply, onCancel } = props;

  const { selectedScoreFrom, setSelectedScoreFrom, selectedScoreTo, setSelectedScoreTo } =
    useContext(ScoringFilterContext);

  /** @type {StateVariable<number>} */
  const [tempFromState, setTempFormState] = useState(null);
  /** @type {StateVariable<number>} */
  const [tempToState, setTempToState] = useState(null);

  const onClickApply = () => {
    setSelectedScoreFrom(tempFromState);
    setSelectedScoreTo(tempToState);
    onApply();
  };

  const onClickCancel = () => {
    setTempFormState(selectedScoreFrom);
    setTempToState(selectedScoreTo);
    onCancel();
  };

  useEffect(() => {
    setTempFormState(selectedScoreFrom);
    setTempToState(selectedScoreTo);
  }, [selectedScoreFrom, selectedScoreTo]);

  return (
    <Box
      px={2}
      pb={2}
      sx={{ maxHeight: '300px', minWidth: '400px', overflowY: 'auto', marginTop: '2px' }}
    >
      <>
        <Box display={'flex'} flexDirection={'row'} gap={2} pt={2}>
          <TextField
            fullWidth
            focused
            label="From"
            size="small"
            placeholder="Input a number"
            type="number"
            value={tempFromState}
            inputProps={{
              min: 0,
              max: tempToState,
            }}
            onInput={(event) => {
              // @ts-ignore
              const val = event?.target?.value;
              const value = val < 0 ? 0 : val;
              setTempFormState(value);
            }}
          />
          <TextField
            fullWidth
            label="Upto"
            size="small"
            placeholder="Input a number"
            type="number"
            focused
            value={tempToState}
            inputProps={{
              min: tempFromState,
              max: 100,
            }}
            onInput={(event) => {
              // @ts-ignore
              const val = event?.target?.value;
              const value = val > 100 ? 100 : val;
              setTempToState(value);
            }}
          />
        </Box>
        <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }} onClick={onClickCancel}>
          <Button size="small">Cancel</Button>
          <Button onClick={() => onClickApply()} variant="contained" size="small">
            Apply
          </Button>
        </Box>
      </>
    </Box>
  );
}
