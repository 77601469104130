import { formatTimestamp } from '@/utils/datetime';
import { fetchGeoInfo } from '@/utils/geoinfo';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

/** @param {{item: Trip} & import('@mui/material').BoxProps} props */
export function TripListViewItemStartAddress(props) {
  const { item, ...extra } = props;

  /** @type {StateVariable<import('@/utils/geoinfo').GeoInfo>} */
  const [startGeoInfo, setStartGeoInfo] = useState(null);

  useEffect(() => {
    if (!item?.startGeo) return;
    fetchGeoInfo(item.startGeo.lat, item.startGeo.lon).then(setStartGeoInfo);
  }, [item?.startGeo]);

  if (!item) return null;

  return (
    <Box display="flex" px={2} gap="15px" {...extra}>
      <Box display="flex" flexDirection="column" alignItems="center" mt="4px">
        {item.tripStatus === 'ENDED' ? (
          <Box border="2px solid #0b2547" width="12px" height="12px" borderRadius="50%" />
        ) : (
          <Box border="2px solid #608ac3" width="12px" height="12px" borderRadius="50%" />
        )}
        <Box
          borderLeft="1px dashed #0b2547"
          borderColor={item.tripStatus === 'ENDED' ? '#0b2547' : '#608ac3'}
          height="calc(100% - 12px)"
          width="2px"
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="40px"
        gap="8px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        mb="1px"
      >
        <Typography
          title={startGeoInfo?.fullAddress}
          component="div"
          variant="subtitle2"
          fontSize="0.75rem"
          fontWeight="normal"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {startGeoInfo?.fullAddress || 'Unknown Location'}
          <br />
          {formatTimestamp(item.startTimestamp)}
        </Typography>
      </Box>
    </Box>
  );
}
