import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { FileUploadProgress } from '@/web/@components/FileUploadProgress';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FirmwareForm } from '../../@components/FirmwareForm/index';
import { uploadFirmwareFile } from '../../utils';

export function FirmwareEditPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: editingFirmwareId } = useParams();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);
  /** @type {StateVariable<number>} */
  const [percentage, setPercentage] = useState(0);
  /** @type {StateVariable<boolean>} */
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  /** @type {StateVariable<FirmwareV2>} */
  const [firmware, setFirmware] = useState(null);
  /** @type {StateVariable<AbortController>} */
  const [submitting, setSubmitting] = useState();

  useEffect(() => {
    if (!editingFirmwareId) {
      navigate('/smarter-ai/firmwares');
      return;
    }
    const request = api.ac.v2.repository.firmware.$get({
      params: {
        secretToken,
        firmwareId: editingFirmwareId,
        tenantId,
      },
    });
    setLoading(true);
    request
      .process()
      .then(setFirmware)
      .catch(() => navigate('/smarter-ai/firmwares'))
      .finally(() => setLoading(false));
  }, [secretToken, editingFirmwareId, tenantId, navigate]);

  useEffect(() => {
    if (!firmware?.name) return;
    setBreadcrumbTitle(firmware.name);
  }, [firmware.name, setBreadcrumbTitle]);

  /** @type {import('../../@components/FirmwareForm').FirmwareFormProps['onSubmit']} */
  const updateFirmware = async (fields) => {
    const aborter = new AbortController();
    setSubmitting((prev) => {
      prev?.abort();
      return aborter;
    });

    try {
      setPercentage(0);
      setProgressModalOpen(true);

      /** @type {FirmwareV2} */
      const body = { ...firmware };
      body.name = fields.name;
      body.version = fields.version;
      body.description = fields.description;
      body.properties = {
        ...firmware.properties,
        productId: fields.product.productId,
      };

      if (fields.firmwareFile) {
        await uploadFirmwareFile(fields.firmwareFile, body, setPercentage, aborter.signal);
      }

      const request = api.ac.v2.repository.firmware.$put({
        data: body,
        params: {
          secretToken,
          forceUpdate: true,
          tenantId,
          type: body.type,
          version: body.version,
        },
      });
      await request.process();
      toastSuccess('Success', 'Firmware updated successfully');
      navigate('/smarter-ai/firmwares');
    } catch (err) {
      if (!aborter.signal.aborted) {
        if (
          err?.response?.data?.length &&
          err.response.data[0]?.message === 'ALREADY_EXISTS_VERSION'
        ) {
          toastWarning('Error', 'This Firmware version already exists.');
        } else {
          toastWarning('Error', 'Could not update Firmware.');
        }
      }
    } finally {
      setSubmitting(null);
      setProgressModalOpen(false);
    }
    return null;
  };

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box m={2.5}>
      {progressModalOpen && (
        <FileUploadProgress
          percentage={percentage}
          headerText="Update Firmware"
          progressText="Uploading firmware..."
          onClose={() => {
            submitting?.abort();
            setProgressModalOpen(false);
          }}
        />
      )}
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['firmware.update.page.title'])}
      </Typography>
      <FirmwareForm firmware={firmware} onSubmit={updateFirmware} disabled={Boolean(submitting)} />
    </Box>
  );
}
