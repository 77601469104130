import api from '@/api';
import { SCHEDULE_TIMEZONES } from '@/assets/operations/geofences';
import { GOOGLE_MAP_API_KEY, GOOGLE_MAP_ID } from '@/config';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { formatTimestamp, secondToTimeFormat } from '@/utils/datetime';
import { humanReadableTimeDiff } from '@/utils/geofence';
import { fetchGeoInfo } from '@/utils/geoinfo';
import { CenterBox } from '@/web/@components/CenterBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, Card, CircularProgress, Grid, Typography } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export function GeofenceDetailsPage() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);

  const params = useParams();
  const navigate = useNavigate();

  const geofenceId = useMemo(() => params.id, [params]);

  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [locationName, setLocationName] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [vins, setVins] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [aiContainer, setAiContainer] = useState(null);
  const [timeZone, setTimeZone] = useState(null);

  const [scheduleDuration, setScheduleDuration] = useState(null);

  /** @type {StateVariable<GeofenceWithScheduleAndDeploymentDetails['geofence']>} */
  const [geofence, setGeofence] = useState(null);

  const fetchTriggerData = useCallback(
    (id) => {
      const request = api.ac.v5.trigger.composite.$triggerId(id).$get({
        headers: {
          Authorization: secretToken,
        },
        params: {},
      });
      request.process().then((res) => {
        setTriggers((triggers) => [...triggers, res.name]);
      });
    },
    [secretToken]
  );

  const fetchContainerData = useCallback(
    (containerId) => {
      const request = api.ac.v2.repository.aicontainer.$get({
        params: {
          containerId,
          secretToken,
        },
      });
      request.process().then((res) => {
        setAiContainer(res);
      });
    },
    [secretToken]
  );

  useEffect(() => {
    if (!geofenceId) return;
    setLoading(true);
    const req = api.ac.v3.geolocation.fence.$id(geofenceId).$get({
      headers: {
        Authorization: secretToken,
      },
      params: {
        tenantId,
      },
    });
    req.process().then((result) => {
      const { geofence, scheduleWithVinListAndDeploymentDetails } = result;
      const { deployedTriggerIdList, deployedAiContainerId, schedule } =
        scheduleWithVinListAndDeploymentDetails;
      const { latitude, longitude, name, timezone } = geofence;
      const { startTimestamp, endTimestamp, daySecondStart, daySecondEnd } = schedule;

      setGeofence(geofence);
      setLocation({ lat: latitude, lng: longitude });
      setSchedule(scheduleWithVinListAndDeploymentDetails.schedule);
      setVins(scheduleWithVinListAndDeploymentDetails.attachedVinList);
      setBreadcrumbTitle(name);

      const tZone = SCHEDULE_TIMEZONES.find((item) => item.value === timezone);
      setTimeZone(tZone?.label);

      const diffInText = humanReadableTimeDiff(
        startTimestamp,
        endTimestamp,
        daySecondStart,
        daySecondEnd
      );
      setScheduleDuration(diffInText);

      deployedTriggerIdList?.forEach((item) => {
        fetchTriggerData(item);
      });

      if (deployedAiContainerId) {
        fetchContainerData(deployedAiContainerId);
      }
      setLoading(false);
    });
    return () => req.abort();
  }, [geofenceId, secretToken, tenantId, setBreadcrumbTitle, fetchTriggerData, fetchContainerData]);

  const handleApiLoaded = ({ map, maps }) => {
    const circle = new maps.Circle({
      strokeColor: '#3B7CD6',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#3B7CD6',
      fillOpacity: 0.3,
      map,
      center: location,
      radius: geofence?.radius,
      draggable: false,
      editable: false,
    });
    map?.fitBounds(circle.getBounds());
    fetchGeoInfo(location.lat(), location.lng)
      .then((result) => {
        setLocationName(result.fullAddress);
      })
      .catch(console.error);
    return () => circle?.setMap(null);
  };

  // Navigate Geofence list page while tenant change
  useEffect(() => {
    return () => navigate('/operations/geofences');
  }, [tenantId, navigate]);

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box p={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'} pb="20px">
        Geofence Details
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Geofence Name
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {geofence?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            AI Container
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {aiContainer?.name || 'Not Available'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Triggers
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {triggers?.join(', ')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            VINs
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {vins.join(', ')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Address
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {locationName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Latitude
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {geofence?.latitude}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Longitude
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {geofence?.longitude}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Radius
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {geofence?.radius?.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Timezone
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {timeZone}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Schedule
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {scheduleDuration}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Start Date
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {formatTimestamp(schedule?.startTimestamp)}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            End Date
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {formatTimestamp(schedule?.endTimestamp)}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Start Time
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {secondToTimeFormat(schedule?.daySecondStart)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            End Time
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {secondToTimeFormat(schedule?.daySecondEnd)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Description
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
            {geofence?.description || '...'}
          </Typography>
        </Grid>
      </Grid>

      <Card sx={{ zIndex: 1, height: 'calc(100vh - 500px)', mt: '20px' }}>
        {geofence ? (
          <GoogleMapReact
            defaultZoom={10}
            center={location}
            onGoogleApiLoaded={handleApiLoaded}
            yesIWantToUseGoogleMapApiInternals={true}
            bootstrapURLKeys={{
              key: GOOGLE_MAP_API_KEY,
            }}
            options={{
              mapTypeControl: true,
              mapId: GOOGLE_MAP_ID,
            }}
          />
        ) : (
          <CenterBox>
            <CircularProgress />
          </CenterBox>
        )}
      </Card>
    </Box>
  );
}
