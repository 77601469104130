import { FIRMWARE_FILE_UPLOAD_LIMIT } from '@/assets/file';
import { toastWarning } from '@/utils/toaster';
import { FileUpload } from '@mui/icons-material';
import { Box, Button, FormLabel, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { get, useFormContext } from 'react-hook-form';

/**
 * @typedef {object} FirmwareFileUploadProps
 * @property {string} firmwareId
 * @property {string} label
 * @property {'firmwareFile'} name
 * @property {(file: File) => any} onDropFile
 * @property {import('react-hook-form').ControllerProps['rules']} [rules]
 */

/** @param {FirmwareFileUploadProps} props */
export function FirmwareFileUpload(props) {
  const { name, label, onDropFile, firmwareId } = props;
  const theme = useTheme();
  const { watch, formState } = useFormContext();

  const error = get(formState.errors, name);

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      onDropFile && onDropFile(acceptedFiles[0]);
    },
    [onDropFile]
  );

  const onDropRejected = () => {
    toastWarning('Maximum file upload size is 5000MB');
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    maxFiles: 1,
    minSize: 0,
    maxSize: FIRMWARE_FILE_UPLOAD_LIMIT, //bytes
  });

  const formattedName = watch(name)?.name || (firmwareId ? watch('storedFileNamePrev') : '');

  return (
    <Box mb={2}>
      <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
        {label}
      </FormLabel>
      <Button
        {...getRootProps()}
        fullWidth
        variant="text"
        style={{
          padding: '20px',
          textAlign: 'center',
          borderRadius: '4px',
          border: '1px dashed #C5D9F0',
          fontWeight: 'normal',
          fontSize: '0.875rem',
          color: error
            ? theme.palette.error.main
            : formattedName
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
        }}
      >
        <input {...getInputProps()} />
        <Box>{formattedName || "Drag 'n' drop the file here, or click to select file"}</Box>
        <Box flex={1} />
        <FileUpload htmlColor="#596A82" />
      </Button>
    </Box>
  );
}
