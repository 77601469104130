import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/smarter-ai/scheme-management/schemes/actions';
import { DEVICE_GROUP_TABLE_COLUMNS } from '@/web/smarter-ai/scheme-management/schemes/columns';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export function SchemeListView() {
  const { t } = useTranslation();
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  /** @param {Array<SchemeInfo>} selectedItems */
  const deleteSchemes = async (selectedItems) => {
    const success = [];
    const failed = [];
    await Promise.allSettled(
      selectedItems.map(async (scheme) => {
        try {
          const request = api.ac.endpoint.group.delete.$delete({
            params: {
              groupId: scheme.id,
              secretToken,
            },
          });
          await request.process();
          success.push(scheme.name);
        } catch (e) {
          failed.push(scheme.name);
        }
      })
    );
    if (success?.length) {
      toastSuccess(
        'Success',
        t(T['scheme.delete.success'], {
          count: success.length,
          schemeName: success.join(', '),
        })
      );
    }
    if (failed?.length) {
      toastWarning(
        'Failure',
        t(T['scheme.delete.retry'], {
          count: failed.length,
          schemeName: failed.join(', '),
        })
      );
    }
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<SchemeInfo>['onAction']} */
  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteSchemes(selectedItems);
    }
    setTableReload(true);
  };

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={t(T['scheme.title'])}
          actions={ACTION_ITEMS}
          onAction={onActionHandle}
        />
        <OffsetPagination
          key={secretToken}
          fetcher={async ({ signal, limit, offset, searchText }) => {
            const request = api.ac.v5.scheme.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                tenantId,
                limit,
                offset,
                ...(searchText !== '' && { name: searchText }),
              },
            });
            await request.process();
            return {
              // @ts-ignore
              data: request.result.list,
              count: Number(request.response.headers['x-total-count']),
            };
          }}
          renderList={(/** @type {Array<SchemeInfo>} */ results) => (
            <ItemsResponsiveView
              results={results}
              columns={DEVICE_GROUP_TABLE_COLUMNS}
              defaultSortKey="name"
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
