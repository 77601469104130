// @ts-nocheck
import api from '@/api';
import {
  CAMERA_N_OFF_ICON,
  TENANT_ICON_OFF,
  TRIGGER_CAMERA_GROUP_ICON,
} from '@/assets/constants/images';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { BoxImage } from '@/web/@components/BoxImage';
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isUndefined, sortBy, truncate } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TriggerConfigurationContext } from '../../@context/TriggerConfigurationContext';

const triggerConfiguration = new SmartCache(`trigger-configuration`, 120 * 24 * 3600 * 1000);

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-inputRoot': {
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    'whiteSpace': 'nowrap',
    'maxWidth': '100%',
    '& .MuiBox-root': {
      maxWidth: '92% !important',
    },
  },
}));

export function AutoCompleteItemSelection({ isEditMode }) {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const { setSelectionType } = useContext(TriggerConfigurationContext);

  /** @type {StateVariable<array>} */
  const [selectedItems, setSelectedItems] = useState([]);
  /** @type {StateVariable<array>} */
  const [itemList, setItemList] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [tenantsLoading, setTenantsLoading] = useState(true);
  /** @type {StateVariable<boolean>} */
  const [childItemLoading, setChildItemLoading] = useState(false);
  /** @type {StateVariable<any>} */
  const [currentTenant, setCurrentTenant] = useState(null);
  /** @type {StateVariable<string>} */
  const [filter, setFilter] = useState('');
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);
  /** @type {StateVariable<boolean>} */
  const [open, setOpen] = useState(false);

  const handleCloseAutocomplete = () => {
    setOpen(false);
  };

  /**
   * Fetch Tenant Data
   */
  const getParentTenantDetails = useCallback(async () => {
    const request = api.ac.v5.tenant.$tenantId(tenantId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    await request.process();
    return request.result;
  }, [secretToken, tenantId]);

  /**
   * Fetch Tenant -> Groups Data
   */
  const getSelectedTenantGroups = useCallback(
    async (tenantId) => {
      try {
        setChildItemLoading(true);
        const limit = 100;
        let offset = 0;
        const results = [];
        while (true) {
          const request = api.ac.v5.group.device.list.$get({
            headers: {
              Authorization: secretToken,
            },
            params: {
              limit,
              offset,
              tenantId,
            },
          });
          await request.process();
          offset += limit;
          const result = request.result;
          results.push(...result);
          if (result?.length < limit) break;
        }
        return results || [];
      } catch (ex) {
        return [];
      } finally {
        setChildItemLoading(false);
      }
    },
    [secretToken]
  );

  const fetchSubTenantList = useCallback(
    async (signal) => {
      //get the current tenant data
      const currentTenant = await getParentTenantDetails();

      //get current tenant subtenants data
      const results = [];
      while (true) {
        const request = api.ac.v5.tenant.$tenantId(tenantId).subtenants.$get({
          signal: signal,
          headers: {
            Authorization: secretToken,
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result;
        results.push(...result);
        break;
      }

      const mappedData = results?.map((item) => ({
        name: item?.tenantName,
        id: item?.tenantId,
        type: 'TENANT',
        parentTenantId: currentTenant?.tenantId,
      }));
      const sortedData = sortBy(mappedData, [(item) => item.name.toLowerCase()]);

      //get the current tenant  group data
      const groups = await getSelectedTenantGroups(currentTenant?.tenantId);
      const sortedGroup = sortBy(groups, [(item) => item.groupName.toLowerCase()]);
      const mappedGroupData = sortedGroup?.map((item) => ({
        name: item?.groupName,
        id: item?.id,
        tenantId: currentTenant?.tenantId,
        parentTenantId: currentTenant?.tenantId,
        type: 'GROUP',
        children: [],
      }));

      //merge sub tenants and group data
      const mergedData = [...sortedData, ...mappedGroupData];

      //creating the final array
      const tenantData = [
        {
          name: currentTenant?.tenantName,
          id: currentTenant?.tenantId,
          type: 'TENANT',
          children: mergedData,
        },
      ];
      setCurrentTenant(tenantData);
      setItemList(tenantData);
      setTenantsLoading(false);
    },
    [tenantId, secretToken, getParentTenantDetails, getSelectedTenantGroups]
  );

  /**
   * Fetch Tenant -> Group -> Endpoints Data
   */
  const getSelectedGroupEndpoints = useCallback(
    async (tree) => {
      try {
        setChildItemLoading(true);
        const limit = 100;
        let offset = 0;
        const results = [];
        while (true) {
          const request = api.ac.v1.endpoint.group.list.resources.$get({
            params: {
              limit,
              offset,
              groupId: tree?.id,
              tenantID: tree?.tenantId,
              secretToken,
              groupResourceType: 'DEVICE',
            },
          });
          await request.process();
          offset += limit;
          const result = request.result.endpoints;
          results.push(...result);
          if (result?.length < limit) break;
        }
        return results || [];
      } catch (ex) {
        return [];
      } finally {
        setChildItemLoading(false);
      }
    },
    [secretToken]
  );

  const flattenDescendantTenants = useCallback((itemList) => {
    let flattenedList = [];
    for (const tenant of itemList || []) {
      flattenedList.push(tenant);
      if (tenant.children?.length > 0) {
        flattenedList = flattenedList.concat(flattenDescendantTenants(tenant.children));
      }
    }
    return flattenedList;
  }, []);

  const flattenTreeData = useMemo(
    () => flattenDescendantTenants(itemList),
    [itemList, flattenDescendantTenants]
  );

  function findParentTenants(data, targetTenantIds, path = []) {
    const targetTenantId = targetTenantIds[targetTenantIds?.length - 1];
    for (const item of data) {
      path?.push(item);
      if (item.id === targetTenantId) {
        if (targetTenantIds?.length === 1) {
          return path;
        } else {
          const remainingIds = targetTenantIds?.slice(0, -1);
          const parent = findParentTenants(data, remainingIds, path);
          if (parent) {
            return parent;
          }
        }
      }
      if (item?.children?.length > 0) {
        const result = findParentTenants(item.children, targetTenantIds, path);
        if (result) {
          return result;
        }
      }
      path?.pop();
    }
    return null;
  }

  function orderDataByTenantIds(data, targetTenantIds) {
    const orderedData = [];
    for (const targetId of targetTenantIds) {
      const item = data?.find((item) => item.id === targetId);
      if (item) {
        orderedData.push(item);
        data = item.children;
      } else {
        break; // Stop if any id is not found
      }
    }
    return orderedData;
  }

  /** @type {DescendantTenant[]} */
  const filteredTenantTree = useMemo(() => {
    const applyFilter = (tree, depth = 0) => {
      if (!tree?.length) return undefined;

      const filteredTree = [];
      for (const tenant of tree) {
        if (!tenant) continue;

        // Recursively apply filter to children
        const filteredChildren = applyFilter(tenant.children, depth + 1);

        // Check if the current tenant matches the filter condition
        const matchesFilter =
          !filter ||
          tenant.name?.toLowerCase()?.includes(filter?.toLowerCase()) ||
          filteredChildren?.length > 0;

        // Add the tenant to the filtered tree if it matches the filter condition
        if (matchesFilter) {
          filteredTree.push({ ...tenant, children: filteredChildren });
        }
      }

      // Sort the filtered tree by data type
      filteredTree.sort((a, b) => {
        const order = { TENANT: 1, GROUP: 2, ENDPOINT: 3 };
        return order[a.type] - order[b.type];
      });

      return filteredTree;
    };
    return applyFilter(itemList);
  }, [itemList, filter]);

  /**
   * @param {string[]} treePath
   */
  const handleItemChange = async (treePath) => {
    if (!treePath?.length) return;
    setLoading(true);
    try {
      const parent = findParentTenants(flattenTreeData, treePath);
      const orderedData = orderDataByTenantIds(parent, treePath);
      setSelectedItems(orderedData);
    } catch (err) {
      console.error('Tenant switch failed', err);
    } finally {
      setLoading(false);
      setFilter('');
      handleCloseAutocomplete();
    }
  };

  const handleClickCollapsed = async (tree) => {
    //setFilter('');
    if (!isUndefined(tree?.children)) return;

    const isTenant = tree?.type === 'TENANT';
    if (isTenant) {
      const groups = await getSelectedTenantGroups(tree?.id);
      const mappedData = groups?.map((item) => ({
        name: item?.groupName,
        id: item?.id,
        tenantId: tree?.id,
        parentTenantId: tree?.parentTenantId,
        type: 'GROUP',
      }));

      const updatedList = itemList.map((parent) => {
        if (parent.id === tree?.parentTenantId) {
          const updatedChildren = parent.children.map((child) => {
            if (child.id === tree?.id) {
              return { ...child, children: mappedData };
            }
            return child;
          });
          return { ...parent, children: updatedChildren };
        }
        return parent;
      });
      setItemList(updatedList);
    } else {
      const endpoints = await getSelectedGroupEndpoints(tree);
      const mappedData = endpoints?.map((item) => ({
        name: item?.label,
        id: item?.id,
        parentTenantId: tree?.parentTenantId,
        tenantId: tree?.tenantId,
        groupId: tree?.id,
        type: 'ENDPOINT',
      }));

      const handleNestedLayerData = () => {
        const updatedList = itemList.map((parentTenant) => {
          if (parentTenant.id === tree.parentTenantId) {
            const updatedTenants = parentTenant.children.map((tenant) => {
              if (tenant.id === tree?.tenantId) {
                const updatedGroups = tenant.children.map((group) => {
                  if (group.id === tree?.id) {
                    return { ...group, children: mappedData };
                  }
                  return group;
                });
                return { ...tenant, children: updatedGroups };
              }
              return tenant;
            });
            return { ...parentTenant, children: updatedTenants };
          }
          return parentTenant;
        });
        setItemList(updatedList);
      };

      const handleFirstLayerData = (parentId, children) => {
        const updatedList = itemList.map((parentTenant) => {
          if (parentTenant.children) {
            const updatedChildren = parentTenant.children.map((childItem) => {
              if (childItem.id === parentId) {
                return { ...childItem, children: [...childItem.children, ...children] };
              }
              return childItem;
            });
            return { ...parentTenant, children: updatedChildren };
          }
          return parentTenant;
        });
        setItemList(updatedList);
      };

      if (tree?.parentTenantId === tree?.tenantId) {
        handleFirstLayerData(tree?.id, mappedData);
      } else {
        handleNestedLayerData();
      }
    }
  };

  const selectedItemsName = useMemo(
    () => selectedItems?.map((item) => item?.name),
    [selectedItems]
  );

  //Only first time
  useEffect(() => {
    triggerConfiguration
      ?.getItem(tenantId)
      .then((data) => {
        if (data && data?.length > 0) {
          setSelectedItems(data);
        } else {
          if (!currentTenant) return;
          const { type, id, name } = currentTenant || {};
          setSelectionType({ type, id, name });
          setSelectedItems(currentTenant);
        }
      })
      .catch((ex) => {
        if (!currentTenant) return;
        const { type, id, name } = currentTenant || {};
        setSelectionType({ type, id, name });
        setSelectedItems(currentTenant);
      });
  }, [tenantId, currentTenant, setSelectionType]);

  useEffect(() => {
    if (childItemLoading) return;
    const data = selectedItems?.at(selectedItems?.length - 1);
    if (data) {
      setSelectionType({ type: data?.type, value: data?.id, name: data?.name });
    } else {
      setSelectionType(null);
    }
  }, [selectedItems, flattenTreeData, setSelectionType, childItemLoading]);

  useEffect(() => {
    setTenantsLoading(true);
    const aborter = new AbortController();
    fetchSubTenantList(aborter?.signal);
    return () => aborter.abort();
  }, [fetchSubTenantList]);

  useEffect(() => {
    if (selectedItems?.at(0)?.id === tenantId) {
      triggerConfiguration?.setItem(tenantId, selectedItems);
    }
  }, [selectedItems, tenantId]);

  useEffect(() => {
    setCurrentTenant(null);
    setSelectedItems([]);
  }, [tenantId]);

  const filterOptions = (options, { inputValue }) => filteredTenantTree;

  return isEditMode ? (
    <Box width="100%" pb={2}>
      <Typography variant="subtitle2" sx={{ color: '#4D6076', opacity: 0.5 }}>
        {selectedItemsName?.join(' > ')}
      </Typography>
    </Box>
  ) : (
    <StyledAutocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleCloseAutocomplete}
      disablePortal
      disableClearable
      disabled={(filteredTenantTree || [])?.length === 0 && tenantsLoading}
      loading={tenantsLoading}
      options={filteredTenantTree || []}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.name}
      fullWidth
      sx={{ width: '100%', pb: 2 }}
      // clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={selectedItems?.length > 0 ? '' : 'Search items'}
          onChange={(event) => {
            setFilter(event?.target?.value);
          }}
        />
      )}
      multiple={true}
      value={selectedItems}
      onChange={(event, value) => {
        setSelectedItems(value);
      }}
      renderTags={(value) => (
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value
            .map((x) => x?.name)
            .filter(Boolean)
            .join(' > ')}
        </Box>
      )}
      renderOption={(props, option, { inputValue }) => {
        return (
          <TenantTreeItem
            key={option.id}
            tree={option}
            filter={filter}
            selected={selectedItems || []}
            onSelect={loading ? undefined : handleItemChange}
            onClickCollapsed={handleClickCollapsed}
            selectedItems={selectedItems}
            childItemLoading={childItemLoading}
          />
        );
      }}
    />
  );
}

/**
 * @typedef {object} TenantTreeItemProps
 * @property {number} [indent]
 * @property {string} [filter]
 * @property {string[]} selected
 * @property {DescendantTenant} tree
 * @property {(path: string[]) => any} [onSelect]
 */

/** @param {TenantTreeItemProps} props */
export function TenantTreeItem(props) {
  const {
    indent = 0,
    tree,
    filter,
    selected,
    selectedItems,
    childItemLoading,
    onSelect,
    onClickCollapsed,
  } = props;

  const [collapsed, setCollapsed] = useState(true);

  const [collapsedId, setCollapsedId] = useState('');

  const leaf = tree?.type === 'ENDPOINT' ? true : false;

  const active = selected?.at(selected?.length - 1)?.id === tree?.id;

  useEffect(() => {
    const ids = selectedItems?.map((i) => i?.id);
    ids?.push(collapsedId);
    setCollapsed(!ids?.includes(tree.id));
  }, [tree.id, filter, selectedItems, collapsedId]);

  const imageType = useMemo(() => {
    if (tree?.type === 'TENANT') {
      return TENANT_ICON_OFF;
    } else if (tree?.type === 'GROUP') {
      return TRIGGER_CAMERA_GROUP_ICON;
    } else {
      return CAMERA_N_OFF_ICON;
    }
  }, [tree?.type]);

  return (
    <>
      <MenuItem
        disableRipple
        key={tree.id}
        onClick={() => {
          onSelect && onSelect([tree.id]);
          onClickCollapsed(tree);
          setCollapsedId(tree.id);
        }}
        sx={{
          'pl': 2 + indent * 3.5,
          'color': active ? '#fff' : undefined,
          'bgcolor': active ? '#586B81' : undefined,
          '&:hover': {
            bgcolor: active ? undefined : '#F6F9FE',
          },
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" gap={1} justifyContent="center" alignItems="center">
            <BoxImage src={imageType} height="20px" width="20px" />
            <Box flex="1">{truncate(tree?.name, { length: 60 })}</Box>
            {collapsedId === tree.id && childItemLoading && (
              <Box>
                <CircularProgress size={15} sx={{ color: 'white' }} />
              </Box>
            )}
          </Box>

          {leaf ? null : (
            <Box
              width="24px"
              height="24px"
              bgcolor="#F8FAFF"
              borderRadius="50%"
              onClick={(e) => {
                e.stopPropagation();
                setCollapsedId((prev) => (prev === tree.id ? '' : tree.id));
                //setCollapsed((v) => !v);
                onClickCollapsed(tree);
              }}
            >
              <svg
                width="12px"
                height="12px"
                viewBox="0 0 2 2"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transition: 'all 0.3s ease-in',
                  margin: collapsed ? '6px 10px' : '10px 6px',
                  transform: collapsed ? 'rotate(-90deg)' : undefined,
                }}
              >
                <path d="M1,1,2,0H0Z" fill="#5C6A80" />
              </svg>
            </Box>
          )}
        </Box>
      </MenuItem>

      {collapsed || !tree.children?.length ? null : (
        <>
          {tree.children.map((child) => (
            <TenantTreeItem
              key={child.id}
              indent={indent + 1}
              tree={child}
              filter={filter}
              selected={selected.slice(1)}
              onSelect={(path) => onSelect && onSelect([tree.id, ...path])}
              onClickCollapsed={onClickCollapsed}
              selectedItems={selectedItems}
              childItemLoading={childItemLoading}
            />
          ))}
        </>
      )}
    </>
  );
}
