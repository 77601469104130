import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

/**
 * Fetch list of all Ai Containers
 */
export function useFetchAIContainers() {
  return useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      let conToken = '';
      const limit = 100;
      /** @type {Array<AIContainerV2>} */
      let results = [];
      while (!signal.aborted) {
        const request = api.ac.v2.repository.aicontainer.assign.$get({
          signal,
          params: {
            tenantId,
            secretToken,
            pageSize: limit,
            continuationToken: conToken,
          },
        });
        await request.process();
        if (!request.result) break;
        conToken = request.result.continuationToken;
        const result = request.result.data;
        results.push(...request.result.data);
        if (!conToken?.trim() || result.length < limit) break;
      }
      return results?.filter((item) => item.publicationStatus !== 'DISCONTINUED');
    },
    [],
    { cache: true }
  );
}
