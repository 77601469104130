import api from '@/api';
import { BOX_MODIFY_ICON } from '@/assets/constants/images';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { PageLink } from '@/web/@components/PageLink';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { sortBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AssignSchemeModalPage } from './@components/AssignSchemeModalPage';

export function ChildTenantsAccordions() {
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  /** @type {StateVariable<string>} */
  const [expanded, setExpanded] = useState('');
  /** @type {StateVariable<boolean>} */
  const [showDeploymentModal, setShowDeploymentModal] = useState(false);
  /** @type {StateVariable<boolean>} */
  const [deployedDataLoading, setDeployedDataLoading] = useState(true);

  const fetchSchemeData = async ({ secretToken, tenantId, limit, offset }) => {
    /** @type {Array<SchemeInfo>} */
    const results = [];
    while (true) {
      const request = api.ac.v5.scheme.$get({
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
          limit,
          offset,
        },
      });
      const result = await request.process();
      // @ts-ignore
      results.push(...result.list);
      offset += limit;
      // @ts-ignore
      if (result.list.length < limit) break;
    }
    return results;
  };

  const { result: availableSchemes = [] } = useFutureLoader(
    async ({ secretToken }) => {
      const limit = 100;
      let offset = 0;
      return fetchSchemeData({ secretToken, tenantId, limit, offset });
    },
    [],
    { cache: false }
  );

  const {
    result: tenantDeployedSchemes = [],
    loading: deployedSchemeLoading,
    retry: fetchDeployedSchemeList,
  } = useFutureLoader(
    async ({ secretToken }) => {
      if (expanded === '') {
        return [];
      }
      const limit = 100;
      let offset = 0;
      const schemes = await fetchSchemeData({ secretToken, tenantId: expanded, limit, offset });
      setDeployedDataLoading(false);
      return schemes;
    },
    [expanded],
    { cache: false }
  );

  const getTenantDetails = useCallback(async () => {
    const request = api.ac.v5.tenant.$tenantId(tenantId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    await request.process();
    return request.result;
  }, [secretToken, tenantId]);

  const {
    result: tenants,
    loading: tenantLoading,
    error: tenantListError,
  } = useFutureLoader(
    async ({ secretToken }) => {
      if (!tenantId) return;
      const request = api.ac.v5.tenant.$tenantId(tenantId).subtenants.$get({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      const tenants = request.result;

      return sortBy(
        tenants.map((item) => ({ ...item })),
        ['tenantStatus', (item) => item.tenantName.toLowerCase()]
      );
    },
    [tenantId, getTenantDetails]
  );

  const currentSelectedTenant = useMemo(
    () => (expanded !== '' ? tenants?.find((item) => item?.tenantId === expanded) : {}),
    [expanded, tenants]
  );

  // @ts-ignore
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : '');
    setDeployedDataLoading(true);
  };

  const handleSchemeDeployment = (event) => {
    event?.stopPropagation();
    setShowDeploymentModal(true);
  };

  const onModalClose = (reload) => {
    setShowDeploymentModal(false);
    if (reload) {
      setDeployedDataLoading(true);
      fetchDeployedSchemeList();
      //setExpanded('');
    }
  };

  if (tenantListError) {
    throw new KnownUserError('Sorry! Could not fetch Tenant list');
  }

  return (
    <Box mx={2.5} mt={2}>
      <Typography variant="subtitle2" fontSize="1.15rem" pb={2}>
        Tenant Deployment
      </Typography>

      {showDeploymentModal && expanded !== '' && (
        <AssignSchemeModalPage
          onModalClose={onModalClose}
          assignedSchemes={tenantDeployedSchemes}
          availableSchemes={availableSchemes}
          childTenantId={expanded}
          currentSelectedTenant={currentSelectedTenant}
        />
      )}

      {!tenantLoading && !tenants?.length ? (
        <CenterBox fullView>
          <IconMessageBox
            size="128px"
            src="/images/empty/no-search-results.svg"
            message="No data found!"
          />
        </CenterBox>
      ) : (
        <Box py="5px" height="100%" mb="20px">
          {(tenants || [])?.map((tenant, index) => {
            return (
              <Accordion
                sx={{
                  'border': 'none',
                  '& .MuiAccordionSummary-root.Mui-expanded': {
                    backgroundColor: '#f5f9ff',
                    borderTop: '1px solid #c5d9f0',
                  },
                  '& .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content': {
                    fontWeight: 'bold',
                  },
                  '& .MuiAccordionSummary-root': {
                    alignItems: 'stretch',
                    margin: '-10px',
                  },
                  '& .MuiAccordionDetails-root': {
                    border: 'none',
                    mx: '20px',
                  },
                  '& .MuiAccordion-root:before': {
                    backgroundColor: 'none !important',
                  },
                  '& .MuiPaper-root:before': {
                    backgroundColor: 'none !important',
                  },
                  '& .MuiSvgIcon-root': {
                    marginTop: '7px',
                  },
                  '& .MuiAccordionSummary-content': {
                    width: '100%',
                    mt: '5px',
                  },
                }}
                disableGutters
                elevation={0}
                square
                disabled={tenantLoading}
                key={tenant.tenantId + index}
                TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                expanded={expanded === tenant.tenantId}
                onChange={handleChange(tenant.tenantId)}
              >
                <AccordionSummary expandIcon={<ArrowDropDown fontSize="large" />}>
                  <Box sx={{ width: '100% !important' }}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        sx={{
                          color: '#0B2547',
                          opacity: '0.68',
                          fontSize: '1rem',
                          fontWeight: 500,
                          mt: 1,
                        }}
                      >
                        {tenant?.tenantName}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        gap={3}
                        alignItems="center"
                      >
                        {expanded === tenant.tenantId && (
                          <Button sx={{ gap: 1, mt: 0.3 }} onClick={handleSchemeDeployment}>
                            <BoxImage src={BOX_MODIFY_ICON} size={'15px'} />
                            <Typography fontSize="0.875rem">Schemes Deployment</Typography>
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ minHeight: '200px', mt: 2, py: 0, px: 2 }}>
                  {deployedDataLoading || deployedSchemeLoading ? (
                    <CenterBox
                      style={{ height: '200px' }}
                      children={<CircularProgress size={25} />}
                    />
                  ) : !deployedDataLoading &&
                    !deployedSchemeLoading &&
                    tenantDeployedSchemes?.length === 0 ? (
                    <CenterBox>
                      <IconMessageBox
                        size="128px"
                        src="/images/empty/no-search-results.svg"
                        message="No data found!"
                      />
                    </CenterBox>
                  ) : (
                    <Box py={2}>
                      {tenantDeployedSchemes?.map((deployedItem, index) => (
                        <PageLink
                          key={index}
                          to={`/smarter-ai/scheme-management/schemes/${deployedItem?.id}`}
                          target="_blank"
                        >
                          <Typography fontSize={'0.90rem'} pb={2}>
                            {deployedItem.name}
                          </Typography>
                        </PageLink>
                      ))}
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
