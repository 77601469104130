import api from '@/api';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { store } from '@/store';
import { findTenant, selectTenant } from '@/store/auth';
import { formatCameraName } from '@/utils/cameras';
import { formatDateTimeWeek } from '@/utils/datetime';
import { trimString } from '@/utils/formatting';
import { BoxImage } from '@/web/@components/BoxImage';
import { ImageNotAvailable } from '@/web/@components/SnapshotImageSlider/ImageNotAvailable';
import { parseObject } from '@/web/administration/device-groups/_id/cameras/manage-cameras/@utils';
import { CameraStatusTag } from '@/web/cameras/@components/CameraGridListItem/CameraStatusTag';
import { CameraThumbnailContext } from '@/web/cameras/@components/CameraThumbnailProvider';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { CameraHealthContext } from '../..';
import { HealthTagList } from '../HealthTagList';
import { AddHealthTagButton } from './AddHealthTagButton';
import { NetworkStatusButtons } from './NetworkStatusButtons';

/** @param {{endpoint: EndpointHealthInfo}} props */
export function EndpointListSliderItem(props) {
  const { endpoint } = props;
  const { query } = useRouteQuery();

  const fetcher = useContext(CameraHealthContext);
  const { thumbnails } = useContext(CameraThumbnailContext);

  /** @type {StateVariable<any>} */
  const [networkRecord, setNetworkRecord] = useState(null);

  const grouping = useMemo(
    () => trimString(query.group) || 'TENANT', //
    [query.group]
  );
  const cameraTenant = useMemo(
    () => findTenant(endpoint.tenantId), //
    [endpoint.tenantId]
  );
  const thumbnail = useMemo(
    () => thumbnails[endpoint?.endpointId],
    [thumbnails, endpoint?.endpointId]
  );
  const loading = useMemo(
    () => !thumbnail || (!thumbnail.src && !thumbnail.error), //
    [thumbnail]
  );
  const snapshotUrl = useMemo(
    () => thumbnail?.src, //
    [thumbnail]
  );

  const { ref, inView } = useInView({
    delay: 50,
    threshold: 0.1,
    initialInView: false,
  });

  useEffect(() => {
    if (!inView || networkRecord) return;
    const process = async () => {
      const secretToken = await fetcher.getTenantToken(endpoint.tenantId);
      const request = api.ac.v3.report.device.records.$get({
        params: {
          secretToken,
          tenantId: endpoint.tenantId,
          endpointId: Number(endpoint.endpointId),
          subtype: 'network-change',
          pageSize: 1,
        },
      });
      const result = await request.process();
      let props = {};
      if (result.data?.length) {
        props = parseObject(result.data[0].properties) || {};
      }
      setNetworkRecord(props);
    };
    process().catch(console.error);
  }, [inView, networkRecord, fetcher, endpoint]);

  /** @type {import('react').MouseEventHandler} */
  const openCameraDetails = async (e) => {
    e.stopPropagation();
    await store.dispatch(selectTenant({ tenantId: endpoint.tenantId }));
    //navigate(`/cameras/${endpoint.endpointId}`);
    window.open(`/cameras/${endpoint.endpointId}`, '_blank');
  };

  return (
    <>
      <Box
        ref={ref}
        position="relative"
        sx={{
          '.hover': {
            opacity: 0,
          },
          '&:hover .hover': {
            opacity: 1,
            transition: 'all 0.3s',
          },
        }}
      >
        <BoxImage
          src={snapshotUrl}
          objectFit="cover"
          objectPosition="center"
          style={{ width: '100%', aspectRatio: 16 / 9 }}
          fallback={
            <ImageNotAvailable
              children={loading ? <CircularProgress color="secondary" size="32px" /> : null}
              style={{ width: '100%', aspectRatio: 16 / 9 }}
            />
          }
        />
        <Box position="absolute" top="5px" left="10px" right="50px" zIndex={20}>
          {/* right={showRecalibrate ? '90px' : '50px'} */}
          <HealthTagList endpoint={endpoint} />
        </Box>
        <Box
          display="flex"
          gap="5px"
          alignItems="center"
          justifyContent="flex-end"
          position="absolute"
          top="10px"
          right="10px"
          zIndex={50}
        >
          <AddHealthTagButton endpoint={endpoint} />
        </Box>
        <Typography
          className="hover"
          variant="subtitle1"
          sx={{
            color: '#fff',
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            zIndex: 10,
            fontSize: '0.75rem',
          }}
        >
          {formatDateTimeWeek(endpoint.lastOnlineTime)}
        </Typography>
        <Box
          position="absolute"
          bottom="15px"
          right="10px"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="10px"
          zIndex={20}
        >
          <CameraStatusTag
            item={endpoint}
            hideOffline
            position="relative"
            top={undefined}
            left={undefined}
          />
          {networkRecord && <NetworkStatusButtons record={networkRecord} />}
        </Box>
      </Box>

      <Box
        style={{
          width: '100%',
          display: 'table',
          tableLayout: 'fixed',
        }}
      >
        <Link
          fontSize="0.875rem"
          fontWeight={600}
          display="table-row"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          onClick={openCameraDetails}
          lineHeight="18px"
        >
          {formatCameraName(endpoint.deviceLabel, endpoint.deviceSerialNo)}
        </Link>
        {grouping !== 'TENANT' && cameraTenant && (
          <Typography
            variant="subtitle2"
            style={{
              lineHeight: '16px',
              display: 'table-row',
              fontSize: '12px',
            }}
          >
            {cameraTenant.tenantName}
          </Typography>
        )}
      </Box>
    </>
  );
}
