import { formatTimestamp } from '@/utils/datetime';
import { fetchGeoInfo } from '@/utils/geoinfo';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

/** @param {{item: Trip} & import('@mui/material').BoxProps} props */
export function TripListViewItemEndAddress(props) {
  const { item, ...extra } = props;

  /** @type {StateVariable<import('@/utils/geoinfo').GeoInfo>} */
  const [endGeoInfo, setEndGeoInfo] = useState(null);

  useEffect(() => {
    if (!item?.endGeo) return;
    fetchGeoInfo(item.endGeo.lat, item.endGeo.lon).then(setEndGeoInfo);
  }, [item?.endGeo]);

  return (
    <Box display="flex" px={2} gap="15px" {...extra}>
      <Box display="flex" flexDirection="column" alignItems="center" mb="4px">
        <Box borderLeft="1px dashed #0b2547" height="calc(100% - 12px)" width="2px" />
        <Box border="2px solid #0b2547" width="12px" height="12px" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="40px"
        gap="8px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        mt="1px"
      >
        <Typography
          title={endGeoInfo?.fullAddress}
          component="div"
          variant="subtitle2"
          fontSize="0.75rem"
          fontWeight="normal"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {endGeoInfo?.fullAddress || 'Unknown Location'}
          <br />
          {formatTimestamp(item.endTimestamp)}
        </Typography>
      </Box>
    </Box>
  );
}
