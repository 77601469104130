import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DriverSafetyGridListItem } from '../DriverSafetyGridListItem';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { ScoringFilterContext } from '../../@context/ScoringFilterContext';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { debounce } from 'lodash';

export function DriversByPersona(props) {
  const { persona } = props;

  const { filteredData } = useContext(ScoringFilterContext);

  const navigate = useNavigate();
  const theme = useTheme();

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<boolean>} */
  const [isHovered, setIsHovered] = useState(false);
  /** @type {StateVariable<Array<DriverScoreWithDriverDetailDto>>} */
  const [drivers, setDrivers] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);

  const mdAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const gridItems = useMemo(() => {
    if (smAndDown) return 1;
    else if (mdAndDown) return 3;
    else return 4;
  }, [mdAndDown, smAndDown]);

  /** @param {string} id */
  const handleViewAll = (id) => {
    navigate(`/safety/scoring/drivers?personaId=${id}`);
  };

  const fetchDriversByPersona = useCallback(
    async (signal) => {
      try {
        const request = api.ac.v5.score.persona
          .$personaId(persona?.id)
          .tenant?.$tenantId(tenantId)
          .driver.details.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
            params: {
              driverIds: filteredData?.drivers?.map((driver) => driver?.driverId),
              fromTimestamp: startOfTheDay(filteredData?.dateRange?.from),
              toTimestamp: endOfTheDay(filteredData?.dateRange?.to),
              limit: gridItems,
              offset: 0,
              sort: 'DESC',
            },
          });
        const result = await request?.process();
        setDrivers(result?.list);
        if (result?.list) {
          setLoading(false);
        }
      } catch (ex) {
        setLoading(false);
      }
    },
    [
      persona?.id,
      tenantId,
      secretToken,
      gridItems,
      filteredData?.dateRange?.from,
      filteredData?.dateRange?.to,
      filteredData?.drivers,
    ]
  );

  useEffect(() => {
    const aborter = new AbortController();
    setLoading(true);

    const debouncedFetchDrivers = debounce(() => {
      fetchDriversByPersona(aborter?.signal);
    }, 500);

    debouncedFetchDrivers();

    return () => {
      setLoading(false);
      aborter.abort();
    };
  }, [fetchDriversByPersona]);

  return (
    <Box pb={4}>
      <Box
        display="flex"
        alignItems="center"
        mb={1.5}
        gap={1}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Typography sx={{ color: '#0B2547', opacity: '0.68', fontWeight: '600' }}>
          {persona?.name}
        </Typography>
        <Link
          className="view-hover"
          display={drivers?.length > 0 ? 'block' : 'none'}
          onClick={() => handleViewAll(persona?.id)}
          style={{
            fontSize: '0.825rem',
            fontWeight: 600,
            visibility: isHovered ? 'visible' : 'hidden',
            textDecoration: 'none',
            color: '#1E59A8',
          }}
        >
          View All
        </Link>
      </Box>

      <Grid container spacing={1}>
        {loading ? (
          <CenterBox style={{ height: '280px', border: '1px solid #f8f8f8' }}>
            <CircularProgress />
          </CenterBox>
        ) : drivers?.length > 0 ? (
          drivers?.slice(0, 4)?.map((driver, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <DriverSafetyGridListItem driver={driver?.driver} score={driver?.score} />
            </Grid>
          ))
        ) : (
          <CenterBox style={{ border: '1px solid #f8f8f8' }}>
            <IconMessageBox size="150px" src="/images/empty/no-users.svg" message="No Drivers!" />
          </CenterBox>
        )}
      </Grid>
    </Box>
  );
}
