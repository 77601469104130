import { LongTextShortener } from '@/web/@components/LongTextShortener';

/**
 * @type {Array<TableColumn<TenantDTO & import("@/assets/ota")['FIRMWARE_TYPES']>>}
 */
export const TENANT_TABLE_COLUMNS = [
  {
    id: 'tenantName',
    label: 'Tenant name',
    align: 'left',
    isPrimary: true,
    searchable: true,
    format: (value) => <LongTextShortener text={value} length={40} />,
  },
  {
    id: 'APPLICATION_PACKAGE',
    label: 'Camera Firmware',
    align: 'left',
    searchable: true,
    sort: true,
  },
  {
    id: 'SYSTEM_IMAGE',
    label: 'System Image',
    align: 'left',
    searchable: true,
    sort: true,
  },
  {
    id: 'OEM_IMAGE',
    label: 'OEM Image',
    align: 'left',
    searchable: true,
    sort: true,
  },
  {
    id: 'CAN_BUS_FW',
    label: 'CAN Bus Firmware ',
    align: 'left',
    searchable: true,
    sort: true,
  },
  {
    id: 'SUPPORTING_APPLICATION',
    label: 'Supporting Application',
    align: 'left',
    searchable: true,
    sort: true,
  },
  {
    id: 'SMART_CABLE_FW',
    label: 'Smart Cable',
    align: 'left',
    searchable: true,
    sort: true,
  },
];
