import { BOX_DISCONTINUE } from '@/assets/constants/images';
import { AddOutlined } from '@mui/icons-material';
import { T } from '@/assets/locales';

/** @type {Array<TableHeaderAction<TriggerClassification>>} */
export const ACTION_ITEMS = [
  {
    label: T['button.discontinue'],
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    confirmationMessage: T['labels.confirmations.discontinue'],
    hidden: (selected) => !selected.length,
  },
  {
    label: 'Search',
    placeholder: T['label.actions.search'],
    component: 'SEARCH',
  },
  {
    label: T['label.actions.create'],
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/smarter-ai/labels/create',
  },
];
