import { useRouteQuery } from '@/hooks/useRouteQuery';
import { trimString } from '@/utils/formatting';
import { useMemo } from 'react';
import { TagWiseGroupedList } from './TagWiseGroupedList';
import { TenantWiseGroupedList } from './TenantWiseGroupedList';

export function GroupedList() {
  const { query } = useRouteQuery();
  const grouping = useMemo(() => trimString(query.group) || 'TENANT', [query.group]);
  if (grouping === 'TENANT') {
    return <TenantWiseGroupedList />;
  } else {
    return <TagWiseGroupedList />;
  }
}
