import { T } from '@/assets/locales';
import { UnderDevelop } from '@/web/@components/UnderDevelop';
import { FirmwareListView } from '@/web/smarter-ai/firmwares';
import { FirmwareEditPage } from './_id/edit';
import { FirmwareCreatePage } from './create';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <FirmwareListView />,
    breadcrumb: 'Firmwares',
  },
  {
    path: 'create',
    element: <FirmwareCreatePage />,
    breadcrumb: 'Create Firmware',
  },
  {
    path: ':id',
    children: [
      {
        path: 'details',
        element: <UnderDevelop />,
        breadcrumb: T['button.details'],
      },
      {
        path: 'edit',
        element: <FirmwareEditPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
