import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { capitalize, startCase } from 'lodash';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { CreateDeviceDialog } from '../CreateDeviceDialog';
import { DevicePoolDetailsContext } from '../DevicePoolDetailsLayout';
import { DeviceTransferDialog } from '../DeviceTransferDialog';
import { ACTION_ITEMS } from './actions';

export function DevicePoolDeviceActions() {
  const secretToken = useSelector(selectSecretToken);
  const { setTableReload } = useContext(PaginatedTableContext);
  const { poolId } = useContext(DevicePoolDetailsContext);

  /** @type {StateVariable<boolean>} */
  const [createDialog, setCreateDialog] = useState(false);
  /** @type {StateVariable<Array<Device>>} */
  const [transferDialog, setTransferDialog] = useState([]);

  /** @type {import('../DeviceTransferDialog').DeviceTransferDialogProps['onSubmit']} */
  const handleTransfer = async (newPool) => {
    try {
      const deviceIds = transferDialog.map((item) => item.id);
      const request = api.ac.v5.device.transfer.$post({
        headers: {
          Authorization: `Bearer ${secretToken}`,
        },
        data: {
          deviceIds,
          fromPool: poolId,
          toPool: newPool.id,
        },
      });
      await request.process();
      setTableReload(true);
      setTransferDialog(null);
    } catch (err) {
      console.error('Device transfer failed', err);
      toastWarning('Sorry!', `Could not transfer devices`);
    }
  };

  /** @type {import('../CreateDeviceDialog').CreateDeviceDialogProps['onSubmit']} */
  // @ts-ignore
  const handleAddDevice = async (data) => {
    try {
      const deviceList = data.devices.map((item) => {
        return {
          batchId: item.batch.id,
          deviceId: item.deviceId,
          skuNumber: item.skuNumber,
          imeiList: item.imei
            ?.toString()
            .split(',')
            .filter((element) => element !== ''),
        };
      });

      const request = api.ac.v5.device.$post({
        data: {
          deviceList: deviceList,
          poolId,
          productId: data.product.productId,
        },
        headers: { Authorization: `Bearer ${secretToken}` },
      });
      await request.process();
      if (request.result.resultList[0].code > 200) {
        throw new Error(request.result.resultList[0].message);
      }
      setTableReload(true);
      setCreateDialog(false);
    } catch (err) {
      console.error('Device add', err);
      toastWarning(
        'Sorry!',
        err?.message ? capitalize(startCase(err.message)) : `Could not add device to pool`
      );
    }
  };

  //TODO:: This API will not work, Service working on it
  const deletePoolDevice = async (device) => {
    try {
      const request = api.ac.endpoint.devicepools.$poolId(poolId).devices.$delete({
        headers: {
          Authorization: secretToken,
        },
        params: {
          deviceSerialNumber: device.id,
        },
      });
      await request.process();
    } catch (e) {
      toastWarning('Error', 'Failed to delete device from the pool');
    }
  };

  const deletePoolDevices = async (selectedItems) => {
    await Promise.all(selectedItems.map(deletePoolDevice));
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<Device>['onAction']} */
  const onActionHandle = async (type, selectedItems) => {
    switch (type) {
      case 'TRANSFER':
        setTransferDialog(selectedItems);
        break;
      case 'CREATE':
        setCreateDialog(true);
        break;
      case 'DISCONTINUE':
        await deletePoolDevices(selectedItems);
        setTableReload(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ItemsListHeader title="Devices" actions={ACTION_ITEMS} onAction={onActionHandle} />
      {createDialog && (
        <CreateDeviceDialog
          open
          onSubmit={handleAddDevice}
          onCancel={() => setCreateDialog(false)}
        />
      )}
      {transferDialog?.length > 0 && (
        <DeviceTransferDialog
          open
          title="Transfer Device"
          message="Choose a Device Pool to transfer the selected Device"
          onSubmit={handleTransfer}
          onCancel={() => setTransferDialog([])}
        />
      )}
    </>
  );
}
