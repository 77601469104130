import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { FileUploadProgress } from '@/web/@components/FileUploadProgress';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FirmwareForm } from '../@components/FirmwareForm';
import { uploadFirmwareFile } from '../utils';

export function FirmwareCreatePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<AbortController>} */
  const [loading, setLoading] = useState();
  /** @type {StateVariable<number>} */
  const [percentage, setPercentage] = useState(0);
  /** @type {StateVariable<boolean>} */
  const [progressModalOpen, setProgressModalOpen] = useState(false);

  /** @type {import('../@components/FirmwareForm').FirmwareFormProps['onSubmit']} */
  const createFirmware = async (fields) => {
    if (!fields.firmwareFile) {
      setProgressModalOpen(false);
      toastWarning('Please select a firmware file');
      return;
    }

    const aborter = new AbortController();
    setLoading((prev) => {
      prev?.abort();
      return aborter;
    });

    try {
      setPercentage(0);
      setProgressModalOpen(true);

      /** @type {CreateFirmwareRequest} */
      const body = {};
      body.firmwareId = uuidv4();
      body.name = fields.name;
      body.version = fields.version;
      body.description = fields.description;
      body.firmwareTypeId = Number(fields.type?.value);

      await uploadFirmwareFile(fields.firmwareFile, body, setPercentage, aborter.signal);

      const request = api.ac.v5.firmware.$post({
        data: body,
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      toastSuccess('Success', 'Firmware created');
      navigate('/smarter-ai/firmwares');
    } catch (err) {
      if (!aborter.signal.aborted) {
        if (err.response.data?.status === 'CONFLICT') {
          toastWarning('Error', 'This Firmware version already exists.');
        } else {
          toastWarning('Error', 'Could not create Firmware.');
        }
      }
    } finally {
      setLoading(undefined);
      setProgressModalOpen(false);
    }
  };

  return (
    <Box m={2.5}>
      {progressModalOpen && (
        <FileUploadProgress
          percentage={percentage}
          headerText="Add New Firmware"
          progressText={'Uploading file...'}
          onClose={() => {
            loading?.abort();
            setProgressModalOpen(false);
          }}
        />
      )}
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['firmware.create.page.title'])}
      </Typography>
      <FirmwareForm onSubmit={createFirmware} disabled={Boolean(loading)} />
    </Box>
  );
}
