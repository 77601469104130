import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { buildTripStats } from '@/utils/geo';
import { Circle } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { TripDetailsCache } from '../TripContext';
import { TripListViewItemEndAddress } from './EndAddress';
import { TripListViewItemFooter } from './Footer';
import { TripListViewItemHeader } from './Header';
import { TripListViewItemStartAddress } from './StartAddress';

const EIGHT_HOURS_IN_MILLIS = 28800000;

/** @type {SmartCache<import('@/utils/geo').TripStats>} */
export const TripStatsCache = new SmartCache(`trip-stats-cache`, 120 * 24 * 3600 * 1000);

/** @param {{item: Trip|TripDetailsResponse} & import('@mui/material').BoxProps} props */
export function TripListViewItem(props) {
  const { item, ...extra } = props;

  const visited = useRef(null);

  /** @type {StateVariable<import('@/utils/geo').TripStats>} */
  const [stats, setStats] = useState();

  const isLongOfflineTrip = useMemo(() => {
    if (!item || !stats) return false;
    const lastUpdateTime = stats.lastUpdateTime;
    const LastUpdateTimeDiff = new Date()?.getTime() - (lastUpdateTime || item.startTimestamp);
    return item.tripStatus === 'STARTED' && LastUpdateTimeDiff >= EIGHT_HOURS_IN_MILLIS;
  }, [item, stats]);

  const { ref } = useInView({
    delay: 50,
    threshold: 0.1,
    initialInView: false,
    onChange: async (inView) => {
      try {
        if (!inView) {
          visited.current?.abort();
          visited.current = null;
          return;
        }

        if (visited.current) return;
        const aborter = new AbortController();
        visited.current = aborter;
        const state = store.getState();

        {
          let stats = await TripStatsCache.getItem(item.id);
          if (stats) setStats(stats);
        }

        let trip = await TripDetailsCache.getItem(item.id);
        if (!trip) {
          const request = api.ac.v5.trips.$tripId(item.id).$get({
            signal: aborter.signal,
            headers: {
              Authorization: selectSecretToken(state),
            },
            params: {
              tenantId: selectTenantId(state),
            },
          });
          trip = await request.process();
          TripDetailsCache.setItem(item.id, trip);
        }

        {
          const stats = buildTripStats(trip);
          TripStatsCache.setItem(item.id, stats);
          setStats(stats);
        }

        const tid = setTimeout(async () => {
          try {
            const request = api.ac.v5.endpoint.$endpointId(item.endpointId).details.$get({
              signal: aborter.signal,
              headers: {
                Authorization: selectSecretToken(state),
              },
            });
            const camera = await request.process();
            if (camera) {
              const stats = buildTripStats(trip, camera.isOnline);
              TripStatsCache.setItem(item.id, stats);
              setStats(stats);
            }
          } catch (err) {
            console.error('Failed to fetch trip details', err);
          }
        }, 1000);
        aborter.signal.addEventListener('abort', () => clearTimeout(tid));
      } catch (err) {
        console.error('Failed to fetch trip details', err);
      }
    },
  });

  return (
    <Box
      ref={ref}
      {...extra}
      bgcolor="white"
      position="relative"
      boxShadow="0px 0px 10px #608AC30F"
      sx={{
        'cursor': 'pointer',
        '&:hover .hover-item': { opacity: 1 },
        ...extra?.sx,
      }}
    >
      <TripListViewItemHeader item={item} />

      <Box mb={1}>
        <TripListViewItemStartAddress item={item} />
        {item?.tripStatus === 'ENDED' && <TripListViewItemEndAddress item={item} />}
      </Box>

      <Divider />

      <TripListViewItemFooter item={item} stats={stats} />

      {item?.tripStatus === 'STARTED' && (
        <Box
          position="absolute"
          top={10}
          right={10}
          width="13px"
          height="13px"
          borderRadius="50%"
          bgcolor="transparent"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            'animation': 'pulse-animation 2s infinite',
            '@keyframes pulse-animation': {
              '0%': {
                boxShadow: '0 0 0 0px rgba(182, 215, 252, 1)',
              },
              '100%': {
                boxShadow: `0 0 0 5px rgba(182, 215, 252, 0)`,
              },
            },
          }}
        >
          <Circle
            htmlColor={isLongOfflineTrip ? '#BFCBDB' : '#2680EB'}
            style={{ fontSize: '13px' }}
          />
        </Box>
      )}
    </Box>
  );
}
