import { FilteredDateRangePicker } from '@/web/@components/FilteredDateRangePicker';
import { FilterContextProvider } from '@/web/@components/FilterContext';
import { Box, Divider, Typography } from '@mui/material';
import { OnboardLogsFilter } from './@components/OnboardLogsFilter';
import { OnboardLogsTable } from './@components/OnboardLogsTable';

export function OnboardingLogs() {
  return (
    <Box
      px={2.5}
      mt={2}
      sx={{
        '& .MuiPaper-elevation': {
          border: '1px solid #c5d9f0',
          borderRadius: '5px',
        },
        'overflowY': 'auto',
      }}
      height="98%"
    >
      <FilterContextProvider>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
          <Typography variant="body2" fontSize="1rem" fontWeight="500">
            Onboard Logs
          </Typography>
          <Box display="flex" gap={1}>
            <OnboardLogsFilter searchable={false} selectable={true} />
            <FilteredDateRangePicker />
          </Box>
        </Box>
        <Divider />
        <OnboardLogsTable />
      </FilterContextProvider>
    </Box>
  );
}
