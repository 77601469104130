import { useRouteQuery } from '@/hooks/useRouteQuery';
import { parseUnix } from '@/utils/datetime';
import { CameraThumbnailProvider } from '@/web/cameras/@components/CameraThumbnailProvider';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, Grid, Link } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EndpointListSliderItem } from './EndpointListSliderItem';

const ITEM_WIDTH = 400;

/**
 * @typedef {object} EndpointListSliderProps
 * @property {Array<EndpointHealthInfo>} endpoints
 * @property {import('react').ReactNode} [headerTitle]
 * @property {import('react').MouseEventHandler<HTMLElement>} [onViewAll]
 */

/** @param {EndpointListSliderProps & import('@mui/material').BoxProps} props */
export function EndpointListSlider(props) {
  const { endpoints } = props;
  return (
    <CameraThumbnailProvider cameras={endpoints}>
      <EndpointListSliderContainer {...props} />
    </CameraThumbnailProvider>
  );
}

/** @param {EndpointListSliderProps & import('@mui/material').BoxProps} props */
function EndpointListSliderContainer(props) {
  const { endpoints, headerTitle, onViewAll, ...boxProps } = props;
  const { query } = useRouteQuery();

  /** @type {import('react').Ref<HTMLElement>} */
  const containerRef = useRef();

  const viewAll = useMemo(() => Boolean(query.view), [query.view]);
  const ascSorting = useMemo(() => query.sort === 'asc', [query.sort]);

  const sortedEndpoints = useMemo(() => {
    const now = Date.now();
    return (endpoints || []).sort((a, b) => {
      const at = a.deviceOnlineStatus ? now : parseUnix(a.lastOnlineTime);
      const bt = b.deviceOnlineStatus ? now : parseUnix(b.lastOnlineTime);
      let c = at - bt;
      if (!c) c = a.deviceLabel.localeCompare(b.deviceLabel);
      if (!ascSorting) c = -c;
      return c;
    });
  }, [endpoints, ascSorting]);

  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const handleScroll = useCallback(() => {
    try {
      const div = containerRef.current;
      const left = div.scrollLeft;
      const right = div.scrollWidth - (left + div.clientWidth);
      setShowLeft(left > 1);
      setShowRight(right > 1);
    } catch {}
  }, []);

  useEffect(() => {
    if (viewAll) return;
    const tid = setTimeout(() => {
      requestAnimationFrame(handleScroll);
    }, 100);
    return () => clearTimeout(tid);
  }, [sortedEndpoints, viewAll, handleScroll]);

  useEffect(() => {
    if (viewAll) return;
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll);
  }, [viewAll, handleScroll]);

  const handleScrollLeft = () => {
    const div = containerRef.current;
    div.scrollTo({
      behavior: 'smooth',
      left: div.scrollLeft - ITEM_WIDTH + 10,
    });
  };

  const handleScrollRight = () => {
    const div = containerRef.current;
    div.scrollTo({
      behavior: 'smooth',
      left: div.scrollLeft + ITEM_WIDTH + 10,
    });
  };

  return (
    <Box position="relative" mb={4} {...boxProps}>
      {!viewAll && (
        <Box
          display="flex"
          alignItems="baseline"
          gap="10px"
          position="sticky"
          top={-2}
          height={'40px'}
          zIndex={100}
          bgcolor="#fff"
          py={1}
          sx={{
            ...boxProps?.sx,
            '.view-hover': {
              display: 'none',
            },
            '&:hover .view-hover': {
              display: 'block',
            },
          }}
        >
          {headerTitle}
          {(showRight || showLeft) && (
            <Link
              className="view-hover"
              onClick={onViewAll}
              style={{ fontSize: '0.825rem', fontWeight: 500 }}
            >
              View All
            </Link>
          )}
        </Box>
      )}
      {viewAll ? (
        <Grid container spacing="10px">
          {sortedEndpoints.map((endpoint) => (
            <Grid item key={endpoint.endpointId} xs={12} sm={6} md={4} xl={3}>
              <EndpointListSliderItem endpoint={endpoint} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box position="relative">
          <Box
            ref={containerRef}
            onScroll={handleScroll}
            display="flex"
            gap="10px"
            alignItems="center"
            overflow="scroll hidden"
            sx={{
              '&::-webkit-scrollbar': {
                height: 0,
                width: 0,
              },
            }}
          >
            {sortedEndpoints.map((endpoint) => (
              <Box key={endpoint.endpointId} style={{ minWidth: ITEM_WIDTH, maxWidth: ITEM_WIDTH }}>
                <EndpointListSliderItem endpoint={endpoint} />
              </Box>
            ))}
          </Box>
          {showLeft && (
            <Button
              onClick={handleScrollLeft}
              style={{
                color: 'white',
                position: 'absolute',
                left: 0,
                top: 0,
                minWidth: '30px',
                height: (ITEM_WIDTH * 9) / 16,
                borderRadius: 0,
                background: 'linear-gradient(90deg, #07070760 0%, #FFFFFF00 100%) no-repeat',
              }}
            >
              <ChevronLeft />
            </Button>
          )}
          {showRight && (
            <Button
              onClick={handleScrollRight}
              style={{
                color: 'white',
                position: 'absolute',
                right: 0,
                top: 0,
                minWidth: '30px',
                height: (ITEM_WIDTH * 9) / 16,
                borderRadius: 0,
                background: 'linear-gradient(270deg, #07070760 0%, #FFFFFF00 100%) no-repeat',
              }}
            >
              <ChevronRight />
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
