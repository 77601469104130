import { BoxImage } from '@/web/@components/BoxImage';
import { FilterContext, SetFilterContext } from '@/web/@components/FilterContext';
import { Box, IconButton, MenuItem, Select, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ONBOARDING_LOGS_SEARCH_TYPES } from './assets';

/**
 * @template T
 * @typedef {object} OnboardLogsFilterProps<T>
 * @property {Array<string>} [hidden]
 * @property {boolean} [searchable]
 * @property {boolean} [selectable]
 */

/**
 * @template T
 * @param {OnboardLogsFilterProps<T>} props
 */

export function OnboardLogsFilter(props) {
  const { hidden, searchable = true, selectable = true } = props;

  const setFilter = useContext(SetFilterContext);
  const { searchText, searchType } = useContext(FilterContext);

  const [text, setText] = useState(searchText);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (!dirty && searchText) {
      setText(searchText || '');
    }
  }, [searchText, dirty]);

  const visibleOptions = useMemo(
    () =>
      !hidden?.length
        ? ONBOARDING_LOGS_SEARCH_TYPES
        : ONBOARDING_LOGS_SEARCH_TYPES.filter((x) => !hidden.includes(x.value)),
    [hidden]
  );

  const applyFilter = debounce(() => {
    setFilter({
      searchText: text.trim(),
      searchType: searchType || visibleOptions[0].value,
    });
  }, 100);

  /** @param {string} type */
  const handleSearchTypeChange = (type) => {
    setFilter({
      searchText: '',
      searchType: type,
    });
    setText('');
  };

  return (
    <Box
      display="inline-flex"
      gap={1}
      sx={{
        '& .MuiOutlinedInput-root': {
          height: '35px',
          padding: 0,
        },
        '& .MuiInputBase-root': {
          color: '#596A81',
          fontWeight: '500',
          borderRadius: '6px',
        },
      }}
    >
      {visibleOptions?.length && selectable && (
        <Select
          size="small"
          value={searchType || visibleOptions[0].value}
          onChange={(e) => handleSearchTypeChange(e.target.value)}
        >
          {visibleOptions.map(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      )}
      {searchable && (
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          placeholder={'Search'}
          value={text}
          sx={{ width: '250px' }}
          onChange={(e) => {
            setDirty(true);
            setText(e.currentTarget.value);
          }}
          onKeyDown={(e) => e.key === 'Enter' && applyFilter()}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => applyFilter()}>
                <BoxImage src="/images/commons/search-icon.svg" size="100%" />
              </IconButton>
            ),
          }}
        />
      )}
    </Box>
  );
}
