import {
  BOX_CREATE_ICON,
  BOX_LIST_ICON,
  DRIVER_ON_ICON,
  DRIVER_TAG_ICON_ON,
  FLEET_ON_ICON,
  VIN_GROUP_ICON_ON,
} from '@/assets/constants/images';

/** @type {Array<PageCardItem>} */
export const FLEETS_ITEMS = [
  {
    name: 'My Fleet',
    image: FLEET_ON_ICON,
    color: '#EFF3F7',
    type: 'DRIVER',
    defaultCount: 1,
    link: '/fleets/my-fleet',
    accessLevel: 'USER',
    components: [
      {
        name: 'Browse Fleet',
        image: BOX_LIST_ICON,
        link: '/fleets/my-fleet',
      },
    ],
  },
  {
    name: 'Drivers',
    image: DRIVER_ON_ICON,
    color: '#F7F5EF',
    type: 'DRIVER',
    link: '/fleets/drivers',
    accessLevel: 'ADMIN',
    components: [
      {
        name: 'Browse Drivers',
        image: BOX_LIST_ICON,
        link: '/fleets/drivers',
      },
      {
        name: 'Create New Driver',
        image: BOX_CREATE_ICON,
        link: '/fleets/drivers/create',
      },
    ],
  },
  {
    name: 'Drivers Group',
    image: VIN_GROUP_ICON_ON,
    color: '#F4F7EF',
    type: 'DEVICE_BATCH',
    link: '/fleets/driver-groups',
    accessLevel: 'HIDDEN',
    components: [
      {
        name: 'Browse Drivers Group',
        image: BOX_LIST_ICON,
        link: '/fleets/driver-groups',
      },
      {
        name: 'Create Drivers Group',
        image: BOX_CREATE_ICON,
        link: '/fleets/driver-groups/create',
      },
    ],
  },

  {
    name: 'Driver Tags',
    image: DRIVER_TAG_ICON_ON,
    color: '#F4F7EF',
    countHide: true,
    link: '/fleets/driver-tags',
    accessLevel: 'ADMIN',
    components: [
      {
        name: 'Browse Driver Tags',
        image: BOX_LIST_ICON,
        link: '/fleets/driver-tags',
      },
    ],
  },
];
