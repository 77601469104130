import { T } from '@/assets/locales';
import { AnnotationList } from '@/web/operations/annotations/index';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <AnnotationList />,
    breadcrumb: T['pages.operations.name'],
  },
];

export default routes;
