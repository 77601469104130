import { isDev } from '@/config';
import { isCameraInParkingMode } from '@/utils/cameras';
import { PageFilteringArea } from '@/web/@components/PageFilteringArea';
import { sortedUniq } from 'lodash';
import { useMemo, useState } from 'react';

/**
 * @typedef {object} FilteringAreaProps
 * @property {Array<EndpointInfoAggregated>} cameras
 * @property {(filtered: Array<EndpointInfoAggregated>) => any} onUpdate
 */

const initialState = {
  status: [],
  names: [],
  serials: [],
  vins: [],
  locations: [],
};

/** @param {FilteringAreaProps} props */
export function CameraFilteringArea(props) {
  const { cameras, onUpdate } = props;

  const [value, setValue] = useState(initialState);

  const cameraNames = useMemo(
    () =>
      sortedUniq(
        (cameras || [])
          .map((x) => x.deviceLabel)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [cameras]
  );

  const cameraSerials = useMemo(
    () =>
      sortedUniq(
        (cameras || [])
          .map((x) => x.deviceSerialNo)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [cameras]
  );

  const cameraVins = useMemo(
    () =>
      sortedUniq(
        (cameras || [])
          .map((x) => x.vin)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [cameras]
  );

  const cameraLocations = useMemo(
    () =>
      sortedUniq(
        (cameras || [])
          .map((x) => x.deviceRegion)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [cameras]
  );

  /** @param {typeof value} updates */
  const handleChange = (updates) => {
    if (!updates) return;
    setValue(updates);
    let filtered = cameras;
    if (updates.status?.length) {
      filtered = filtered.filter((camera) =>
        isCameraInParkingMode(camera.parkingStatus)
          ? updates.status.includes('Parked')
          : camera.deviceOnlineStatus
            ? updates.status.includes('Online')
            : updates.status.includes('Offline')
      );
    }
    if (
      updates.names?.length ||
      updates.serials?.length ||
      updates.vins?.length ||
      updates.locations?.length
    ) {
      filtered = filtered.filter(
        (camera) =>
          updates.names?.includes(camera.deviceLabel) ||
          updates.serials?.includes(camera.deviceSerialNo) ||
          updates.vins?.includes(camera.vin) ||
          updates.locations?.includes(camera.deviceRegion)
      );
    }
    onUpdate(filtered);
  };

  return (
    <PageFilteringArea
      value={value}
      onChange={handleChange}
      filters={[
        {
          type: 'SELECT',
          key: 'names',
          title: 'Camera',
          submenu: cameraNames,
          hidden: !cameraNames.length,
        },
        {
          type: 'SELECT',
          key: 'status',
          title: 'Status',
          submenu: ['Online', 'Parked', 'Offline'],
        },
        {
          type: 'SELECT',
          key: 'serials',
          title: 'Serial',
          submenu: cameraSerials,
          hidden: !cameraSerials.length,
        },
        {
          type: 'SELECT',
          key: 'vins',
          title: 'VIN',
          submenu: cameraVins,
          hidden: !cameraVins.length,
        },
        {
          type: 'SELECT',
          key: 'locations',
          title: 'Location',
          submenu: cameraLocations,
          hidden: !cameraLocations.length || !isDev,
        },
      ]}
    />
  );
}
