import { TripDateRangePicker } from '@/web/trips/@components/TripDateRangePicker';
import { TripFilterContextProvider } from '@/web/trips/@components/TripFilterContext';
import { TripsItemSearch } from '@/web/trips/@components/TripsItemSearch';
import { Box, Hidden, Typography } from '@mui/material';
import { TripDownloadButton } from './@components/TripDownloadButton';
import { TripReportContextProvider } from './@components/TripReportContext';
import { TripTableView } from './@components/TripTableView';

export function DownloadTripReportPage() {
  return (
    <TripFilterContextProvider>
      <TripReportContextProvider>
        <Box px={2.5} mt={1} display="flex" gap={1} alignItems="center">
          <Typography variant="body2" fontSize="1.125rem" fontWeight="500">
            Reports
          </Typography>
          <Box flex={1} />
          <Hidden mdDown>
            <TripsItemSearch />
            <TripDateRangePicker />
            <TripDownloadButton />
          </Hidden>
        </Box>
        <TripTableView />
      </TripReportContextProvider>
    </TripFilterContextProvider>
  );
}
