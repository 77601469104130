import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { selectSecretToken } from '@/store/auth';
import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { SearchField } from '@/web/@components/SearchField';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { flattenDeep, isEmpty, isObject } from 'lodash';
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { TriggerConfigurationContext } from '../../@context/TriggerConfigurationContext';
import { convertUnit, convertValueByUnit } from '../../utils';
import { AutoCompleteItemSelection } from '../AutoCompleteItemSelection';
import { BufferInfo } from '../BufferInfo';
import { CabinDataInfo } from '../CabinDataInfo';
import { CoolDownTimeBar } from '../CoolDownTimeBar';
import { EditButton } from '../EditButton';
import { RecordingInfo } from '../RecordingInfo';
import { TableSensitivityChart } from '../TableSensitivityChart';
import { TriggerActivateSwitch } from '../TriggerActivateSwitch';
import { TriggerForm } from '../TriggerForm';
import { TriggerNameWithImage } from '../TriggerNameWithImage';

const TriggerTable = () => {
  const secretToken = useSelector(selectSecretToken);

  const {
    triggerCategories,
    variablesDefinition,
    selectionType,
    parentLoading,
    triggers,
    setTriggers,
  } = useContext(TriggerConfigurationContext);

  const { query, updateQuery } = useRouteQuery();
  // Get specific parameters from the URL
  const triggerId = useMemo(() => query?.id, [query?.id]);

  const wrapperRef = useRef();
  const filterRef = useRef('');

  /** @type {StateVariable<boolean>} */
  const [isEditMode, setIsEditMode] = useState(false);
  /** @type {StateVariable<boolean>} */
  const [isHovered, setIsHovered] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<any>} */
  const [error, setError] = useState(null);
  /** @type {StateVariable<any>} */
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [searchEnable, setSearchEnable] = useState(false);
  /** @type {StateVariable<string>} */
  const [filter, setFilter] = useState('');
  /** @type {StateVariable<object>} */
  const [filteredTriggers, setFilteredTriggers] = useState({});

  const form = useForm({
    mode: 'all',
    defaultValues: {},
  });

  const columns = [
    {
      key: 'on-off-switch',
      label: 'Off/On',
      textAlign: 'left',
      width: '40px',
      component: (trigger) => (
        <Box maxWidth={10}>
          <TriggerActivateSwitch trigger={trigger} />
        </Box>
      ),
    },
    {
      key: 'trigger-name',
      label: 'Trigger',
      textAlign: 'center',
      width: '30px',
      component: (trigger) => (
        <Box ml={5} mr={{ xs: 0, sm: 0, md: 0, xl: -15 }}>
          <TriggerNameWithImage triggerName={trigger?.triggerCategoryName} />
        </Box>
      ),
    },
    {
      key: 'cabin',
      label: 'Cabin',
      textAlign: 'center',
      width: '100px',
      component: (trigger) => {
        const ttsEnabled = !!trigger?.enableTts?.value && trigger?.tts !== '';

        return (
          <CabinDataInfo
            isChimeEnabled={trigger?.chime?.value !== 'NONE'}
            isTtsEnabled={ttsEnabled}
            chime={trigger?.defaultValues?.chime?.value}
            tts={trigger?.tts}
          />
        );
      },
    },
    {
      key: 'recording',
      label: 'Recording',
      textAlign: 'center',
      width: '100px',
      component: (trigger) => (
        <RecordingInfo
          recodingType={trigger?.uploadStrategy?.value}
          defaultRecodingType={trigger?.defaultValues?.uploadStrategy?.value}
        />
      ),
    },
    {
      key: 'buffers',
      label: 'Buffers',
      textAlign: 'center',
      width: '100px',
      component: (trigger) => (
        <BufferInfo preBuffer={trigger?.preBuffer?.value} postBuffer={trigger?.postBuffer?.value} />
      ),
    },
    {
      key: 'sensitivity',
      label: 'Sensitivity',
      textAlign: 'center',
      width: '100px',
      component: (trigger, category) => (
        <TableSensitivityChart
          trigger={trigger}
          category={category}
          isHovered={isHovered === trigger?.id}
        />
      ),
    },
    {
      key: 'cooldown',
      label: 'Cool Down',
      textAlign: 'center',
      width: '100px',
      component: (trigger) => (
        <CoolDownTimeBar
          isEnable={trigger?.cooldown?.value}
          cooldownTimer={trigger?.cooldownTimer?.value / 1000}
          defaulTooldownTimer={trigger?.defaultValues?.cooldownTimer?.value / 1000}
        />
      ),
    },
    {
      key: 'action',
      label: '',
      textAlign: 'right',
      width: '30px',
      component: (trigger) => (
        <EditButton
          isVisible={isHovered === trigger?.id}
          onEdit={() => {
            handleOnEdit();
            updateQuery({ id: trigger?.id });
            setSelectedTrigger(trigger);
          }}
        />
      ),
    },
  ];

  const handleOnEdit = () => {
    setIsEditMode(true);
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    updateQuery({ id: null });
  };

  const handleMouseEnterOnRow = async (trigger) => {
    setIsHovered(trigger?.id);
    if (trigger?.defaultValues) return;
    fetchTriggerData(trigger?.id);
  };

  const groupDataByTriggerCategory = (triggers) => {
    const groupedData = triggers.reduce((result, item) => {
      const triggerCategoryName = item.triggerCategoryName;

      if (!result[triggerCategoryName]) {
        result[triggerCategoryName] = [];
      }
      result[triggerCategoryName].push(item);
      return result;
    }, {});

    return groupedData;
  };

  const transFormData = useCallback(
    async (triggers) => {
      if (!triggerCategories?.length || !variablesDefinition?.length) return;

      const transformedResult = (triggers || [])
        .map((trigger) => {
          const triggerCategory = triggerCategories.find(
            (i) => i.id === trigger?.triggerCategoryId
          );

          // Skip the current item if trigger category is not found
          // Or it a Shadow Category , id = 36
          // Or it a Default Category , id = 1
          if (!triggerCategory || [1, 36].includes(triggerCategory?.id)) {
            return null;
          }

          const variablesData = trigger?.body?.variableOverrides?.variables;

          const triggerVariables = (variablesDefinition || [])
            .filter((variable) => variable.triggerCategoryId === trigger.triggerCategoryId)
            .map((variable) => {
              const originalUnit = variable.unit;
              const convertedUnitName = convertUnit(originalUnit);

              const triggerVariableData =
                variablesData?.find((i) => i.variableName === variable.name) || null;

              const { defaultValue, constraint } = variable;
              const { minValue, maxValue, stepSize } = constraint;

              const itemMinValue = convertValueByUnit(minValue, originalUnit, convertedUnitName);
              const itemMaxValue = convertValueByUnit(maxValue, originalUnit, convertedUnitName);
              const itemStepSize = convertValueByUnit(stepSize, originalUnit, convertedUnitName);
              const itemDefaultValue = convertValueByUnit(
                defaultValue,
                originalUnit,
                convertedUnitName
              );

              const itemValue = convertValueByUnit(
                triggerVariableData?.value ?? null,
                originalUnit,
                convertedUnitName
              );

              return {
                ...triggerVariableData,
                key: variable.name,
                description: variable.description,
                unit: convertedUnitName,
                originalUnit,
                minValue: itemMinValue,
                maxValue: itemMaxValue,
                stepSize: itemStepSize,
                default: itemDefaultValue,
                value: itemValue || itemDefaultValue,
              };
            })
            .sort((a, b) => a.key.localeCompare(b.key));

          return {
            ...trigger,
            triggerCategoryName: triggerCategory.name,
            variables: variablesData?.length === 0 ? variablesData : triggerVariables,
          };
        })
        .filter(Boolean);

      const sortedResult = transformedResult.sort((a, b) =>
        a.triggerCategoryName.localeCompare(b.triggerCategoryName)
      );
      const categoriesTriggerCategoryData = await groupDataByTriggerCategory(sortedResult);
      return { transformedResult, categoriesTriggerCategoryData };
    },
    [triggerCategories, variablesDefinition]
  );

  const fetchTenantTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      try {
        const request = api.ac.v5.trigger.composite.tenant
          .$tenantId(selectionType?.value)
          .detail.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
        const result = request.result?.compositeTriggers;
        return result;
      } catch (ex) {
        setError(ex?.response?.data?.message);
        console.log('ex', ex);
      }
    },
    [secretToken, selectionType?.value]
  );

  const fetchGroupTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      try {
        const request = api.ac.v5.trigger.composite.group
          .$groupId(Number(selectionType?.value))
          .detail.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
        const result = request.result?.compositeTriggers;
        return result;
      } catch (ex) {
        setError(ex?.response?.data?.message);
      }
    },
    [secretToken, selectionType?.value]
  );

  const fetchEndpointTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      try {
        const request = api.ac.v5.trigger.composite.endpoint
          .$endpointId(Number(selectionType?.value))
          .detail.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
        const result = request.result?.compositeTriggers;
        return result;
      } catch (ex) {
        setError(ex?.response?.data?.message);
      }
    },
    [secretToken, selectionType?.value]
  );

  const updateTriggerById = useCallback(
    (id, fieldName, newValue) => {
      setTriggers((prevTriggers) => {
        const updatedTriggers = { ...prevTriggers };
        Object.keys(updatedTriggers).forEach((category) => {
          const categoryTriggers = updatedTriggers[category];
          const updatedCategoryTriggers = categoryTriggers.map((trigger) => {
            if (trigger.id === id) {
              return { ...trigger, [fieldName]: newValue };
            }
            return trigger;
          });
          updatedTriggers[category] = updatedCategoryTriggers;
        });
        return updatedTriggers;
      });
    },
    [setTriggers]
  );

  const fetchTriggerData = useCallback(
    async (id) => {
      const request = api.ac.v5.trigger.composite.$triggerId(id).$get({
        headers: {
          Authorization: secretToken,
        },
        params: {},
      });
      const result = await request?.process();
      const { transformedResult } = await transFormData([result]);
      updateTriggerById(id, 'defaultValues', transformedResult?.at(0));
    },
    [secretToken, updateTriggerById, transFormData]
  );

  useEffect(() => {
    setLoading(true);
    setError(null);

    if (!selectionType?.type) {
      setLoading(false);
      return;
    }

    const aborter = new AbortController();

    const fetchData = async () => {
      try {
        if (!selectionType.value) return;
        setLoading(true);
        let result;
        if (selectionType.type === 'TENANT') {
          result = await fetchTenantTriggerData(aborter.signal);
        } else if (selectionType.type === 'ENDPOINT') {
          result = await fetchEndpointTriggerData(aborter.signal);
        } else if (selectionType.type === 'GROUP') {
          result = await fetchGroupTriggerData(aborter.signal);
        }
        const transformData = await transFormData(result);
        if (transformData?.categoriesTriggerCategoryData) {
          setTriggers(transformData?.categoriesTriggerCategoryData);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1200);
      } catch (ex) {
        setLoading(false);
        setError(ex?.response?.data?.message);
        console.log('ex', ex);
      }
    };

    fetchData();

    return () => {
      aborter.abort();
      setLoading(false);
      setIsEditMode(false);
    };
  }, [
    fetchTenantTriggerData,
    fetchGroupTriggerData,
    fetchEndpointTriggerData,
    transFormData,
    setTriggers,
    selectionType?.type,
    selectionType?.value,
  ]);

  useEffect(() => {
    if (!triggerId) {
      setIsEditMode(false);
    } else {
      let flattenTriggers = flattenDeep(Object.values(triggers));
      const trigger = flattenTriggers?.find((t) => t?.id === triggerId);
      if (flattenTriggers?.length > 0 && !trigger) {
        updateQuery({ id: null });
      }
      if (trigger) {
        setIsEditMode(true);
        setSelectedTrigger(trigger);
      }
    }
  }, [triggerId, triggers, updateQuery]);

  // Handle Click outside for bigger display
  const handleMenuCloseWhileClickOutside = useCallback(
    (e) => {
      if (filterRef?.current?.trim()?.length) return;
      if (
        wrapperRef.current &&
        searchEnable &&
        // @ts-ignore
        !wrapperRef.current?.contains(e.target)
      ) {
        setTimeout(() => {
          setSearchEnable(false);
        }, 100);
      }
    },
    [searchEnable]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMenuCloseWhileClickOutside);
    return () => {
      document.removeEventListener('visibilitychange', handleMenuCloseWhileClickOutside);
    };
  }, [handleMenuCloseWhileClickOutside]);

  const searchByCategoryName = (data, searchString) => {
    const result = {};
    for (const key in data) {
      if (key?.toLowerCase()?.includes(searchString ? searchString?.toLowerCase() : '')) {
        result[key] = data[key];
      }
    }
    return result;
  };

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  useEffect(() => {
    const filteredItems = searchByCategoryName(triggers, filter);
    setFilteredTriggers(filteredItems);
  }, [triggers, filter, setFilteredTriggers]);

  const SearchComponent = () => {
    if (isEditMode) return null;
    return searchEnable ? (
      <Box
        ref={wrapperRef}
        sx={{
          'width': '100%',
          'display': 'flex',
          '& .MuiInputBase-root': {
            'padding': '5px !important',
            '& input::placeholder': {
              fontSize: '16px',
            },
            '& fieldset': {
              display: 'none',
            },
          },
        }}
      >
        <SearchField
          clearable
          ignoreCase
          autoFocus
          value={filter}
          placeholder="Search"
          onSearch={setFilter}
          sx={{ width: '100%', height: '44px', borderRadius: '5px' }}
        />
      </Box>
    ) : (
      <Box
        sx={{
          display: 'flex',
          textAlign: 'end',
          justifyContent: 'flex-end',
          alignItems: 'center',
          cursor: 'pointer',
          mt: '20px',
        }}
        onClick={() => setSearchEnable(true)}
      >
        <IconButton sx={{ mr: '-5px' }}>
          <BoxImage src="/images/commons/search-icon.svg" size="16px" />
        </IconButton>
        <Typography variant="subtitle2" color="#99a9bd">
          Search Triggers
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      <Box>
        <Typography variant="body2" fontSize="1rem" fontWeight={'medium'} mb={1} color="#596A82">
          {!isEditMode ? 'Select Entity' : selectedTrigger?.name}
        </Typography>
        <Box width="100%" display={'flex'} gap={2}>
          <Box width={{ xs: '100%', md: '60%' }}>
            <AutoCompleteItemSelection isEditMode={isEditMode} />
          </Box>
          <Box width={{ xs: '100%', md: '40%' }}>
            <SearchComponent />
          </Box>
        </Box>
      </Box>

      <Box>
        {loading || parentLoading ? (
          <CenterBox sx={{ height: '80vh !important' }}>
            <CircularProgress />
          </CenterBox>
        ) : !selectionType ? (
          <CenterBox sx={{ height: '80vh !important' }}>
            <IconMessageBox
              size="256px"
              src={NO_SEARCH_RESULT_IMG}
              message="Sorry! No entity selected."
            />
          </CenterBox>
        ) : !loading && error ? (
          <CenterBox sx={{ height: '80vh !important' }}>
            <IconMessageBox
              size="256px"
              src={NO_SEARCH_RESULT_IMG}
              message={
                error?.includes('Internal')
                  ? error
                  : 'The specified target tenant is not under the control of the initiator tenant.'
              }
            />
          </CenterBox>
        ) : isObject(filteredTriggers) && isEmpty(filteredTriggers) ? (
          <CenterBox sx={{ height: '80vh !important' }}>
            <IconMessageBox
              size="256px"
              src={NO_SEARCH_RESULT_IMG}
              message="Sorry! No Trigger found."
            />
          </CenterBox>
        ) : !isEmpty(filteredTriggers) ? (
          <Box maxHeight="80vh" sx={{ overflowY: 'auto' }}>
            {isEditMode && (
              <TriggerForm onCancel={handleCancelEdit} triggerItem={selectedTrigger} />
            )}
            <Grid container spacing={3} sx={{ display: isEditMode ? 'none' : 'block' }}>
              <Grid item xs={12}>
                <TableContainer
                  pb={5}
                  component={Box}
                  sx={{
                    'maxHeight': '80vh',
                    '& .MuiTableCell-stickyHeader': {
                      opacity: 1,
                      background: '#f2f6fc',
                    },
                  }}
                >
                  <Table
                    stickyHeader
                    sx={{
                      '& .MuiTableCell-head': {
                        padding: '8px 0px !important',
                        borderBottom: 'none',
                      },
                      '& .MuiTableHead-root': {
                        border: '1px solid #d6e4f5 !important',
                        borderRadius: '100px',
                        background: '#d6e4f5',
                      },
                      '& .MuiTableCell-body': {
                        padding: '0px 4px !important',
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column, index) => (
                          <TableCell
                            sx={{
                              color: '#0B2547',
                              opacity: 0.68,
                              fontSize: '0.875rem',
                              textAlign: 'center',
                            }}
                            key={index}
                          >
                            {column?.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <FormProvider {...form}>
                      <TableBody>
                        {Object.entries(filteredTriggers).map(
                          ([category, triggersByCategories], index) => (
                            <Fragment key={index}>
                              {triggersByCategories?.map((trigger, index2) => (
                                <TableRow
                                  key={index2}
                                  sx={{
                                    'px': 2,
                                    'height': '80px',
                                    '&:hover': {
                                      backgroundColor: '#E3EDFD',
                                    },
                                    '&:hover .edit-button': {
                                      visibility: 'visible',
                                    },
                                  }}
                                  onMouseEnter={() => handleMouseEnterOnRow(trigger)}
                                  onMouseLeave={() => setIsHovered(null)}
                                >
                                  {columns?.map((item, index) => (
                                    <TableCell
                                      sx={{
                                        textAlign: item?.textAlign,
                                        width: item?.width,
                                      }}
                                      key={index}
                                    >
                                      {item?.component(trigger, category)}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </Fragment>
                          )
                        )}
                      </TableBody>
                    </FormProvider>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <CenterBox sx={{ height: '80vh !important' }}>
            <CircularProgress />
          </CenterBox>
        )}
      </Box>
    </Box>
  );
};

export default TriggerTable;
