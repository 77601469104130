import { T } from '@/assets/locales';
import { GeofenceListView } from '@/web/operations/geofences/index';
import { CreateGeofencePage } from './create';
import { GeofenceDetailsPage } from './_id/details';
import { UpdateGeofencePage } from './_id/edit';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <GeofenceListView />,
  },
  {
    path: 'create',
    element: <CreateGeofencePage />,
    breadcrumb: 'Create Geofence',
  },
  {
    path: ':id',
    children: [
      {
        path: '',
        element: <GeofenceDetailsPage />,
        breadcrumb: T['button.details'],
      },
      {
        path: 'edit',
        element: <UpdateGeofencePage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
