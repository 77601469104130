import {
  BOX_CREATE_ICON,
  BOX_CUSTOMER_ICON,
  BOX_LIST_ICON,
  BOX_SOFTWARE_ICON,
  NAV_CONFIGURATION_ICON_ON,
  NAV_OPERATION_ICON_ON,
  NAV_REPORT_ICON_ON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';

/** @type {Array<PageCardItem>} */
export const WASTE_MANAGEMENT_ITEMS = [
  {
    name: T['waste-management.operations.page.card.name'],
    image: NAV_OPERATION_ICON_ON,
    color: '#EFF3F7',
    link: '/waste-management/operations',
  },
  {
    name: T['waste-management.configurations.page.card.name'],
    image: NAV_CONFIGURATION_ICON_ON,
    color: '#F7F5EF',
    link: '/waste-management/configurations',
    components: [
      {
        name: T['waste-management.configurations.page.card.customer'],
        image: BOX_CUSTOMER_ICON,
        link: '',
      },
      {
        name: T['waste-management.configurations.page.card.softwareConf'],
        image: BOX_SOFTWARE_ICON,
        link: '',
      },
    ],
  },
  {
    name: T['waste-management.reports.page.card.name'],
    image: NAV_REPORT_ICON_ON,
    color: '#F4F7EF',
    link: '/waste-management/reports',
    components: [
      {
        name: T['waste-management.reports.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '',
      },
      {
        name: T['waste-management.reports.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '',
      },
    ],
  },
];
