import { Outlet } from 'react-router-dom';
import { CoachingDriverDetailsContextProvider } from '../@context/DriverDetailsContext';

export function CoachingDriverPage() {
  return (
    <CoachingDriverDetailsContextProvider>
      <Outlet />
    </CoachingDriverDetailsContextProvider>
  );
}
