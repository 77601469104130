/**
 * @typedef {object} SliderArrowPropsBase
 * @property {boolean} [visible]
 */

import { IconButton } from '@mui/material';

/**
 * @typedef {SliderArrowPropsBase & Partial<import('@mui/material').IconButtonProps>} SliderArrowProps
 */

/** @param {SliderArrowProps} props */
export function SliderArrow(props) {
  const { visible, sx, ...extra } = props;

  if (!visible) {
    return null;
  }

  return (
    <IconButton
      {...extra}
      sx={{
        'position': 'absolute',
        'top': 'calc(50% - 15px)',
        'width': '30px',
        'height': '30px',
        'opacity': 0.4,
        'background': '#000',
        '& .MuiSvgIcon-root': {
          color: '#fff',
        },
        '&:hover': {
          opacity: 0.6,
          background: '#000',
        },
        ...sx,
      }}
    />
  );
}
