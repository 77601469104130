import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { PageLink } from '@/web/@components/PageLink';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { TripDetailsView } from '@/web/trips/@components/TripDetailsView';
import { TripListViewItem } from '@/web/trips/@components/TripListViewItem';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useContext } from 'react';

const OLDEST_TRIP_TIME = 30 * 24 * 3600 * 1000;

/** @param {import('@mui/material').BoxProps} props */
export function RecentTripsArea(props) {
  const { camera, cameraId } = useContext(CameraDetailsContext);

  const {
    result: trip,
    error,
    loading,
  } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      if (!cameraId) return {};
      const request = api.ac.v5.trips.$get({
        signal,
        params: {
          offset: 0,
          limit: 5,
          tenantId,
          toTimestamp: Date.now(),
          fromTimestamp: Date.now() - OLDEST_TRIP_TIME,
          ...(cameraId ? { endpointId: cameraId + '' } : {}),
        },
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      return (
        request.result?.tripList
          .filter((trip) => trip.tripStatus === 'STARTED' || trip?.distance > 50)
          ?.at(0) || {}
      );
    },
    [cameraId]
  );

  const {
    result: tripDetails,
    error: detailsError,
    loading: detailsLoading,
  } = useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      if (loading || !trip?.id) return {};
      const request = api.ac.v5.trips.$tripId(trip.id).$get({
        signal,
        params: {
          tenantId,
        },
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      return request.result;
    },
    [loading, trip?.id]
  );

  if (error) {
    return null;
  }

  return (
    <Box {...props}>
      <Box py={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600} color="#0B2547" sx={{ opacity: 0.68 }}>
          Recent Trips
        </Typography>

        {!isEmpty(trip) && (
          <PageLink
            fontSize={'0.875rem'}
            to={`./trips?${qs.stringify({
              from: startOfTheDay(trip.startTimestamp),
              to: endOfTheDay(Date.now()),
              fullWidth: true,
            })}`}
          >
            View All Trips
          </PageLink>
        )}
      </Box>

      {loading || detailsLoading ? (
        <CenterBox style={{ border: '1px solid #f8f8f8', height: '200px' }}>
          <CircularProgress></CircularProgress>
        </CenterBox>
      ) : isEmpty(trip) ? (
        <CenterBox style={{ border: '1px solid #f8f8f8' }}>
          <IconMessageBox
            size="150px"
            src="/images/pages/trips/no-trips-icon.svg"
            message="No Trips"
          />
        </CenterBox>
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            md={4}
            xl={3}
            p={1.5}
            bgcolor="#F2F7FC"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <TripListViewItem item={trip} />
          </Grid>
          <Grid item xs={12} md={8} xl={9} bgcolor="#F0F0F0" height="200px">
            {detailsLoading ? (
              <CenterBox>
                <CircularProgress />
              </CenterBox>
            ) : detailsError || !tripDetails ? (
              <CenterBox>
                <IconMessageBox
                  size="150px"
                  src="/images/pages/trips/no-trips-icon.svg"
                  message="No Trips"
                />
              </CenterBox>
            ) : (
              <TripDetailsView details={tripDetails} camera={camera} />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
