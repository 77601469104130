import { restoreAuthState, store } from '@/store';
import { PageView } from '@/store/page-view';
import { fetchMyGeoInfo } from '@/utils/geoinfo';
import { throttle } from 'lodash';
import { useEffect } from 'react';

export function Preload() {
  useEffect(() => {
    const aborter = new AbortController();

    fetchMyGeoInfo().catch(console.log);

    const handleVisibility = throttle(() => {
      if (document.visibilityState === 'visible') {
        restoreAuthState().catch(console.error);
      }
      store.dispatch(PageView.setTabVisible(document.visibilityState === 'visible'));
    }, 100);
    document.addEventListener('visibilitychange', handleVisibility, {
      passive: true,
      signal: aborter.signal,
    });

    return () => {
      aborter.abort();
      handleVisibility.cancel();
    };
  }, []);

  return null;
}
