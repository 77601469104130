import { store } from '@/store';
import { Auth, findTenant, selectTenant, selectTenantId } from '@/store/auth';
import { parseJwtToken } from '@/utils/crypto';
import { toastWarning } from '@/utils/toaster';
import { CircularProgress } from '@mui/material';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { CenterBox } from '../@components/CenterBox';

/** @param {{children: any}} props */
export function EmbedWorker(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  // Auto login from query parameters
  // - tenantId: [required]
  // - token: [optional] The account token
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const state = store.getState();
        const query = qs.parse(location.search.substring(1));
        const tenantId = (query.tenantId || '') + '';
        if (!tenantId || tenantId === selectTenantId(state)) return;
        const userToken = (query.token || '') + '';
        const tenant = findTenant(tenantId);
        if (!userToken) {
          // switch to available tenant
          if (tenant) {
            await store.dispatch(selectTenant({ tenantId, support: tenant.path }));
          }
        } else {
          // login with tenant
          console.log('Attempting login. TenantId:', tenantId, 'Token:', userToken);
          const { email } = parseJwtToken(userToken);
          store.dispatch(Auth.setRequest({ email }));
          await store.dispatch(selectTenant({ tenantId, token: userToken, email }));
          store.dispatch(
            Auth.setAccount({
              email,
              refreshToken: userToken,
              tenantAccessList: [tenant || { tenantId }],
            })
          );
        }
        // redirect page
        delete query.tenantId;
        delete query.token;
        navigate(
          {
            pathname: location.pathname,
            search: qs.stringify(query),
          },
          { replace: true }
        );
      } catch (err) {
        console.error('Failed to process query parameters', err);
        toastWarning('Could not log in to the specific tenant');
      } finally {
        setLoading(false);
      }
    })();
  }, [location, navigate]);

  if (loading) {
    return (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );
  }

  return props.children;
}
