import { InstallDashcamPage } from './dashcam';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: 'dashcam',
    element: <InstallDashcamPage />,
    visibility: 'ALWAYS',
  },
];

export default routes;
