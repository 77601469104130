import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { downloadFile } from '@/utils/file-utils';
import { toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/smarter-ai/firmwares/actions';
import { FIRMWARE_TABLE_COLUMNS } from '@/web/smarter-ai/firmwares/columns';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

export function FirmwareListView() {
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  /** @param {FirmwareDto} firmware */
  const downloadFirmware = async (firmware) => {
    try {
      const request = api.ac.v5.firmware.$firmwareId(firmware.firmwareId).$get({
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request.process();
      await downloadFile(result.firmwareDownloadUrl, result.actualFileName);
    } catch (err) {
      toastWarning('Error', 'Failed to download file');
    }
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<FirmwareDto>['onAction']} */
  const onActionHandle = async (type, selectedItems) => {
    if (type === 'DISCONTINUE') {
      await deleteFirmwares(selectedItems);
    } else if (type === 'DOWNLOAD') {
      await downloadFirmware(selectedItems[0]);
    }
  };

  /** @param {FirmwareDto} item */
  const deleteFirmware = async (item) => {
    try {
      const request = api.ac.v2.repository.firmware.$delete({
        params: {
          firmwareId: item.firmwareId,
          secretToken,
          tenantId,
        },
      });
      await request.process();
    } catch (err) {
      let message = `Could not discontinue device firmware ${item.name}.`;
      const results = err?.response?.data?.results;
      if (results?.length) {
        if (results[0].message === 'NO_PERMISSION') {
          message = `Could not discontinue device firmware ${item.name}. You do not have permissions to delete this device firmware.`;
        } else if (results[0].message === 'ALREADY_DEPLOYED') {
          message = `Could not discontinue device firmware ${item.name}. This device firmware is already deployed.`;
        } else if (results[0].message === 'ALREADY_ASSOCIATED') {
          message = `Could not discontinue device firmware ${item.name}. This device firmware is already associated with an AI Container.`;
        }
      }
      toastWarning(message);
    }
  };

  const deleteFirmwares = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteFirmware));
  };

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title={'Firmwares'} actions={ACTION_ITEMS} onAction={onActionHandle} />
        <OffsetPagination
          key={secretToken}
          itemsPerPage={50}
          fetcher={async ({ signal, limit, offset, searchText, searchType }) => {
            const request = api.ac.v5.firmware.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                tenantId,
                limit,
                offset,
                ...(searchText !== '' && searchType && { [searchType]: searchText }),
              },
            });
            await request.process();
            return {
              data: request.result.firmwares,
              count: Number(request.response.headers['x-total-count']),
            };
          }}
          renderList={(/** @type {Array<FirmwareDto>} */ results) => (
            <ItemsResponsiveView
              results={results}
              columns={FIRMWARE_TABLE_COLUMNS}
              defaultSortKey="name"
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
