import { T } from '@/assets/locales';
import { FIRMWARE_TYPES } from '@/assets/ota';
import { useFetchAIAccelerators } from '@/hooks/useFetchAIAccelerators';
import { selectCurrentTenantName } from '@/store/auth';
import { formatFirmwareName } from '@/utils/firmwares';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import {
  CustomInput,
  CustomMultiSelect,
  CustomSelect,
  CustomSwitch,
} from '@/web/@components/CustomForm';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { AddOutlined as AddOutlinedIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProductInputSourceFormItem } from '../ProductInputSourceForm';

/**
 * @typedef {object} ProductFormFields
 * @property {string} author
 * @property {string} name
 * @property {string} serial
 * @property {AIAccelerator} aiAccelerator
 * @property {string} website
 * @property {string} manual
 * @property {Array<string>} firmwareTypes
 * @property {string} description
 * @property {string} cpu
 * @property {string} gpu
 * @property {string} npu
 * @property {string} ram
 * @property {string} storage
 * @property {boolean} wifi
 * @property {boolean} cellular
 * @property {boolean} ethernet
 * @property {boolean} bluetooth
 * @property {Array<Input>} inputSources
 */

/**
 * @typedef {object} ProductFormProps
 * @property {boolean} [disabled]
 * @property {import('@/types').ProductProperties} [product]
 * @property {import('react-hook-form').SubmitHandler<ProductFormFields>} onSubmit
 */

/**
 * @param {ProductFormProps} props
 */
export function ProductForm(props) {
  const { onSubmit, disabled, product } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const tenantName = useSelector(selectCurrentTenantName);
  const { result: aiAccelerators = [], loading: acceleratorLoading } = useFetchAIAccelerators();

  /** @type {import('react-hook-form').UseFormReturn<ProductFormFields>} */
  const form = useForm({
    mode: 'all',
    defaultValues: {
      author: tenantName,
      serial: product?.serial || '',
      description: product?.description || '',
      cpu: product?.cpu || '',
      npu: product?.npu || '',
      gpu: product?.gpu || '',
      name: product?.productName || '',
      manual: product?.manual || '',
      website: product?.website || '',
      ram: product?.ram || '',
      storage: product?.storage || '',
      wifi: product?.wifi === 'true',
      cellular: product?.cellular === 'true',
      bluetooth: product?.bluetooth === 'true',
      ethernet: product?.ethernet === 'true',
      firmwareTypes: product?.firmwareTypeList || [],
      inputSources: product?.inputs || [],
      aiAccelerator: product?.aiAcceleratorList?.at(0),
    },
  });

  useEffect(() => {
    if (!product) return;
    form.setValue('serial', product.serial);
    form.setValue('description', product.description);
    form.setValue('firmwareTypes', product.firmwareTypeList);
    form.setValue('cpu', product.cpu);
    form.setValue('npu', product.npu);
    form.setValue('gpu', product.gpu);
    form.setValue('name', product.productName);
    form.setValue('manual', product.manual);
    form.setValue('website', product.website);
    form.setValue('ram', product.ram);
    form.setValue('storage', product.storage);
    form.setValue('wifi', product.wifi === 'true');
    form.setValue('cellular', product.cellular === 'true');
    form.setValue('bluetooth', product.bluetooth === 'true');
    form.setValue('ethernet', product.ethernet === 'true');
    form.setValue('inputSources', product.inputs);
    form.setValue('aiAccelerator', product.aiAcceleratorList[0]);
  }, [product, form]);

  const handleCancel = () => {
    navigate('/smarter-ai/products');
  };

  const inputSourceField = useFieldArray({
    control: form.control,
    name: 'inputSources',
  });

  const handleAddInputSource = () => {
    inputSourceField.prepend({
      inputType: 'VIDEO',
      inputName: '',
      inputCode: '',
      properties: [],
    });
  };

  /** @param {ProductFormFields} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmit) return;
    data.author = trimString(data.author);
    data.name = trimString(data.name);
    data.serial = trimString(data.serial);
    data.website = trimString(data.website);
    data.manual = trimString(data.manual);
    data.description = trimString(data.description);
    data.cpu = trimString(data.cpu);
    data.gpu = trimString(data.gpu);
    data.npu = trimString(data.npu);
    data.ram = trimString(data.ram);
    data.storage = trimString(data.storage);
    data.inputSources.forEach((item) => {
      item.inputName = trimString(item.inputName);
    });
    onSubmit(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
        {/*
            ╔═╗┬─┐┌─┐┌┬┐┬ ┬┌─┐┌┬┐  ╔╦╗┌─┐┌┬┐┌─┐┬┬  ┌─┐
            ╠═╝├┬┘│ │ │││ ││   │    ║║├┤  │ ├─┤││  └─┐
            ╩  ┴└─└─┘─┴┘└─┘└─┘ ┴   ═╩╝└─┘ ┴ ┴ ┴┴┴─┘└─┘
        */}
        <Typography variant="body2" fontSize="1rem" mt={'20px'} mb={'5px'} fontWeight={500}>
          Product Details
        </Typography>
        <Divider />
        <Box height="10px" />
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <CustomInput name="author" label={'Author *'} disabled />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="name"
              label={'Name *'}
              placeholder={'Product name'}
              rules={{ required: 'Product name is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="serial"
              label={'Serial No *'}
              placeholder={'Product serial number'}
              rules={{ required: 'Product serial number is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomSelect
              name="aiAccelerator"
              label={'AI Accelerator'}
              placeholder={'Select AI Accelerator'}
              options={aiAccelerators || []}
              loading={acceleratorLoading}
              getLabel={(item) => item?.name || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomMultiSelect
              name="firmwareTypes"
              label="Firmware Types"
              placeholder="Select firmware types"
              options={Object.keys(FIRMWARE_TYPES)}
              getLabel={(item) => formatFirmwareName(FIRMWARE_TYPES[item])}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="website"
              label={'Product Website'}
              placeholder={'Enter product website link'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="manual"
              label={'Product Manual'}
              placeholder={'Enter product manual link'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="description"
              label={'Description'}
              placeholder={'Enter Product description'}
              minRows={3}
            />
          </Grid>
        </Grid>
        <Box height="15px" />

        {/*
            ╦ ╦┌─┐┬─┐┌┬┐┬ ┬┌─┐┬─┐┌─┐  ╔═╗┌─┐┌─┐┌─┐┌┐ ┬┬  ┬┌┬┐┬┌─┐┌─┐
            ╠═╣├─┤├┬┘ │││││├─┤├┬┘├┤   ║  ├─┤├─┘├─┤├┴┐││  │ │ │├┤ └─┐
            ╩ ╩┴ ┴┴└──┴┘└┴┘┴ ┴┴└─└─┘  ╚═╝┴ ┴┴  ┴ ┴└─┘┴┴─┘┴ ┴ ┴└─┘└─┘
        */}
        <Typography variant="body2" fontSize="1rem" mt={'20px'} mb={'5px'} fontWeight={500}>
          Hardware Capabilities
        </Typography>
        <Divider />
        <Box height="10px" />
        <Grid container columnSpacing={1}>
          <Grid item xs={12} md={4}>
            <CustomInput name="cpu" label={'CPU'} placeholder={'The CPU specification'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput name="gpu" label={'GPU'} placeholder={'The GPU specification'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput name="npu" label={'NPU'} placeholder={'The NPU specification'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput name="ram" label={'RAM'} placeholder={'The RAM capacity'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput name="storage" label={'Storage'} placeholder={'The Storage capacity'} />
          </Grid>
        </Grid>
        <Box height="15px" />

        {/*
            ╔═╗┌─┐┌┐┌┌┐┌┌─┐┌─┐┌┬┐┬┬  ┬┬┌┬┐┬ ┬  ╔═╗┌─┐┌─┐┌─┐┌┐ ┬┬  ┬┌┬┐┬┌─┐┌─┐
            ║  │ │││││││├┤ │   │ │└┐┌┘│ │ └┬┘  ║  ├─┤├─┘├─┤├┴┐││  │ │ │├┤ └─┐
            ╚═╝└─┘┘└┘┘└┘└─┘└─┘ ┴ ┴ └┘ ┴ ┴  ┴   ╚═╝┴ ┴┴  ┴ ┴└─┘┴┴─┘┴ ┴ ┴└─┘└─┘
        */}
        <Typography variant="body2" fontSize="1rem" mt={'20px'} mb={'5px'} fontWeight={500}>
          Connectivity Capabilities
        </Typography>
        <Divider />
        <Box height="10px" />
        <Grid container columnSpacing={1}>
          <Grid item xs={12} md={4}>
            <CustomSwitch name="wifi" label={'Wi-Fi'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomSwitch name="bluetooth" label={'Bluetooth'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomSwitch name="cellular" label={'Cellular (LTE / 4G)'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomSwitch name="ethernet" label={'Ethernet (LAN)'} />
          </Grid>
        </Grid>
        <Box height="15px" />

        {/*
            ╦┌┬┐┌─┐┌─┐┌─┐┬─┐  ┌─┐┬─┐  ╔═╗┌─┐┌┐┌┌─┐┌─┐┬─┐  ╦┌┐┌┌─┐┬ ┬┌┬┐┌─┐
            ║│││├─┤│ ┬├┤ ├┬┘  │ │├┬┘  ╚═╗├┤ │││└─┐│ │├┬┘  ║│││├─┘│ │ │ └─┐
            ╩┴ ┴┴ ┴└─┘└─┘┴└─  └─┘┴└─  ╚═╝└─┘┘└┘└─┘└─┘┴└─  ╩┘└┘┴  └─┘ ┴ └─┘
        */}
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="body2" fontSize="1rem" mt={'20px'} mb={'5px'} fontWeight={500}>
            Imager / Sensor Inputs
          </Typography>
          <Button
            size="small"
            variant="outlined"
            onClick={handleAddInputSource}
            startIcon={<AddOutlinedIcon />}
            sx={{ mb: 0.5 }}
          >
            Input Source
          </Button>
        </Box>
        <Divider />
        <Box height="10px" />
        {!inputSourceField.fields.length && (
          <CenterBox>
            <IconMessageBox message="No input sources" text="secondary" />
          </CenterBox>
        )}
        {inputSourceField.fields.map((itemField, fieldIndex) => (
          <ProductInputSourceFormItem
            key={fieldIndex}
            fieldIndex={fieldIndex}
            remove={inputSourceField.remove}
          />
        ))}
        <Box height="30px" />

        {/*
            ╔═╗┬ ┬┌┐ ┌┬┐┬┌┬┐  ╔╗ ┬ ┬┌┬┐┌┬┐┌─┐┌┐┌┌─┐
            ╚═╗│ │├┴┐││││ │   ╠╩╗│ │ │  │ │ ││││└─┐
            ╚═╝└─┘└─┘┴ ┴┴ ┴   ╚═╝└─┘ ┴  ┴ └─┘┘└┘└─┘
        */}
        <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'}>
          <Button variant="text" onClick={handleCancel}>
            {t(T['button.cancel'])}
          </Button>
          <Button disabled={disabled} variant="contained" type="submit">
            {disabled && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
            {product ? t(T['button.update']) : t(T['button.save'])}
          </Button>
        </Box>
        <Box height="50px" />
      </form>
    </FormProvider>
  );
}
