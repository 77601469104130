// navigation
export const BOX_LIST_ICON = '/images/navigations/box-list.svg';
export const BOX_CREATE_ICON = '/images/navigations/box-create.svg';
export const BOX_MANAGE_ICON = '/images/navigations/box-manage.svg';
export const BOX_DELETE_ICON = '/images/navigations/box-delete.svg';
export const BOX_MANAGE_SETTING_ICON = '/images/navigations/box-manage-setting.svg';
export const BOX_RELEASE_ICON = '/images/navigations/box-deploy.svg';
export const BOX_MODIFY_ICON = '/images/navigations/box-edit.svg';
export const BOX_DISCONTINUE = '/images/navigations/discontinue-icon.svg';
export const BOX_CHANGE_GROUP = '/images/navigations/change_group.png';
export const BOX_BIOMETRIC_ICON = '/images/navigations/biometric-box-icon.svg';
export const BOX_CONSENT_ICON = '/images/navigations/consent-box-icon.svg';
export const BOX_REPORT_ICON = '/images/navigations/report-icon.svg';
export const BOX_TOOLS_ICON = '/images/navigations/tools-box-icon.svg';
export const BOX_SCHEME_ICON = '/images/navigations/schemes.svg';
export const BOX_SOFTWARE_ICON = '/images/navigations/software-box.svg';
export const BOX_CUSTOMER_ICON = '/images/navigations/customer-box.svg';
export const ASSIGN_CHILD_ICON = '/images/navigations/assign-child.svg';
export const ASSIGN_CHILD_ICON_ON = '/images/navigations/assign-child-on.svg';
export const ASSIGN_ICON = '/images/navigations/assign-icon.svg';
export const TRANSFER_ICON = '/images/navigations/transfer-icon.svg';

// layouts
export const USER_FALLBACK_ICON = '/images/layouts/topbar/user-icon.svg';
export const AI_OFF_ICON = '/images/layouts/sidebar/ai-n-off.svg';
export const AI_ON_ICON = '/images/layouts/sidebar/ai-n-on.svg';
export const AI_MODEL_ICON_OFF = '/images/layouts/sidebar/ai-model-n-off.svg';
export const AI_MODEL_ICON_ON = '/images/layouts/sidebar/ai-model-n-on.svg';
export const AI_CONTAINER_ICON_OFF = '/images/layouts/sidebar/container-n-off.svg';
export const AI_CONTAINER_ICON_ON = '/images/layouts/sidebar/container-n-on.svg';
export const OPERATION_ICON_OFF = '/images/layouts/sidebar/operation-off.svg';
export const OPERATION_ICON_ON = '/images/layouts/sidebar/operation-on.svg';
export const TRIGGERS_ICON_OFF = '/images/layouts/sidebar/triggers-n-off.svg';
export const TRIGGERS_ICON_ON = '/images/layouts/sidebar/triggers-n-on.svg';
export const ANNOTATION_ICON_OFF = '/images/layouts/sidebar/annotations-n-off.svg';
export const ANNOTATION_ICON_ON = '/images/layouts/sidebar/annotations-n-on.svg';
export const GEOFENCE_ICON_OFF = '/images/layouts/sidebar/geofence-n-off.svg';
export const GEOFENCE_ICON_ON = '/images/layouts/sidebar/geofence-n-on.svg';
export const ADMINISTRATION_ICON_OFF = '/images/layouts/sidebar/administration-off.svg';
export const ADMINISTRATION_ICON_ON = '/images/layouts/sidebar/administration-on.svg';
export const DEVICE_GROUP_ICON_OFF = '/images/layouts/sidebar/device-group-n-off.svg';
export const DEVICE_GROUP_ICON_ON = '/images/layouts/sidebar/device-group-n-on.svg';
export const DEVICE_POOL_ICON_OFF = '/images/layouts/sidebar/device-pool-n-off.svg';
export const DEVICE_POOL_ICON_ON = '/images/layouts/sidebar/device-pool-n-on.svg';
export const VIN_GROUP_ICON_OFF = '/images/layouts/sidebar/vin-n-off.svg';
export const VIN_GROUP_ICON_ON = '/images/layouts/sidebar/vin-n-on.svg';
export const USERS_ICON_OFF = '/images/layouts/sidebar/users-n-off.svg';
export const USERS_ICON_ON = '/images/layouts/sidebar/users-n-on.svg';
export const ORGANIZATION_ICON_OFF = '/images/layouts/sidebar/settings-n-off.svg';
export const ORGANIZATION_ICON_ON = '/images/layouts/sidebar/settings-n-on.svg';
export const FIRMWARE_ICON_OFF = '/images/layouts/sidebar/firmware-n-off.svg';
export const FIRMWARE_ICON_ON = '/images/layouts/sidebar/firmware-n-on.svg';
export const DEVICE_BATCH_ICON_OFF = '/images/layouts/sidebar/container-n-off.svg';
export const DEVICE_BATCH_ICON_ON = '/images/layouts/sidebar/container-n-on.svg';
export const PRODUCT_ICON_OFF = '/images/layouts/sidebar/products-n-off.svg';
export const PRODUCT_ICON_ON = '/images/layouts/sidebar/products-n-on.svg';
export const TENANT_ICON_OFF = '/images/layouts/sidebar/tenants-n-off.svg';
export const TENANT_ICON_ON = '/images/layouts/sidebar/tenants-n-on.svg';
export const CAMERA_N_OFF_ICON = '/images/layouts/sidebar/camera-n-off.svg';
export const DRIVER_ON_ICON = '/images/layouts/sidebar/drivers-n-on.svg';
export const DRIVER_OFF_ICON = '/images/layouts/sidebar/drivers-n-off.svg';
export const EVENTS_N_ON_ICON = '/images/layouts/sidebar/events-n-on.svg';
export const EVENTS_N_OFF_ICON = '/images/layouts/sidebar/events-n-off.svg';
export const CAMERA_N_ON_ICON = '/images/layouts/sidebar/camera-n-on.svg';
export const LABEL_ICON_OFF = '/images/layouts/sidebar/labels-off.svg';
export const LABEL_ICON_ON = '/images/layouts/sidebar/labels-on.svg';
export const AI_ACCELERATOR_ON_ICON = '/images/layouts/sidebar/ai-accelerator-on.svg';
export const AI_ACCELERATOR_OFF_ICON = '/images/layouts/sidebar/ai-accelerator-off.svg';
export const FLEET_ON_ICON = '/images/layouts/sidebar/fleet-on.svg';
export const FLEET_OFF_ICON = '/images/layouts/sidebar/fleet-off.svg';
export const ONBOARD_ICON_OFF = '/images/layouts/sidebar/logs-icon-off.svg';
export const ONBOARD_ICON_ON = '/images/layouts/sidebar/logs-icon-on.svg';
export const SCHEME_ICON_OFF = '/images/layouts/sidebar/create-schemes-off.svg';
export const SCHEME_ICON_ON = '/images/layouts/sidebar/create-schemes-on.svg';
export const CAMERA_HEALTH_OFF = '/images/layouts/sidebar/camera-health-off.svg';
export const CAMERA_HEALTH_ON = '/images/layouts/sidebar/camera-health-on.svg';
export const SCHEME_VIEW_MODE_ICON_OFF = '/images/layouts/sidebar/schemes-off.svg';
export const SCHEME_VIEW_MODE_ICON_ON = '/images/layouts/sidebar/schemes-on.svg';
export const DRIVER_TAG_ICON_OFF = '/images/layouts/sidebar/driver-tag-off.svg';
export const DRIVER_TAG_ICON_ON = '/images/layouts/sidebar/driver-tag-on.svg';
export const LIVE_TRACKING_ICON_OFF = '/images/fleets/tracking-off.svg';
export const LIVE_TRACKING_ICON_ON = '/images/fleets/tracking-on.svg';
export const TRIGGER_SENSITIVITY_ICON_OFF = '/images/layouts/sidebar/triggers_sensitivity_off.svg';
export const TRIGGER_SENSITIVITY_ICON_ON = '/images/layouts/sidebar/triggers_sensitivity_on.svg';
export const SCHEME_LIST_ICON_OFF = '/images/layouts/sidebar/scheme-list-off.svg';
export const SCHEME_LIST_ICON_ON = '/images/layouts/sidebar/scheme-list-on.svg';
export const SCHEME_LANDING_ICON_OFF = '/images/layouts/sidebar/scheme-main-off.svg';
export const SCHEME_LANDING_ICON_ON = '/images/layouts/sidebar/scheme-main-on.svg';

export const SAFETY_ICON_ON = '/images/layouts/sidebar/safety-on.svg';
export const SAFETY_ICON_OFF = '/images/layouts/sidebar/safety-off.svg';
export const SCORING_ICON_ON = '/images/layouts/sidebar/scoring-on.svg';
export const SCORING_ICON_OFF = '/images/layouts/sidebar/scoring-off.svg';
export const REVIEW_ICON_ON = '/images/layouts/sidebar/review-on.svg';
export const REVIEW_ICON_OFF = '/images/layouts/sidebar/review-off.svg';
export const COACHING_ICON_ON = '/images/layouts/sidebar/coaching-on.svg';
export const COACHING_ICON_OFF = '/images/layouts/sidebar/coaching-off.svg';

//common
export const DELETE_ICON = '/images/commons/delete-icon.svg';
export const WHITE_BOX_EDIT_ICON = '/images/commons/box-edit-white.svg';
export const CAMERA_ON_ICON = '/images/pages/common/camera-on.svg';
export const CAMERA_OFF_ICON = '/images/pages/common/camera-off.svg';
export const CAMERA_PARKING_ICON = '/images/pages/common/camera-parking.svg';
export const CALENDAR_ICON = '/images/commons/calendar-icon.svg';
export const PLAY_ICON_ENABLED = '/images/commons/play-bg-icon.svg';
export const PLAY_GENERIC_ICON = '/images/commons/play-generic.svg';
export const CAMERA_DOWNLOAD_SD_ICON = '/images/commons/camera-download-icon.svg';
export const PLAY_ICON_DISABLED = '/images/commons/play-off.svg';
export const REBOOT_ICON = '/images/commons/reboot-icon.svg';

// player
export const ACCELERATION_ICON = '/images/pages/camera/acceleration-icon.svg';
export const ANNOTATION_EDIT_ICON = '/images/annotations/edit-icon.svg';
export const ANNOTATION_DELETE_ICON = '/images/annotations/delete-icon.svg';
export const MAP_ICON = '/images/commons/map-view-off.svg';
export const SEEK_NEXT_ICON = '/images/player/player-next-icon.svg';
export const SEEK_PREV_ICON = '/images/player/player-back-icon.svg';
export const PLAY_REWIND_ICON = '/images/player/rewind-icon.svg';
export const BOUNDING_BOX_ICON = '/images/player/bounding-on-icon.svg';
export const PLAYER_DOWNLOAD_ICON = '/images/player/download-icon.svg';
export const MAP_MARKER_ICON = '/images/player/car-marker.svg';
export const OVERLAY_PLAY_ICON = '/images/player/overlay-play-icon.svg';
export const CALENDAR_OFF_ICON = '/images/player/date-cal-icon.svg';
export const RECORDING_DURATION_ICON = '/images/player/recording-duration.svg';
export const VIDEO_PLAY_ICON = '/images/player/play-icon.svg';
export const VIDEO_PAUSE_ICON = '/images/player/pause-icon.svg';

// ai-model
export const AI_MODEL_ICON = '/images/pages/ai/ai-model-icon.svg';

// ai-container
export const AI_CONTAINER_ICON = '/images/pages/ai/ai-container-icon.svg';
export const AI_CONTAINER_SENSOR_ICON = '/images/pages/ai/ai-container/accelerometer-icon.svg';
export const AI_CONTAINER_ADD_PLUS_ICON = '/images/pages/ai/ai-container/add-plus-icon.svg';
export const AI_CONTAINER_AI_MODEL_ICON = '/images/pages/ai/ai-container/ai-model-icon.svg';
export const AI_CONTAINER_AUDIO_ICON = '/images/pages/ai/ai-container/audio-icon.svg';
export const AI_CONTAINER_CAMERA_VIDEO_ICON = '/images/pages/ai/ai-container/camera-video-icon.svg';
export const AI_CONTAINER_EDIT_ICON = '/images/pages/ai/ai-container/edit-icon.svg';
export const AI_CONTAINER_PRODUCT_ICON = '/images/pages/ai/ai-container/product-icon.svg';

// operations
export const TRIGGER_ICON = '/images/pages/operations/triggers-icon.svg';
export const ANNOTATION_ICON = '/images/pages/operations/annotation-icon.svg';
export const GEO_FENCE_ICON = '/images/pages/operations/geofence-icon.svg';
export const LABEL_ICON = '/images/pages/operations/label-box.svg';
export const MANAGED_CAMERA_ON_ICON = '/images/pages/operations/manage-cameras-on.svg';
export const MANAGED_CAMERA_OFF_ICON = '/images/pages/operations/manage-cameras-off.svg';
//manage camera
export const CONTROLS_ICON = '/images/pages/operations/manage-cameras/controls.svg';
export const DIAGNOSTICS_ICON = '/images/pages/operations/manage-cameras/diagnostics.svg';
export const CAMERA_SETTINGS_ICON = '/images/pages/operations/manage-cameras/camera-settings.svg';
export const PRESENCE_ICON = '/images/pages/operations/manage-cameras/presence.svg';
export const USAGES_ICON = '/images/pages/operations/manage-cameras/usages.svg';
export const SOFTWARES_ICON = '/images/pages/operations/manage-cameras/softwares.svg';
export const SENSORS_ICON = '/images/pages/operations/manage-cameras/sensors.svg';

// administration
export const CAMERA_GROUP_ICON = '/images/pages/administration/camera-groups-icon.svg';
export const DEVICE_POOL_ICON = '/images/pages/administration/device-pool-on.svg';
export const VIN_GROUP_ICON = '/images/pages/administration/VIN-group-icon.svg';
export const USERS_ICON = '/images/pages/administration/users-icon.svg';
export const APN_SETTINGS_ICON = '/images/pages/administration/apn-setting-icon.svg';
export const VIDEO_MINUTES_OFF = '/images/layouts/sidebar/video-minutes-off.svg';
export const VIDEO_MINUTES_ON = '/images/layouts/sidebar/video-minutes-on.svg';

// smarter ai
export const FIRMWARES_ICON = '/images/pages/organization/camera-firmware-icon.svg';
export const DEVICE_BATCH_ICON = '/images/pages/organization/device-batch.svg';
export const PRODUCTS_ICON = '/images/pages/organization/products.svg';
export const TENANTS_ICON = '/images/pages/organization/tenants-icon.png';
export const ONBOARD_LOGS_ICON = '/images/layouts/sidebar/logs-icon-on.svg';

// waste management
export const WASTE_MANAGEMENT_ICON_OFF = '/images/layouts/sidebar/waste-management-off.svg';
export const WASTE_MANAGEMENT_ICON_ON = '/images/layouts/sidebar/waste-management-on.svg';
export const NAV_OPERATION_ICON_OFF = '/images/layouts/sidebar/operation-n-off.svg';
export const NAV_OPERATION_ICON_ON = '/images/layouts/sidebar/operation-n-on.svg';
export const NAV_CONFIGURATION_ICON_OFF = '/images/layouts/sidebar/configuration-n-off.svg';
export const NAV_CONFIGURATION_ICON_ON = '/images/layouts/sidebar/configuration-n-on.svg';
export const NAV_REPORT_ICON_OFF = '/images/layouts/sidebar/reports-n-off.svg';
export const NAV_REPORT_ICON_ON = '/images/layouts/sidebar/reports-n-on.svg';

// winter operation
export const WINTER_OPERATION_ICON_OFF = '/images/layouts/sidebar/winter-operations-off.svg';
export const WINTER_OPERATION_ICON_ON = '/images/layouts/sidebar/winter-operations-on.svg';
export const NAV_GALLERY_ICON_OFF = '/images/layouts/sidebar/gallery-n-off.svg';
export const NAV_GALLERY_ICON_ON = '/images/layouts/sidebar/gallery-n-on.svg';

// data privacy
export const DATA_PRIVACY_ICON_OFF = '/images/layouts/sidebar/data-privacy-off.svg';
export const DATA_PRIVACY_ICON_ON = '/images/layouts/sidebar/data-privacy-on.svg';
export const DATA_RESIDENCY_ICON_OFF = '/images/layouts/sidebar/data-residency-n-off.svg';
export const DATA_RESIDENCY_ICON_ON = '/images/layouts/sidebar/data-residency-n-on.svg';
export const DATA_RETENTION_ICON_OFF = '/images/layouts/sidebar/data-retention-n-off.svg';
export const DATA_RETENTION_ICON_ON = '/images/layouts/sidebar/data-retention-n-on.svg';
export const DATA_PRIVACY_POLICY_ICON_OFF = '/images/layouts/sidebar/privacy-policy-n-off.svg';
export const DATA_PRIVACY_POLICY_ICON_ON = '/images/layouts/sidebar/privacy-policy-n-on.svg';

// status
export const NO_SEARCH_RESULT_IMG = '/images/empty/no-search-results.svg';
export const STATUS_DEPLOY_ICON = '/images/status/status-deploy.svg';
export const STATUS_DRAFT_ICON = '/images/status/status-draft.svg';
export const STATUS_DISCONTINUE_ICON = '/images/status/status-discontinue.svg';

// trips
export const TRIPS_OFF_ICON = '/images/pages/trips/trips-off.svg';
export const TRIPS_ON_ICON = '/images/pages/trips/trips-on.svg';
export const TRIP_START_ICON = '/images/pages/trips/trip-start-icon.svg';
export const TRIP_STOP_ICON = '/images/pages/trips/trip-stop-icon.svg';
export const TRIP_RUNNING_ICON = '/images/pages/trips/trip-running-icon.svg';
export const MAP_START_ICON = '/images/pages/trips/map-start-icon.svg';
export const MAP_STOP_ICON = '/images/pages/trips/map-stop-icon.svg';
export const MAP_RUNNING_ICON = '/images/pages/trips/running-car-icon.svg';
export const MAP_ARROW_ICON = '/images/pages/trips/arrow-going-icon.svg';
export const MAP_CIRCLE_STOP_ICON = '/images/pages/trips/circle-stop-map.svg';
export const MAP_EVENT_ALERT_ICON = '/images/pages/trips/event-red-alert.svg';
export const MAP_DRIVER_EVENT_ALERT_ICON = '/images/pages/trips/event-orange-alert.svg';
export const EVENT_CLUSTER_ICON = '/images/pages/trips/cluster-icon.svg';
export const MAP_THUMBNAIL_ICON = '/images/pages/trips/map-point-icon.svg';
export const DRIVER_DEFAULT_ICON = '/images/empty/driver-default-Icon.svg';
export const DRIVER_CONTAINED_ICON = '/images/fleets/driver-contained-icon.svg';
export const USER_APP_DEFAULT_ICON = '/images/pages/administration/app-device.png';
export const USER_HARDWARE_DEVICE_DEFAULT_ICON =
  '/images/pages/administration/harware-device-default.png';

// health
export const RECALIBRATION_ICON = '/images/pages/health/callibration.svg';
export const SORT_ASC_ICON = '/images/pages/health/sort-asc-icon.svg';
export const SORT_DES_ICON = '/images/pages/health/sort-des-icon.svg';

// fleet
export const LIVE_TRACKING_CLUSTER_ICON = '/images/fleets/cluster-blue.svg';
export const LIVE_TRACKING_SINGLE_CLUSTER_ICON = '/images/fleets/cluster-single-blue.svg';
export const LIVE_TRACKING_ONLINE_ICON = '/images/fleets/green-car-icon.svg';
export const LIVE_TRACKING_OFFLINE_ICON = '/images/fleets/gray-car-icon.svg';
export const LIVE_TRACKING_PARKED_ICON = '/images/fleets/blue-car-icon.svg';

//toaster

export const TOAST_SUCCESS_ICON = '/images/toast/success-toast.svg';
export const TOAST_WARNING_ICON = '/images/toast/warning-toast.svg';
export const TOAST_ERROR_ICON = '/images/toast/error-toast.svg';
export const TOAST_INFO_ICON = '/images/toast/info-toast.svg';

//Trigger Sensitivity
export const TRIGGER_CAMERA_GROUP_ICON = '/images/trigger/camera-group.svg';
export const TRIGGER_CHIME_ICON = '/images/trigger/chime-icon.svg';
export const TRIGGER_VOICE_ICON = '/images/trigger/voice-icon.svg';
export const TRIGGER_EDIT_ICON = '/images/trigger/edit-icon.svg';
export const TRIGGER_ON_CLOUD_ICON = '/images/trigger/on-cloud-icon.svg';
export const TRIGGER_ON_CAMERA_ICON = '/images/trigger/on-camera-icon.svg';
export const TRIGGER_SNAPSHOT_ICON = '/images/trigger/snapshot-blue.svg';

export const TRIGGER_DISTRACTION = '/images/trigger/sensitivity/distraction-icon.svg';
export const TRIGGER_DRIVER_ON_PHONE = '/images/trigger/sensitivity/driver-on-phone-icon.svg';
export const TRIGGER_HARSH_ABC = '/images/trigger/sensitivity/harsh-abcs-icon.svg';
export const TRIGGER_NEAR_COLLISIONS = '/images/trigger/sensitivity/near-collisions-icon.svg';
export const TRIGGER_ROLLING_STOP = '/images/trigger/sensitivity/rollling-stops-icon.svg';
export const TRIGGER_SPEEDING = '/images/trigger/sensitivity/speeding-icon.svg';
export const TRIGGER_STRADDLING = '/images/trigger/sensitivity/straddling-lane-icon.svg';
export const TRIGGER_TAILGATING = '/images/trigger/sensitivity/tailgating-icon.svg';
export const TRIGGER_COLLISIONS = '/images/trigger/sensitivity/Collision.svg';
export const TRIGGER_DRIVER_EATING = '/images/trigger/sensitivity/driver-eating.svg';
export const TRIGGER_DRIVER_SMOKING = '/images/trigger/sensitivity/driver-smoking.svg';
export const TRIGGER_ENGINE_COOLANT = '/images/trigger/sensitivity/engine-coolant.svg';
export const TRIGGER_NO_SEATBELT = '/images/trigger/sensitivity/no-seatbelt.svg';
export const TRIGGER_PEDESTRIAN = '/images/trigger/sensitivity/pedestrian-icon.svg';
export const TRIGGER_RED_LINE_VIOLATION = '/images/trigger/sensitivity/red-light-violation.svg';
export const TRIGGER_YELLOW_LINE_VIOLATION =
  '/images/trigger/sensitivity/yellow-light-violation.svg';
export const TRIGGER_ROAD_CONDITION = '/images/trigger/sensitivity/road-condition';
export const TRIGGER_STOP_SIGN = '/images/trigger/sensitivity/stop-icon.svg';
