import { LongMessageViewer } from '@/web/@components/LongMessageViewer';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<AIAccelerator>>} */
export const AI_ACCELERATOR_TABLE_COLUMNS = [
  {
    id: 'codeName',
    align: 'left',
    width: '1%',
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'codeName'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'codeName',
    label: 'Code Name',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'vendor',
    label: 'Vendor',
  },
  {
    id: 'description',
    label: 'Description',
    format: (value, row) => <LongMessageViewer message={row.description} title={row.name} />,
  },
];
