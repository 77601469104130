import { BoxImage } from '@/web/@components/BoxImage';
import { Download } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { useContext } from 'react';
import { TripReportContext } from '../TripReportContext';

export function TripDownloadButton() {
  const { loading, download } = useContext(TripReportContext);
  return (
    <Button disabled={loading} variant="outlined" onClick={() => download()} sx={{ gap: '5px' }}>
      {loading ? (
        <CircularProgress size="16px" />
      ) : (
        <BoxImage src={'/images/pages/trips/csv-icon.svg'} size="16px" />
      )}
      Download
      <div />
      <Download />
    </Button>
  );
}
