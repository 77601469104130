import {
  ASSIGN_ICON,
  BOX_CREATE_ICON,
  BOX_LIST_ICON,
  CAMERA_GROUP_ICON,
  DEVICE_POOL_ICON,
  SCHEME_VIEW_MODE_ICON_ON,
  TENANTS_ICON,
  TRIGGER_SENSITIVITY_ICON_ON,
  USERS_ICON,
  VIDEO_MINUTES_ON,
  VIN_GROUP_ICON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AI_ITEMS } from '@/assets/page-cards/ai';

/** @type {Array<PageCardItem>} */
export const ADMINISTRATION_ITEMS = [
  ...AI_ITEMS,
  {
    name: T['device.groups.page.card.name'],
    image: CAMERA_GROUP_ICON,
    color: '#EFF3F7',
    type: 'DEVICE_GROUP',
    link: '/administration/device-groups',
    accessLevel: 'ADMIN',
    components: [
      {
        name: T['device.groups.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/administration/device-groups',
      },
      {
        name: T['device.groups.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '/administration/device-groups/create',
      },
    ],
  },
  {
    name: T['triggers.configuration.page.card.name'],
    image: TRIGGER_SENSITIVITY_ICON_ON,
    color: '#EFF3F7',
    // type: 'AI_TRIGGER',
    countHide: true,
    link: '/administration/trigger-configuration',
    accessLevel: 'ADMIN',
    components: [
      {
        name: T['triggers.configuration.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/administration/trigger-configuration',
      },
    ],
  },
  {
    name: T['device.pools.page.card.name'],
    image: DEVICE_POOL_ICON,
    color: '#F7F5EF',
    type: 'DEVICE_POOL',
    link: '/administration/device-pools',
    accessLevel: 'ADMIN',
    components: [
      {
        name: T['device.pools.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/administration/device-pools',
      },
      {
        name: T['device.pools.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '/administration/device-pools/create',
      },
    ],
  },
  {
    name: T['vin.groups.page.card.name'],
    image: VIN_GROUP_ICON,
    color: '#F4F7EF',
    type: 'VIN_GROUP',
    link: '/administration/vin-groups',
    accessLevel: 'ADMIN',
    components: [
      {
        name: T['vin.groups.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/administration/vin-groups',
      },
      {
        name: T['vin.groups.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '/administration/vin-groups/create',
      },
    ],
  },
  {
    name: T['tenants.page.card.name'],
    image: TENANTS_ICON,
    color: '#F2EEE9',
    type: 'TENANTS',
    link: '/administration/tenants',
    components: [
      {
        name: T['tenants.page.card.browse.items'],
        image: BOX_LIST_ICON,
        link: '/administration/tenants',
      },
      {
        name: T['tenants.page.card.create.new'],
        image: BOX_CREATE_ICON,
        link: '/administration/tenants/create',
      },
    ],
  },
  {
    name: T['users.page.card.name'],
    image: USERS_ICON,
    color: '#F2EEE9',
    type: 'USERS',
    link: '/administration/users',
    accessLevel: 'ADMIN',
    components: [
      {
        name: T['users.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/administration/users',
      },
      {
        name: T['users.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '/administration/users/create',
      },
    ],
  },
  {
    name: T['scheme.page.card.title'],
    image: SCHEME_VIEW_MODE_ICON_ON,
    color: '#EDF2F2',
    // type: 'SCHEMES',
    countHide: true,
    link: '/administration/schemes/',
    components: [
      {
        name: T['scheme.page.card.browse'],
        image: BOX_LIST_ICON,
        link: '/administration/schemes/list',
      },
      {
        name: T['scheme.page.card.apply'],
        image: ASSIGN_ICON,
        link: '/administration/schemes/apply',
      },
    ],
  },
  {
    name: T['navigation.video-minutes'],
    image: VIDEO_MINUTES_ON,
    color: '#EFF3F7',
    countHide: true,
    link: '/administration/video-minutes',
    accessLevel: 'ADMIN',
    components: [
      {
        name: T['navigation.videominutes.browse'],
        image: BOX_LIST_ICON,
        link: '/administration/video-minutes',
      },
    ],
  },
];
