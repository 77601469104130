import { T } from '@/assets/locales';
import { DASHBOARD_VERSION } from '@/config';
import { BoxImage } from '@/web/@components/BoxImage';
import { LanguageSelection } from '@/web/@components/LanguageSelection';
import { Box, Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export function AuthLayout() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container component="main" display="flex" position="relative" minHeight="100%">
      <Grid
        component="section"
        item
        xs={12}
        md={6}
        sx={{
          position: 'relative',
          color: '#596a81',
          width: '100%',
          minHeight: { xs: '150px', md: '100%' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: { xs: 'center', md: 'flex-start' },
          backgroundImage: 'url(/images/pages/auth/cover.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'top right',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box
          textAlign="center"
          p={{ xs: 0, md: '5px' }}
          mt={{ xs: 0, md: '15%' }}
          maxWidth={{ xs: '80%', md: '360px' }}
          fontSize={{ xs: '24px', md: '30px' }}
          style={{
            height: 'fit-content',
            lineHeight: '36px',
            fontWeight: '600',
            letterSpacing: '0px',
            borderRadius: '25px',
            backdropFilter: 'blur(4px)',
            textShadow: '0px 0px 99px #fff',
          }}
        >
          {t(T['login.banner'])}
        </Box>
      </Grid>
      <Grid
        component="section"
        item
        xs={12}
        md={6}
        className="layout-content"
        sx={{
          maxHeight: { md: '100%' },
          overflowY: { md: 'auto' },
          minHeight: { xs: 'calc(100% - 150px)', md: '100%' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box textAlign="center" px={1} py={{ xs: 3, md: 4, xl: 6 }}>
          <BoxImage width="256px" src="/images/layouts/logo.svg" alt="logo" />
        </Box>

        <Outlet />

        <Box textAlign="right" position="absolute" top={10} left={20} right={20}>
          <LanguageSelection />
        </Box>
      </Grid>

      <Box
        color={theme.palette.text.secondary}
        fontSize="0.625rem"
        textAlign="left"
        position="absolute"
        bottom={10}
        left={25}
      >
        v{DASHBOARD_VERSION}
      </Box>
    </Grid>
  );
}
