import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<TriggerClassification>>} */
export const LABEL_TABLE_COLUMNS = [
  {
    id: 'label',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'label'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'displayName',
    label: 'Display Name',
    align: 'left',
    isPrimary: true,
    sort: true,
    searchable: true,
  },
  {
    id: 'name',
    label: 'Label',
    align: 'left',
    sort: true,
    searchable: true,
  },
  {
    id: 'unit',
    label: 'Unit',
    align: 'left',
    sort: true,
    searchable: true,
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    sort: true,
    searchable: true,
    width: '350px',
  },
];
