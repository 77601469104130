import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

/**
 * @typedef {object} CoachingDriverDetailsContextData
 * @property {boolean} loading
 * @property {number} driverId
 * @property {Driver} driver
 * @property {EventV5ResponseModel[]} events
 * @property {number} visitedCount
 * @property {number} totalCount
 * @property {number} startIndex
 * @property {{[key: string]: boolean}} visited
 * @property {(eventId: string) => any} markVisited
 * @property {EventV5ResponseModel | null} selectedEvent
 * @property {(index: number) => any} updateSelection
 */

// TODO: temporary visit status tracking. replace this with API
/** @type {SmartCache<string[]>} */
const visitedCache = new SmartCache('visited-event-tracking', 365 * 24 * 3600 * 1000);

/** @type {import('react').Context<CoachingDriverDetailsContextData>} */
export const CoachingDriverDetailsContext = createContext(null);

/** @param {{children: import('react').ReactNode}} props */
export function CoachingDriverDetailsContextProvider(props) {
  const params = useParams();
  const { setBreadcrumbTitle } = useContext(MainContext);

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<EventV5ResponseModel[]>} */
  const [events, setEvents] = useState([]);
  /** @type {StateVariable<Driver>} */
  const [driver, setDriver] = useState();
  /** @type {StateVariable<{[key: string]: boolean}>} */
  const [visited, setVisited] = useState({});
  /** @type {StateVariable<number>} */
  const [startIndex, setStartIndex] = useState(0);

  const driverId = useMemo(() => Number(params['driverId'] || ''), [params]);

  const visitedCount = useMemo(
    () => Object.keys(visited).filter((x) => visited[x]).length,
    [visited]
  );

  const totalCount = useMemo(() => events?.length || 0, [events?.length]);

  const selectedEvent = useMemo(
    () => (events?.length ? events[startIndex] : null),
    [startIndex, events]
  );

  useEffect(() => {
    if (!events?.length) return;
    // TODO: get it from an API
    visitedCache.getItem(driverId).then((items) => {
      let start = false;
      /** @type {{[key: string]: boolean}} */
      const data = {};
      for (let i = 0; i < events.length; ++i) {
        const id = events[i]?.id;
        if (!id) continue;
        if (items && items[id]) {
          data[id] = true;
        } else if (!start) {
          start = true;
          setStartIndex(i);
        }
      }
      setVisited(data);
      if (!start) {
        setStartIndex(events.length - 1);
      }
    });
  }, [driverId, events]);

  /** @type {CoachingDriverDetailsContextData['markVisited']} */
  const markVisited = useCallback(
    (eventId) => {
      setVisited((v) => ({ ...v, [eventId]: true }));
      // TODO: call an API to update
      visitedCache.getItem(driverId).then((v) => {
        visitedCache.setItem(driverId, { ...v, ...visited });
      });
    },
    [visited, driverId]
  );

  /** @type {CoachingDriverDetailsContextData['updateSelection']} */
  const updateSelection = useCallback(
    (index) => {
      setStartIndex(Math.max(0, Math.min(totalCount - 1, index)));
    },
    [totalCount]
  );

  // TODO: Get session details API.
  // TODO: Get coachable events for the session.

  useEffect(() => {
    const request = api.ac.v5.driver.$driverId(driverId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request
      .process()
      .then((r) => setDriver(r.result))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [secretToken, driverId]);

  useEffect(() => {
    if (!driver) return;
    const name = `${driver.firstName} ${driver.lastName}`;
    setBreadcrumbTitle(name, (path) => path.endsWith(driver.driverId + ''));
  }, [driver, setBreadcrumbTitle]);

  useEffect(() => {
    const request = api.ac.v5.events.filter.$get({
      headers: {
        Authorization: secretToken,
      },
      params: {
        tenantId,
        pageSize: 100,
        startTimestamp: 0,
        endTimestamp: Date.now() - 3600 * 1000,
      },
    });
    request
      .process()
      .then((result) =>
        setEvents(
          result.events
            .filter((item) =>
              Boolean(
                item &&
                  item.uploadCompleted &&
                  item.recordingEndTimestamp &&
                  item.recordingStartTimestamp &&
                  // (recordingState === 'OnSDCard' || recordingState === 'OnCloud') &&
                  item.recordingEndTimestamp - item.recordingStartTimestamp > 100 &&
                  item.recordingEndTimestamp - item.recordingStartTimestamp < 60 * 3600
              )
            )
            .slice(0, 3)
        )
      )
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [secretToken, tenantId]);

  return (
    <CoachingDriverDetailsContext.Provider
      children={props.children}
      value={{
        driverId,
        loading,
        driver,
        events,
        visited,
        markVisited,
        startIndex,
        totalCount,
        visitedCount,
        selectedEvent,
        updateSelection,
      }}
    />
  );
}
