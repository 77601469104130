import { TriggerDetailsModal } from '@/web/@components/TriggerDetailsModal';
import { Link } from '@mui/material';
import { useState } from 'react';

/**
 * @typedef {object} TriggerItem
 * @property {string} [id]
 * @property {string} [name]
 * @property {string} [code]
 * @property {string} [category]
 */

/**
 * @typedef {object} TriggerNameColumnProps
 * @property {TriggerItem} [row]
 */

/** @param {TriggerNameColumnProps} props */
export function TriggerNameColumn(props) {
  const { row } = props;

  /** @type {StateVariable<boolean>} */
  const [detailsDialog, setDetailsDialog] = useState(false);

  return (
    <>
      {detailsDialog && (
        <TriggerDetailsModal
          onCancel={() => setDetailsDialog(false)}
          triggerId={row?.id}
          triggerCategory={row.category}
        />
      )}
      <Link onClick={() => setDetailsDialog(true)}>{row.name}</Link>
    </>
  );
}
