import { T } from '@/assets/locales';
import { MonitoringLandingPage } from '.';
import { CameraHealthMonitoring } from './camera-health';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <MonitoringLandingPage />,
  },
  {
    path: 'triggers',
    children: require('./triggers/routes').default,
    breadcrumb: T['navigation.triggers'],
  },
  {
    path: 'camera-health',
    element: <CameraHealthMonitoring />,
    breadcrumb: T['camera.health.page.card.name'],
    accessLevel: 'HIDDEN',
  },
  {
    path: 'firmwares',
    children: require('./firmwares/routes').default,
    breadcrumb: 'Firmwares',
    accessLevel: 'SMARTER_AI_ADMIN',
  },
];

export default routes;
