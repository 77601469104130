import { Grid, Typography } from '@mui/material';

/**
 * Displays expression details for a trigger.
 * @param {object} props - React component props.
 * @param {SingleTriggerDto} props.body - The trigger details to display.
 */
export function ExpressionTriggerDetails({ body }) {
  if (!body) return null;
  return (
    <Grid item xs={12}>
      <Typography variant="subtitle2" lineHeight="18px">
        Expression
      </Typography>
      <Typography variant="subtitle1" fontWeight={500} lineHeight="18px" className="word-break">
        {body?.displayFormula || body?.expression}
      </Typography>
    </Grid>
  );
}
