import api from '@/api';
import { T } from '@/assets/locales';
import { useFullProductList } from '@/hooks/useFullProductList';
import { selectSecretToken } from '@/store/auth';
import { formatFirmwareName } from '@/utils/firmwares';
import { trimString } from '@/utils/formatting';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FirmwareFileUpload } from '../FirmwareFileUpload';

/**
 * @typedef {object} TypeProps
 * @property {string} [label]
 * @property {string} [value]
 */

/**
 * @typedef {object} FirmwareFormFields
 * @property {File} firmwareFile
 * @property {string} firmwareId
 * @property {string} name
 * @property {string} version
 * @property {string} storedFileNamePrev
 * @property {string} description
 * @property {import('@/types').ProductProperties} product
 * @property {TypeProps} type
 */

/**
 * @typedef {object} FirmwareFormProps
 * @property {boolean} disabled
 * @property {FirmwareV2} [firmware]
 * @property {import('react-hook-form').SubmitHandler<FirmwareFormFields>} onSubmit
 */

/**
 * @param {FirmwareFormProps} props
 */
export function FirmwareForm(props) {
  const { onSubmit, disabled, firmware } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const secretToken = useSelector(selectSecretToken);

  /** @type {import('react-hook-form').UseFormReturn<FirmwareFormFields>} */
  const form = useForm({
    mode: 'all',
    defaultValues: {
      firmwareId: uuidv4(),
      name: '',
      version: '',
      description: '',
      type: null,
      product: null,
      firmwareFile: null,
      storedFileNamePrev: '',
    },
  });

  const { watch } = form;

  const selectedProduct = watch('product');

  const { result: products, loading: productLoading } = useFullProductList();

  const [firmwareTypeOptions, setFirmwareTypeOptions] = useState([]);

  const getFirmwareTypesByProduct = useCallback(() => {
    if (!selectedProduct) return;
    const request = api.ac.v5.firmware.type.product.$productId(selectedProduct?.productId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request.process().then((res) => {
      const types = res.list?.map((item) => ({
        label: formatFirmwareName(item?.name),
        value: item?.id,
      }));
      setFirmwareTypeOptions(types);
    });
  }, [selectedProduct, setFirmwareTypeOptions, secretToken]);

  useEffect(() => {
    getFirmwareTypesByProduct();
  }, [getFirmwareTypesByProduct]);

  useEffect(() => {
    if (!firmware) return;
    form.setValue('firmwareId', firmware.firmwareId);
    form.setValue('name', firmware.name);
    form.setValue('version', firmware.version);
    form.setValue('description', firmware.description);

    form.setValue('storedFileNamePrev', firmware.storedFileName);
    if (products) {
      const selectedProduct = products?.find(
        (item) => item?.productId === firmware.properties.productId
      );
      // @ts-ignore
      form.setValue('product', selectedProduct);
      // form.setValue('type', { label: formatFirmwareName(firmware?.type), value: firmware.type });
    }
  }, [firmware, form, products]);

  const handleCancel = () => {
    navigate('/smarter-ai/firmwares');
  };

  /** @param {File} file */
  const handleFirmwareFileUpload = async (file) => {
    form.setValue('firmwareFile', file);
  };

  /** @param {FirmwareFormFields} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmit) return;
    data.name = trimString(data.name);
    data.version = trimString(data.version);
    data.description = trimString(data.description);
    return onSubmit(data);
  };

  return (
    <FormProvider {...form}>
      <Typography variant="body2" fontSize="1rem" mt={'20px'} mb={'5px'} fontWeight={500}>
        Firmware Details
      </Typography>
      <Divider />

      <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="name"
              label="Name *"
              placeholder="Enter a firmware name"
              rules={{ required: 'Name is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="version"
              label="Version *"
              placeholder="Enter a version"
              rules={{ required: 'Version is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomSelect
              name="product"
              label="Product *"
              loading={productLoading}
              disabled={disabled}
              options={products}
              getKey="productId"
              getLabel="productName"
              placeholder="Select a product"
              rules={{ required: 'Product is required' }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomSelect
              name="type"
              label="Type *"
              loading={productLoading}
              disabled={!watch('product')}
              options={firmwareTypeOptions}
              placeholder="Select a type"
              rules={{ required: 'Type is required' }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomInput
              name="description"
              label="Description"
              placeholder="Enter firmware description"
              minRows={3}
            />
          </Grid>
        </Grid>

        <FirmwareFileUpload
          name="firmwareFile"
          firmwareId={firmware?.firmwareId}
          onDropFile={handleFirmwareFileUpload}
          label="Upload File *"
          rules={{ required: 'This is required' }}
        />

        <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={10}>
          <Button variant="text" onClick={handleCancel}>
            {t(T['button.cancel'])}
          </Button>
          <Button disabled={disabled} variant="contained" type="submit">
            {disabled && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
            {firmware ? t(T['button.update']) : t(T['button.save'])}
          </Button>
        </Box>
      </form>
      <Box height="30px" />
    </FormProvider>
  );
}
