import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTIONS_ITEMS } from '@/web/operations/geofences/actions';
import { GEOFENCE_TABLE_COLUMNS } from '@/web/operations/geofences/columns';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

export function GeofenceListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteGeofences(selectedItems);
    }
    if (type === 'RELEASE') {
      await releaseGeofence(selectedItems[0]);
    }
    setTableReload(true);
  };

  const deleteGeofence = async (geofence) => {
    try {
      const request = api.ac.v3.geolocation.fence.$id(geofence.id).discontinue.$post({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      toastSuccess('Success', `Successfully discontinued ${geofence.name}`);
    } catch (e) {
      toastWarning('Error', `Failed to discontinue ${geofence.name}`);
    }
  };

  const deleteGeofences = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteGeofence));
  };

  const releaseGeofence = async (geofence) => {
    try {
      const request = api.ac.v3.geolocation.fence.$id(geofence.id).publish.$post({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      toastSuccess('Success', `Successfully released ${geofence.name}`);
    } catch (e) {
      toastWarning('Error', `Failed to release ${geofence.name}`);
    }
  };

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={'Geofence List'}
          actions={ACTIONS_ITEMS}
          onAction={onActionHandle}
        />
        <OffsetPagination
          key={generateUniqueKey([secretToken, 'geo_fence'])}
          fetcher={async ({ signal, limit, offset, searchText, searchType }) => {
            const request = api.ac.v3.geolocation.fence.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                limit: limit,
                offset,
                tenantId,
                ...(searchText !== '' && searchType && { [searchType]: searchText }),
              },
            });
            await request.process();
            return {
              data: request.result,
              count: Number(request.response.headers['x-total-count']),
            };
          }}
          renderList={(/** @type {Array<GeofenceResponseModel>} */ results) => (
            <ItemsResponsiveView results={results} columns={GEOFENCE_TABLE_COLUMNS} />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
