import api from '@/api';
import { CAMERA_OFF_ICON, CAMERA_ON_ICON } from '@/assets/constants/images';
import { selectSecretToken } from '@/store/auth';
import { formatCameraName, isCameraInParkingMode } from '@/utils/cameras';
import { BoxImage } from '@/web/@components/BoxImage';
import { LiveIcon } from '@/web/@components/LiveIcon';
import { LongTextShortener } from '@/web/@components/LongTextShortener';
import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {object} EndpointInfoProps
 * @property {EndpointDetailsResponse & {endpointId: number}} [endpoint]
 */

/**
 * @param {EndpointInfoProps} props
 */
export function EndpointInfo(props) {
  const { endpoint } = props;
  const navigate = useNavigate();

  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<{[key: string]: string}>} */
  const [capabilities, setCapabilities] = useState({});

  const isInParkingMode = useMemo(
    () => isCameraInParkingMode(capabilities?.parkingModeStatus),
    [capabilities?.parkingModeStatus]
  );

  useEffect(() => {
    if (!endpoint?.endpointId) return;
    const request = api.ac.endpoint.capabilities.$get({
      params: {
        secretToken,
        endpointId: Number(endpoint?.endpointId),
      },
    });
    request
      .process()
      .then(({ capabilities }) => {
        /** @type {{[key: string]: string}} */
        const result = {};
        capabilities.properties.forEach((item) => {
          result[item.key] = item.value;
        });
        result.parkingModeStatus = capabilities?.parkingModeStatus;
        setCapabilities(result);
      })
      .catch((err) => {
        console.error('Failed to fetch endpoint capabilities', err);
      });
  }, [secretToken, endpoint?.endpointId]);

  return (
    <Box sx={{ width: { xs: '100%', sm: '300px' }, height: { xs: '100%', md: '300px' } }}>
      <Box pt={1} display={'flex'} flexDirection={'row'} gap={'10px'}>
        <Box display="flex" flexDirection="column" gap="6px" alignItems="center" paddingTop={0.5}>
          <BoxImage
            src={endpoint?.endpointOnlineStatus ? CAMERA_ON_ICON : CAMERA_OFF_ICON}
            height="25px"
          />
          {!isInParkingMode && endpoint?.endpointOnlineStatus && (
            <LiveIcon onClick={() => navigate(`/cameras/live?id=${endpoint.endpointId}`)} />
          )}
        </Box>
        <Box>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            <LongTextShortener
              text={formatCameraName(endpoint.endpointName, endpoint.deviceSerialNumber)}
              length={45}
            ></LongTextShortener>
          </Typography>
          <Box fontSize={'0.925em'} display="flex" gap={1}>
            {isInParkingMode && (
              <Typography variant="subtitle2" mr="-5px">
                Parked,
              </Typography>
            )}

            <Typography variant="subtitle2">
              {endpoint?.endpointOnlineStatus ? 'Online' : 'Offline'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
