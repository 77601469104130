import { BoxImage } from '@/web/@components/BoxImage';
import { DriverImageNotAvailable } from '@/web/fleets/drivers/@components/DriverImageNotAvailable';
import { Box, CircularProgress, Typography } from '@mui/material';
import { isNull } from 'lodash';

/**
 * @typedef {object} DriverInfoProps
 * @property {Driver} [driver]
 */

/**
 * @param {DriverInfoProps} props
 */
export function DriverInfo(props) {
  const { driver } = props;

  return (
    <Box>
      <BoxImage
        loading="eager"
        alt={'driver photo'}
        src={driver?.pictureUrl}
        objectFit="cover"
        objectPosition="center"
        sx={{
          height: '300px',
          width: { xs: '100%', sm: '300px' },
          borderRadius: '8px',
        }}
        fallback={
          <Box
            mb={0.5}
            width={{ xs: '100%', sm: '300px' }}
            height="300px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            bgcolor="#F5F5F5"
            gap="5px"
            borderRadius="8px"
          >
            {!isNull(driver?.pictureUrl) ? <CircularProgress /> : <DriverImageNotAvailable />}
          </Box>
        }
      />

      <Box pt={1}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {driver?.firstName} {driver?.lastName}
        </Typography>
        <Typography variant="subtitle2">Driver</Typography>
      </Box>
    </Box>
  );
}
