import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useFormContext } from 'react-hook-form';

export function GeofenceDateRangePicker() {
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));

  const { getValues, setValue } = useFormContext();

  const startTimestamp = useMemo(() => getValues('startTimestamp'), [getValues]);
  const endTimestamp = useMemo(() => getValues('endTimestamp'), [getValues]);

  /** @type {import('react-date-range').Range} */
  const defaultRange = useMemo(
    () => ({
      key: 'selection',
      endDate: endTimestamp ? new Date(endTimestamp) : new Date(),
      startDate: startTimestamp ? new Date(startTimestamp) : new Date(),
    }),
    [startTimestamp, endTimestamp]
  );

  const [ranges, setRanges] = useState([defaultRange]);

  useEffect(() => {
    setRanges([defaultRange]);
  }, [defaultRange]);

  /** @param {import('react-date-range').RangeKeyDict} item */
  const handleChange = (item) => {
    setRanges([item.selection]);
    const { startDate, endDate } = item.selection;
    setValue('startTimestamp', startOfTheDay(startDate));
    setValue('endTimestamp', endOfTheDay(endDate));
  };

  return (
    <Box sx={{ '& .rdrMonths': { flexWrap: 'wrap' } }}>
      <DateRange
        ranges={ranges}
        onChange={handleChange}
        months={mdAndUp ? 2 : 1}
        preventSnapRefocus
        editableDateInputs
        displayMode="dateRange"
        calendarFocus="backwards"
        direction={mdAndUp ? 'horizontal' : 'vertical'}
        showDateDisplay={false} // top date preview
        showMonthAndYearPickers={false} // month, year selection manual
        weekdayDisplayFormat="EEEEE"
        monthDisplayFormat="MMMM yyyy"
        rangeColors={[theme.palette.primary.main]}
        minDate={new Date()}
      />
    </Box>
  );
}
