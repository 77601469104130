/**
 * @typedef {object} ScoreCircularChart
 * @property {number} [value]
 * @property {number} [prevScore]
 * @property {string} [height]
 */

import { useMemo } from 'react';

/**
 * @param {ScoreCircularChart} props
 */
export function ScoreCircularChart(props) {
  const { value = 0, prevScore = 0, height } = props;

  const innerRadius = 82;
  const innerPerimeter = 2 * innerRadius * Math.PI;
  const innerValues = [0.84 * 1, 0.84 * (value / 100)];
  const innerColors = ['#A9B5C6', '#2680EB'];

  // Calculate center of the ellipse
  const ellipseCenterX = 100;
  const ellipseCenterY = 100;

  const percentage = useMemo(() => {
    const percentageChange = ((value - prevScore) / Math.abs(prevScore)) * 100;
    return isFinite(percentageChange) ? percentageChange : 0;
  }, [value, prevScore]);

  function formatNumberWithSign(number) {
    const roundedNumber = Math.round(number);
    const sign = roundedNumber >= 0 ? '+' : '';
    return `${sign}${roundedNumber}`;
  }

  return (
    <svg
      width="100%"
      height={height || '100%'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
    >
      <g>
        {innerValues.map((x, i) => (
          <ellipse
            key={i}
            cx={ellipseCenterX}
            cy={ellipseCenterY}
            rx={innerRadius}
            ry={innerRadius}
            fill="none"
            strokeWidth="12"
            strokeLinecap="round"
            stroke={innerColors[i]}
            strokeDasharray={`${x * innerPerimeter} ${(1 - x) * innerPerimeter}`}
            transform="translate(236 62) rotate(119)"
            opacity={value > 0 ? 1 : 0.4}
            filter={value > 0 ? '' : 'grayscale(80%)'}
          />
        ))}

        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={50}
          fontWeight={600}
          fill="#213251"
          opacity={value > 0 ? 1 : 0.6}
          filter={value > 0 ? '' : 'grayscale(80%)'}
        >
          {Math.round(value)}
        </text>

        {percentage !== 0 ? (
          <>
            <path
              d={percentage > 0 ? 'M-10,8L10,8L0,-8Z' : 'M-10,-8L10,-8L0,8Z'}
              transform="translate(85 140)"
              fill={percentage > 0 ? '#008000' : '#ff0000'}
            />
            <text
              x="58%"
              y="70%"
              dominantBaseline="middle"
              textAnchor="middle"
              fill="#8892a1"
              fontSize="17px"
            >
              {formatNumberWithSign(percentage)}
            </text>
          </>
        ) : null}
      </g>
    </svg>
  );
}
