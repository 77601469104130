import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { generateUniqueKey } from '@/utils/table';
import { FilterContext } from '@/web/@components/FilterContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { InfiniteScrollTableView } from '@/web/@components/InfiniteScrollView/InfiniteScrollTableView';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { subDays } from 'date-fns';
import { isUndefined, uniq } from 'lodash';
import qs from 'qs';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { ONBOARD_LOG_TABLE_COLUMNS } from './columns';

export function OnboardLogsTable() {
  const tenantId = useSelector(selectTenantId);
  const { searchText, searchType, startTime, endTime } = useContext(FilterContext);

  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => qs.parse(location.search.substring(1)), [location.search]);

  const users = [];

  const timestamps = {
    fromTimestamp: startTime,
    toTimestamp: endTime,
  };

  // SET 7 days initial duration
  useEffect(() => {
    if (isUndefined(query.to) || isUndefined(query.from)) {
      const date = new Date();
      const params = { from: startOfTheDay(subDays(date, 7)), to: endOfTheDay(date) };
      const options = {
        pathname: '/smarter-ai/onboard-logs',
        // @ts-ignore
        search: `?${createSearchParams(params)}`,
      };
      navigate(options, { replace: true });
    }
  }, [query, navigate]);

  return (
    <Box mt={2} height="90%">
      <PaginatedTableContextProvider>
        <InfiniteScrollView
          key={generateUniqueKey([tenantId, searchText, searchType, startTime, endTime])}
          initialToken={''}
          fullView={false}
          itemsPerPage={20}
          fetcher={async ({ signal, limit, token }) => {
            const secretToken = selectSecretToken(store.getState());
            const request = api.ac.v3.report.onboard.log.list.$get({
              signal,
              // @ts-ignore
              params: {
                secretToken,
                pageSize: limit,
                tenantId,
                lastItemTieBreak: token ? token.sortTieBreak : '',
                lastItemTimestamp: token ? token.reportingTimestamp : '',
                onboardingType: !searchType || searchType === 'ALL' ? '' : searchType,
                ...(startTime && timestamps),
              },
            });

            await request.process();
            const result = request.result.onboardLogList;
            const lastItem = result[result?.length - 1];

            const userIds = result.map((log) => log.userId);

            await Promise.all(
              uniq(userIds).map(async (userId) => {
                const isExists = users.some((item) => item.id === Number(userId));
                if (isExists) return;
                const req = api.ac.v1.auth.user.get.user.$get({
                  params: {
                    secretToken,
                    userId: Number(userId),
                  },
                });
                await req.process();
                users.push(req.result);
              })
            );

            const mappedData = result.map((item, index) => {
              const user = users.find((user) => user.id === Number(item.userId));
              return {
                ...item,
                ...{ userName: user?.name },
              };
            });

            return { result: mappedData, token: lastItem };
          }}
          renderEmpty={() => (
            <IconMessageBox
              size="256px"
              src="/images/player/no-data-icon.svg"
              message="No Data Found"
            />
          )}
          renderList={(state) => (
            <InfiniteScrollTableView
              state={state}
              columns={ONBOARD_LOG_TABLE_COLUMNS}
              sx={{ height: 'calc(100vh - 200px)' }}
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
