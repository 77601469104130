import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/smarter-ai/labels/actions';
import { LABEL_TABLE_COLUMNS } from '@/web/smarter-ai/labels/columns';
import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export function LabelListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { t } = useTranslation();

  const [pageRefresh, setPageRefresh] = useState(true);
  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<TriggerClassification>['onAction']} */
  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteLabels(selectedItems);
    }
    setTableReload(true);
    setPageRefresh(true);
  };

  const deleteLabel = async (label) => {
    try {
      const request = api.ac.v1.tenant.trigger.classification.discontinue.$post({
        params: {
          secretToken,
          label: label.label,
          type: label.type,
        },
      });
      await request.process();
      toastSuccess('Success', t(T['label.delete.success'], { name: label.displayName }));
    } catch (e) {
      toastWarning('Error', t(T['label.delete.failed'], { name: label.displayName }));
    }
  };

  const deleteLabels = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteLabel));
  };

  const fetchAILabels = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      setLoading(true);
      setError(null);
      try {
        let conToken = '';
        const limit = 500;
        /** @type {Array<TriggerClassification>} */
        const results = [];
        while (!signal.aborted) {
          const request = api.ac.v1.tenant.trigger.classifications.$get({
            signal,
            params: {
              secretToken,
              pageSize: limit,
              continuationToken: conToken,
              tenantId,
            },
          });
          await request.process();
          const result = request.result.data;
          conToken = request.result.continuationToken;
          const filteredData = result.filter((item) => item.publicationStatus !== 'DISCONTINUED');
          results.push(...filteredData);
          if (result.length < limit) break;
        }
        setLabels(results);
      } catch (ex) {
        setError(null);
      } finally {
        setLoading(false);
        setPageRefresh(false);
      }
    },
    [tenantId, secretToken]
  );

  useEffect(() => {
    if (!pageRefresh) return;
    const aborter = new AbortController();
    fetchAILabels(aborter.signal);
    return () => aborter.abort();
  }, [fetchAILabels, pageRefresh]);

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch Trigger classifications');
  }

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={t(T['labels.table.title'])}
          actions={ACTION_ITEMS}
          onAction={onActionHandle}
        />
        <ContinuationTokenTable
          results={labels}
          loading={loading}
          columns={LABEL_TABLE_COLUMNS}
          onReload={() => {
            setPageRefresh(true);
          }}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
