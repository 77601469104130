import { CustomDateRangePicker } from '@/web/@components/CustomDateRangePicker';
import { SetTripFilterContext, TripFilterContext } from '@/web/trips/@components/TripFilterContext';
import { useContext } from 'react';

/**
 * @param {Omit<import('react-date-range').DateRangeProps, 'onChange'>} props
 */
export function TripDateRangePicker(props) {
  const setFilter = useContext(SetTripFilterContext);
  const { startTime, endTime } = useContext(TripFilterContext);
  return (
    <CustomDateRangePicker
      {...props}
      startTime={startTime}
      endTime={endTime}
      onChange={setFilter}
    />
  );
}
