import { BoxImage } from '@/web/@components/BoxImage';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

/**
 * @typedef {object} LogoImagePropsBase
 * @property {string} [src]
 * @property {string} [href]
 * @property {string} [fallbackSrc]
 * @property {import('react').CSSProperties['height']} [size]
 * @property {import('@mui/material').SxProps<import('@mui/material').Theme>} [sx]
 */

/**
 * @typedef {LogoImagePropsBase & Partial<import('react-router-dom').LinkProps>} LogoImageProps
 */

/** @param {LogoImageProps} props */
export function LogoImage(props) {
  const { src, fallbackSrc = '/images/layouts/icon.svg', href, to, sx, size, ...linkProps } = props;

  return (
    <Link
      component={RouterLink}
      {...linkProps}
      to={href || to || '.'}
      reloadDocument={Boolean(href || !to)}
      sx={{
        userSelect: 'none',
        textAlign: 'center',
        ...sx,
      }}
    >
      <BoxImage
        src={src}
        width="100%"
        height={size || '50px'}
        fallbackSrc={fallbackSrc}
        alt="SmarterAI Logo"
      />
    </Link>
  );
}
