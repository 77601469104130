import { resources } from '@/assets/locales';
import { isProduction } from '@/config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/** @param {import('@mui/material').BoxProps} props */
export function LanguageSelection(props) {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.languages[0]);

  if (isProduction) return null;

  /** @type {import('react').ChangeEventHandler<HTMLSelectElement>} */
  const handleLangChange = (e) => {
    const lang = e.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <select
      value={language}
      onChange={handleLangChange}
      style={{ border: 'none', fontSize: '12px', color: '#5a6c83' }}
    >
      {Object.keys(resources).map((name) => (
        <option key={name} value={name}>
          {name}
        </option>
      ))}
    </select>
  );
}
