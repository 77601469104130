import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LabelForm } from '../@components/LabelForm';
import { v4 as uuidv4 } from 'uuid';

export function CreateLabelPage() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  /** @type {import('../@components/LabelForm').LabelFormProps['onSubmitData']} */
  const createLabel = async (form) => {
    const {
      description,
      displayName,
      inputStepSize,
      max,
      min,
      unit,
      triggerThresholdInputType,
      name,
      type,
    } = form;
    try {
      setLoading(true);
      const req = await api.ac.v1.tenant.trigger.classification.$post({
        data: {
          label: uuidv4(),
          unit: unit.value,
          triggerThresholdInputType: triggerThresholdInputType.value,
          name,
          min,
          max,
          type: type.value,
          inputStepSize,
          displayName,
          description,
        },
        params: {
          secretToken,
          tenantId,
        },
      });
      await req.process();
      toastSuccess('Success', t(T['label.create.success'], { name }));
      navigate('/smarter-ai/labels');
    } catch (err) {
      toastWarning('Error', t(T['label.create.retry']));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['label.create.page.title'])}
      </Typography>
      <LabelForm onSubmitData={createLabel} isLoading={loading} />
    </Box>
  );
}
