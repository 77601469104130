import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CustomLogger } from '@/utils/logger';
import { collapseDecimal } from '@/utils/sensors/extractor';
import { parseObject } from '@/web/administration/device-groups/_id/cameras/manage-cameras/@utils';
import { History } from '@mui/icons-material';
import { CircularProgress, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const logger = new CustomLogger('CameraDowntime');

/**
 * @param {{trip: TripDetailsResponse}} props
 */
export function CameraDowntime(props) {
  const { trip } = props;

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<string>} */
  const [downPercent, setDownPercent] = useState(null);

  useEffect(() => {
    logger.log(trip.endpointName);
    const aborter = new AbortController();
    /** @type {Array<{time: number, uptime: number}>} */
    const results = [];
    const process = async () => {
      const limit = 100;
      let continuationToken = '';
      while (true) {
        const request = api.ac.v3.report.device.records.$get({
          signal: aborter.signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            tenantId,
            secretToken,
            pageSize: limit,
            subtype: 'uptime',
            continuationToken,
            endpointId: trip.endpointId,
            startTime: '0',
            endTime: '' + (trip?.endTimestamp ? trip?.endTimestamp + 10 * 60 * 1000 : Date.now()),
          },
        });
        const result = await request.process();
        const records = result?.data || [];
        continuationToken = result?.continuationToken;
        for (const item of records) {
          const time = Number(item.reportingTime);
          const uptime = Number(parseObject(item.properties)?.uptime) || 0;
          if (!time || !uptime) break;
          results.push({ time, uptime });
          if (time < trip.startTimestamp) break;
        }
        if (!continuationToken || records.length < limit) break;
        const lastTime = Number(records[records.length - 1].reportingTime);
        if (!lastTime || lastTime < trip.startTimestamp) break;
      }
      logger.log('number of records:', results.length);
      if (results.length < 2) return;
      results.sort((a, b) => a.time - b.time);

      let downTime = 0;
      let totalTime = 0;
      for (let i = 1; i < results.length; ++i) {
        const a = results[i - 1];
        const b = results[i];
        if (b.time < trip.startTimestamp || a.time > trip.endTimestamp) continue;
        const timeDelta = Math.round((b.time - a.time) / 1000);
        totalTime += timeDelta;
        logger.log(
          ' | time diff = ',
          timeDelta,
          'uptime diff =',
          b.uptime - a.uptime,
          ' | current uptime = ',
          b.uptime
        );
        if (timeDelta <= b.uptime) continue;
        downTime += timeDelta - b.uptime;
      }
      logger.log('downtime =', downTime, ' | total time = ', totalTime);
      const downPercent = collapseDecimal((100 * downTime) / totalTime, 0);
      setDownPercent(downPercent + '%');
    };
    const tid = setTimeout(() => {
      setLoading(true);
      process()
        .catch(logger.error)
        .finally(() => setLoading(false));
    }, 1500);
    return () => {
      aborter.abort();
      clearTimeout(tid);
    };
  }, [tenantId, secretToken, trip]);

  return (
    <Paper
      style={{
        zIndex: 100,
        position: 'absolute',
        bottom: '5px',
        left: '6px',
        fontWeight: 500,
        fontSize: 12,
        padding: '5px 10px',
        background: '#fff',
      }}
    >
      <span>
        {loading ? <CircularProgress size="16px" /> : <History style={{ fontSize: '18px' }} />}
      </span>{' '}
      <span>
        Downtime: <b>{downPercent || '--'}</b>
      </span>
    </Paper>
  );
}
