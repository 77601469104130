import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

export function TriggerCloneDialog(props) {
  const { onClose, onSubmit, name, isCloning } = props;

  const [triggerName, setTriggerName] = useState(`Clone - ${name}`);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleOnClone = () => {
    onSubmit && onSubmit(triggerName);
  };

  return (
    <Dialog
      fullWidth
      open={true}
      keepMounted
      disableEscapeKeyDown={true}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Trigger Clone</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="subtitle2">New Trigger Name</Typography>
        <TextField
          required
          disabled={isCloning}
          sx={{ width: '100%' }}
          value={triggerName}
          onChange={(e) => {
            setTriggerName(e.currentTarget.value);
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} disabled={isCloning}>
          Cancel
        </Button>
        <Button
          onClick={handleOnClone}
          autoFocus
          variant="contained"
          disabled={isCloning}
          endIcon={isCloning && <CircularProgress size={15} color="primary" />}
        >
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  );
}
