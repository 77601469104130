import { Box, CircularProgress, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { useContext } from 'react';
import { TripContext } from '../TripContext';

export function TripsCountHeader() {
  const { totalTrips } = useContext(TripContext);
  return (
    <Box>
      {!isNumber(totalTrips) ? (
        <CircularProgress size="16px" />
      ) : (
        <Box component="span" fontWeight="medium">
          {totalTrips}
        </Box>
      )}
      <Typography component="span" ml="5px" variant="subtitle2">
        Trips
      </Typography>
    </Box>
  );
}
