import { DRIVER_CONTAINED_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { PageLink } from '@/web/@components/PageLink';
import { DriverImageNotAvailable } from '@/web/fleets/drivers/@components/DriverImageNotAvailable';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScoreMiniWidget } from '../ScoreMiniWidget';

/**
 * @typedef {object} DriverSafetyGridListItem
 * @property {Driver} [driver]
 * @property {Number} [score]
 */

/**
 * @param {DriverSafetyGridListItem} props
 */
export function DriverSafetyGridListItem(props) {
  const { driver, score } = props;
  const navigate = useNavigate();

  const driverScore = useMemo(() => (score ? Math.round(score) : null), [score]);

  return (
    <>
      <BoxImage
        width="100%"
        loading="eager"
        alt={'driver photo'}
        src={driver?.pictureUrl}
        objectFit="cover"
        objectPosition="center"
        sx={{ height: { xs: '350px', md: '270px' }, cursor: 'pointer' }}
        onClick={() => navigate(`/safety/scoring/drivers/${driver?.driverId}`)}
        fallback={
          <Box
            mb={0.5}
            width="100%"
            height="270px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            bgcolor="#F5F5F5"
            gap="5px"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(`/safety/scoring/drivers/${driver?.driverId}`)}
          >
            <DriverImageNotAvailable />
          </Box>
        }
      />
      <Box
        sx={{ display: 'flex !important', justifyContent: 'space-between', alignItems: 'center' }}
        pt={'4px'}
        style={{
          width: '100%',
          display: 'table',
          tableLayout: 'fixed',
        }}
      >
        <IconLabelItem
          mt="5px"
          src={DRIVER_CONTAINED_ICON}
          fontWeight={600}
          fontSize={'14px'}
          title={
            <PageLink to={`/safety/scoring/drivers/${driver?.driverId}`}>
              <Typography title={driver?.firstName + ' ' + driver?.lastName}>
                {driver?.firstName + ' ' + driver?.lastName}
              </Typography>
            </PageLink>
          }
        />
        <Box width="28px" height="28px" position="relative">
          <ScoreMiniWidget value={driverScore} />
          <Typography
            position="absolute"
            left={5}
            right={5}
            top={1}
            bottom={0}
            textAlign={'center'}
            borderRadius="50%"
            lineHeight={driverScore ? '28px' : '20px'}
            fontWeight={600}
            fontSize={Math.round(driverScore) > 99 ? '10px' : '12px'}
          >
            {Math.round(driverScore) || '...'}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
