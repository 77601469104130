/**
 * Human readable Time difference
 * @param {number} startTimestamp
 * @param {number} endTimestamp
 * @param {number} dayTimeStart
 * @param {number} dayTimeEnd
 * @returns
 */

export function humanReadableTimeDiff(startTimestamp, endTimestamp, dayTimeStart, dayTimeEnd) {
  const dayDiff = Math.round((endTimestamp - startTimestamp) / (1000 * 60 * 60 * 24));
  let timeDiff = dayTimeEnd - dayTimeStart + 1;
  const hours = Math.floor(timeDiff / 3600);
  timeDiff %= 3600;
  const minutes = Math.floor(timeDiff / 60);
  return startTimestamp && dayTimeEnd !== null
    ? `${dayDiff} days, ${hours > 0 ? hours + ' hours' : ''} ${
        minutes > 0 ? minutes + ' minutes' : ''
      } per day`
    : '';
}

/**
 * Convert Day Time to seconds
 * @param {string} duration
 * @returns
 */

export function convertTimeToSeconds(duration) {
  if (!duration) return 0;
  const [hours = 0, minutes = 0, seconds = 0] = duration.split(':');
  return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
}
