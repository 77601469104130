import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ProductForm } from '../@components/ProductForm';

export function CreateProductPage() {
  const navigate = useNavigate();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [submitting, setSubmitting] = useState(false);

  /** @param {import('../@components/ProductForm').ProductFormFields} form */
  const handleSubmit = async (form) => {
    const productId = uuidv4();
    const request = api.ac.v1.tenant.product.$post({
      params: {
        tenantId,
        secretToken,
        productId,
      },
      data: {
        ownerTenantId: tenantId,
        inputs: form.inputSources,
        firmwareTypes: form.firmwareTypes,
        aiAccelerators: [form.aiAccelerator?.codeName || 'NONE'],
        tenantInfoList: [{ role: 'Owner', tenantId }],
        properties: [
          {
            key: 'productId',
            value: productId + '',
          },
          {
            key: 'productName',
            value: form.name + '',
          },
          {
            key: 'serial',
            value: form.serial + '',
          },
          {
            key: 'cpu',
            value: form.cpu || '',
          },
          {
            key: 'gpu',
            value: form.gpu || '',
          },
          {
            key: 'npu',
            value: form.npu || '',
          },
          {
            key: 'ram',
            value: form.ram || '',
          },
          {
            key: 'storage',
            value: form.storage || '',
          },
          {
            key: 'wifi',
            value: form.wifi + '',
          },
          {
            key: 'bluetooth',
            value: form.bluetooth + '',
          },
          {
            key: 'ethernet',
            value: form.ethernet + '',
          },
          {
            key: 'cellular',
            value: form.cellular + '',
          },
          {
            key: 'website',
            value: form.website || '',
          },
          {
            key: 'manual',
            value: form.manual || '',
          },
          {
            key: 'description',
            value: form.description || '',
          },
        ],
      },
    });
    try {
      setSubmitting(true);
      await request.process();
      toastSuccess('Success', 'New product created');
      navigate('..');
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Could not create the product');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Create Product
      </Typography>
      <ProductForm onSubmit={handleSubmit} disabled={submitting} />
    </Box>
  );
}
