import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomInput } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const defaultValues = {
  description: '',
  packageId: '',
  secret: '',
};

/**
 * @typedef {object} DeviceBatchFormPropsBase
 * @property {import('react-hook-form').SubmitHandler<typeof defaultValues>} onSubmitData
 * @property {boolean} isLoading
 */

/**
 * @typedef {DeviceBatchFormPropsBase & import('@mui/material').BoxProps} DeviceBatchFormProps
 */

/**
 * @param {DeviceBatchFormProps} props
 */
export function DeviceBatchForm(props) {
  const { onSubmitData, isLoading, ...extra } = props;

  const secretToken = useSelector(selectSecretToken);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const batchId = useMemo(() => params.id, [params]);

  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  useEffect(() => {
    if (!batchId) return;
    setLoading(true);
    const req = api.ac.v5['device-batch'].$id(batchId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    req.process().then((result) => {
      const { description, packageId, secret } = result;
      form.setValue('description', description);
      form.setValue('packageId', packageId);
      form.setValue('secret', secret);
      setLoading(false);
    });
    return () => req.abort();
  }, [batchId, secretToken, form]);

  /** @param {typeof defaultValues} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;
    data.description = trimString(data.description);
    onSubmitData(data);
  };

  const handleCancel = () => {
    navigate('/smarter-ai/device-batches');
  };

  if (loading)
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );

  return (
    <Box {...extra}>
      <Typography
        variant="body2"
        fontSize="1rem"
        mt={'20px'}
        mb={'10px'}
        sx={{ color: ' #0B2547', opacity: 0.68, fontSize: '16px' }}
      >
        {t(T['device-batch.form.details'])}
      </Typography>
      <Divider />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="description"
                label={t(T['device-batch.form.name.title'])}
                placeholder={t(T['device-batch.form.name.placeholder'])}
                rules={{ required: t(T['device-batch.form.name.required']) }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="packageId"
                label={t(T['device-batch.form.packageId.title'])}
                placeholder={t(T['device-batch.form.packageId.placeholder'])}
                rules={{ required: t(T['device-batch.form.packageId.required']) }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {!batchId && (
                <CustomInput
                  name="secret"
                  label={t(T['device-batch.form.secret.title'])}
                  placeholder={t(T['device-batch.form.secret.placeholder'])}
                  rules={{ required: t(T['device-batch.form.secret.required']) }}
                />
              )}
            </Grid>
          </Grid>

          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={10}>
            <Button variant="text" onClick={handleCancel}>
              {t(T['button.cancel'])}
            </Button>
            <Button disabled={isLoading} variant="contained" type="submit">
              {isLoading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              {batchId ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
